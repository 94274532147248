import   {$postThird } from '@/utils/request';


export function uploadVideo(file, onUploadProgress) {
    
    
    const formData = new FormData();
    formData.append('video', file);

    //xxl post third
    return $postThird('/upVideo', formData, {
        headers: {
            'Content-Type': 'application/octet-stream',
            accessKey: 'uptick',
            accessKeySecret: 'AF0F59F1EEC1728CCFCD5B7B6203D229'
        },
        onUploadProgress
    })
}

// export let getNftVideo = (parms) => {
// 	return $postThird('/video', `hash=${parms.hash}&size=${parms.size}`, {
// 		headers: {
// 			accessKey: 'uptick',
// 			accessKeySecret: 'AF0F59F1EEC1728CCFCD5B7B6203D229',
// 		},
// 	});
// };

//视频显示
export function getNftVideo(hash){

    return 'https://d3i65oqeoaoxhj.cloudfront.net/' + hash + '/original'

};
