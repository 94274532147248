<template>
   <div class="top" :class="{strcky:!show}" >
      <v-expand-transition>
         <v-main tag="div" class="drawer-nav" :elevation="3" v-if="value" absolute>
         
            <template v-if="navOpen">
              <v-container
                class="d-flex flex-column py-0 pb-12 pb-lg-0"
                style="height: 100%"
              >
                <DropSelectNav  @input="close" :type="navType"/>
              </v-container>
            </template>
         </v-main>
      </v-expand-transition>
      <v-app-bar
          app
          absolute
          hide-on-scroll
          :scroll-target="`#${containerId}`"
          :fixed="value"
          class="pa-0"
          color="#fff"
          :class="{darkBackground:this.$vuetify.theme.dark}"
          :elevation="0"
          :height="height"
      >
      <!-- 1380px -->
         <v-container  style=" max-width: 1920px;">  
        
            <div  class="d-flex align-center py-0  px-lg-0">
               <WhiteLogo height="43px" :simple="isMobile" style="margin-right: 15px;" :class="{mobileLogo:isMobile}"  v-if="this.$vuetify.theme.dark"/>
               <Logo height="43px" :simple="isMobile" style="margin-right: 15px;" :class="{mobileLogo:isMobile}" v-else/>
      
               
           <!-- <ChainSelect class="ml-lg-7" :class="{mobileChain:isMobile}"/> -->
            <!-- <LangSelect class="ml-lg-2 langselect" style="z-index: 202;" :class="{mobileLang:isMobile}" :isDrak="$vuetify.theme.dark"/> -->
             <MarketSelect class="ml-lg-2 langselect" style="z-index: 202;" :class="{mobileLang:isMobile}" :isDrak="$vuetify.theme.dark" />
            
            <Pool class="ml-lg-2" :class="{ mobileLang: isMobile }" v-if="isShowPool == 'true'"/>
            <img v-lazy="darkImg" v-if="!$vuetify.theme.dark" class="darkimg ml-lg-4 ml-0 mr-lg-0 mr-2" alt="" @click="darkMode">
             <img v-lazy="normalImg" v-else class="darkimg ml-lg-4 ml-0 mr-lg-0 mr-2" alt="" @click="darkMode">
            <!-- <v-switch v-model="isDark" @click="clickMode">Dark</v-switch> -->
            <v-spacer></v-spacer>
            <!-- <SearchInput style="width: 250px" v-if="!isMobile"/> -->
            <template v-if="!isIpad">
               
               <div v-if="user" class="mr-lg-7 mr-3">
                   <UserCenterSelect class="ml-lg-4 ml-1" v-if="user" @click="UserCenterClick" v-show="navOpen!=2" :isdarks="$vuetify.theme.dark" />
                  <v-btn icon class="primary" v-if="userCenterOpen" @click="close()">
                     <v-icon color="#fff">mdi-close</v-icon>
                  </v-btn>
                  <!-- bug 576 隐藏头像 -->
                  <!-- <v-avatar v-else @click="open()">
                     <v-img
                         src="https://cdn.vuetifyjs.com/images/john.jpg"
                         alt="Midoriya"
                     />
                  </v-avatar> -->
               </div>
               <template>
                  <v-btn icon  v-if="navOpen==1" @click="close()">
                     <v-icon >mdi-close</v-icon>
                      
                  </v-btn>
                  <v-btn icon v-else @click="open()">
                     <v-icon >mdi-menu }</v-icon> 
                    
                  </v-btn>
               </template>
            </template>
            <template v-else>
               <Nav/>
               <v-btn class="uc creatbtn ml-3 "   v-if="showCreate" @click="toCreateBtn" rounded depressed>{{ $t('headerBtnCreate') }}</v-btn>
                <v-btn icon class="primary ml-2" v-if="navOpen==2" @click="close">
                     <v-icon color="#fff">mdi-close</v-icon>
                  </v-btn>
               <UserCenterSelect class="ml-4" v-if="user" @click="UserCenterClick" v-show="navOpen!=2" :darkSelect="$vuetify.theme.dark" />
               <v-btn
                   v-else
                   class="ml-4 "
                   rounded
                   depressed
                   style="background-color: #270645 ; color: #ffffff;"
                   :class="{grayBackground:$vuetify.theme.dark}" 
                   @click="connectWallet"
               >{{ $t('headerBtnConnectWallet') }}
               </v-btn>
                <DownloadPortal :isDrak="$vuetify.theme.dark" v-if="isShowIcon" />
            </template>
            </div>
            <!-- <SearchInput v-if="isMobile" class="mt-9"/> -->
         </v-container>
      </v-app-bar>
      <v-dialog v-model="openWallet">
         <wallet :keyChain="keyChain" @getOpen="Open" @getStatus="Status"></wallet>
      </v-dialog>
       <v-dialog v-model="openCreateChoose">
         <createChoose  @getOpen="OpenCreateChoose"></createChoose>
      </v-dialog>
         <!-- 被投诉提醒框 -->
    <v-dialog v-model="Banprompt">
      <banprompt v-if="Banprompt"  @getOpenBan="openBanprompt"></banprompt>
    </v-dialog>
      <v-snackbar
          v-model="isShow"
          color="error"
          centered
          timeout="2000"
          style="opacity: 0.8;"
      ><span style="color:white;font-size:20px;">{{title}}</span></v-snackbar>
   </div>
</template>

<script lang="js">
   import Logo from '@/components/logo/index.vue';
   import WhiteLogo from '@/components/logo/whiteLogo.vue';
   import Nav from './components/Nav.vue';
    import Notice from './components/Notice.vue';
   import ChainSelect from './components/ChainSelect.vue';
   import LangSelect from './components/LangSelect.vue';
   import MarketSelect from './components/marketSelect.vue';
   
   import UserCenterSelect from './components/UserCenterSelect.vue';
   import DropSelectNav from './components/DropSelectNav.vue';
   import Wallet from "../components/popup/wallet";
   import CreateChoose from "../components/popup/Create";
   import api from "@/api";
   import Banprompt from "@/components/popup/banprompt.vue";
   import DownloadPortal from './components/DownloadPortal.vue';
   import Pool from './components/pool.vue';

   import SearchInput from '@/components/SearchInput.vue'
   import { connectWallets as walletConnect, events } from "@/walletConnectU";
   import { isMatamasklogin } from "@/metaMask/wallet.js";
   import { initProvider} from "@/walletConnectU";


   export default {
      name: 'Header',
      components: {Wallet, Logo,WhiteLogo, Nav, ChainSelect,Pool,  LangSelect, UserCenterSelect, DropSelectNav, SearchInput,CreateChoose,Banprompt,DownloadPortal,Notice,MarketSelect },
      props: {
         value: Boolean,
         containerId: String
      },
      data: function () {
         return {
            Banprompt:false,
            openWallet: false,
            navOpen: 0,
            userCenterOpen: false,
            isShow:false,
            title:'',
            user: null,
			   keyChain: "IRIS",
            showCreate: false,
            openCreateChoose:false,
            isShowPool:'false',
            isLoginIn:false,
            navType: "head",
            isIpad:false,
            isDark:false,
            isShowIcon:true,
            isCookies:true,
            darkImg:require('@/assets/darkimage/icon_night.png'),
            normalImg:require('@/assets/darkimage/icon_dawn.png'),
            isWalletConnect:false
           
         };
      },
     
      computed: {
         isMobile: function () {
            return this.$vuetify.breakpoint.mobile;
         },
         height: function () {
            if ((this.$route.name === 'Auctionlist' || this.$route.name === 'Marketplace') && this.isMobile) {
               return 100
            }
            return  100;
         },
         show: function () {
        return this.$route.name === 'EditSpotlight' || this.$route.name === 'Spotlight'
     },
      },
       beforeDestroy () {
      if (typeof window === 'undefined') return

      window.removeEventListener('resize', this.onResize, { passive: true })
    },
	  async mounted() {  
      debugger

      // 监听wallectConnect 账户切换
      const data = localStorage.getItem("walletconnect");
    if(!data)
    {
        this.isWalletConnect = false
    } else{
       this. isWalletConnect = JSON.parse(data).connected
    }
    if(this.isWalletConnect){
      await initProvider();
      events.$on("connect", res => {
        if(res){

         console.log("wxl ---- accountsChanged",res);
//       let address = accounts[0];
        let did = res
        this.$store.commit('SET_DID', did);
       let address = did.toLowerCase();
        window.bscAddress = address;
        localStorage.setItem("key_user", JSON.stringify({ user: true, did: this.$store.state.did, bscAddress: address }));
        
        location.reload();
        }
      });
    }
    
       
  
      
        isMatamasklogin();
           // 初始化
    let currentChain = `${this.$env.VUE_APP_MARKET_TYPE}`
    this.srcName = currentChain
    if(this.srcName == 'UPTICK'){
      this.isShowIcon = true
    }else if(this.srcName == 'BSC' || this.srcName == 'POLYGON' ||currentChain ==  'ARBITRUM'){
      this.isShowIcon = false
    }
   
        // 判断是否为黑暗模式
        let isDarkMode =  localStorage.getItem('isDarkMode',this.isDark);
        if(isDarkMode){
           if(isDarkMode == 'true'){
              this.$vuetify.theme.dark = true
              this.isDark = true
           }else{
             this.$vuetify.theme.dark = false 
             this.isDark = false
           }   
        }else{
         this.$vuetify.theme.dark = false
          this.isDark = false
        }
        this.onResize()
      window.addEventListener('resize', this.onResize, { passive: true })
      window.eventBus.$on("isWallet", this.walletShow);

      window.eventBus.$on('disconnect', this.disConnect);
      window.eventBus.$on('ShowLogoBox', this.toCreateBtn);
           window.eventBus.$on('showCreate', this.showCreates);
		   
          this.getChainToken();
          this.showPool();
        let json = window.localStorage.getItem("key_user");
         let LoginObj =  window.localStorage.getItem("LogIn");
         if(LoginObj){
            this.isLoginIn = true
         }else{
            this.isLoginIn = false
         }

        //xxl fix the bug 343
    debugger
        if(json != null && JSON.stringify(json.length) != 0 && this.isLoginIn)
        {
           
            
            //keplrKeystoreChange();
            this.showCreate = true;

            let data = JSON.parse(json);
            // console.log(77,data);
            this.user=data.user;
            this.$store.commit('SET_DID', data.did);
            window.bscAddress = data.bscAddress;
              let showTip = localStorage.getItem("isShowTip_"+data.did)
         if(!showTip){
         localStorage.setItem("isShowTip_"+data.did,"true")
         }
        } else {
            this.showCreate = false;
        }
        this.setShowCreate();
        this.walletEvents();
       
	  },
      methods: {
      onResize () {
        this.isIpad = 900<window.innerWidth 
      },
		  walletShow(){
			this.openWallet = true;
		  },
         walletEvents() {
         const data = localStorage.getItem("walletconnect");
         if(!data) return;
         // walletConnect();
         // events.$on("disconnect", res => {
         //    console.log(" wxl ---   disconnect",res);

         //    localStorage.clear();
         //    sessionStorage.clear();
            
         //    location.reload();
         // });
         
  
         events.$on("session_update", res => console.log("session_update",res));
      },
      disConnect(){
           // 退出登录记录cookies acceptIndexCookie
        let cookies = localStorage.getItem("acceptIndexCookie");
       this.isCookies = cookies
         console.log("999999999999999999")
         localStorage.clear();
            sessionStorage.clear();
      
             localStorage.setItem("acceptIndexCookie",this.isCookies);
 this.$router.push({ name: 'Home' })
            location.reload();

      },
      darkMode(){
          this.$gtag.event('导航-黑暗模式', { 'event_category': 'Click', 'event_label': '黑暗模式' })
         this.$vuetify.theme.dark = !this.$vuetify.theme.dark
          localStorage.setItem('isDarkMode',this.$vuetify.theme.dark);
      },
         async showPool(){
            let res = await api.home.irisLuckEnable();
            if(res.success)
            this.isShowPool = res.data.enable
         },
         async  getChainToken() {
      
         let res = await api.home.chainToken();
         let list = [];
         for (let i = 0; i < res.data.length; i++) {
            list.push({ 
                     name: res.data[i].symbol,
                     img: res.data[i].logourl,
                     tokenId: res.data[i].tokenId,
                     address: res.data[i].address,
                     decimals:res.data[i].decimals
          });
            
         }
         localStorage.setItem("ChainToken", JSON.stringify(list));
      },
         connectWallet: function () {
			 let key = localStorage.getItem("KEY_CHAIN");
             if(key) this.keyChain = key;

            this.openWallet = true
            this.user = false
         },
          openBanprompt(e){
         this.Banprompt = e
      },
       UserCenterClick() {
         this.navOpen = 2;
         this.navType = "user";
         this.userCenterOpen=true
         this.$emit('input', true);
      },
         open: function () {
            console.log(1)
              this.navOpen = 1;
              this.navType = "head";
            this.$emit('input', true);
         },
         close: function () {
            this.navOpen = 0;
             this.userCenterOpen=false
            this.$emit('input', false);
         },
         //弹框关闭后获取open值
         Open(e) {
            this.openWallet = e;
         },
         OpenCreateChoose(e){
            this.openCreateChoose = e
         },
         clickMode(){
            // this.isDark = !this.isDark
         },
		
     
         Status(s, account,type){
            if(type == 3){
                sessionStorage.setItem("isDeposit", type);
                localStorage.setItem("LogIn", true);
            }
            this.user=s;
            this.setShowCreate();
			   localStorage.setItem("key_user", JSON.stringify({user:this.user,did:this.$store.state.did,bscAddress:account}));
         },
        async toCreateBtn(){
            this.$gtag.event('创建资产', { 'event_category': 'Click', 'event_label': 'create' })
            let param = {address: this.$store.state.did  };
			  if(!this.$store.state.did){
				  return;
			  }
         let myInfo = await api.home.loopringUserInfo(param);  
         if(myInfo&&myInfo.data&&myInfo.data.grade == 2){
               this.Banprompt = true
               console.log("wxl ---- 2222")
            }else{
                this.openCreateChoose = true
            }

        },
        showCreates(s){
             this.user=s;
             this.setShowCreate();
         },
        async setShowCreate() {
         console.log("this.$store.state.did 1111111",this.$store.state.did);
            if(this.$store.state.did) {
				setTimeout(async () => {
				 let param = {address: this.$store.state.did  };
				 let myInfo = await api.home.loopringUserInfo(param);
             localStorage.setItem("key_user_grade", JSON.stringify({  grade: myInfo.data.grade}));
             if(!this.showCreate)
				 window.eventBus.$emit("isLoading");
				 if(myInfo.data) {
				     this.showCreate = true;
				 } else {
				     let grade = myInfo.data.grade;
				     if(grade == 2 || grade == 4) {
				         this.showCreate = false;
				     } else {
				         this.showCreate = true;
				     }
				 }
				}, 100);
				
             
            }
            
        }
      }
   };
</script>

<style lang="scss" scoped>
.darkimg{
   width: 30px;
   height: 30px;
}
   .top {
      letter-spacing: 0px;

      .drawer-nav {
         height: 100vh;
      }
   }
   .strcky{
   z-index: 201;
   position: sticky;
    top: 30px;
   }
   .langselect{
        &::v-deep .v-chip {
    box-shadow: none;
    border: none;
    margin-bottom: 2px;
    font-weight: bold;
  }
   }

   .creatbtn{
       animation: tada 1s;
      font-family: MicrosoftYaHei-Bold;
	   font-size: 15px;
	   font-weight: bold;
	   font-stretch: normal;
	   line-height: 100px;
	   letter-spacing: 0px;
	   color: #ffffff;
   }

.creatbtn:active{
  animation: none;
}

   .mobileLogo{
      margin-left:10px;
   }

   .mobileChain{
      // border: solid 1px red;
      margin-left:-12px;
      margin-right: 3px;
   }
</style>
