import { render, staticRenderFns } from "./receiveCode.vue?vue&type=template&id=0426817a&scoped=true"
import script from "./receiveCode.vue?vue&type=script&lang=js"
export * from "./receiveCode.vue?vue&type=script&lang=js"
import style0 from "./receiveCode.vue?vue&type=style&index=0&id=0426817a&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0426817a",
  null
  
)

export default component.exports