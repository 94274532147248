import {
  SigningStargateClient,
} from '@uptsmart/stargate'

const uptickChainId = process.env.VUE_APP_UPTICK_CHAIN_ID
const irisChainId = process.env.VUE_APP_IRIS_CHAIN_ID
// const uptickRpcUrl = process.env.VUE_APP_UPTICK_RPC
 const uptickRpcUrl = window.location.protocol + "//" + window.location.host + "/upticKeplrNode";


export async function getAccountInfo(chainType) {
    console.log('getAccountInfo',chainType);
    debugger

    try {
          console.log("xxl keplr ",window.keplr);
          if (window.keplr) {
            await window.keplr.enable(chainType);
          }
          else {
            console.log('xxl connect keplr ');
          }
          
          const accountKeplr = await window.keplr.getKey(chainType); 
          console.log("xxl accountKeplr 111------");
          console.log(accountKeplr);
  
          const offlineSigner = window.getOfflineSigner(chainType);
          const accounts = await offlineSigner.getAccounts();
  
          console.log("xxl accounts",accounts);
  
          return accounts[0];
      }catch (error) {
            console.log(error);
            console.log('denied getAccountInfo ALL Keplr');
      }
      
  }

  export async function uptickERC721ToIris(evmContractAddress,evmTokenIdsList,sourcePort,sourceChannel,classId,cosmosTokenIdsList,evmSender,cosmosReceiver) {


    let account = await getAccountInfo(uptickChainId);
    console.log("account address is : ",account);

    // evmContractAddress: jspb.Message.getFieldWithDefault(msg, 1, ""),
    // evmTokenIdsList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f,
    // sourcePort: jspb.Message.getFieldWithDefault(msg, 3, ""),
    // sourceChannel: jspb.Message.getFieldWithDefault(msg, 4, ""),
    // classId: jspb.Message.getFieldWithDefault(msg, 5, ""),
    // cosmosTokenIdsList: (f = jspb.Message.getRepeatedField(msg, 6)) == null ? undefined : f,
    // evmSender: jspb.Message.getFieldWithDefault(msg, 7, ""),
    // cosmosReceiver: jspb.Message.getFieldWithDefault(msg, 8, ""),
    // timeoutHeight: (f = msg.getTimeoutHeight()) && ibc_core_client_v1_client_pb.Height.toObject(includeInstance, f),
    // timeoutTimestamp: jspb.Message.getFieldWithDefault(msg, 10, 0),
    // memo: jspb.Message.getFieldWithDefault(msg, 11, "")

  // classId="uptick002zhou";
    let typeUrl = "/uptick.erc721.v1.MsgTransferERC721"

    let curTime = new Date().getTime() * 1000000;
    let timespan = (curTime + 3600000000000);
    console.log("xxl timespan ",timespan);
    
    let msg =  {
        typeUrl:typeUrl,
        value:[evmContractAddress,evmTokenIdsList,sourcePort,sourceChannel,classId,cosmosTokenIdsList,evmSender,cosmosReceiver,0,timespan,""]
    }

    // let msg =  {
    //     typeUrl:typeUrl,
    //     value:[evmContractAddress,evmTokenIdsList,sourcePort,sourceChannel,classId,cosmosTokenIdsList,evmSender,cosmosReceiver]
    // }

    console.log(msg);

    const result = await sendMsgsTx(account.address, [msg], 10000, "");
	 console.log("uptickERC721ToIris Result",result);
  

    return result;

}

async function sendMsgsTx(address, msgs, amount, data,isOther=false) {



  let client,fee;

      fee = {
          amount: [{
              denom: 'auptick',
              amount: amount,
          }],
          gas: '10000000',
      };
      const offlineSigner = await window.getOfflineSigner(uptickChainId);  

      client = await SigningStargateClient.connectWithSigner(
          uptickRpcUrl,
          offlineSigner
      )
  

  console.log("###xxl sendMsgsTx",[address, msgs, fee, data]);
  const result = await client.sendMsgsTx(address, msgs, fee, data);
  console.log("###xxl result",result);
  return result;

}



export async function addUptickNetwork() { 
  
if (!window.getOfflineSigner || !window.keplr) {
   console.log('Please install keplr extension');
} else {
   if (window.keplr.experimentalSuggestChain) {
       try {
           // Keplr v0.6.4 introduces an experimental feature that supports the feature to suggests the chain from a webpage.
           // cosmoshub-3 is integrated to Keplr so the code should return without errors.
           // The code below is not needed for cosmoshub-3, but may be helpful if you’re adding a custom chain.
           // If the user approves, the chain will be added to the user's Keplr extension.
           // If the user rejects it or the suggested chain information doesn't include the required fields, it will throw an error.
           // If the same chain id is already registered, it will resolve and not require the user interactions.
		   console.log("process.env.VUE_APP_UPTICK_CHAIN_ID",process.env.VUE_APP_UPTICK_CHAIN_ID,process.env.VUE_APP_UPTICK_CHAIN_NAME,process.env.VUE_APP_UPTICK_RPC,process.env.VUE_APP_UPTICK_REST)
		   await window.keplr.experimentalSuggestChain({
		       // Chain-id of the Osmosis chain.
		       chainId: process.env.VUE_APP_UPTICK_CHAIN_ID,
		       // # UPTICK环境
		           
		   
		       // The name of the chain to be displayed to the user.
		       chainName:  process.env.VUE_APP_UPTICK_CHAIN_NAME,
		       // RPC endpoint of the chain. In this case we are using blockapsis, as it's accepts connections from any host currently. No Cors limitations.
		       rpc: process.env.VUE_APP_UPTICK_RPC,
		       // REST endpoint of the chain.
		       rest: process.env.VUE_APP_UPTICK_REST,
		       // Staking coin information
		       stakeCurrency: {
		           coinDenom: "uptick",
		           coinMinimalDenom: "auptick",
		           coinDecimals: 18,
		   
		       },
		   
		       bip44: {
		       
		           coinType: 60,
		       },
		      
		       bech32Config: {
		           bech32PrefixAccAddr: "uptick",
		           bech32PrefixAccPub: "uptickpub",
		           bech32PrefixValAddr: "uptickvaloper",
		           bech32PrefixValPub: "uptickvaloperpub",
		           bech32PrefixConsAddr: "uptickvalcons",
		           bech32PrefixConsPub: "uptickvalconspub",
		       },
		       currencies: [
		           {
		               coinDenom: "uptick",
		               coinMinimalDenom: "auptick",
		               coinDecimals: 18,
		           },
		       ],
		       // List of coin/tokens used as a fee token in this chain.
		       feeCurrencies: [
		           {
		               coinDenom: "uptick",
		               coinMinimalDenom: "auptick",
		               coinDecimals: 18,
		           },
		       ],
		     
		       coinType: 60,
		      
		       gasPriceStep: {
		         low: 1 * 100000000000,
		         average: 2 * 100000000000,
		         high: 4 * 100000000000,
		       },
		       
		               
		                 
		       features:['ibc-transfer','ibc-go', 'eth-address-gen', 'eth-key-sign']
		   });
		   

           // location.reload();
       } catch {
           alert("Failed to suggest the chain");
           // location.reload();
       }
   } else {
       alert("Please use the recent version of keplr extension");
   }
}
}