<template>
  <div id="container">
    <v-card :class="{ card: true, mobile: isMobile }">
      <img
        class="clo"
        @click="closeDialog"
        :src="$vuetify.theme.dark?darkcloseIcon:closeIcon"
        alt=""
      />
      <div class="top">
        <h3 v-if="this.Type == 'fixedPrice'" :class="{darkFont:$vuetify.theme.dark }">{{ $t("onsaleFixedPrice") }}</h3>
        <h3 v-else-if="this.Type == 'discountSale'" :class="{darkFont:$vuetify.theme.dark }">
          {{ $t("discountSale") }}
        </h3>
        <h3 v-else-if="this.Type == 'execlusiveSale'" :class="{darkFont:$vuetify.theme.dark }">
          {{ $t("execlusiveSale") }}
        </h3>
        <h3 class="pupils mt-n1" v-if=" onsaleType !='multi'" :class="{darkFont:$vuetify.theme.dark }">{{ NftName }}</h3>
      </div>
      <div class="form mt-2">
        <div class="forms mb-3">
          <div class="title mb-lg-3 mb-3" :class="{darkFont:$vuetify.theme.dark }">Token</div>
          <ChainSelectInput @change="chainChange"></ChainSelectInput>
      </div>
        <div class="title mb-lg-3 mb-3" :class="{darkFont:$vuetify.theme.dark }">{{ $t("popupMarketSalesPrice") }}</div>
        <v-text-field
          ref="SalePrice"
          v-model="SalePrice"
          outlined
          :suffix="chain"
          type="input"
          :rules="[rules.errorPrice]"
          onkeyup="value=value.replace(/^\D*(\d*(?:\.\d{0,4})?).*$/g, '$1')"
          maxlength="10"
        ></v-text-field>
        <!-- <ChainSelectInput @change="chainChange" @input = "salePrice"></ChainSelectInput> -->
      </div>
      <div
        class="maxamount  mb-1 d-flex flex-row justify-space-between"
        v-if="!contractCollectionId && parentValue.nftType != 'ERC721'&& onsaleType != 'multi'"
      >
        <div class="box d-flex flex-row">
			<div class="d-flex flex-column">
				
				<div class="amount mt-4" :class="{darkFont:$vuetify.theme.dark }">
				  {{ $t("popupMarketMaximumPurchaseAmount") }}
						
				</div>
    <div class="tip  mt-4" :class="{darkFont:$vuetify.theme.dark }">
					{{$t('popupMarketMaximumPurchaseAmount_des')}} 
				</div>
			</div>
    

          <v-text-field
            class="txt"
            ref="amount"
            v-model="amount"
            height:10px
            full-width:false
            outlined
            :rules="[rules.maxCount]"
            onkeyup="value=value.replace(/^\D*(\d*(?:\.\d{0,4})?).*$/g, '$1')"
            maxlength="10"
            v-if="isShow"
          ></v-text-field>
        </div>
        <div class="box2 mr-n4">
          <v-sheet class="sth">
            <v-switch v-model="switch1" inset></v-switch>
          </v-sheet>
        </div>
      </div>

         <div
        class="maxamount   d-flex flex-row justify-space-between"
        v-if="this.Type == 'fixedPrice' && onsaleType != 'multi'"
      >
        <div class="box d-flex flex-column">
          <div class="amount mt-6" :class="{darkFont:$vuetify.theme.dark }">
          {{$t('LimitedTime_notRequire')}} 
          </div>
         
        </div>
        <div class="box2 mr-n4">
          <v-sheet class="sth">
            <v-switch v-model="switch2" inset></v-switch>
          </v-sheet>
        </div>
      </div>
       <div class="preferential" v-if="this.Type == 'fixedPrice' && onsaleType != 'multi'" :class="{darkFont:$vuetify.theme.dark }">
            {{$t('LimitedTime_des')}} 
          </div>
       <div class="formTime d-flex flex-row" v-if="switch2">
        <div class="ti-in">
          <v-datetime-picker label="" v-model="StartTime">
            <template v-slot:dateIcon>
              <img
                style="
                  display: inline;
                  width: 24px;
                  height: 24px;
                  margin: 0;
                  background-color: transparent;
                "
                src="@/assets/icons/icon-calendar.png"
                alt
              />
            </template>
            <template v-slot:timeIcon>
              <img
                style="
                  display: inline;
                  width: 24px;
                  height: 24px;
                  margin: 0;
                  background-color: transparent;
                "
                src="@/assets/icons/icon-clock.png"
                alt
              />
            </template>
          </v-datetime-picker>
        </div>
        <div class="ml-8 mr-8 mt-3">~</div>
         <div class="ti-in">
          <v-datetime-picker label="" v-model="EndTime">
            <template v-slot:dateIcon>
              <img
                style="
                  display: inline;
                  width: 24px;
                  height: 24px;
                  margin: 0;
                  background-color: transparent;
                "
                src="@/assets/icons/icon-calendar.png"
                alt
              />
            </template>
            <template v-slot:timeIcon>
              <img
                style="
                  display: inline;
                  width: 24px;
                  height: 24px;
                  margin: 0;
                  background-color: transparent;
                "
                src="@/assets/icons/icon-clock.png"
                alt
              />
            </template>
          </v-datetime-picker>
        </div>
      </div>
        <div class="form mt-2" v-if="switch2">
        <v-text-field
          ref="preferentialPrice"
          :placeholder="this.$t('discountPriceNull')"
          v-model="preferentialPrice"
          outlined
          :suffix="chain"
          type="input"
          :rules="[rules.errorPrice]"
          onkeyup="value=value.replace(/^\D*(\d*(?:\.\d{0,4})?).*$/g, '$1')"
          maxlength="10"
        ></v-text-field>
        <!-- <ChainSelectInput @change="chainChange" @input = "salePrice"></ChainSelectInput> -->
      </div>
      

      <!--2022/02/25新增-->
      <div
        class="sale d-flex flex-column mt-n2 mb-n3"
        v-if="this.Type == 'execlusiveSale'"
      >
        <div class="d-flex flex-column mt-5">
          <!-- <div class="newtop d-flex flex-row justify-space-between">
                  <div class="title">{{ $t("monopoly") }}</div>
                  <v-sheet class="sth">
                     <v-switch v-model="switch2" inset></v-switch>
                  </v-sheet>
               </div> -->
          <h3 :class="{darkFont:$vuetify.theme.dark }"> {{ $t("relevance_nft") }}</h3>
          <div class="tip  mt-n1" :class="{darkFont:$vuetify.theme.dark }">{{ $t("monopoly_text") }}</div>
          <!-- 任意/全部 -->
          <div class="icons d-flex flex-row mt-4">
            <div class="any d-flex flex-row" @click="iconBtn1">
              <img
                src="../../assets/icons/icon_dx_n.png"
          
                alt="1"
                v-if="isYes"
              />
              <img
              
                :src="$vuetify.theme.dark ?darkIcon :Icon"
                alt="2"
                v-if="!isYes"
              />
              <div :class="{darkFont:$vuetify.theme.dark }"> {{ $t("onsale_or") }}</div>
            </div>
            <div class="all d-flex flex-row" @click="iconBtn2">
              <img
                src="../../assets/icons/icon_dx_n.png"
                alt="1"
                v-if="!isYes"
              />
              <img
                 :src="$vuetify.theme.dark ?darkIcon :Icon"
                alt="2"
                v-if="isYes"
              />
              <div :class="{darkFont:$vuetify.theme.dark }"> {{ $t("onsale_and") }}</div>
            </div>
            <!-- <v-sapcer></v-sapcer>
          <v-sapcer></v-sapcer> -->
          </div>
          
        </div>

        <div class="selects d-flex flex-row flex-wrap">
          <div
            class="nftbox d-flex flex-row align-center"
            v-for="(item, index) in getNftList"
            :key="index"
          >
            <img :src="item.src"  style="border: solid 1px #c185fe;" alt="" />
            <div class="txt">{{ item.text }}</div>
            <img
              class="clos"
              @click="closenft(index)"
              src="@/assets/icons/btn_close.png"
              alt=""
            />
          </div>
          <div
            class="add d-flex flex-row align-center"
            v-if="this.getNftList.length < 12"
            @click="subCommit1"
          >
            <v-icon
              class="icon"
              style="font-size: 40px; margin-right: 15px;margin-left: 55px;"
              color="black"
              >mdi-plus</v-icon
            >
            <!-- <p>Select NFT for discount</p> -->
          </div>
        </div>
        <!-- <div class="select mt-3">
          <v-select
            :items="starsCardlist"
            :disabled="switch2 == false"
            :placeholder="this.$t('please_zm')"
            label=""
            v-model="starsCard"
            item-text="text"
            item-value="value"
            solo
            any
          ></v-select>
        </div> -->
      </div>
      <div
        class="you mt-5 d-flex flex-column"
        v-if="this.Type == 'discountSale'"
      >
        <div class="d-flex flex-column">
          <!-- <div class="newtop d-flex flex-row justify-space-between">
                  <div class="title">{{ $t("coupon") }}</div>
                  <v-sheet class="sth">
                     <v-switch v-model="switch3" inset></v-switch>
                  </v-sheet>
               </div> -->
          <h3 :class="{darkFont:$vuetify.theme.dark }"> {{ $t("relevance_nft") }}</h3>
           <div class="tip mt-n1">{{ $t("coupon_text") }}</div>
          <!-- 任意/全部 -->
          <div class="icons d-flex flex-row mt-4">
            <div class="any d-flex flex-row" @click="iconBtn1">
              <img
             
                src="../../assets/icons/icon_dx_n.png"
                alt="1"
                v-if="isYes"
              />
              <img
               
                :src="$vuetify.theme.dark ?darkIcon :Icon"
                alt="2"
                v-if="!isYes"
              />
              <div :class="{darkFont:$vuetify.theme.dark }"> {{ $t("onsale_or") }}</div>
            </div>
            <div class="all d-flex flex-row" @click="iconBtn2">
              <img
                
                src="../../assets/icons/icon_dx_n.png"
                alt="1"
                v-if="!isYes"
              />
              <img
           
                :src="$vuetify.theme.dark ?darkIcon :Icon"
                alt="2"
                v-if="isYes"
              />
              <div :class="{darkFont:$vuetify.theme.dark }"> {{ $t("onsale_and") }}</div>
            </div>
            <!-- <v-sapcer></v-sapcer>
          <v-sapcer></v-sapcer> -->
          </div>
         
        </div>
        <div class="selects d-flex flex-row flex-wrap">
          <div
            class="nftbox d-flex flex-row align-center"
            v-for="(item, index) in getNftList"
            :key="index"
          >
            <img :src="item.src" style="border: solid 1px #c185fe;" alt="" />
            <div class="txt">{{ item.text }}</div>
            <img
              class="clos"
              @click="closenft(index)"
              src="@/assets/icons/btn_close.png"
              alt=""
            />
          </div>
          <div
            class="add d-flex flex-row align-center"
            v-if="this.getNftList.length < 12"
            @click="subCommit1"
          >
            <v-icon
              class="icon"
              style="font-size: 40px; margin-right: 15px;margin-left: 55px;"
              color="black"
              >mdi-plus</v-icon
            >
            <!-- <p>Select NFT for discount</p> -->
          </div>
        </div>
        <div class="select mt-3 mb-n7 d-flex flex-column justify-space-between">
          <div class="shownft">
            <!-- <v-select
              :items="reducedPriceCardlist"
              label=""
              :disabled="switch3 == false"
              :placeholder="this.$t('please_yh')"
              v-model="reducedPriceCard"
              item-text="text"
              item-value="value"
              solo
              any
              style="width: 500px; height: 46px"
            ></v-select> -->
          </div>
          <div class="inp mt-2">
            <v-text-field
              ref="DiscountPrice"
              v-model="reducedPrice"
              outlined
              :suffix="chain"
              type="input"
              :rules="[rules.errorPrice]"
              :placeholder="this.$t('discountPriceNull')"
              :loader-height="5"
              :height="5"
              onkeyup="value=value.replace(/^\D*(\d*(?:\.\d{0,4})?).*$/g, '$1')"
              maxlength="10"
            ></v-text-field>
          </div>
        </div>
      </div>

    <div class="footer">
        <span class="price"
          >{{ $t("popupAuctionDefectPriceHandingFee") }}：{{ value1 }}
          {{feeName}}</span
        >
         <button class="subdefect v-btn uc" :class="{ 'load-dis': isLoad }" v-if="isLoad">
          {{ $t("loadBalance") }}
        </button>
        <button class="sub" :class="{ 'sub-dis': isPay }" v-else @click="subCommit">
          {{ $t("popupTransferCommit") }}
        </button>
        <span class="price"
          >{{ $t("popupNewTransferBalance") }}：{{ value3 }}  {{feeName}}
         </span
        >
      </div>
    </v-card>
    <!-- <PromptBox ref="promptBox"></PromptBox> -->
    <uComponents ref="ucom"></uComponents>
    <v-dialog v-model="selectnft">
      <select-nft
        :parentValue="parentValue"
        :getNftList="getNftList"
        :tokenId="onsaleTokenId"
        :deductionType = "deductionType"
        @Openselectnft="Openselectnft"
        @getCardList="getCardList"
        v-if="selectnft"
      ></select-nft>
    </v-dialog>

  </div>
</template>

<script>
import api from "@/api";
import SelectNft from "./selectNft.vue";
import ChainSelectInput from "@/components/ChainSelectInput.vue";



//xxl ##02 add web3 convert import
import Web3 from "web3";
const web3Obj = new Web3();

export default {
  components: {  SelectNft,ChainSelectInput },
  name: "transfer",
  data: () => ({
    isLoad: false,
    open: true,
    value1:'',
    value3:'',
    selectnft: false,
    SalePrice: "",
    reducedPrice: "",
    // starsCard: "",
    // reducedPriceCard: "",
    amount: "",
    switch1: false,
     switch2: false,
    // switch2: false,
    switch3: false,
    feePrice: "",
    value2: "0.075",
    assetBalance: "",
    NftName: "",
    isShow: false,
    isPay: false,
    discountVer: "",
    rules: {
      //amount: (value) => !!value || "Place amount is required.",
      maxCount: (value) => {
        const pattern = /^(\+?[1-9][0-9]{0,8})$/;
        return pattern.test(value) || "verify amount";
      },
      errorPrice: (value) => {

         const pattern = /^((\+?[0-9][0-9]{0,8})\.([0-9]{1,4})$)|^(\+?[0-9][0-9]{0,8})$/;
        return pattern.test(value) || "Invalid";
      //  const pattern =  /^(?:0|[1-9][0-9]?|100)(\.[0-9]{0,8})?$/;
      //   return pattern.test(value) || "Invalid";
      
        // if (parseInt(value) === parseFloat(value)){
        //      const pattern =/^[1-9]\d{0,7}$/;
        // return pattern.test(value) || "Invalid";
        //   //^[1-9]\d{0,7}$
        // }else{
        //     value = value.replace(/^\s+|\s+$/g,"")
        // // const pattern = /^(\+?[0-9][0-9]{0,8})$/;
        // const pattern =/^\d*(?:\.\d{0,4})?$/;
        // return pattern.test(value) || "Invalid";
        // }
      
      },
    },
    starsCardlist: [],
    reducedPriceCardlist: [],
    getNftList: [],
    flag: true,
    isYes: false,
    deductionType: 2,
    onsaleTokenId:"",
    chain:'UPTICK',
    chainAddress:'0x0000000000000000000000000000000000000000',
    decimals:18,
    coindenom:'uiris',
    multiNFTids:'',
    prices:[],
    StartTime: "",
    EndTime:"",
    preferentialPrice:'',
    maxlength:'',
    feeName:'UPTICK',
    Icon:require('@/assets/icons/icon_dx_y.png'),
    darkIcon:require('@/assets/darkimage/icon_dx_y.png'),
    closeIcon:require('@/assets/btn_close.png'),
    darkcloseIcon:require('@/assets/darkimage/btn_close1.png'),
  }),

  props: {
    address: {
      type: String,
    },
     onsaleType:{
      type:String
    },
    name: {
      type: String,
    },
    parentValue: {
      type: Object,
    },
      contractCollectionId: {
      type: Number,
    },
     Type: {
      type: String,
    },
    onSaleTokenIds: {
      type: Array,
    },
    offSaleTokenIds: {
      type: Array,
    },
     nftAddresss: {
      type: Array,
    },
     NFTAmount:{
      type: Array,
    },
  },
  computed: {
    isMobile: function () {
      return this.$vuetify.breakpoint.mobile;
    },
    handler: function () {
      return {
        switch: this.switch1,
      };
    },
  },
  watch: {
    handler: {
      handler: function () {
        if (this.switch1 == true) {
          console.log("true");
       
          this.isShow = true;
        } else {
          console.log("false");

          this.isShow = false;
        }
      },
      deep: true,
    },
     switch1:{
       handler: function (value) {
         if(value){
            this.$gtag.event('普通上架-限购数量', { 'event_category': 'Click', 'event_label': '限购数量' })
         }
      },
      immediate: true,
    },
    switch2:{
       handler: function (value) {
         if(value){
            this.$gtag.event('普通上架-限时售卖', { 'event_category': 'Click', 'event_label': '限时售卖' })
         }
      },
      immediate: true,
    },
    //  SalePrice(newValue) {
    //    if (parseInt(newValue) === parseFloat(newValue)){
    //      if(newValue > 100){
    //      this.SalePrice =100
    //      this.maxlength = 3
    //   }
    //    }else{
    //      if(parseInt(this.SalePrice) < 10){
    //        this.maxlength = 6
    //      }else{
    //        this.maxlength = 7
    //      }
           
    //    }
 
    // },
    // preferentialPrice(newValue){
    //    if (parseInt(newValue) === parseFloat(newValue)){
    //      if(newValue > 100){
    //      this.preferentialPrice =100
    //      this.maxlength = 3
    //   }
    //    }else{
    //       if(parseInt(this.preferentialPrice) < 10){
    //        this.maxlength = 6
    //      }else{
    //        this.maxlength = 7
    //      }
    //    }
    // },
    //  reducedPrice(newValue) {
     
    //   if (parseInt(newValue) === parseFloat(newValue)){
    //      if(newValue > 100){
    //      this.reducedPrice =100
    //      this.maxlength = 3
    //   }
    //    }else{
    //        if(parseInt(this.reducedPrice) < 10){
    //        this.maxlength = 6
    //      }else{
    //        this.maxlength = 7
    //      }
    //    }
    // },
  },
   created() {
    this.StartTime = new Date();
    // this.EndTime = new Date();
  },
  async mounted() {
    // 当前所在市场
     let currentChain = `${this.$env.VUE_APP_MARKET_TYPE}`
      let currentSymbol = `${this.$env.VUE_APP_ADD_NETWORK_CHAIN_SYMBOL}` 
      if(currentChain == 'POLYGON'){
        this.chain = 'MATIC'
         this.feeName = 'MATIC'
      }else if(currentChain == 'BSC'){
        this.chain = 'BNB'
         this.feeName = 'BNB'
      }else if(currentChain == 'ARBITRUM'){
        this.chain = currentSymbol
         this.feeName =currentSymbol
      }
  
   if(this.onSaleTokenIds &&this.onSaleTokenIds.length >0){
     for (let index = 0; index < this.onSaleTokenIds.length; index++) {
     this.multiNFTids+=this.onSaleTokenIds[index]+ ",";
     
   }
    this.multiNFTids = this.multiNFTids.substring(0, this.multiNFTids.lastIndexOf(','));
   }
   
   console.log("wxl -- eeee", this.multiNFTids)
    this.NftName = this.name;
    //let balance =  await getMyBalance();
    this.isLoad = true
  
    let res = await this.$wallet.getMyBalance();
    console.log("updata balance ----");
    console.log(res);
   

    let mount = localStorage.getItem("key_balance");
    this.value3 = mount;
   


    let res2 = await api.home.getFee();
    if(this.parentValue.nftType=="ERC721") {
      this.value1=Number(res2.data.erc721OnsaleUptickAssetFee);
      if(this.onsaleType == 'multi'){
        this.value1 =this.NumberMul(this.value1,this.onSaleTokenIds.length)
      }
    }
    else if(this.parentValue.nftType=="ERC1155") {
      this.value1=Number(res2.data.erc1155OnsaleUptickAssetFee);
      if(this.onsaleType == 'multi'){
        this.value1 =this.NumberMul(this.value1,this.onSaleTokenIds.length)
      }
    }

   this.isLoad = false
    console.log(this.parentValue);
    //  this.value1 = 0.1 * this.offSaleTokenIds.length
  },
  methods: {
    verify() {
      let saleVer = this.$refs.SalePrice.validate(true);
       let preferentialVar
      if(this.switch2){
          preferentialVar = this.$refs.preferentialPrice.validate(true);
        !preferentialVar ? this.$refs.preferentialPrice.focus() : "";
      }
     
      let amountVer = true;
      if (this.switch1 == true) {
        amountVer = this.$refs.amount.validate(true);
      }

    
          !saleVer ? this.$refs.SalePrice.focus() : "";
      !amountVer ? this.$refs.amount.focus() : "";
      if(this.switch2){
          return saleVer && amountVer && preferentialVar;
      }else{
          return saleVer && amountVer ;
      }
    
    },
        async chainChange(chain,coinname) {

          console.log("wxl --- 00000",chain,coinname)
         this.chain = coinname
         this.chainAddress = chain.address
         this.decimals = chain.decimals
        
        },
    //提交转送数据
    async subCommit() {
      
      try {
        if (!this.verify()) {
          return;
        }
        if (this.switch1 && this.amount <= 0) {
          return;
        }
           if(!this.SalePrice ){
        this.$toast("error", this.$t("PleaseInPutBid"));
        return
        
      }
       if (this.Type == "discountSale") {
          if (this.getNftList.length == 0) {
            this.$toast("error", this.$t("please_yh"));
            return;
          }
        } else if (this.Type == "execlusiveSale") {
          if (this.getNftList.length == 0) {
            this.$toast("error", this.$t("please_zm"));
            return;
          }
        }
      if(!this.reducedPrice&&this.Type == "discountSale"){
        this.$toast("error", this.$t("PleaseInPutBid"));
        return
        
      }
         if (this.reducedPrice != "") {
          if (Number(this.SalePrice) <= Number(this.reducedPrice)) {
            console.log("wxl 111111  price_yh");
            console.log(this.SalePrice, this.reducedPrice);
            this.$toast("error", this.$t("price_yh"));
            return;
          }
        }
           if (this.preferentialPrice != "") {
          if (Number(this.SalePrice) <= Number(this.preferentialPrice)) {
            console.log("wxl 111111  price_yh");
            console.log(this.SalePrice, this.reducedPrice);
            this.$toast("error", this.$t("price_yh"));
            return;
          }
        }
      
        this.isPay = true;
        let res = await api.home.getFee();
        let linkNftAddress = '';
       let linkNftId = '';
        this.getNftList.forEach(v => {
          if(v.type == 'nft'){
            linkNftId += v.nftId + ","
          }else{
            linkNftAddress += v.nftAddress + ","
          }
         
       });
         let result, couponCode;
        if(this.Type == 'discountSale' || this.switch2){
             let startTimeStamp = parseInt(this.StartTime.valueOf());
             let endTimeStamp = parseInt(this.EndTime.valueOf());
             // onSaleBefore
      let onsaleParams = {
        nftId:this.parentValue.nftId,
        nftAddress:this.parentValue.nftAddress,
        owner:this.parentValue.owner,
        couponType:this.deductionType,
        deduction:this.switch2 ? this.preferentialPrice : this.reducedPrice,
        linkNftAddress:linkNftAddress.slice(0,linkNftAddress.length-1),
        linkNftId:linkNftId.slice(0,linkNftId.length-1),
        saleStatus:6

      }
      if(this.switch2){
        onsaleParams.marketType = 5
        onsaleParams.startTime = startTimeStamp
         onsaleParams.endTime = endTimeStamp
      }
        let onSaleResult = await api.home.onSaleBefore(onsaleParams);
         couponCode = onSaleResult.data.couponCode ;
         if (this.$chainName == "UPTICK EVM") {
          this.$wallet.setContractAddress(this.parentValue.nftType, {
            address: this.parentValue.nftAddress,
            platformAddress: this.parentValue.nftType == 'ERC721'? res.data.erc721CouponPlatform : res.data.erc1155CouponPlatform ,
          });
          // let vvv = this.parentValue;
          // console.log(vvv);
          //nftType, nftAddress,nftid, value,couponCode,reducedPrice,fee,amount,chainAddress
          result = await this.$wallet.couponOnSale(
            this.parentValue.nftType,
            this.parentValue.nftAddress,
            this.parentValue.nftId,
            this.SalePrice,
            couponCode,
            this.switch2 ? this.preferentialPrice:this.reducedPrice,
            this.value1,
            this.parentValue.amount,
            this.chainAddress,
            this.decimals
          );
          console.log("wxl ---- hash",result);
        }

       }else{
            if (this.$chainName == "UPTICK EVM") {
          this.$wallet.setContractAddress(this.parentValue.nftType, {
            address: this.parentValue.nftAddress,
            platformAddress: this.parentValue.nftType == 'ERC721'? res.data.erc721Platform : res.data.erc1155Platform ,
          });

          if(this.onsaleType == 'multi'){
            

            for (let index = 0; index < this.onSaleTokenIds.length; index++) {
         this.prices.push(this.SalePrice)
        }
     
             result = await this.$wallet.onSaleBatch(
            this.parentValue.nftType,
            this.parentValue.nftAddress,
            this.onSaleTokenIds,
           this.prices,
            this.value1,
            this.NFTAmount,
            this.chainAddress,
            this.decimals
          );
          }else{
             result = await this.$wallet.onSale(
            this.parentValue.nftType,
            this.parentValue.nftAddress,
            this.parentValue.nftId,
            this.SalePrice,
            this.value1,
            this.parentValue.amount,
            this.chainAddress,
            this.decimals
          );
          }
     
         
          console.log("wxl ---- hash",result);
        }    
       
       }
        // let buyLimit = "";
        // if (this.switch1 == true) {
        //   buyLimit = 0;
        // } else {
        //   buyLimit = this.amount;
        // }
        if(!result.hash){
           this.isPay = false
           this.$toast("error",this.$t("requireFailed"))
           return
        }
         if(!this.switch1){
        this.amount = 0
      }
        let isDoingParams = {
          nftAddress: this.parentValue.nftAddress,
          nftId:this.onsaleType == 'multi' ? this.multiNFTids: this.parentValue.nftId,
          hash:result.hash,
          owner:this.$store.state.did,
          saleStatus:6,
          buyLimit:this.amount
        };
     
        if(this.Type == 'execlusiveSale'){
          isDoingParams.exclusiveType = this.deductionType
          isDoingParams.linkNftAddress =  linkNftAddress.slice(0,linkNftAddress.length-1)
          isDoingParams.linkNftId =   linkNftId.slice(0,linkNftId.length-1);
        }else if(this.Type == 'discountSale'){
           isDoingParams.couponCode = couponCode 
        }
        if(this.switch2){
        isDoingParams.marketType = 5
        isDoingParams.couponCode = couponCode 
      }
     
        let isding = await api.home.isDoing(isDoingParams);
        console.log(isding);
        if (isding.success == true) {
          // this.$toast("success", this.$t("success")).then(
          //   () => {
          //     this.$emit("getOpen", false);
          //   this.isPay = false;
          //   }
          // );
    await this.sleep(5000).then(()=>{
      window.eventBus.$emit("backgroundUpdate");
      this.isPay = false;  
      this.$emit("getOpen", false,'onSaleSuccess')
   
    
   })
        
     
       
       
         
        // let isShowTip = localStorage.getItem("isShowTip_"+this.$store.state.did)
        // if(isShowTip == 'true'){
        //    this.$mtip({
        //       title: this.$t("onSaleSuccess"),
        //     });
        // }
          
       

       // window.eventBus.$emit("collectionPay");
        
        }
     
      } catch (e) {
        console.log("eee",e)
        //this.$refs.promptBox.show(this.$t('putawayFailed'));
        //this.$toast("error", this.$t("amCreateFalseTitle2"));
        this.$toast("error", this.$t("transactionInprogressError"));
        this.isPay = false;
      }
    },
     sleep(time) {
      return new Promise((resolve) => setTimeout(resolve, time));
    },
     NumberMul(arg1, arg2) {
      var m = 0;
      var s1 = arg1.toString();
      var s2 = arg2.toString();
      try {
		  if(s1.indexOf(".") != -1){
			    m += s1.split(".")[1].length;
		  }
      
      } catch (e) {
        console.log(e)
      }
      try {
		  if(s2.indexOf(".") != -1){
		  			 m += s2.split(".")[1].length;
		  }
       
      } catch (e) {
        console.log(e)
      }

      return (
        (Number(s1.replace(".", "")) * Number(s2.replace(".", ""))) /
        Math.pow(10, m)
      );
    },
     subCommit1() {
      this.selectnft = true;
    },
    //弹框关闭后获取open值
    Openselectnft(e) {
      this.selectnft = e;
    },
    getCardList(data) {
      this.getNftList = data;
      
      this.$forceUpdate();
    },
   //关闭弹框，发送open值
    closeDialog() {

      this.open = false;
      this.$emit("getOpen", this.open);
    },
    iconBtn1() {
      this.$gtag.event('上架-Holding one', { 'event_category': 'Click', 'event_label': 'Holding one' })
      this.isYes = false;
      this.deductionType = 2;
      this.getNftList = []
    },
    iconBtn2() {
      this.$gtag.event('上架-Holding all', { 'event_category': 'Click', 'event_label': 'Holding all' })
      this.isYes = true;
      this.deductionType = 1;
      this.getNftList = []
    },
    closenft(index) {
      this.getNftList.splice(index, 1);
    },
  },
};
</script>

<style lang="scss" scoped>

.sth {
  margin-left: 108px;
  width: 60px;
  height: 35px;
  // background-color: #270645;
  display: block;
  position: relative;
  right: -15px;
  bottom: 15px;
  border-radius: 18px;
}
::v-deep .v-text-field--outlined > .v-input__control > .v-input__slot{
   min-height: 45px;
 }
.tip {
  font-family: "\5FAE\8F6F\96C5\9ED1";
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  letter-spacing: 0px;
  color: #766983;
}
.card {
  display: flex;
  flex-direction: column;
  min-height: 470px;
  width: 600px;

  .clo {
    position: absolute;
    right: 10px;
    top: 10px;
    width: 30px;
    height: 31px;
    border-radius: 50%;
    margin-right: 0;
    cursor: pointer;
  }

  .top {
    margin-left: 52px;

    h3 {
      margin: 41px 0 29px;
      font-family: "\5FAE\8F6F\96C5\9ED1";
      font-size: 25px;
      color: #270645;
    }

    .pupils {
      margin: 0 0 22px;
      width: 496px;

      font-family: "\5FAE\8F6F\96C5\9ED1";
      font-size: 18px;
      color: #270645;
    }
  }

  .form {
    margin: 10px 52px;
    
    .title {
      font-family: "\5FAE\8F6F\96C5\9ED1";
      font-size: 15px !important;
      font-weight: bold;
      font-stretch: normal;
      color: #270645;
    }
    .forms {
  
    img{
      width: 25px;
      height: 25px;
      background-color:white;
    }
    .title {

      font-size: 15px;
      font-weight: bold;
      font-stretch: normal;
      color: #270645;
    }
      .reserveContant{
          position: absolute;
          right: 150px;
          top: 10px;
          width: 212px;
          height: 101px;
          background-color: #270645;
          border-radius: 3px;
          z-index:1;
          .des{
            margin: 15px;
            width: 183px;
            height: 71px;
            font-family: MicrosoftYaHei;
            font-size: 12px;
          font-weight: normal;
          font-stretch: normal;
          letter-spacing: 0px;
          color: #ffffff;
          line-height: 15px;
          }
          
  
        }
  }
  }
  .formTime{
      margin: 10px 52px;
    &::v-deep .v-input {
      width: 218px;
    height: 46px;

    border-radius: 5px;
    border: solid 2px #e3e3e3;
        
      }
       &::v-deep .v-input__slot{
     position: inherit !important;
     margin-top:-6px;
     margin-left: 10px;
   }
  }
  
  
  .sale {
    margin: 10px 52px;
    .newtop {
      .title {
        font-family: "\5FAE\8F6F\96C5\9ED1";
        font-size: 15px;
        font-weight: bold;
        font-stretch: normal;
        letter-spacing: 0px;
        color: #270645;
      }
    }
    h3 {
      min-width: 61px;
      height: 16px;
      font-family:Helvetica;
      font-size: 15px;
      font-weight: bold;
      font-stretch: normal;
      line-height: 16px;
      letter-spacing: 0px;
      color: #270645;
      margin-bottom: 14px;
    }
    .icons {
      margin-bottom: 10px;
      .any {
        margin-right: 100px;
        img {
          width: 27px;
          height: 27px;
          margin-right: 9px;
          margin-left: 0;
        }
        span {
          display: block;
          width: 61px;
          height: 15px;
          font-family:Helvetica;
          font-size: 15px;
          font-weight: normal;
          font-stretch: normal;
          letter-spacing: 0px;
          color: #270645;
        }
      }
      .all {
        img {
          width: 27px;
          height: 27px;
          margin-right: 9px;
          margin-left: 0;
        }
        span {
          display: block;
          width: 61px;
          height: 15px;
          font-family:Helvetica;
          font-size: 15px;
          font-weight: normal;
          font-stretch: normal;
          letter-spacing: 0px;
          color: #270645;
        }
      }
    }
    .selects {
      // margin-top: 13px;
      max-height: 200px;
      overflow-y: auto;
      .nftbox {
        width: 157px;
        height: 61px;
        background-color: #270645;
        border-radius: 3px;
        padding-left: 11px;
        margin-right: 5px;
        margin-bottom: 0;
        margin-top:5px;
        position:relative;
        img {
          width: 36px;
          height: 36px;
          margin: 0;
          object-fit: cover;
          
        }
        .txt {
          width: 76px;
          // min-height: 36px;
          font-family: Helvetica;
          font-size: 12px;
          font-weight: normal;
          font-stretch: normal;
          letter-spacing: 0px;
          color:white;
          margin-left: 6px;
          overflow: hidden;
          line-height:12px;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 3;
        }
        .clos {
          background:white;
          position: absolute;
          right:-5px;
          top: -5px;
          width: 23px;
          height: 23px;
          border-radius: 50%;
          margin-right: 0;
          cursor: pointer;
        }
      }
      .add {
        cursor: pointer;
        width: 157px;
        height: 61px;
        background-image: linear-gradient(#ffffff, #ffffff),
          linear-gradient(#f8f6fd, #f8f6fd);
        background-blend-mode: normal, normal;
        border-radius: 3px;
        border: solid 2px #e3e3e3;
        margin-top:5px;
        p {
          width: 70px;
          font-family:Helvetica;
          font-size: 12px;
          font-weight: normal;
          font-stretch: normal;
          letter-spacing: 0px;
          color: #766983;
          margin: 0;
        }
      }
    }
  }
  .you {
    margin: 10px 52px;
    .newtop {
      .title {
        font-family: "\5FAE\8F6F\96C5\9ED1";
        font-size: 15px;
        font-weight: bold;
        font-stretch: normal;
        letter-spacing: 0px;
        color: #270645;
      }
    }
    h3 {
      min-width: 61px;
      height: 16px;
      font-family:Helvetica;
      font-size: 15px;
      font-weight: bold;
      font-stretch: normal;
      line-height: 16px;
      letter-spacing: 0px;
      color: #270645;
      margin-bottom: 14px;
    }
    .icons {
      margin-bottom: 10px;
      .any {
        margin-right: 100px;
        img {
          width: 27px;
          height: 27px;
          margin-right: 9px;
          margin-left: 0;
        }
        span {
          display: block;
          width: 61px;
          height: 15px;
          font-family:Helvetica;
          font-size: 15px;
          font-weight: normal;
          font-stretch: normal;
          letter-spacing: 0px;
          color: #270645;
        }
      }
      .all {
        img {
          width: 27px;
          height: 27px;
          margin-right: 9px;
          margin-left: 0;
        }
        span {
          display: block;
          width: 61px;
          height: 15px;
          font-family:Helvetica;
          font-size: 15px;
          font-weight: normal;
          font-stretch: normal;
          letter-spacing: 0px;
          color: #270645;
        }
      }
    }
    .selects {
      // margin-top: 13px;
      max-height: 200px;
      overflow-y: auto;
      .nftbox {
        width: 157px;
        height: 61px;
        background-color: #270645;
        border-radius: 3px;
        padding-left: 11px;
        margin-right: 5px;
        margin-bottom: 0;
        margin-top:5px;
        position:relative;
        img {
          width: 36px;
          height: 36px;
          margin: 0;
          object-fit: cover;
        }
        .txt {
          width: 76px;
          // min-height: 36px;
          margin-left: 6px;
          font-family: Helvetica;
          font-size: 12px;
          font-weight: normal;
          font-stretch: normal;
          letter-spacing: 0px;
          color: #fff;
          line-height:12px;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 3;
        }
        .clos {
          background:white;
          position: absolute;
          right:-5px;
          top: -5px;
          width: 23px;
          height: 23px;
          border-radius: 50%;
          margin-right: 0;
          cursor: pointer;
        }
      }
      .add {
        cursor: pointer;
        width: 157px;
        height: 61px;
        background-image: linear-gradient(#ffffff, #ffffff),
          linear-gradient(#f8f6fd, #f8f6fd);
        background-blend-mode: normal, normal;
        border-radius: 3px;
        border: solid 2px #e3e3e3;
        margin-top:5px;
        p {
          width: 70px;
          font-family:Helvetica;
          font-size: 12px;
          font-weight: normal;
          font-stretch: normal;
          letter-spacing: 0px;
          color: #766983;
          margin: 0;
        }
      }
    }
    .select ::v-deep fieldset {
      height: 57px;
      margin-top: -1px;
      // .v-text-field--outlined > .v-input__control ::v-deep.v-input__slot {
      //    min-height:48px!important;
      // }
    }
    .select ::v-deep .v-text-field__slot {
      height: 53px;
      // .v-text-field--outlined > .v-input__control ::v-deep.v-input__slot {
      //    min-height:48px!important;
      // }
    }
  }
  .preferential {
    margin-top: -5px;
    margin: 0 52px;
    width: 490px;
	height: 13px;
	font-family: Helvetica;
	font-size: 13px;
	font-weight: normal;
	font-stretch: normal;
	letter-spacing: 0px;
	color: #766983;
  }

  .maxamount {
    margin: 0 52px;
    height: 56px;

    .amount {
      margin-right: 18px;
      font-weight: bold;
      font-size: 15px;
      min-width: 212px;
      height: 17px;
      color: #270645;
      display: block;
    }

    .txt {
         &::v-deep  .v-input__slot {
      
                height:10px!important;
           
            }
      width: 55px;
      height: 55px;
      // background-image: linear-gradient(#ffffff, #ffffff),
      //   linear-gradient(#f8f6fd, #f8f6fd);
      // background-blend-mode: normal, normal;
      border-radius: 3px;
      display: inline-block;
    }

    .sth {
      margin-left: 40px;
      width: 60px;
      height: 35px;
      // background-color: #270645;
      display: block;
      position: relative;
      right: 0px;
      bottom: 0px;
      border-radius: 18px;
    }
  }

  .footer {
    display: flex;
    flex-direction: column;
    text-align: center;
    margin: 10px auto;

    .price {
      display: block;
      font-family: "\5FAE\8F6F\96C5\9ED1";
      font-size: 13px;
      font-weight: normal;
      font-stretch: normal;
      line-height: 40px;
      color: #766983;
    }

    .sub {
      margin: 0 auto;
      width: 450px;
      height: 51px;
        background-color: #7800f4;
      border-radius: 25px;
      opacity: 0.9;
      font-size: 20px;
      color: #ffffff;
    }
     .subdefect {
      margin: 0px auto;
      width: 450px;
      height: 51px;
      background-image: linear-gradient(#766983, #766983),
        linear-gradient(#270645, #270645);
      background-blend-mode: normal, normal;
      border-radius: 25px;
      opacity: 0.9;
      font-size: 20px;
      color: #ffffff;
    }
    .load-dis{
      
      }
      .load-dis::after {
  content: "";
  background-image: url(../../assets/loading.gif);
  background-size: 100%;
  display: inline-block;
  position: absolute;
  width: 20px;
  height: 20px;
  margin-left: 190px;
}
  }

  &.mobile {
    .maxamount {
      .sth {
        margin-left: 50px;
      }
    }

    .footer {
      margin: 10px 10%;

      .sub {
        width: 80%;
      }
    }
  }
}

img {
  display: inline-block;
  width: 126px;
  height: 126px;
  border-radius: 5px;
  margin: 1px 22px 10px 52px;
}

span {
  align-items: center;
  font-family: "\5FAE\8F6F\96C5\9ED1";
  color: #270645;
}

.sub-dis {
  position: relative;
  pointer-events: none;
  background-image: linear-gradient(#766983, #766983),
    linear-gradient(#270645, #270645) !important;
  background-blend-mode: normal, normal;
  border-radius: 25px;
  opacity: 0.9;
}

.sub-dis::after {
  content: "";
  background-image: url(../../assets/loading.gif);
  background-size: 100%;
  display: inline-block;
  position: absolute;
  width: 20px;
  height: 20px;
  margin-left: 10px;
  margin-top: 5px;

  // box-shadow: 0 0 10px #fff;
  // border-radius: 50%;
}
  ::v-deep .v-messages__message {
    color: red;
  }
</style>

