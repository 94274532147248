<template>
  <div>template</div>
</template>

<script lang="js">

export default {
  name: 'Template',
  props: {},
  data: function() {
    return {};
  }
};
</script>

<style lang="scss" scoped></style>
