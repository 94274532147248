<template>
  <v-list nav dense  class="userList">
    <v-list-item exact v-for="item in userRuter" :key="item.value" color="#fff"
      @click="change(item.value)">
      <v-list-item-content>
        <v-list-item-title class="d-flex flex-row align-center" style="height:22px" :class="{darkFont:isDark }">
         <img :src="item.icon" alt="" class="mr-4"> {{ item.label }}
          <template v-if="item.value === 'Message' && newMsgCount > 0">
            <span class="star"></span>
          </template>
          <template v-if="item.value === 'NightMode'">
            <!-- <span> <v-switch v-model="isDark" @click="clickMode">Night Mode</v-switch></span> -->
            <el-switch v-model="isDark"  @change="clickMode" class="ml-2">Night Mode</el-switch>
           
          </template>
        </v-list-item-title>
      </v-list-item-content>
    </v-list-item>
      <v-dialog v-model="languageOpen" v-if="languageOpen">
      <Language
    
        @getOpen="openLanguage"
      ></Language>
    </v-dialog>
  </v-list>
  
  
</template>

<script lang="js">
import api from "@/api"
 import { killSession  } from "@/walletConnectU";
 import Language from "@/components/popup/language.vue";


export default {
  	
  name: 'UserCenterNav',
    components: {
      Language
    },
  props: {
    darkSelect:Boolean
   
  },
  data: function () {
    return {
      isCookies:'',
      languageOpen:false,
       isDark:false,
      newMsgCount: 0,
	  userRuter: [
        { label: this.$t('showcase_menu'), value: 'Spotlight',icon:require('@/assets/icons/m_spotlight.png')},
	      { label: this.$t('headerUserCenterNavMyHomePage'), value: 'PersonalPage',icon:require('@/assets/icons/m_myprofile.png') },
        { label: this.$t('headerUserCenterNavMyAsset'), value: 'MyAsset',icon:require('@/assets/icons/m_assets.png')},
        { label: this.$t('headerUserCenterNavMyLuckyDraw'),value:'Lucky',icon:require('@/assets/icons/m_lottery.png')}, 
	      { label: this.$t('scroe_task_center'), value: 'PointsCenter',icon:require('@/assets/icons/m_points.png')},
	      { label: this.$t('headerUserCenterNavMyFavorites'), value: 'Collect',icon:require('@/assets/icons/m_favorites.png')},
        { label: this.$t('headerUserCenterNavMyOrders'), value: 'MyOrders',icon:require('@/assets/icons/m_orders.png') },
        { label: this.$t('headerUserCenterNavMyReferences'), value: 'MyReferences',icon:require('@/assets/icons/m_social.png') },
	      { label: this.$t('messageMessage'), value: 'Message',icon:require('@/assets/icons/m_messages.png')},
        { label: this.$t('headerUserCenterNavSetting'), value: 'Setting',icon:require('@/assets/icons/m_settings.png')},  
        { label: this.$t('headerUserCenterNavLanguage'), value: 'Language',icon:require('@/assets/icons/m_language.png')}, 
        { label: this.$t('headerUserCenterNavDarkMode'), value: 'NightMode',icon:require('@/assets/icons/m_darkmode.png')},      
	      { label: this.$t('headerUserCenterNavdisConnect'), value: 'DisConnect',icon:require('@/assets/icons/m_disconnect.png') },
	  
	    ],
		isPost:false,
		
    };
  },
  computed: {



  },
       watch:{
         isDark: {
      handler: function (newValue) {
        this.$vuetify.theme.dark = newValue
      },
     
      immediate: true,
    },
     darkSelect(value){
        console.log("darkSelect",value);
        this.isDark = value
      },
      },
 
  async mounted() {

    // 退出登录记录cookies acceptIndexCookie
       let cookies = localStorage.getItem("acceptIndexCookie");
       this.isCookies = cookies
       // 初始化
    let currentChain = `${this.$env.VUE_APP_MARKET_TYPE}`

 if(currentChain == 'BSC' || currentChain== 'POLYGON' || currentChain == 'ARBITRUM'){
      this.userRuter= [
        { label: this.$t('showcase_menu'), value: 'Spotlight',icon:require('@/assets/icons/m_spotlight.png')},
	      { label: this.$t('headerUserCenterNavMyHomePage'), value: 'PersonalPage',icon:require('@/assets/icons/m_myprofile.png') },
	      { label: this.$t('scroe_task_center'), value: 'PointsCenter',icon:require('@/assets/icons/m_points.png')},
	      { label: this.$t('headerUserCenterNavMyFavorites'), value: 'Collect',icon:require('@/assets/icons/m_favorites.png')},
        { label: this.$t('headerUserCenterNavMyOrders'), value: 'MyOrders',icon:require('@/assets/icons/m_orders.png') },
        { label: this.$t('headerUserCenterNavMyReferences'), value: 'MyReferences',icon:require('@/assets/icons/m_social.png') },
	      { label: this.$t('messageMessage'), value: 'Message',icon:require('@/assets/icons/m_messages.png')},
        { label: this.$t('headerUserCenterNavSetting'), value: 'Setting',icon:require('@/assets/icons/m_settings.png')},  
        { label: this.$t('headerUserCenterNavLanguage'), value: 'Language',icon:require('@/assets/icons/m_language.png')}, 
        { label: this.$t('headerUserCenterNavDarkMode'), value: 'NightMode',icon:require('@/assets/icons/m_darkmode.png')},      
	      { label: this.$t('headerUserCenterNavdisConnect'), value: 'DisConnect',icon:require('@/assets/icons/m_disconnect.png') },
	  
	    ]
    }
  //  if(cookies){
  //     if(cookies == 'false'){
  //    this.isopenCookies = true
  //  }else{
  //    this.isopenCookies = false
  //    this.loaded();
  //  }
  //  }else{
  //    this.isopenCookies = true

  //  }

          // 判断是否为黑暗模式
        let isDarkMode =  localStorage.getItem('isDarkMode');
        if(isDarkMode){
          if(isDarkMode == 'true'){
              this.isDark = true
           }else{
             this.isDark = false 
           } 
        }else{
        this.isDark = false
        }
        this.$forceUpdate();
    window.eventBus.$on('LangChange', this.onLangChange);

    if (this.$store.state.did != null) {
      let param = { address: this.$store.state.did };
	  console.log("info===6")
	  if(!param.address){
		  return
	  }
	  if(this.isPost){
		  return;
	  }
	  this.isPost=true
      let res = await api.home.loopringUserInfo(param);
	  if(res.data){
		  this.isPost=false
		  let grade = res.data.grade;
		  if (grade == 2 || grade == 4) {
		    let isAppeal = await api.home.checkComplaint({
		      did: this.$store.state.userAddress
		    });
		    
		  }
	  }


    }

     let res2 = await api.home.isNewMessage({address:this.$store.state.did});
     this.newMsgCount = res2.data; 
     window.eventBus.$on("ViewMessage", async () => {
         let res3 = await api.home.isNewMessage({address:this.$store.state.did});
         this.newMsgCount = res3.data;
     });
  },
  methods: {
    openLanguage(e){
      this.languageOpen = e
    },
    clickMode(){
      this.$emit("DarkMode",this.isDark)
       localStorage.setItem('isDarkMode', Boolean(this.isDark) );
        window.eventBus.$emit("DarkModeChange", Boolean(this.isDark));
    },
    change: async function (v) {
       this.$gtag.event('菜单栏-'+ v, { 'event_category': 'Click', 'event_label': v })
      if (v == "DisConnect") {
        const data = localStorage.getItem("walletconnect");
         if(data) {
          await killSession();
         }
      
        debugger
        localStorage.clear();
        sessionStorage.clear();
   
        this.$store.commit('SET_DID', "");
        window.bscAddress = "";
         sessionStorage.setItem('initApplication',true)
          localStorage.setItem('isDarkMode', Boolean(this.isDark) );
          if(this.isCookies){
             localStorage.setItem("acceptIndexCookie",this.isCookies);
          }
        this.$router.push({ name: 'Home' })
        window.location.reload();
      }else if(v == 'appeal'){
        console.log('wxl --ee')
      }else if(v == 'NightMode'){
        console.log('wxl --ee')
      }
      else if(v == 'Spotlight'){
         this.$router.push({
            name: "Spotlight",
            query: { did: this.$store.state.did},
          });
      } else if(v == 'MyAsset'){
         window.open("https://myassets.uptick.network/");
      }else if(v== 'Language'){
        this.languageOpen = true
        console.log("lsssssss");
      }
      
       else {
        this.$router.push({ name: v });
      }
       this.$emit('change',v)
    },
    onLangChange(){

       let currentChain = `${this.$env.VUE_APP_MARKET_TYPE}`

 if(currentChain == 'BSC' || currentChain== 'POLYGON' || currentChain == 'ARBITRUM'){
      this.userRuter= [
        { label: this.$t('showcase_menu'), value: 'Spotlight',icon:require('@/assets/icons/m_spotlight.png')},
	      { label: this.$t('headerUserCenterNavMyHomePage'), value: 'PersonalPage',icon:require('@/assets/icons/m_myprofile.png') }, 
	      { label: this.$t('scroe_task_center'), value: 'PointsCenter',icon:require('@/assets/icons/m_points.png')},
	      { label: this.$t('headerUserCenterNavMyFavorites'), value: 'Collect',icon:require('@/assets/icons/m_favorites.png')},
        { label: this.$t('headerUserCenterNavMyOrders'), value: 'MyOrders',icon:require('@/assets/icons/m_orders.png') },
        { label: this.$t('headerUserCenterNavMyReferences'), value: 'MyReferences',icon:require('@/assets/icons/m_social.png') },
	      { label: this.$t('messageMessage'), value: 'Message',icon:require('@/assets/icons/m_messages.png')},
        { label: this.$t('headerUserCenterNavSetting'), value: 'Setting',icon:require('@/assets/icons/m_settings.png')},  
        { label: this.$t('headerUserCenterNavLanguage'), value: 'Language',icon:require('@/assets/icons/m_language.png')}, 
        { label: this.$t('headerUserCenterNavDarkMode'), value: 'NightMode',icon:require('@/assets/icons/m_darkmode.png')},      
	      { label: this.$t('headerUserCenterNavdisConnect'), value: 'DisConnect',icon:require('@/assets/icons/m_disconnect.png') },
	  
	    ]
    }else{
       this.userRuter= [
        { label: this.$t('showcase_menu'), value: 'Spotlight',icon:require('@/assets/icons/m_spotlight.png')},
	      { label: this.$t('headerUserCenterNavMyHomePage'), value: 'PersonalPage',icon:require('@/assets/icons/m_myprofile.png') },
        { label: this.$t('headerUserCenterNavMyAsset'), value: 'MyAsset',icon:require('@/assets/icons/m_assets.png')},
        { label: this.$t('headerUserCenterNavMyLuckyDraw'),value:'Lucky',icon:require('@/assets/icons/m_lottery.png')}, 
	      { label: this.$t('scroe_task_center'), value: 'PointsCenter',icon:require('@/assets/icons/m_points.png')},
	      { label: this.$t('headerUserCenterNavMyFavorites'), value: 'Collect',icon:require('@/assets/icons/m_favorites.png')},
        { label: this.$t('headerUserCenterNavMyOrders'), value: 'MyOrders',icon:require('@/assets/icons/m_orders.png') },
        { label: this.$t('headerUserCenterNavMyReferences'), value: 'MyReferences',icon:require('@/assets/icons/m_social.png') },
	      { label: this.$t('messageMessage'), value: 'Message',icon:require('@/assets/icons/m_messages.png')},
        { label: this.$t('headerUserCenterNavSetting'), value: 'Setting',icon:require('@/assets/icons/m_settings.png')},  
        { label: this.$t('headerUserCenterNavLanguage'), value: 'Language',icon:require('@/assets/icons/m_language.png')}, 
        { label: this.$t('headerUserCenterNavDarkMode'), value: 'NightMode',icon:require('@/assets/icons/m_darkmode.png')},      
	      { label: this.$t('headerUserCenterNavdisConnect'), value: 'DisConnect',icon:require('@/assets/icons/m_disconnect.png') },
	  
	    ];
    }
     
    }
   
  }
};
</script>

<style lang="scss" scoped>
img{
  width:20px;
  height: 20px;
}
.v-list-item--active {
  background: #270645;

  .v-list-item__title {
    color: #fff;

  }
}
.userList {
  &::v-deep .v-list-item {
    min-height: 37px !important;
    .v-list-item__content {
      padding: 5px 0;
    }
  }
   &::v-deep .v-input__slot{
     margin-top: -2px;
     margin-left: 5px;
   }
}

.star {
  position: relative;

  &::after {
    content: ".";
    font-size: 60px;
    color: red;
    position: absolute;
    right: -20px;
    top: -25px;
  }

}
</style>
