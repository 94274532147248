<template>
   <div>
      <v-menu v-model="show" :attach="$el" right offset-y nudge-bottom="10px">
         <template v-slot:activator="{ on, attrs, value }" >
            <Chip v-if="!isMobile || isIpad " v-bind="attrs" v-on="on" pill class="pl-0" :class="{darkBackgroung:$vuetify.theme.dark}">
               <!-- [todo: size 40] -->
                <v-avatar :size="40" style="overflow:visible; position: relative;margin-left: 4px;">
               <v-img :src="usersrc"/>
               <img
			      v-if="grade==3"
			     src="@/assets/icons/icon_v.png"
			     alt="V"
			   	class="middlev"
			   />
            <!-- <img
            v-if="isNftImage"
			     src="@/assets/icons/icon_nft3.png"
			     alt="V"
			   	class="avatalImg"
			   /> -->
               </v-avatar>
               <span class="ml-4 user" :class="{ star: newMsgCount > 0,darkFont: $vuetify.theme.dark}"  >{{ userName }}</span>
               <v-icon class="ml-3" color="primary" v-if="value">mdi-menu-up
               </v-icon>
               <v-icon class="ml-3" color="primary" v-else>mdi-menu-down</v-icon>
            </Chip>
            <Chip v-else v-bind="attrs" class="pa-0" @click="avatarClick">
               <v-avatar :size="48" class="mobile-avatar">
                  <v-img :src="usersrc" />
               </v-avatar>
            </Chip>
         </template>
         <UserCenterNav @change="change" @DarkMode='darkchange' :darkSelect='darkSelect' />
      </v-menu>
      <v-dialog v-model="openAppeal">
         <accountAppeal @getOpen="Open"></accountAppeal>
      </v-dialog>

      <v-dialog v-model="disAppeal">
         <v-card>
            <img class="card-close" width="31px" height="31px" @click="disAppeal = false" :src="$vuetify.theme.dark?darkcloseIcon:closeIcon"
               alt="" />
            <div style="width: 500px; height: 300px;">
               <!-- <img alt="logo" src="/src/assets/image/raredroplogo.ico" height="43px" width="auto" class="logo"> -->
               <div class="text">
                  <h1>用户不可以申诉</h1>
               </div>
            </div>
         </v-card>
      </v-dialog>
   </div>
</template>

<script lang="js">
import Chip from "@/components/showCaseChip.vue";
import UserCenterNav from "./UserCenterNav.vue";
import AccountAppeal from "@/components/popup/accountAppeal.vue";
import api from "@/api";
import { getFileSrc } from "@/utils/file";
import {  events } from "@/walletConnectU";

const AVATAR_KEY = "AVATAR";
import defaultImg from "@/assets/default.png";


export default {
   name: "UserCenterSelect",
   components: {
      Chip,
      UserCenterNav,
      AccountAppeal
   },
   props: {
    darkSelect:Boolean
   
  },
   data: function () {
      return {
         openAppeal: false,
         disAppeal: false,
         show: false,
         userName: "",
         usersrc: "",
         defaultImg,
         newMsgCount: 0,
          grade:"",
          isNftImage:false,
          isIpad:false,
          isDark:false,
          closeIcon:require('@/assets/btn_close.png'),
         darkcloseIcon:require('@/assets/darkimage/btn_close1.png'),

      };
   },
  
   computed: {
      isMobile: function () {
         return this.$vuetify.breakpoint.mobile;
      },
      
   },
   methods: {
      backgroundUpdate(){
      this.init();
    },
      darkchange(value){
         console.log('darkchange',value);
         this.isDark = value
      },
      onResize () {
        this.isIpad = 900<window.innerWidth
      },
      async init() {
      //   this.isDark =  this.$vuetify.theme.dark

         // 判断有新消息

          let res3 = await api.home.isNewMessage({address:this.$store.state.did});
          this.newMsgCount = res3.data;

         if (this.$store.state.did != null) {
            let param = { address: this.$store.state.did };
            let res = await api.home.loopringUserInfo(param);
            if (res.data != null) {
               window.sessionStorage.setItem("UserData", JSON.stringify(res.data));
                 this.grade = res.data.grade
                 this.isNftImage = res.data.isNftImage

               if (res.data && res.data.profilePhoto) {
             
                  this.imgUrl = res.data.profilePhoto;
                  this.usersrc = await getFileSrc(AVATAR_KEY, this.imgUrl);
               } else {
                  this.usersrc = defaultImg;
                
               }
               if (res.data && res.data.name) {
                  this.userName = res.data.name;
                 
               } else {

                  this.userName = `${this.$store.state.did.substr(0, 3)}...${this.$store.state.did.substr(-3, 3)}`;
               }

            } else {
               this.usersrc = defaultImg;
               this.userName = `${this.$store.state.did.substr(0, 3)}...${this.$store.state.did.substr(-3, 3)}`;
               // this.$router.push({
               //    name: "MyInfo"
               // });
            }
            
         }
      },
      async change(v) {
         if (v.toLowerCase() == "appeal") {
            this.openAppeal = true;
         } else if (v.toLowerCase() == "appealed") {
            this.disAppeal = true;
         }

         this.show = false;
      },
     
      Open(e) {
         this.openAppeal = e;
      },

      avatarClick() {
         this.$emit("click");
      },
    async allReadMessage(){
           let res3 = await api.home.isNewMessage({address:this.$store.state.did});
          this.newMsgCount = res3.data;
      }
   },
     beforeDestroy () {
      if (typeof window === 'undefined') return

      window.removeEventListener('resize', this.onResize, { passive: true })
    },
   async mounted() {
      events.$on("connect", res => {
     this.init()
       
  
      });
      window.eventBus.$on('backgroundUpdate', this.backgroundUpdate);
            // 判断是否为黑暗模式
        let isDarkMode =  localStorage.getItem('isDarkMode');
        if(isDarkMode){
             if(isDarkMode == 'true'){
              this.isDark = true
           }else{
             this.isDark = false 
           } 
        }else{
            this.isDark = false
        }
            this.onResize()
      window.addEventListener('resize', this.onResize, { passive: true })
      window.eventBus.$on("MidifyUser", this.init);
         window.eventBus.$on("allReadMessage", this.allReadMessage);
      this.init();
   //     let res2 = await api.home.isNewMessage({userAddress:this.$store.state.did});
   //    this.newMsgCount = res2.data;


   //   // 有新消息进行弹窗
   //   if(this.newMsgCount >0){
   //      let params={
   //      pageSize: 10,
   //      pageNumber: 1,
   //      owner:this.$store.state.did,
   //      isRead:false
   //    };
   //    let res = await api.home.sysMessage(params)
   //    console.log("wxl --- sysMessage",res)
   //    if(res.data){
   //       let list = res.data.list
   //       for (let index = 0; index < list.length; index++) {
   //         setTimeout(() => {
   //            this.$mtip({
   //              title:list[index].pushContent,
   //            });
   //         }, 1000);
           
   //       }
   //    }
   //    this.newMsgCount = 0
   //   }

      window.eventBus.$on("ViewMessage", async () => {
          let res3 = await api.home.isNewMessage({address:this.$store.state.did});
          this.newMsgCount = res3.data;
      });
      
      
      
   },
   
};
</script>

<style lang="scss" scoped>
.v-menu__content{
  position:fixed !important;
  top: 80px !important;
}
.darkBackgroung{
       background-image: linear-gradient(#272727, #272727), linear-gradient(#272727, #272727) !important;
}
.user {
   text-overflow: ellipsis;
   white-space: nowrap;
   overflow: hidden;
   width: 55px;
}

.card-close {
   display: block;
   position: absolute;
   right: 10px;
   top: 10px;
   border-radius: 50%;
   cursor: pointer;
}
  .middlev{
	   width:15px;
	  height:15px;
	    position: absolute;
	  right: -4px;
	  bottom: 1px;
	}
   .avatalImg{
      width:15px;
	  height:15px;
	    position: absolute;
	  right: 20px;
	  bottom: 1px;

   }

.logo {
   margin: 20px;
}

.text {
   display: flex;
   height: 300px;
   justify-content: center;
   align-items: center;
   letter-spacing: 0.4rem;
}

.star::before {
   content: ".";
   font-size: 60px;
   color: red;
   position: absolute;
   left: 30px;
   top: -15px;
}

.mobile-avatar {
   width: 40px !important;
   height: 40px !important;
}
</style>
