<template>
<div class="contain">
  <v-carousel hide-delimiters height="auto"  cycle interval="10000" class="rounded-carousel" > 
    <!-- <v-carousel-item
      v-for="(item,i) in items"
      :key="i"
      :src="item.src"
      cover
    ></v-carousel-item> -->

       <v-carousel-item
       v-for="(item,i) in items"
      :key="i"
        >
            <div class="card">  
               <drop-card :dropList="item"  ></drop-card>
            </div>
  
        </v-carousel-item>

  </v-carousel>
    <loading  :isShowLoading="isShowLoading" ></loading>
</div>

</template>

<script>

import dropCard from "./workCard/dropCarousCard.vue";
import Loading from "@/components/loading.vue";
import api from "@/api";
export default {
  name: "dropCarousel",

  components: {
    dropCard,
    Loading
  },
  data:function () {
    return{
      isShowLoading: false,
       items: [
         
        ],  
    }
  },
  async mounted(){
    this.isShowLoading = true
    let res= await api.home.DropsList()
    this.items = res.data
    this.isShowLoading =false
  }
  
};
</script>
<style lang='scss' scoped>
.contain{
  &::v-deep .v-responsive {
  border-radius: 15px;
     overflow :hidden;
            transform :translateY(0);
}
// &::v-deep .v-carousel__item {
//   height: 550px !important;
// }
.rounded-carousel {
  border-radius: 15px;
  overflow :hidden;
            transform :translateY(0);
}

}

</style>


