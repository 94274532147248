<template>
  <div id="container">
    <v-card :class="{ card: true, mobile: isMobile }">
      <img
        class="clo"
        @click="closeDialog"
        :src="$vuetify.theme.dark ? darkcloseIcon : closeIcon"
        alt=""
      />
      <div class="content">
        <div class="cross_title" :class="{ darkFont: $vuetify.theme.dark }">
          {{ $t("cross_transfer") }}
        </div>
        <div class="cross_des mt-4" :class="{darkFont: $vuetify.theme.dark }">{{ $t("cross_des") }}</div>
        <div class="iris_des mt-4" v-if="this.currentToken == 'IRIS'">
          {{ $t("iris_des") }}
        </div>
        <div class="title-12" v-if="this.currentToken == 'IRIS'">
          {{ $t("expected_address") }} 
        </div>
        <div class="title-12" v-if="this.currentToken == 'IRIS'">
          {{UptickAddress}}
        </div>
    
        <div class="title-12" v-if="this.currentToken == 'IRIS'">
          {{ $t("current_address") }} 
        </div>
        <div class="title-12" v-if="this.currentToken == 'IRIS'">
        {{ irisToUptickAddress  }} <span v-if="UptickAddress.toLowerCase() != irisToUptickAddress.toLowerCase()"> ,</span> {{irisAddress}} <span v-if="UptickAddress.toLowerCase() != irisToUptickAddress.toLowerCase()">{{$t("please_change")}}</span> 
        </div>
        <div class="cross d-flex flex-row mt-7">
          <div class="left">
            <img class="img" :src="productPic" alt="" />
            <div
              class="nftName mt-4"
              :class="{ darkFont: $vuetify.theme.dark }"
            >
              {{ this.parentValue.name }}
            </div>
            <div class="d-flex flex-row mt-3">
              <div class="title-12 mb-1">{{ $t("Quantity") }}</div>
              <div class="title-12" :class="{ darkFont: $vuetify.theme.dark }">
                {{ this.parentValue.amount }}
              </div>
            </div>
          </div>
          <div class="right">
            <div class="top d-flex flex-row align-center">
              <div
                class="from d-flex flex-column align-center"
                v-if="srcName == 'UPTICK'"
              >
                <div
                  class="fromtitle"
                  :class="{ darkFont: $vuetify.theme.dark }"
                >
                  {{ $t("saledetailHistoryForm") }}
                </div>
                <img
                  :src="$vuetify.theme.dark ? upticDarkLogo : uptickLogo"
                  class="mt-5"
                  width="50"
                  height="50"
                  alt=""
                />
                <div
                  class="chainName mt-5"
                  :class="{ darkFont: $vuetify.theme.dark }"
                >
                  Uptick Chain
                </div>
              </div>
              <div
                class="from d-flex flex-column align-center"
                v-else-if="srcName == 'BSC'"
              >
                <div
                  class="fromtitle"
                  :class="{ darkFont: $vuetify.theme.dark }"
                >
                  {{ $t("saledetailHistoryForm") }}
                </div>
                <img
                  src="@/assets/chainicon/chain_bsc.png"
                  class="mt-5"
                  width="50"
                  height="50"
                  alt=""
                />
                <div
                  class="chainName mt-5"
                  :class="{ darkFont: $vuetify.theme.dark }"
                >
                  BSC
                </div>
              </div>
              <div
                class="from d-flex flex-column align-center"
                v-else-if="srcName == 'POLYGON'"
              >
                <div
                  class="fromtitle"
                  :class="{ darkFont: $vuetify.theme.dark }"
                >
                  {{ $t("saledetailHistoryForm") }}
                </div>
                <img
                  src="@/assets/chainicon/chain_polygon.png"
                  class="mt-5"
                  width="50"
                  height="50"
                  alt=""
                />
                <div
                  class="chainName mt-5"
                  :class="{ darkFont: $vuetify.theme.dark }"
                >
                  POLYGON
                </div>
              </div>
              <div
                class="from d-flex flex-column align-center"
                v-else-if="srcName == 'ARBITRUM'"
              >
                <div
                  class="fromtitle"
                  :class="{ darkFont: $vuetify.theme.dark }"
                >
                  {{ $t("saledetailHistoryForm") }}
                </div>
                <img
                  src="@/assets/icons/chain_arbitrum.png"
                  class="mt-5"
                  width="50"
                  height="50"
                  alt=""
                />
                <div
                  class="chainName mt-5"
                  :class="{ darkFont: $vuetify.theme.dark }"
                >
                  ARBITRUM
                </div>
              </div>
              <img
                src="@/assets/icons/icon_jtcross.png"
                class="ml-6 mr-6"
                width="22"
                height="15"
                alt=""
              />
              <div class="from d-flex flex-column align-center">
                <div
                  class="fromtitle"
                  :class="{ darkFont: $vuetify.theme.dark }"
                >
                  {{ $t("saledetailHistoryTo") }}
                </div>
                <img
                  :src="
                    require('@/assets/chainicon/' +
                      `${
                        currentToken == 'BSC'
                          ? 'chain_bsc'
                          : currentToken == 'IRIS'
                          ? 'chain_iris'
                          : currentToken == 'UPTICK'
                          ? $vuetify.theme.dark ? 'chain_dark_uptick':'chain_uptick'
                          : currentToken == 'ARBITRUM'
                          ? 'chain_arbitrum'
                          : 'chain_polygon'
                      }` +
                      '.png')
                  "
                  class="mt-5"
                  width="50"
                  height="50"
                  alt=""
                />
                <div
                  class="chainName mt-5"
                  :class="{ darkFont: $vuetify.theme.dark }"
                >
                  {{ currenShowtToken }}
                </div>
              </div>
            </div>
            <div class="Quantity d-flex flex-row mt-3">
              <div class="From"></div>
              <div class="emptyNumber"></div>
              <div class="From">
                <div class="numbertitle mb-1">{{ $t("Target_chain") }}</div>
                <div
                  class="chainList d-flex flex-row"
                  v-if="srcName == 'UPTICK'"
                >
                  <img
                    v-if="parentValue.nftType == 'ERC721' && this.srcName == 'UPTICK'"
                    :src="
                      require('@/assets/chainicon/' +
                        `${
                          currentToken == 'IRIS'
                            ? 'logo_irisnet'
                            : 'logo_irisnet1'
                        }` +
                        '.png')
                    "
                    @click="chainChange('IRIS', 'IRISnet')"
                    alt=""
                    class="mr-2"
                    width="30"
                    height="30"
                  />
                  <img
                    v-if="
                      systemPolygonContractAddress != parentValue.nftAddress &&
                      systemArbitrumContractAddress != parentValue.nftAddress
                    "
                    :src="
                      require('@/assets/chainicon/' +
                        `${currentToken == 'BSC' ? 'logo_bsc' : 'logo_bsc1'}` +
                        '.png')
                    "
                    @click="chainChange('BSC', 'BSC')"
                    alt=""
                    width="30"
                    height="30"
                    class="mr-2"
                  />
                  <!-- <img  :src="require('@/assets/chainicon/' +`${currentToken == 'IRIS' ?'logo_irisnet':'logo_irisnet1'}`  + '.png')" @click="chainChange('IRIS')" alt="" width="30" height="30" class="mr-2"> -->
                  <img
                    v-if="
                      systemBscContractAddress != parentValue.nftAddress &&
                      systemArbitrumContractAddress != parentValue.nftAddress
                    "
                    :src="
                      require('@/assets/chainicon/' +
                        `${
                          currentToken == 'POLYGON'
                            ? 'logo_polygon'
                            : 'logo_polygon1'
                        }` +
                        '.png')
                    "
                    @click="chainChange('POLYGON', 'Polygon')"
                    alt=""
                    width="30"
                    height="30"
                    class="mr-2"
                  />
                  <img
                    v-if="
                      systemBscContractAddress != parentValue.nftAddress &&
                      systemPolygonContractAddress != parentValue.nftAddress
                    "
                    :src="
                      require('@/assets/chainicon/' +
                        `${
                          currentToken == 'ARBITRUM'
                            ? 'logo_arbitrum'
                            : 'logo_arbitrum1'
                        }` +
                        '.png')
                    "
                    @click="chainChange('ARBITRUM', 'Arbiturm')"
                    alt=""
                    width="30"
                    height="30"
                    class="mr-2"
                  />
                </div>
                <div class="chainList d-flex flex-row" v-if="srcName == 'BSC'">
                  <img
                    :src="
                      require('@/assets/chainicon/' +
                        `${
                          currentToken == 'UPTICK'
                            ? 'logo_uptickchain'
                            : 'logo_uptickchain1'
                        }` +
                        '.png')
                    "
                    @click="chainChange('UPTICK', 'Uptick Chain')"
                    alt=""
                    width="30"
                    height="30"
                    class="mr-2"
                  />
                  <!-- <img  :src="require('@/assets/chainicon/' +`${currentToken == 'IRIS' ?'logo_irisnet':'logo_irisnet1'}`  + '.png')" @click="chainChange('IRIS')" alt="" width="30" height="30" class="mr-2"> -->
                  <!-- <img  :src="require('@/assets/chainicon/' +`${currentToken == 'POLYGON' ?'logo_polygon':'logo_polygon1'}`  + '.png')" @click="chainChange('POLYGON')" alt="" width="30" height="30"> -->
                </div>
                <div
                  class="chainList d-flex flex-row"
                  v-if="srcName == 'POLYGON'"
                >
                  <img
                    :src="
                      require('@/assets/chainicon/' +
                        `${
                          currentToken == 'UPTICK'
                            ? 'logo_uptickchain'
                            : 'logo_uptickchain1'
                        }` +
                        '.png')
                    "
                    @click="chainChange('UPTICK', 'Uptick Chain')"
                    alt=""
                    width="30"
                    height="30"
                    class="mr-2"
                  />
                  <!-- <img  :src="require('@/assets/chainicon/' +`${currentToken == 'IRIS' ?'logo_irisnet':'logo_irisnet1'}`  + '.png')" @click="chainChange('IRIS')" alt="" width="30" height="30" class="mr-2"> -->
                  <!-- <img :src="require('@/assets/chainicon/' +`${currentToken == 'BSC' ?'logo_bsc':'logo_bsc1'}`  + '.png')" @click="chainChange('BSC')" alt="" width="30" height="30" class="mr-2"> -->
                </div>
                <div
                  class="chainList d-flex flex-row"
                  v-if="srcName == 'ARBITRUM'"
                >
                  <img
                    :src="
                      require('@/assets/chainicon/' +
                        `${
                          currentToken == 'UPTICK'
                            ? 'logo_uptickchain'
                            : 'logo_uptickchain1'
                        }` +
                        '.png')
                    "
                    @click="chainChange('UPTICK', 'Uptick Chain')"
                    alt=""
                    width="30"
                    height="30"
                    class="mr-2"
                  />
                  <!-- <img  :src="require('@/assets/chainicon/' +`${currentToken == 'IRIS' ?'logo_irisnet':'logo_irisnet1'}`  + '.png')" @click="chainChange('IRIS')" alt="" width="30" height="30" class="mr-2"> -->
                  <!-- <img :src="require('@/assets/chainicon/' +`${currentToken == 'BSC' ?'logo_bsc':'logo_bsc1'}`  + '.png')" @click="chainChange('BSC')" alt="" width="30" height="30" class="mr-2"> -->
                </div>
              </div>
            </div>
            <div class="footer">
              <span class="price mb-2"
                >{{ $t("popupAuctionDefectPriceHandingFee") }}：{{ fee }}
                {{ chain }}</span
              >
              <button
                class="subdefect v-btn uc"
                :class="{ 'load-dis': isLoad }"
                v-if="isLoad"
              >
                {{ $t("loadBalance") }}
              </button>
              <button
                class="sub"
                :class="{ 'sub-dis': isPay }"
                v-else-if="isBalance"
                @click="subCommit"
              >
                {{ $t("popupTransferCommit") }}
              </button>
              <button
                class="subdefect"
                v-else-if="!isBalance"
                disabled="disabled"
              >
                {{ $t("popupAuctionDefectPriceInsufficientFunds") }}
              </button>
              <span class="price mt-2"
                >{{ $t("popupNewTransferBalance") }}：{{ value3 }} {{ chain }}
              </span>
            </div>
          </div>
        </div>
      </div>
    </v-card>
    <uComponents ref="ucom"></uComponents>
  </div>
</template>
<script>
import api from "@/api";

import {
  getCrossID,
  getBrageType,
  convertFromERC2Metadata,
} from "@/utils/helper";
import Web3 from "web3";

const web3Obj = new Web3();

import {
  addUptickNetwork,
  getAccountInfo,
  uptickERC721ToIris,
} from "@/keplr/wallet";

export default {
  data: () => ({
    open: true,
    uptickLogo: require("@/assets/chainicon/chain_uptick.png"),
    upticDarkLogo: require("@/assets/chainicon/chain_dark_uptick.png"),
    isPay: false,
    multiNFTids: [],
    closeIcon: require("@/assets/btn_close.png"),
    darkcloseIcon: require("@/assets/darkimage/btn_close1.png"),
    currentToken: "IRIS",
    currenShowtToken: "IRISnet",
    isLoad: false,
    isBalance: false,
    fee: "",
    irisAddress: "",
    irisToUptickAddress:"",
    value3: "",
    chain: "UPTICK",
    chainAddress: "0x0000000000000000000000000000000000000000",
    productPic: "",
    contractAddress: "",
    srcName: "",
    systemParams: {},
    systemBscContractAddress: "",
    systemPolygonContractAddress: "",
    systemArbitrumContractAddress: "",
    systemUptickContractAddress: "",
    genericHandlerContract: "",
    myInfo: {},
    EvmAddress: "",
    UptickAddress: "",
    
  }),
  props: {
    parentValue: {
      type: Object,
    },
  },
  computed: {
    isMobile: function () {
      return this.$vuetify.breakpoint.mobile;
    },
  },
  async mounted() {
    // 获取跨链BSC/POLYGON合约地址
    this.systemBscContractAddress = `${this.$env.VUE_APP_RECEIVE_BSC_CONTRACT}`;
    this.systemPolygonContractAddress = `${this.$env.VUE_APP_RECEIVE_POLYGON_CONTRACT}`;
    this.systemArbitrumContractAddress = `${this.$env.VUE_APP_ARBITRUM_CONTRACT}`;
    this.systemUptickContractAddress = `${this.$env.UE_APP_RECEIVE_UPTICK_CONTRACT}`;
    let currentSymbol = `${this.$env.VUE_APP_ADD_NETWORK_CHAIN_SYMBOL}`;

    console.log(
      "-----------",
      this.systemBscContractAddress,
      this.systemPolygonContractAddress
    );

    // 初始化
    let currentChain = `${this.$env.VUE_APP_MARKET_TYPE}`;
    this.srcName = currentChain;
    if (this.srcName == "UPTICK") {
      // this.currentToken = 'BSC'
      if (this.systemBscContractAddress == this.parentValue.nftAddress) {
        this.currentToken = "BSC";
        this.currenShowtToken = "BSC";
      } else if (
        this.systemArbitrumContractAddress == this.parentValue.nftAddress
      ) {
        this.currentToken = "ARBITRUM";
        this.currenShowtToken = "Arbitrum";
      } else {
        if (this.parentValue.nftType == "ERC1155") {
          this.currentToken = "BSC";
          this.currenShowtToken = "BSC";
        } else {
          this.currentToken = "IRIS";
          this.currenShowtToken = "IRISnet";
        }
      }
    } else if (this.srcName == "BSC") {
      this.currentToken = "UPTICK";
      this.currenShowtToken = "Uptick Chain";
      this.chain = "BNB";
    } else if (this.srcName == "POLYGON") {
      this.currentToken = "UPTICK";
      this.currenShowtToken = "Uptick Chain";
      this.chain = "MATIC";
    } else if (this.srcName == "ARBITRUM") {
      this.currentToken = "UPTICK";
      this.currenShowtToken = "Uptick Chain";
      this.chain = currentSymbol;
    }

    console.log("toAddress", this.$store.state.did);
    console.log("pageValue", this.parentValue);

    let metadataUrls = this.parentValue.imgUrl.indexOf("https");

    if (metadataUrls == -1) {
      this.productPic = await api.image.getNftImg(this.parentValue.imgUrl);
    } else {
      this.productPic = this.parentValue.imgUrl;
    }

  await  this.getBridgeFee();
  if (this.currentToken == "IRIS") {
      await addUptickNetwork();
      const irisChainId = process.env.VUE_APP_IRIS_CHAIN_ID;
      const uptickChainId = process.env.VUE_APP_UPTICK_CHAIN_ID;
      let res = await getAccountInfo(irisChainId);
      let res1 = await getAccountInfo(uptickChainId);
         // 获取Uptick 地址
     let params = {
      address: this.$store.state.did,
    };

    let res2 = await api.home.loopringUserInfo(params);
    this.myInfo = res2.data;
    this.UptickAddress = this.myInfo.uptickAddress;
    this.EvmAddress = this.myInfo.address;
      this.irisAddress = res.address;
      this.irisToUptickAddress = res1.address
      this.fee = 0;
    }

  
  },
  methods: {

    async chainChange(token, showToken) {
      this.currentToken = token;
      this.currenShowtToken = showToken;
      console.log("this.currentToken", this.currentToken);
      if (this.currentToken != "IRIS") {
        await this.getBridgeFee();

      }else{
        this.fee = 0
      }
   
    },
    async getBridgeFee() {
      try {
        this.isLoad = true;
      if (this.currentToken != "IRIS") {
        let result = await api.home.getFee();
      this.systemParams = result.data;
      this.contractAddress = result.data.ucbBridge;
      this.genericHandlerContract = result.data.genericHandler;
      if (this.parentValue.nftType == "ERC721") {
        this.genericHandlerContract = result.data.erc721Handler;
      } else {
        this.genericHandlerContract = result.data.erc1155Handler;
      }
    

      // 获取跨链手续费
      this.$wallet.setContractAddress(this.parentValue.nftType, {
        address: this.parentValue.nftAddress,
        platformAddress: this.contractAddress,
      });
      let nftId = this.parentValue.nftId;
      let nftIdArr = [];
      nftIdArr.push(nftId);
      console.log("nftId", nftIdArr, nftId);

      // 获取跨链fee
      let PolygonChainID = `${this.$env.VUE_APP_POLYGON_CHIANID}`;
      let BscChainID = `${this.$env.VUE_APP_BSC_CHIANID}`;
      let UptickChainID = `${this.$env.VUE_APP_UPTICK_CHIANID}`;
      let aubitrueChainId = `${this.$env.VUE_APP_ARBITRUM_CHIANID}`;
      let res = await this.$wallet.getFeeByChainID(
        nftIdArr.length,
        this.currentToken == "UPTICK"
          ? UptickChainID
          : this.currentToken == "BSC"
          ? BscChainID
          : this.currentToken == "ARBITRUM"
          ? aubitrueChainId
          : PolygonChainID
      );

      console.log("sssssssss", res);
      this.fee = res;
      }
    

      if (this.chainAddress == "0x0000000000000000000000000000000000000000") {
        let res = await this.$wallet.getMyBalance();
        this.value3 = res.format;
        if (this.value3 > 0) {
          this.isBalance = true;
        } else {
          this.isBalance = false;
        }
      } else {
        this.$wallet.setContractAddress("", {
          address: this.chainAddress,
        });
        let balance = await this.$wallet.getTokenBalance(this.$store.state.did);

        this.value3 = balance;
        if (this.value3 > 0) {
          this.isBalance = true;
        } else {
          this.isBalance = false;
        }
      }
      this.isLoad = false;
        
      } catch (error) {
        this.isPay =false
        this.isLoad = false;
        this.$toast("error", this.$t("transactionInprogressError"));

        
      }
      
    },
    async getCrossData() {
      try {
        let result = await api.home.getCrossChainClassId(
          this.parentValue.nftAddress
        );
        console.log("ssssss === ", result);
        return result;
      } catch (error) {
        return "";
      }
    },
    async subCommit() {
      try {
        debugger;
        this.isPay = true;
        let crossResult;
        if (this.currentToken == "IRIS") {
          console.log("uptickERC721ToIris");
          let sourcePort = "nft-transfer";

          let sourceChannel = process.env.VUE_APP_UPTICKTOIRIS_CHANNEL

          let classId;
          let tokenId = "";
          let conratctAddress = "";
          let res1 = await this.getCrossData();

          let stringId;
          conratctAddress = web3Obj.utils.toChecksumAddress(
            this.parentValue.nftAddress
          );
          stringId = web3Obj.utils.toHex(this.parentValue.nftId);
          if (res1) {
            let classSourceFrom = res1.token_pair.class_id.indexOf("ibc");
            console.log("getCrossData", res1, classSourceFrom);
            if (classSourceFrom == -1) {
              conratctAddress = this.parentValue.nftAddress;
              stringId = this.parentValue.nftId;
              classId = "";
              tokenId = "";
            } else {
              classId = res1.token_pair.class_id;

              // 十六进制字符串
              let hexString = stringId.slice(2, stringId.length);

              for (var i = 0; i < hexString.length; i += 2) {
                tokenId += String.fromCharCode(
                  parseInt(hexString.substr(i, 2), 16)
                );
              }
            }
          } else {
            conratctAddress = this.parentValue.nftAddress;
            stringId = this.parentValue.nftId;
            classId = "";
            tokenId = "";
          }

          // let cosmosTokenIdsList = 'uptick'+this.parentValue.nftId

          crossResult = await uptickERC721ToIris(
            conratctAddress,
            stringId,
            sourcePort,
            sourceChannel,
            classId,
            tokenId,
            this.EvmAddress,
            this.irisAddress
          );
        } else {
          // 获取Type
          let type = getBrageType(this.parentValue.nftType);
          console.log("getBrageType", type);

          // 获取chainID
          let PolygonChainID = `${this.$env.VUE_APP_POLYGON_CHIANID}`;
          let BscChainID = `${this.$env.VUE_APP_BSC_CHIANID}`;
          let UptickChainID = `${this.$env.VUE_APP_UPTICK_CHIANID}`;
          let aubitrueChainId = `${this.$env.VUE_APP_ARBITRUM_CHIANID}`;
          console.log(
            "PolygonChainID",
            PolygonChainID,
            BscChainID,
            UptickChainID
          );

          // 当前时间戳
          let timespan = new Date().getTime();
          //目前地址
          let toAddressArr = [];
          let toAddress = this.$store.state.did;
          toAddressArr.push(toAddress);
          let nftId = this.parentValue.nftId;
          let nftIdArr = [];
          let amountArr = [];
          nftIdArr.push(nftId);
          amountArr.push(this.parentValue.amount);
          // 获取crossId
          let crossParams = {
            type: type,
            fromChainID:
              this.srcName == "BSC"
                ? BscChainID
                : this.srcName == "UPTICK"
                ? UptickChainID
                : this.srcName == "ARBITRUM"
                ? aubitrueChainId
                : PolygonChainID,
            toChainID:
              this.currentToken == "BSC"
                ? BscChainID
                : this.currentToken == "UPTICK"
                ? UptickChainID
                : this.currentToken == "ARBITRUM"
                ? aubitrueChainId
                : PolygonChainID,
            toAddress: toAddress,
            timespan: timespan,
            nftID: nftId,
            tokenAddress: this.parentValue.nftAddress,
          };
          if (this.parentValue.nftType == "ERC1155") {
            crossParams.amount = this.parentValue.amount;
          }

          let crossId = getCrossID(crossParams);
          console.log("getCrossID", crossId, crossParams);

          // 获取Metadata
          // let metadata = convertFromERC2Metadata(type,crossID,timespan,uptickNFT1155Address,amounts,nftIDs)
          let metadata = convertFromERC2Metadata(
            type,
            crossId,
            timespan,
            this.parentValue.nftAddress,
            this.parentValue.nftType == "ERC721" ? "" : amountArr,
            nftIdArr,
            this.srcName == "BSC"
              ? BscChainID
              : this.srcName == "UPTICK"
              ? UptickChainID
              : this.srcName == "ARBITRUM"
              ? aubitrueChainId
              : PolygonChainID,
            this.currentToken == "BSC"
              ? BscChainID
              : this.currentToken == "UPTICK"
              ? UptickChainID
              : this.currentToken == "ARBITRUM"
              ? aubitrueChainId
              : PolygonChainID
          );

          console.log("====================================");
          console.log(
            type,
            crossId,
            timespan,
            this.parentValue.nftAddress,
            this.parentValue.amount == 1 ? "" : this.parentValue.amount,
            nftIdArr,
            metadata
          );
          console.log("====================================");

          // 授权合约地址

          this.$wallet.setContractAddress(this.parentValue.nftType, {
            address: this.parentValue.nftAddress,
            platformAddress: this.genericHandlerContract,
          });

         let result =  await this.$wallet.setBridgeApproval(this.parentValue.nftType);

         console.log("wxl --- sdewwerwer",result);
         if(!result.hash){
           this.isPay = false
           this.$toast("error",this.$t("requireFailed"))
           return
        }

          // 跨链
          await this.$wallet.setContractAddress(this.parentValue.nftType, {
            address: this.parentValue.nftAddress,
            platformAddress: this.contractAddress,
          });
          //contractAddress,destinationChainId, resourceId, toAddress,tokenIds,crossId,nftAddress,time,fee
          crossResult = await this.$wallet.uptickToPolygon(
            this.srcName,
            this.currentToken == "BSC"
              ? BscChainID
              : this.currentToken == "UPTICK"
              ? UptickChainID
              : this.currentToken == "ARBITRUM"
              ? aubitrueChainId
              : PolygonChainID,
            toAddressArr,
            metadata,
            this.fee
          );
        }

        console.log("crossResult", crossResult);
        
        if (crossResult.hash || crossResult.code == 0) {
          // let isDoingParams = {
          //   nftAddress: this.parentValue.nftAddress,
          //   nftId: this.parentValue.nftId,
          //   hash:this.currentToken == 'IRIS' ? crossResult.transactionHash: crossResult.hash,
          //   owner: this.$store.state.did,
          //   saleStatus: 31,
          // };

          // let isding = await api.home.isDoing(isDoingParams);

          this.$toast("success", this.$t("success")).then(() => {
            window.eventBus.$emit("backgroundUpdate");
            this.$emit("getOpen", false);
            this.isPay = false;
            window.eventBus.$emit("collectionPay");
          });
        } else {
          this.$toast("error",this.$t("requireFailed"))
          this.isPay = false;
        }
      } catch (error) {
        console.log(error);
        this.$toast("error", this.$t("transactionInprogressError"));
        this.isPay = false;
      }
    },
    closeDialog() {
      this.open = false;
      this.$emit("getOpen", this.open);
    },
  },
};
</script>
<style lang="scss" scoped>

.card {
  display: flex;
  flex-direction: column;
  min-height: 480px;
  width: 760px;

  .clo {
    position: absolute;
    right: 10px;
    top: 10px;
    width: 30px;
    height: 31px;
    border-radius: 50%;
    margin-right: 0;
    cursor: pointer;
  }
  .content {
    margin: 34px 52px;
    .title-12 {
      font-family: Helvetica;
      font-size: 12px;
      font-weight: normal;
      font-stretch: normal;
      line-height: 20px;
      letter-spacing: 0px;
      color: #766983;
      span{
        color:#7800f4 !important;
        font-weight:bold !important;
      }
    }
    .cross_title {
      font-family: Helvetica;
      font-size: 23px;
      font-weight: bold;
      font-stretch: normal;
      line-height: 30px;
      letter-spacing: 0px;
      color: #270645;
    }
    .cross_des {
      font-family: Helvetica;
      font-size: 13px;
      font-weight: bold;
      font-stretch: normal;
      line-height: 15px;
      letter-spacing: 0px;
      color: #270645;
    }

    .iris_des {
      font-family: Helvetica;
      font-size: 13px;
      font-weight: normal;
      font-stretch: normal;
      line-height: 20px;
      letter-spacing: 0px;
      color: #7800f4;
    }
    .cross {
      .left {
        .img {
          width: 226px;
          height: 226px;

          border-radius: 10px;
          object-fit: cover;
        }
        .nftName {
          font-family: Helvetica;
          font-size: 15px;
          width: 219px;
          font-weight: bold;
          font-stretch: normal;
          line-height: 20px;
          letter-spacing: 0px;
          color: #270645;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
        .des {
          width: 226px;

          font-family: Helvetica;
          font-size: 12px;
          font-weight: normal;
          font-stretch: normal;
          letter-spacing: 0px;
          color: #766983;
        }
      }
      .right {
        margin-left: 56px;
        .top {
          .from {
            width: 150px;
            height: 156px;

            box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.09);
            border-radius: 10px;
            border: solid 1px #ab8aca;
            padding-top: 10px;
            .fromtitle {
              font-family: Helvetica;
              font-size: 13px;
              font-weight: normal;
              font-stretch: normal;
              line-height: 20px;
              letter-spacing: 0px;
              color: #766983;
            }
            .chainName {
              font-family: Helvetica;
              font-size: 13px;
              font-weight: bold;
              font-stretch: normal;
              line-height: 20px;
              letter-spacing: 0px;
              color: #000000;
            }
          }
          .to {
          }
        }
        .Quantity {
          .emptyNumber {
            width: 70px;
          }
          .From {
            width: 150px;
            .numbertitle {
              font-family: Helvetica;
              font-size: 12px;
              font-weight: normal;
              font-stretch: normal;
              line-height: 20px;
              letter-spacing: 0px;
              color: #766983;
            }

            .number {
              font-family: Helvetica;
              font-size: 13px;
              font-weight: bold;
              font-stretch: normal;
              line-height: 20px;
              letter-spacing: 0px;
              color: #270645;
            }
          }
          .chain {
            width: 150px;
          }
        }
      }
    }
  }
  .footer {
    margin-top: 15px;
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    text-align: center;

    .price {
      display: block;
      font-family: Helvetica;
      font-size: 13px;
      font-weight: normal;
      font-stretch: normal;
      /* line-height: 40px; */
      color: #766983;
    }

    .sub {
      margin: 0 auto;
      width: 360px;
      height: 45px;
      background-color: #7800f4;
      border-radius: 25px;
      opacity: 0.9;
      font-size: 18px;
      font-family: Helvetica;
      font-weight: bold;
      color: #ffffff;
    }
    .subdefect {
      margin: 0px auto;
      width: 360px;
      height: 51px;
      background-image: linear-gradient(#766983, #766983),
        linear-gradient(#270645, #270645);
      background-blend-mode: normal, normal;
      border-radius: 25px;
      opacity: 0.9;
      font-size: 20px;
      color: #ffffff;
    }
    .load-dis {
    }
    .load-dis::after {
      content: "";
      background-image: url(../../assets/loading.gif);
      background-size: 100%;
      display: inline-block;
      position: absolute;
      width: 20px;
      height: 20px;
      margin-left: 190px;
    }
    .sub-dis {
      position: relative;
      pointer-events: none;
      background-image: linear-gradient(#766983, #766983),
        linear-gradient(#270645, #270645) !important;
      background-blend-mode: normal, normal;
      border-radius: 25px;
      opacity: 0.9;
    }

    .sub-dis::after {
      content: "";
      background-image: url(../../assets/loading.gif);
      background-size: 100%;
      display: inline-block;
      position: absolute;

      width: 20px;
      height: 20px;
      margin-top: 5px;
      margin-left: 20px;
    }
  }
  //   .footer {
  //     margin: 0 auto;
  //     .sub {
  //       margin: 30px auto;
  //       width: 360px;
  //       height: 51px;
  //       background-blend-mode: normal, normal;
  //       background-color: #7800f4;
  //       border-radius: 25px;
  //       opacity: 0.9;
  //       font-weight: bold;;
  //       font-size: 20px;
  //       color: #ffffff;
  //     }
  //     .sub-dis {
  //   position: relative;
  //   pointer-events: none;
  //   background-image: linear-gradient(#766983,
  //       #766983),
  //     linear-gradient(#270645,
  //       #270645) !important;
  //   background-blend-mode: normal,
  //     normal;
  //   border-radius: 25px;
  //   opacity: 0.9;
  // }

  // .sub-dis::after {
  //   content: "";
  //   background-image: url(../../assets/loading.gif);
  //   background-size: 100%;
  //   display: inline-block;
  //   position: absolute;
  //   width: 20px;
  //   height: 20px;
  //   margin-left: 130px;
  // }

  //   }
  &.mobile {
    .top {
      width: 100%;
      margin: 30px 10% 0;

      .content {
        width: 80%;
        height: auto;
        word-wrap: break-word;
      }
    }
    .footer {
      width: 100%;
      margin-left: 10%;
      .sub {
        width: 80%;
      }
    }
  }
}
</style>
