<template>
  <img
    alt="logo"
    :src="require('@/assets/image/'+ `${srcName=='UPTICK' ? 'logo_w': srcName=='BSC' ? 'logo_on_bsc_w' : srcName=='ARBITRUM'?'logo_on_arbitrum_w':'logo_on_polygon_w'}` +'.svg')"
    :height="height"
    :width="`${simple ? height : 'auto'}`"
    v-bind="$attrs"
    v-on="$listeners"
    @click="openHome"
    :class="{ logo: true }"
  />
</template>

<script lang="js">

export default {
  name: 'Logo',
  inheritAttrs: false,
  data:function () {
    return{
      srcName:'UPTICK'
    }
  },
  props: {
      simple: Boolean,
      height: [String, Number]
  },

  computed: {
    isMobile: function () {
      return this.$vuetify.breakpoint.mobile;
    }
  },
   mounted(){
    let currentChain = `${this.$env.VUE_APP_MARKET_TYPE}`
    this.srcName = currentChain

  },

  methods:{
    openHome(){
       this.$router.push({name:"Home"})
    }

  }
};
</script>

<style lang="scss" scoped>
.logo {
  object-fit: cover;
  object-position: left;
  cursor:pointer;
}

</style>
