<template>
  <div class="contain">
    <v-card :class="{ card: true, mobile: isMobile }">
      <img
        class="clo"
        @click="closeDialog"
       :src="$vuetify.theme.dark?darkcloseIcon:closeIcon"
        alt=""
      />
      <div class="top">
        <div class="d-flex flex-row">
          <h3 :class="{darkFont:$vuetify.theme.dark }">{{ $t("relevance_nft") }}</h3>
          <div class="allselect" :class="{darkFont:$vuetify.theme.dark }">
            <!-- <v-checkbox
            :value="allCheckbox"
            @click="allcheckboxChange"
          ></v-checkbox> -->
            {{ allNftNum }}/12
          </div>
        </div>

        <div class="cardbox" ref="listbox" @scroll="onScroll">
          <div
            class="list d-flex flex-column"
            v-for="(item, index) in nftList"
            :key="index + '2'"
            style="position:relative;padding-left:10px"
          >
            <div class="d-flex flex-row">
              <v-checkbox
                :key="index"
                :value="item.checked"
                @click="childcheckboxChange(index, item)"
                readonly
              ></v-checkbox>
              <div
                class="content d-flex flex-row align-center"
              >
                <img :src="item.src" alt="" />
                <p :class="{darkFont:$vuetify.theme.dark }">{{ item.text }}</p>
              </div>
              <img
                class="img"
          
                :src='$vuetify.theme.dark ? moreIcon:Icon'
                @click="getCollectionList(item,index)"
                alt=""
                :key="index+'3'"
              />
            </div>

            <div v-if="item.expand" style="margin-left:30px;padding-left:10px;max-height:220px;overflow-y: auto;">
              <div
                class="collectionlist d-flex flex-row"
                v-for="(v, i) in item.collectionList"
                :key="i + '1'"
              >
                <v-checkbox
                  :key="i"
                  :value="v.checked"
                  @click="childcheckboxChange1(i, v, item)"
                  readonly
                ></v-checkbox>
                <div class="content d-flex flex-row align-center">
                  <img :src="v.src" alt="" />
                  <p>{{ v.text }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="footer">
          <button class="sub" @click="subCommit">
            {{ $t("popupTransferCommit") }}
          </button>
        </div>
      </div>
    </v-card>
    <PromptBox ref="promptBox"></PromptBox>
  </div>
</template>

<script>
import api from "@/api";
import { getFileSrc } from "@/utils/file";
import PromptBox from "@/components/PromptBox.vue";
const NFT_KEY = "CARDNFT";
export default {
  components: { PromptBox },
  data: () => ({
    collectionList: [],
    nftList: [],
    checkbox: false,
    imgSrc: "",
    src: "",
    allNftNum: 0,
    totalList: [],
    isClick: false,
    collection_id:0,
    showCollection:false,
    ex:[],
    isloading:false,
    pageSize:20,
    pageNumber:1,
    totalPage:0,
    closeIcon:require('@/assets/btn_close.png'),
    darkcloseIcon:require('@/assets/darkimage/btn_close1.png'),
    Icon:require('@/assets/icons/icon_z.png'),
    moreIcon:require('@/assets/darkimage/icon_z.png')

  }),
  props: {
   
	tokenId: {
	  type: String,
	},
    getNftList: {
      type: Array,
    },
    deductionType:{
      type: Number,
    },
    parentValue:{
      type:Object,
    }

  },

  computed: {
    isMobile: function () {
      return this.$vuetify.breakpoint.mobile;
    },
  },
  async mounted() {
    await this.getList();
    // 接口请求
    this.nftList.forEach((ele) => {
      console.log("wxl -- 0000",ele)
      ele.checked = false;
     if(ele.systemContract == 1){
        ele.enabled =false
     }
     
        if(this.deductionType == 1)
        {
           console.log("0000000", ele.enabled)
           ele.enabled =false
        }
       
      
    });
    this.collectionList.forEach((ele) => {
      ele.checked = false;
    });
    console.log("3333333333",this.deductionType)
    // if (this.getNftList) {
    //   this.allNftNum = this.getNftList.length;
    // }
    // for (let i = 0; i < this.nftList.length; i++) {
    //   for (let j = 0; j < this.getNftList.length; j++) {
    //     if (this.nftList[i].address == this.getNftList[j].address) {
    //       this.nftList[i].checked = true;
    //       this.$forceUpdate();
    //     }
    //   }
    // }
  },
  methods: {
     onScroll(e) {
       
        const {
          scrollTop,
          clientHeight,
          scrollHeight
        } = e.target;
        if (
          scrollTop + clientHeight == scrollHeight &&
		  this.totalPage > this.pageNumber
        ) {
          this.pageNumber++;
          this.getList();
        //   this.getMessage();
        // this.loadMore(this.selectModel);
        }
      },
    async getList() {
      let params = {
        pageNumber:this.pageNumber,
        pageSize: this.pageSize,
        owner: this.$store.state.did,
      };
	  if(this.tokenId){
		  params.contractId=0;
	  }
      let resList = await api.home.couponList(params);
     this.totalPage = resList.data.totalPage

      for (let i = 0; i < resList.data?.list.length; i++) {
        if (resList.data.list[i].imgUrl) {
          this.imgSrc = await getFileSrc(NFT_KEY, resList.data.list[i].imgUrl);
        }

        this.nftList.push({
          src: this.imgSrc,
          text: resList.data.list[i].name,
          nftAddress: resList.data.list[i].nftAddress,
          systemContract:resList.data.list[i].systemContract,
          expand: false,
          enabled:true
        });
     
      }
       
    },
    async getCollectionList(item,index) {
      console.log("wxl 00w0eirwe",this.parentValue)
      this.$forceUpdate();
      this.collectionList=[]
       this.collection_id == 0 ? (this.collection_id!=index?this.collection_id=index:this.collection_id=index) : this.collection_id = 0;
   
      let params = {
        nftAddress: item.nftAddress,
         pageNumber: "1",
        pageSize: "200",
         owner: this.$store.state.did,
         currentNftId:this.parentValue.nftId
	    
      };
       if(this.isloading){
        return
      }
       this.isloading = true
      let collectionList = await api.home.getCollectionList(params);
     
     
      for (let i = 0; i < collectionList.data?.list.length; i++) {
        if (collectionList.data.list[i].imgUrl) {
          this.src = await getFileSrc(
            NFT_KEY,
            collectionList.data.list[i].imgUrl
          );
        }

        this.collectionList.push({
          src: this.src,
          text: collectionList.data.list[i].name,
          nftId: collectionList.data.list[i].nftId,
		      nftAddress: collectionList.data.list[i].nftAddress,
          type:"nft"
        });
       
        
      
      }
      this.isloading = false
      item.collectionList = this.collectionList;
      item.expand = !item.expand;
      if(item.expand == false)
      {
        item.enabled =true
       this.allNftNum = this.allNftNum- this.ex.length
       this.ex.length = 0
        
      }
    },
    //关闭弹框，发送open值
    closeDialog() {
      this.$emit("Openselectnft", false);
    },

    childcheckboxChange(index, item) {
      if(item.systemContract == 1){
         item.enabled = false
      }
      if( this.deductionType == 1 ){
        item.enabled = false
      }
      console.log("item.enabled",item.enabled)
      if(!item.enabled) return;

      if (item.checked == false && this.allNftNum >= 12) {
        this.$refs.promptBox.show(this.$t("zm_limit"), 1);
        //this.nftList[index].checked = false;
        //this.nftList[index].disabled = true;
        //  this.nftList[index].checked = false
      } else {
        //item.disabled = false
        this.nftList[index].checked = !this.nftList[index].checked;
        if (this.nftList[index].checked) {
          this.allNftNum++;
        } else {
          this.allNftNum--;
        }
        console.log(item.checked);
      }
      this.$forceUpdate();

      console.log(this.totalList);
    },
     childcheckboxChange1(index, item, parent) {
      if (item.checked == false && this.allNftNum >= 12) {
        this.$refs.promptBox.show(this.$t("zm_limit"), 1);
        
      } else {
       parent.collectionList[index].checked = !parent.collectionList[index].checked;
        if (parent.collectionList[index].checked) {
          this.allNftNum++;
          if(parent.checked ){
             parent.checked = false;
           this.$forceUpdate();
           this.allNftNum--;
          }
         
           
        } else {
          this.allNftNum--;
        }
        console.log(item.checked);
      }
      this.$forceUpdate();
       this.ex = parent.collectionList.filter(f => f.checked);
    
      if(this.ex.length > 0) {
        parent.enabled=false;
      } else {
        parent.enabled=true;
      }

      console.log(this.totalList);
    },
    subCommit() {
      this.totalList = this.nftList.filter((item) => item.checked);
      for (let i = 0; i < this.nftList.length; i++) {

          if(this.nftList[i].collectionList){
            for (let j = 0; j < this.nftList[i].collectionList.length; j++) {
             if(this.nftList[i].collectionList[j].checked == true)
             {
               this.totalList.push(this.nftList[i].collectionList[j])
             }
              
            }

          }
        
      }
      this.$emit("Openselectnft", false);
      this.$emit("getCardList", this.totalList);
    },
  },
};
</script>

<style lang="scss" scoped>
.contain {
  .card {
    width: 600px;
    height: 645px;
    box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.09);
    border-radius: 5px;
    .clo {
      position: absolute;
      right: 10px;
      top: 10px;
      width: 30px;
      height: 31px;
      border-radius: 50%;
      margin-right: 0;
      cursor: pointer;
    }
    .top {
      margin-left: 52px;
      padding: 20px 0;

      h3 {
        font-family:Helvetica;
        font-size: 25px;
        font-weight: bold;
        font-stretch: normal;
        letter-spacing: 0px;
        color: #270645;
      }
      .allselect {
        padding-left: 325px;
        // margin-left: 40px;
        margin-bottom: 30px;
        font-weight: bold;
        span {
          height: 13px;
          font-family:Helvetica;
          font-size: 15px;
          font-weight: bold;
          font-stretch: normal;
          line-height: 63px;
          letter-spacing: 0px;
          color: #270645;
        }
      }
      .cardbox {
        // padding-left: 28px;
        height: 453px;
        overflow-y: auto;
        .list {
          .content {
            width: 400px;
            height: 51px;
            box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.09);
            border-radius: 3px;
            // padding-top:8px;
            padding-left: 9px;
            margin-left: 10px;
            cursor: pointer;
            img {
              width: 35px;
              height: 35px;
              margin-right: 12px;
              object-fit: cover;
            }
            p {
              width: 318px;
              min-height: 14px;
              margin: 0;
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
              -o-text-overflow: ellipsis;
            }
          }
          .img {
            margin-left: 10px;
            width: 31px;
            height: 51px;
          }
          .collectionlist {
               
          .content {
            width: 360px;
            height: 51px;
            box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.09);
            border-radius: 3px;
            // padding-top:8px;
            padding-left: 9px;
            margin-left: 10px;
            cursor: pointer;
            img {
              width: 35px;
              height: 35px;
              margin-right: 12px;
              object-fit: cover;
            }
            p {
              width: 318px;
              min-height: 14px;
              margin: 0;
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
              -o-text-overflow: ellipsis;
            }
          }
          .img {
            margin-left: 10px;
            width: 31px;
            height: 51px;
          }
        }
        }
        
      }
      .footer {
        margin-right: 52px;
        margin-top: 20px;
        text-align: center;
        .sub {
          margin: 0 auto;
          width: 450px;
          height: 51px;
     background-color: #7800f4;
          border-radius: 25px;
          opacity: 0.9;
          font-size: 20px;
          color: #ffffff;
        }
      }
    }
  }
}
</style>
