<template>
  <div id="container">
    <v-card id="card" :class="{ card: true, mobile: isMobile }">
      <img class="clo" @click="closeDialog" :src="$vuetify.theme.dark?darkcloseIcon:closeIcon" alt="" />
      <div class="top">
        <span class="title" :class="{darkFont:this.$vuetify.theme.dark }"> {{ $t("popupBuyPriceBuyNow") }}</span>
        <h3 class="content" :class="{darkFont:this.$vuetify.theme.dark }">
          {{ $t("popupBuyPriceAfterSuccessful") }}
        </h3>
        <div class="form">
            <div class="titles mb-4" :class="{darkFont:this.$vuetify.theme.dark }">{{ $t("popupBuyPricePrice")}} {{ couponOldPrice }}</div>
		  <!-- 没有专卖卡显示 -->
		  <div v-if="exclusiveName!=''" class="redN" v-html="exclusiveName" :class="{darkFont:this.$vuetify.theme.dark }"></div>
		  <!-- 持有优惠卡显示 -->
		   <div v-if="haveCoupon " :class="{darkFont:this.$vuetify.theme.dark }">{{ $t("yh_des_bot") }}</div>
          <v-text-field v-model="fixPrice" outlined :suffix="marketNftInfo.tokenType" type="input" class="textinput" :class="{darkMode:$vuetify.theme.dark}"
		     readonly
            style="margin-top: 8px"></v-text-field>
        </div>

        
   
      </div>


      <div class="footer mt-5">
        <button class="subdefect v-btn uc" :class="{ 'sub-dis': isPay }" v-if="isLoad">
          {{ $t("loadBalance") }}
        </button>
        <button class="sub v-btn uc" :class="{ 'sub-dis': isPay }" submit @click="subCommitMarket" v-else-if="!needToShowLink &&  marketNftInfo.owner != this.$store.state.did && !isNotEexclusive">
          {{ $t("popupTransferCommit") }}
        </button>
         <button class="subdefect"  submit  v-else-if="!needToShowLink &&  marketNftInfo.owner != this.$store.state.did && isNotEexclusive">
          {{ $t("popupTransferCommit") }}
        </button>
        <button class="subdefect"  submit v-else-if="!needToShowLink &&  marketNftInfo.owner == this.$store.state.did">
          {{ $t("popupTransferCommit") }}
        </button>
        <button class="subdefect" v-else-if="needToShowLink" disabled="disabled">
          {{ $t("popupAuctionDefectPriceInsufficientFunds") }}
        </button>
        

        <span class="price">{{ $t("popupNewTransferBalance") }}：{{ amountFormat }} {{marketNftInfo.tokenType}} </span>
        

      </div>
    </v-card>
    <uComponents ref="ucom"></uComponents>
  </div>
</template>
<script>
import api from "@/api";
import {getFeePrice} from "../../utils/helper";

export default {
  data: () => ({
    newOpen: true,
    fixPrice: 0,
    amountFormat: "",
    isPay: false,
    isLoad: false,
    chainToken: "",
    needToShowLink:false,
    loopringLink:"",
    feePrice:"",
    totalPrice:"",
    StorageId:'',
    lockValue:'',
    	exclusiveName:"",
  couponOldPrice:"",
  isNotEexclusive:false,
  haveCoupon:false,
  hash:'',
  closeIcon:require('@/assets/btn_close.png'),
  darkcloseIcon:require('@/assets/darkimage/btn_close1.png')
  }),
  computed: {
    isMobile: function () {
      return this.$vuetify.breakpoint.mobile;
    },
  },
  props: { 
     currentType:{
      type:String
    },
    marketNftInfo: {
      type: Object,
    },
    userInfo: {
      type: Object,
    },
    Nft_sku: {
      type: Object,
    },
    assetLevel: {
      type: Number,
    },
    tokenId: {
      type: String,
    },

    assetId: {
      type: Number,
    },
    onsaleOwner: {
      type: String,
    },
    priceSecond: {
      type: String,
    },
    minprice: {
      type: String,
    },
    buytype: {
      type: Number,
    },
    owerfrom: {
      type: String,
    },
    buyPrice :{
      type: String,
    }
  },
  filters: {
    undef: function (value) {
      if (value != null && value != "null" && value != "underfined") {
        return "";
      }
      return value;
    }
  },
  async mounted() {
    console.log("Nft_sku",this.marketNftInfo);
  window.eventBus.$on('LangChange', this.onLangChange);
    // if(this.marketNftInfo.couponInfo &&this.marketNftInfo.couponInfo.deduction){
    //    this.fixPrice=this.marketNftInfo.couponInfo.deduction
    // }else{
    //      this.fixPrice = Number(this.buyPrice)
    // }
     this.fixPrice = Number(this.buyPrice)
   

// 获取余额
 this.isPay = true
  this.isLoad = true
    let price
   debugger
		if(this.$store.state.did&&(this.marketNftInfo.couponList != null ||this.marketNftInfo.exclusiveList != null)){
		await	api.home.getCouponInfoJson({
			   owner: this.marketNftInfo.owner,
           nftAddress: this.marketNftInfo.nftAddress,
			nftId:this.marketNftInfo.nftId,
			address:this.$store.state.did
			  })
			  .then((resn) => {
       
			   if(resn.success&&resn.data){
				   if(resn.data.coupon){
					if(resn.data.usable){
						this.haveCoupon=resn.data.usable
						
          }  
					  if(this.marketNftInfo.couponList != null)
					  {
					   
					    let t = this.$t("yh_des")
					     let reg=new RegExp('【N】','g')//g代表全部
					     
					    let name = "【" + this.marketNftInfo.couponInfo.name + "】"
					   this.pictureName=t.replace(reg,name);
					  
					   if(this.haveCoupon&&resn.data.usable){
						    this.fixPrice=this.marketNftInfo.couponInfo.deduction;
					   this.couponOldPrice=this.marketNftInfo.marketPrice+ ' ' +this.marketNftInfo.tokenType;
					   price = Number(this.fixPrice)
					   if (this.amountFormat  >= price) {
					     this.isPay = false
					     this.isLoad =false
					     this.isBalance = true;
					   }
					   
					   }else{
						   if(resn.data.usable==false){
							   this.haveCoupon=false
						   }
						   
					   }
					   
					  }
					   
           }
           this.haveExclusive=resn.data.hasExclusive
				   if(this.marketNftInfo.exclusiveList != null)
				     {
				       if(!this.haveExclusive){
				   	//需要专卖卡 但是当前没有专卖卡 
				   		let t = this.$t("zm_des_bot")
				   		  let reg=new RegExp('【N】','g')//g代表全部
				   		    let regN=new RegExp('<br/>','g')//g代表全部
				   		 let name = "【" + this.marketNftInfo.exclusiveName + "】"
				   		this.exclusiveName=t.replace(reg,name).replace(regN," ");
				     // btn显示灰色 btn上的文字显示 submit
				     this.isNotEexclusive = true
				     this.isBalance = false;
				     this.showMeaage = this.$t("popupTransferCommit") 
				   	}
				   }
			   }
			  });
    }
     if(this.marketNftInfo.marketTokenAddress == '0x0000000000000000000000000000000000000000'){
        let res = await this.$wallet.getMyBalance();
        this.isLoad = false
         this.isPay = false

        this.amountFormat= res.format;
        console.log('this.amountFormat',this.amountFormat,this.fixPrice);
debugger
          if( Number(this.amountFormat) < Number(this.fixPrice)){
            this.needToShowLink =true
          }else{
            this.needToShowLink =false
          }
       

    
    }else{
        this.$wallet.setContractAddress('', {
            token20Address: this.marketNftInfo.marketTokenAddress,
           
          });
          let balance = await this.$wallet.getTokenBalance(
           this.$store.state.did
          );
          this.isLoad = false
           this.isPay = false
          this.amountFormat = balance
          if(Number(this.amountFormat) < Number(this.fixPrice)){
            this.needToShowLink =true
          }else{
            this.needToShowLink =false
          }
  
    }
		
		
  },
  methods: {
    onLangChange(){
            if(!this.haveExclusive){
				   	//需要专卖卡 但是当前没有专卖卡 
				   		let t = this.$t("zm_des_bot")
				   		  let reg=new RegExp('【N】','g')//g代表全部
				   		    let regN=new RegExp('<br/>','g')//g代表全部
				   		 let name = "【" + this.marketNftInfo.exclusiveName + "】"
				   		this.exclusiveName=t.replace(reg,name).replace(regN," ");
				     // btn显示灰色 btn上的文字显示 submit
				     this.isNotEexclusive = true
				     this.isBalance = false;
				     this.showMeaage = this.$t("popupTransferCommit") 
				   	}
    },
    async subCommitMarket() {

        try {
          
          this.isPay = true
        
        // 预下单
          let preOrderParams = {
         sender:this.marketNftInfo.owner,
         recipient:this.$store.state.did,
         nftAddress:this.marketNftInfo.nftAddress,
         nftId:this.marketNftInfo.nftId,
      
        };
    
         
        let preOrderRes = await api.home.preOrder(preOrderParams);
        console.log("preOrderRes",preOrderRes)
      if(preOrderRes.success == false){
           this.$toast("error",preOrderRes.msg )
           this.isPay = false
           return;
      }
        // 链上操作
          let result;
        if (this.$chainName == "UPTICK EVM") {
          this.$wallet.setContractAddress(this.marketNftInfo.nftType, {
            address: this.marketNftInfo.nftAddress,
            platformAddress: this.marketNftInfo.marketAddress,
            token20Address:this.marketNftInfo.marketTokenAddress
          });
          // let vvv = this.parentValue;
          // console.log(vvv);
          result = await this.$wallet.placeOrder(
            preOrderRes.data.nftType,
            preOrderRes.data.nftAddress,
           preOrderRes.data.nftId,
           preOrderRes.data.sender,
           Number(preOrderRes.data.payPrice),
           preOrderRes.data.marketType,
            preOrderRes.data.couponCode,
            preOrderRes.data.couponLink,
            preOrderRes.data.payTokenAddress
         


          );
          console.log("wxl ---- hash",result);
        }
        this.hash = result.hash
        debugger
        if(!result.hash){
           this.isPay = false
           this.$toast("error",this.$t("requireFailed"))
           return
        }

        // 订单进行中
         let isDoingParams = {
          orderNo: preOrderRes.data.orderNo,
          hash:result.hash,
          recipient:this.$store.state.did,
          
        };
        let isding = await api.home.payOrder(isDoingParams);
         if (isding.success == true) {
          //    this.$toast("success", this.$t("success")).then(
          //   () => {
          //     this.$emit("getOpen", false);
          //   this.isPay = false;
          //   }
          // );

           this.isPay = false
           let orderinfo = {
             "marketPrice":this.fixPrice,
             "creatorName":this.marketNftInfo.creatorName,
             "creator":this.marketNftInfo.creator,
             "NftName":this.marketNftInfo.name,
             "hash":this.hash,
             "tokenType":this.marketNftInfo.tokenType
             
           }
         this.$emit("getOpen", false,orderinfo);
            
      //    if(this.buytype == 0){
      //                   this.$router.push({ name: 'Marketplace' });
      //                }else{
      //                   this.$router.go(-1)
      //                }
      //     let t = this.$t("buySuccess")
			// 		let reg=new RegExp('【N】','g')//g代表全部
			// 		let regN=new RegExp('<br/>','g')//g代表全部
			// 		let name = "【" + this.marketNftInfo.name + "】"
			// 		let title=t.replace(reg,name).replace(regN," ");
  
		  // //  });
      //     let isShowTip = localStorage.getItem("isShowTip_"+this.$store.state.did)
      //   if(isShowTip == 'true'){
      //       setTimeout(()=>{
      //           this.$mtip({
      //           title:title,
      //         });
      //        },2000)
      //   }
           
         }else{
           this.$toast("error",isding.msg )
         }
       
        // let makerOrderJson = {
        //   "exchange": signature.exchange,
        //   "accountId": signature.accountId,
        //   "storageId": signature.storageId,
        //   "sellToken": signature.sellToken,
        //   "buyToken": signature.buyToken,
        //   "allOrNone": signature.allOrNone,
        //   "fillAmountBOrS": signature.fillAmountBOrS,
        //   "validUntil": signature.validUntil,
        //   "maxFeeBips": signature.maxFeeBips
        // };
        // let parms = {
        //   makerOwner:this.marketNftInfo.owner,
        //   owner: this.$store.state.did,
        //   accountId: this.userInfo.accountId,
        //   nftAddress: this.marketNftInfo.contractAddress,
        //   nftId: this.marketNftInfo.nftId,
        //   takerOrderJson: JSON.stringify(makerOrderJson),
        //   signature: signature.eddsaSignature,
        //   amount: 1
        // }
      //   let trade = await api.home.nftTrade();
    
      //   if (trade.success) {
      //     // this.$toast("success", this.$t("buySuccess")).then(()=>{
			//      this.isPay = false
      //     this.closeDialog();
      //       this.$router.push({ name: 'Marketplace' });
      //     let t = this.$t("buySuccess")
			// 		let reg=new RegExp('【N】','g')//g代表全部
			// 		let regN=new RegExp('<br/>','g')//g代表全部
			// 		let name = "【" + this.marketNftInfo.name + "】"
			// 		let title=t.replace(reg,name).replace(regN," ");
  
		  // //  });
        
      //       setTimeout(()=>{
      //           this.$mtip({
      //           title:title,
      //         });
      //        },2000)
            
        

      //   }else{
      //   this.$toast("error",trade.msg ).then(()=>{
      //   this.isPay = false
      //   this.closeDialog();
      //   });
      //   }
      } catch (error) {
        console.log(error)
        this.$toast("error", this.$t("buyFailed"));
        this.isPay = false
      }
    },
    

maxDecimal(number){
  return String(number).replace(/^(.*\..{4}).*$/, "$1")
},

    async subCommitResale() {
      try {
		 let resTokenId=await api.home.getNftTokenId({"owner":this.marketNftInfo.marketOwner,"tokenAddress": this.marketNftInfo.contractAddress,"nftId": this.marketNftInfo.nftId});
		 		  if(resTokenId==null||resTokenId.data==null){
		 		  this.$toast("error", "invalid NFT");
		 		  return ;   
		 		  }
		        let signature = await this.LRCHandler.takerOrder(
		          resTokenId.data,
		          this.marketNftInfo.nftData,
		          "1",
		          this.chainToken,
		          this.fixPrice.toString()
		        );
				  
   
        let makerOrderJson = {
          "exchange": signature.exchange,
          "accountId": signature.accountId,
          "storageId": signature.storageId,
          "sellToken": signature.sellToken,
          "buyToken": signature.buyToken,
          "allOrNone": signature.allOrNone,
          "fillAmountBOrS": signature.fillAmountBOrS,
          "validUntil": signature.validUntil,
          "maxFeeBips": signature.maxFeeBips
        };
        let parms = {
          owner: this.$store.state.did,
          assetId: this.marketNftInfo.id,
		   accountId: this.userInfo.accountId,
          takerOrderJson: JSON.stringify(makerOrderJson),
          signature: signature.eddsaSignature
        }
        let trade = await api.home.NftSingleTrade(parms);
        if (trade.success) {
          this.isPay = false

          this.closeDialog();
                   	let t = this.$t("buySuccess")
					let reg=new RegExp('【N】','g')//g代表全部
					let regN=new RegExp('<br/>','g')//g代表全部
					let name = "【" + this.marketNftInfo.name + "】"
          let title=t.replace(reg,name).replace(regN," ");
            let isShowTip = localStorage.getItem("isShowTip_"+this.$store.state.did)
        if(isShowTip == 'true'){
              this.$mtip({
                title:title,
              });
        }
             
        
         setTimeout(() => {
              this.$router.push({ name: 'MyOrders' });
        }, 1000);
        }
      } catch (error) {
        this.$toast("error", this.$t("buyFailed"));
        this.isPay = false
      }
    },

    closeDialog() {
      this.newOpen = false;
      this.$emit("getOpen", this.newOpen);
    },
  },
};
</script>
<style lang="scss" scoped>
.card {
  display: flex;
  flex-direction: column;
  min-height: 400px;
  width: 600px;

  .top {
    display: flex;
    flex-direction: column;
    margin-left: 52px;

    .title {
      margin: 42px 0 0 0;
      width: 300px;
      height: 26px;
      font-weight: bold;;
      font-size: 25px;
      font-weight: bold;
      font-stretch: normal;
      letter-spacing: 0px;
      color: #270645;
    }

    .content {
      margin: 10px 0 10px 0;
      width: 467px;
      height: 41px;
      font-family: Helvetica;
      font-size: 15px;
      font-weight: normal;
      font-stretch: normal;
      letter-spacing: 0px;
      color: #766983;
    }

    .form {
      .titles {
        // margin-top: 24px;
       
        font-weight: bold;;
        font-size: 15px;
        font-weight: bold;
        font-stretch: normal;
        letter-spacing: 0px;
        color: #270645;
      }

      .textinput {
        width: 500px;
        height: 55px;    
        border-radius: 5px;
      
        
      }
      .darkMode{
         
      &::v-deep .v-text-field__suffix {
        color:#ffffff ;

      }
      }
    }
  }

  .clo {
    position: absolute;
    right: 10px;
    top: 10px;
    width: 30px;
    height: 31px;
    border-radius: 50%;
    margin-right: 0;
    cursor: pointer;
  }

  .footer {
    display: flex;
    flex-direction: column;
    text-align: center;
  

    .price {
      display: block;
      font-family: Helvetica;
      font-size: 13px;
      font-weight: normal;
      font-stretch: normal;
      color: #766983;
    }

    .sub {
      margin: 10px auto;
      width: 450px;
      height: 51px;
      // background-image: linear-gradient(90deg,
      //     #d300fd 0%,
      //     #a439fe 26%,
      //     #7471ff 52%,
      //     #00fdcf 100%),
      //   linear-gradient(#270645, #270645);
      background-blend-mode: normal, normal;
      border-radius: 25px;
      opacity: 0.9;
      font-size: 20px;
      color: #ffffff;
    }

    .subdefect {
      margin: 10px auto;
      width: 450px;
      height: 51px;
      background-image: linear-gradient(#766983, #766983),
        linear-gradient(#270645, #270645);
      background-blend-mode: normal, normal;
      border-radius: 25px;
      opacity: 0.9;
      font-size: 20px;
      color: #ffffff;
    }
  }

  &.mobile {
    width: 100%;
    .realPrice {
      display: flex;
      flex-direction: column;
      .totalPrice{
        margin-left:unset;
      }
    }
    .top {
      margin: 0 40px;

      .content {
        width: 100%;
        height: auto;
        word-wrap: break-word;
        margin-bottom: 20px;
      }
    }

    .footer {
      .sub {
        width: 90%;
      }
    }
  }
}

img {
  display: inline-block;
  width: 126px;
  height: 126px;
  border-radius: 5px;
  margin: 1px 22px 10px 52px;
}

.sub-dis {
  position: relative;
  pointer-events: none;
  background-image: linear-gradient(#766983,
      #766983),
    linear-gradient(#270645,
      #270645) !important;
  background-blend-mode: normal,
    normal;
  border-radius: 25px;
  opacity: 0.9;
}

.sub-dis::after {
  content: "";
  background-image: url(../../assets/loading.gif);
  background-size: 100%;
  display: inline-block;
  position: absolute;
  width: 20px;
  height: 20px;
  margin-left: 190px;
}

.lrcLink{
  margin-top: 10px;
}



.totalPrice{
  margin-left:20px;
}

.priceContent{
  font-weight:bold;

}
</style>




