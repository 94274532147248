<template>
  <div id="container">
    <v-card :class="{ card: true, mobile: isMobile }">
      <img class="clo" @click="closeDialog" :src="$vuetify.theme.dark?darkcloseIcon:closeIcon" alt />
      
      <div class="link">
        <ul class="d-flex flex-lg-row ">
          <li class="mr-lg-4" :class="{somberBackground:this.$vuetify.theme.dark }" @click="toCreate('collect')">
            <img
              src="@/assets/icons/icon_lazy.png"
              width="69px"
              height="69px"
              alt
            />
            <div class="infotit" :class="{darkFont:this.$vuetify.theme.dark }">{{ $t("lazy_mint") }}</div>
            <div class="infos" :class="{darkFont:this.$vuetify.theme.dark }">{{ $t("lazy_mint_des") }}</div>
          </li>
          <li  class="ml-lg-3" :class="{somberBackground:this.$vuetify.theme.dark }" @click="toCreate('nft')">
            <img
              src="@/assets/icons/icon_direct.png"
              width="74px"
              height="71px"
              alt
            />
             <div class="infotit" :class="{darkFont:this.$vuetify.theme.dark }">{{ $t("direct_mint") }}</div>
            <div class="infos" :class="{darkFont:this.$vuetify.theme.dark }">{{ mintDes }}</div>
          </li>

        </ul>
        <div
            class="d-flex flex-row mt-3"
            v-if="transferValue == 0 && isShowFeacet"
            style="
              justify-content: center;
            
            "
          >
            <div class="faucet">{{ $t("NotEnoughFee") }}</div>
            <div class="uptick ml-2" @click="toFaucet('top')">
              {{ $t("request_token") }} >
            </div>
          </div>
      </div>
    </v-card>
       	<v-dialog v-model="openCreateCollection" v-if="openCreateCollection" >
         <createCollection  @getOpen="OpenCreateCollection"  :createType="createType"
		  :createDrop="createDrop"></createCollection>
      </v-dialog>
      	<v-dialog v-model="openCreateChoose" v-if="openCreateChoose" >
         <createChoose  @getOpen="OpenCreateChoose" ></createChoose>
      </v-dialog>
       	<v-dialog v-model="opendropSuccessDialog"  v-if="opendropSuccessDialog">
	  <createDropSuccess  @getDropOpen="opendropSuccess"  :imageUrl="contractImageUrl"     :contractAddress="contractAddress" ></createDropSuccess>
	</v-dialog>

  </div>
  
</template>

<script>
import CreateCollection from "@/components/popup/createCollection";
import createChoose from "@/components/popup/createChoose";
import createDropSuccess from "@/components/popup/createDropSuccess.vue";
export default {
  components: { CreateCollection,createChoose,createDropSuccess },
  props: {
    createSource:{
      type:String
    }
   
  },
  data: () => ({
      openCreateChoose:false,
    open: false,
    mintDes:'',
      opendropSuccessDialog:false,
      contractAddress:'',
      contractImageUrl:'',
    sum: 1,
    openCreateCollection:false,
    createType:'ERC721',
    createDrop:'',
    closeIcon:require('@/assets/btn_close.png'),
    darkcloseIcon:require('@/assets/darkimage/btn_close1.png'),
    isShowFeacet:false,
    transferValue:''
   
   
  }),
  computed: {
    isMobile: function () {
      return this.$vuetify.breakpoint.mobile;
    },
  },
  async mounted(){
    let balance = await this.$wallet.getMyBalance();
    let mount = balance.format;
    this.transferValue = mount;

      let currentChain = `${this.$env.VUE_APP_MARKET_TYPE}`
      let chain = 'Uptick'
      if(currentChain == 'POLYGON'){
        chain = 'Polygon'
        this.isShowFeacet =false
      }else if(currentChain == 'BSC'){
        chain = 'Bsc'
        this.isShowFeacet =false
      }else if(currentChain == 'ARBITRUM'){
        chain = 'Arbitrum'
        this.isShowFeacet =false
      }else{
        this.isShowFeacet = true
      }
     let t2 = this.$t("direct_mint_des");
		   let reg2 = new RegExp("【N】", "g"); //g代表全部
		  let title = t2.replace(reg2, chain);
		  this.mintDes =title
 

  },
  methods: {
    closeDialog() {
      this.$emit("getOpen", this.open);
    },
     toFaucet() {
     
      this.$router.push({ name: "Faucet" });
    },
    OpenCreateChoose(e,flag){
        debugger
        this.openCreateChoose = e
        if(flag ){
            this.openCreateCollection = true
            this.createType = flag
			this.createDrop=''
        }
    },
   
    closeCreate(){
        console.log("closeCreate");
         this.openCreateCollection = true
         
    },
      opendropSuccess(e){
		this.opendropSuccessDialog = e
	},
    toCreate(createType) {
        if(createType == 'collect'){
           this.$gtag.event('创建-Lazy mint', { 'event_category': 'Click', 'event_label': 'Lazy mint' })
			      debugger
             this.openCreateCollection = true
              this.createType = 'ERC721'
			  this.createDrop='createDrop'
			  
        }else{
           this.$gtag.event('创建-Direct mint', { 'event_category': 'Click', 'event_label': 'Direct mint' })
             this.openCreateChoose = true
        }
   
        this.$emit("getOpen", false);
    },
     		 OpenCreateCollection(e,NftData){
          console.log("OpenCreateCollection",NftData);
         this.openCreateCollection = e
         console.log("contractImageUrl",NftData);
      if(NftData){
        this.contractAddress = NftData.contractAddress,
      this.contractImageUrl =  NftData.contractImageUrl,
        this.opendropSuccessDialog=true
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.card {
  width: 730px;
  height: 360px;
  .clo {
    position: absolute;
    right: 10px;
    top: 10px;
    width: 30px;
    height: 31px;
    border-radius: 50%;
    margin-right: 0;
    cursor: pointer;
  }

  .top {
    margin: 0 52px;
    display: flex;
    flex-direction: column;
    h3 {
      margin: 25px 0 15px;
      font-weight: bold!important;
      font-size: 25px;
      font-weight: normal;
      font-stretch: normal;
      letter-spacing: 0px;
      color: #270645;
    }
    span{
        font-family: Helvetica;
        font-size: 15px;
        font-weight: normal;
        font-stretch: normal;
        letter-spacing: 0px;
        color: #270645;
    }
  }
  .link {
    margin:0 48px 45px;
    padding-top: 50px;
    .uptick{
      cursor: pointer;
  font-family: Helvetica;
  text-decoration: underline;
  font-style: italic;
  font-size: 13px;
  font-weight: bold;
  letter-spacing: 0px;
  color: #7800f4;
  text-align: center;
    }
    .faucet {
  font-family: Helvetica;
  font-size: 13px;
  font-weight: normal;
  font-style: italic;
  font-stretch: normal;
  line-height: 20px;
  letter-spacing: 0px;
  color: #cc0000;
}
    ul {
      list-style: none;
      padding: 0;
      li {
        text-align: center;
        cursor: pointer;
        width: 302px;
        height: 250px;
       background-color: #ffffff;
        box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.09);
        border-radius: 5px;
        display: block;
        margin-bottom: 9px;
        padding: 10px 20px;
        img {
          display: block;
          margin: 20px auto 15px;
        }
        .infotit{
            font-family: Helvetica;
            font-size: 17px;
            font-weight: bold;
            font-stretch: normal;
            letter-spacing: 0px;
            color: #270645;
        }
        .infotype{
            margin-top: 8px;
            font-family: Helvetica;
            font-size: 13px;
            font-weight: normal;
            font-stretch: normal;
            letter-spacing: 0px;
            color: #766983;
        }
        .infotitauc {
          height: 22px;
          font-weight: bold;;
          font-size: 20px;
          font-weight: bold;
          font-stretch: normal;
          letter-spacing: 0px;
          line-height: 22px;
          color: #270645;
          margin: 0 auto 7px;
        }
        .infos {
            margin-top: 8px;
            font-family: Helvetica;
            font-size: 12px;
            font-weight: normal;
            font-stretch: normal;
            letter-spacing: 0px;
            color: #270645;
        }
      }
      .onlyread {
        .infotitauc,
        .infos {
          color: #ccc;
        }
      }
      li:hover {
        box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.36);
      }
    }
  }
  &.mobile {
    height: 550px;
    .top {
      h3 {
        font-size: 20px;
      }
      p {
        height: auto;
      }
    }
    .link {
      ul {
        li {
          img {
            margin: 35px auto;
          }
        }
      }
    }
  }
}
</style>
