<template>
  <div id="container">
    <v-card :class="{ card: true, mobile: isMobile }">
      <img class="clo" @click="closeDialog" :src="$vuetify.theme.dark?darkcloseIcon:closeIcon" alt />
      <div class="top mb-12">
        <h3 :class="{darkFont:$vuetify.theme.dark }">{{$t('popupMarketSelectSaleMode')}}</h3>
       
      </div>
      <div class="link">
        <ul class="d-flex flex-lg-row flex-column align-center" >
          <li class="mr-lg-4" @click="fixedPriceBtn"  :class="{somberBackground:$vuetify.theme.dark }">
            <img
              src="@/assets/icons/icon_fixed.png"
              width="69px"
              height="69px"
              alt
            />
            <div class="infotit" :class="{darkFont:$vuetify.theme.dark }">{{$t('onsaleFixedPrice')}}</div>
            <div class="infos" :class="{darkFont:$vuetify.theme.dark }">{{$t('onsaleModifiabledPrice')}}</div>
          </li>
          <li v-if="supplyLimit ==1&& parentValue.nftType=='ERC721'" class="" @click="StartSaleBtn" :class="{somberBackground:$vuetify.theme.dark }">
            <img
              src="@/assets/icons/icon_auction.png"
              width="74px"
              height="71px"
              alt
            />
            <div class="infotitauc" :class="{darkFont:$vuetify.theme.dark }">{{$t('onsaleTimedAuction')}}</div>
            <div class="infos" :class="{darkFont:$vuetify.theme.dark }">{{$t('onSaleIssues')}}</div>
          </li>
          <li v-else class="onlyread" :class="{somberBackground:$vuetify.theme.dark }">
            <img
              src="@/assets/icons/sale_auction1.png"
              width="74px"
              height="71px"
              alt
            />
            <div class="infotitauc" :class="{darkFont:$vuetify.theme.dark }">{{$t('onsaleTimedAuction')}}</div>
            <div class="infos" :class="{darkFont:$vuetify.theme.dark }">{{$t('onSaleIssues')}}</div>
          </li>
            <li class="ml-lg-4" @click="discountPriceBtn" :class="{somberBackground:$vuetify.theme.dark }">
            <img
              src="@/assets/icons/icon_discount.png"
              width="69px"
              height="69px"
              alt
            />
            <div class="infotit " :class="{darkFont:$vuetify.theme.dark }">{{$t('discountSale')}}</div>
            <div class="infosDiscount ml-7" :class="{darkFont:$vuetify.theme.dark }">{{$t('discountSaleModifiabledPrice')}}</div>
          </li>
           <li class="ml-lg-4" @click="execlusivePriceBtn" :class="{somberBackground:$vuetify.theme.dark }">
            <img
              src="@/assets/icons/icon_execlusive.png"
              width="69px"
              height="69px"
              alt
            />
            <div class="infotit " :class="{darkFont:$vuetify.theme.dark }">{{$t('execlusiveSale')}}</div>
            <div class="infosExeclu ml-5" :class="{darkFont:$vuetify.theme.dark }">{{$t('execlusiveSaleModifiabledPrice')}}</div>
          </li>
          <li class="ml-lg-4" @click="ReceivingBtn" v-if="isShowToken" :class="{somberBackground:$vuetify.theme.dark }">
            <img
              src="@/assets/icons/sale_code.png"
              width="69px"
              height="69px"
              alt
            />
            <div class="infotit " :class="{darkFont:$vuetify.theme.dark }">{{$t('ReceivingSale')}}</div>
            <div class="infosExeclu ml-5" :class="{darkFont:$vuetify.theme.dark }">{{$t('ReceivingSaleModifiabledPrice')}}</div>
          </li>
          
        </ul>
      </div>
    </v-card>
    <!-- 拍卖 -->
    <v-dialog v-model="Sale" persistent>
      <auction
        :address="address"
        :onSaleTokenIds="onSaleTokenIds"
        :offSaleTokenIds="offSaleTokenIds"
        :owerfrom ="owerfrom"
        :name="name"
        :parentValue="parentValue"
        v-bind="$attrs"
        v-on="$listeners"
        @getOpen="openSale"
      ></auction>
    </v-dialog>
    <!-- 开始售卖 -->
    <v-dialog v-model="openmarket" v-if="openmarket" persistent>
      <market
        :address="address"
        :onSaleTokenIds="onSaleTokenIds"
        :offSaleTokenIds="offSaleTokenIds"
        :NFTAmount="NFTAmount"
        :nftAddresss="nftAddresss"
        :name="name"
        :constructID="constructID"
		    :contractCollectionId="contractCollectionId"
        :Type="Type"
        :onsaleType="onsaleType"
        :parentValue="parentValue"
        v-bind="$attrs"
        v-on="$listeners"
        v-if="openmarket"
        @getOpen="openMarket"
      ></market>
    </v-dialog>
    <!-- 二维码领取 -->
     <v-dialog v-model="openreceive" persistent>
      <receiveCode
        :address="address"
        :onSaleTokenIds="onSaleTokenIds"
        :offSaleTokenIds="offSaleTokenIds"
        :NFTAmount="NFTAmount"
        :nftAddresss="nftAddresss"
        :name="name"
        :constructID="constructID"
		    :contractCollectionId="contractCollectionId"
        :Type="Type"
        :onsaleType="onsaleType"
         :parentValue="parentValue"
        v-bind="$attrs"
        v-on="$listeners"
        v-if="openreceive"
        @getOpen="openreceived"
      ></receiveCode>
    </v-dialog>

      <v-dialog v-model="openOnSaleSuccess" v-if="openOnSaleSuccess" persistent >
         <OnSaleSuccess v-bind="$attrs"
        v-on="$listeners"
        v-if="openOnSaleSuccess"  @getonsaleOpen="OpenOnSaleDialog" ></OnSaleSuccess>
      </v-dialog>

  </div>
</template>

<script>
import Auction from "@/components/popup/auction.vue";
import Market from "@/components/popup/market.vue";
import receiveCode from "@/components/popup/receiveCode.vue";
import OnSaleSuccess from "@/components/popup/onSaleSuccess";

export default {
  components: { Auction, Market,receiveCode ,OnSaleSuccess},
  props: {
    supplyLimit: {
      type: Number,
    },
    address: {
      type: String,
    },
    onsaleType:{
      type:String
    },
    onSaleTokenIds: {
      type: Array,
    },
    offSaleTokenIds: {
      type: Array,
    },
    NFTAmount:{
      type: Array,
    },
    nftAddresss: {
      type: Array,
    },
    name:{
      type: String,
    },
    constructID:{
      type: Number,
    },
	contractCollectionId:{
      type: Number,
    },
     owerfrom: {
      type: String,
    },
     parentValue: {
      type: Object,
    },
  },
  data: () => ({
    openOnSaleSuccess:false,
    open: false,
    sum: 1,
    openmarket:false,
    Sale:false,
    Type:"",
    openreceive:false,
    closeIcon:require('@/assets/btn_close.png'),
    darkcloseIcon:require('@/assets/darkimage/btn_close1.png'),
    isShowToken:true
  }),
  computed: {
    isMobile: function () {
      return this.$vuetify.breakpoint.mobile;
    },
  },
  mounted(){
            // 初始化
    let currentChain = `${this.$env.VUE_APP_MARKET_TYPE}`

    if(currentChain == 'UPTICK'){
      this.isShowToken = true
    }else if(currentChain == 'BSC' || currentChain == 'POLYGON' ||currentChain == 'ARBITRUM' ){
      this.isShowToken = false
    }
  },

  methods: {
    //关闭弹框，发送open值
    closeDialog() {
      this.$emit("getOpen", this.open);
    },
    //拍卖发行
    StartSaleBtn() {
      this.$gtag.event('上架模式-Timed Auction', { 'event_category': 'Click', 'event_label': 'Timed Auction' })
       this.$emit("getOpen", false);
      this.Sale = true;
    },
    openSale(e,flag) {
   
      this.Sale = e;
      if(flag == 'onSaleSuccess'){
        this.openOnSaleSuccess = true
      }
    },
    //定价销售
    fixedPriceBtn() {
       this.$gtag.event('上架模式-Fixed Price', { 'event_category': 'Click', 'event_label': 'Fixed Price' })
       this.$emit("getOpen", false);
      this.openmarket = true;
      this.Type = "fixedPrice"
    },
    discountPriceBtn(){
      this.$gtag.event('上架模式-Discounted Sale', { 'event_category': 'Click', 'event_label': 'Discounted Sale' })
       this.$emit("getOpen", false);
       this.openmarket = true;
       this.Type = "discountSale"
      
    },
    execlusivePriceBtn(){
      this.$gtag.event('上架模式-Exclusive Sale', { 'event_category': 'Click', 'event_label': 'Exclusive Sale' })
       this.$emit("getOpen", false);
      this.openmarket = true;
      this.Type = "execlusiveSale"
    },
    ReceivingBtn(){
      this.$gtag.event('上架模式-Receiving code', { 'event_category': 'Click', 'event_label': 'Receiving code' })
       this.$emit("getOpen", false);
      this.openreceive = true
    },

    
    openMarket(e,flag) {
      debugger
      console.log("qwqewe",e,flag);
      this.openmarket = e;
      if(flag == 'onSaleSuccess'){
        this.openOnSaleSuccess = true
      }

    },
    openreceived(e,flag){
      this.openreceive = e
       if(flag == 'onSaleSuccess'){
        this.openOnSaleSuccess = true
      }
    },
    OpenOnSaleDialog(e){
        this.openOnSaleSuccess = e
        
    }
   
  },

};
</script>

<style lang="scss" scoped>
.card {
 	// width: 801px;
	// min-height: 326px;
  // margin:0 52px;
  .clo {
    position: absolute;
    right: 10px;
    top: 10px;
    width: 30px;
    height: 31px;
    border-radius: 50%;
    margin-right: 0;
    cursor: pointer;
  }

  .top {
    padding: 0 52px;
    display: flex;
    flex-direction: column;
    h3 {
      margin: 26px 0 5px;
     font-family:Helvetica;
      font-size: 25px;
      font-weight: normal;
      font-stretch: normal;
      letter-spacing: 0px;
      color: #270645;
    }
    p {
      font-family:Helvetica;
      font-size: 20px;
      font-weight: normal;
      font-stretch: normal;
      letter-spacing: 0px;
      color: #270645;
      margin-bottom: 25px;
    }
  }
  .link {
    margin: 0 52px;
    ul {
      list-style: none;
      padding: 0;
      li {
        text-align: center;
        cursor: pointer;
        width: 175px;
        height: 175px;
        background-color: #ffffff;
        box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.09);
        border-radius: 5px;
        display: block;
        margin-bottom: 34px;
        img {
          display: block;
          margin: 20px auto 20px;
        }
        .infotit,
        .infotitauc {
          height: 22px;
          font-family:Helvetica;
          font-size: 15px;
          font-weight: bold;
          font-stretch: normal;
          letter-spacing: 0px;
          line-height: 22px;
          color: #270645;
          margin: 0 auto 7px;
        }
        .infos {
          height: 12px;
          font-family:Helvetica;
          font-size: 12px;
          font-weight: normal;
          font-stretch: normal;
          letter-spacing: 0px;
          line-height: 16px;
          color: #270645;
          margin-bottom: 17px;
          margin: 0 10px
        }
        .infosDiscount{
          font-family:Helvetica;
          width: 120px;
	        height: 26px;
          font-size: 12px;
          font-weight: normal;
          font-stretch: normal;
          line-height: 12px;
          letter-spacing: 0px;
          color: #270645;
          
        }
        .infosExeclu{
           font-family:Helvetica;
          width: 133px;
	        height: 29px;
          font-size: 12px;
          font-weight: normal;
          font-stretch: normal;
          line-height: 12px;
          letter-spacing: 0px;
          color: #270645;

        }
      }
      .onlyread {
        .infotitauc,
        .infos {
          color: #ccc;
        }
      }
      li:hover {
        box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.36);
      }
    }
  }
  &.mobile {
    height: auto;
    width: 100%;
    .top {
      h3 {
        font-size: 20px;
      }
      p {
        height: auto;
      }
    }
    .link {
      ul {
        li {
          img {
            margin: 18px auto;
          }
        }
      }
    }
  }
}
</style>
