<template>
  <div id="container">
    <v-card :class="{ card: true, mobile: isMobile }">
      <img
        class="clo"
        @click="closeDialog"
        :src="$vuetify.theme.dark?darkcloseIcon:closeIcon"
        alt=""
      />
      <div class="top">
         <div class="d-flex flex-row   align-center mt-5">
          <div class="titles" :class="{darkFont:$vuetify.theme.dark }">{{ $t("Contacts") }}</div>
          <div>
               <v-text-field
              class="search-inpt ml-5"
               :class="{darkBackground:this.$vuetify.theme.dark }"
              v-model="searchInput"
              hide-details
              hide-spin-buttons
              clear-icon="mdi-close-circle"
              clearable
            >
              <template v-slot:prepend-inner
                ><img class="prepend-inner" src="@/assets/icons/search.png" alt
              /></template>
            </v-text-field>
          </div>
 
      </div>
       <div class="list d-flex flex-lg-row flex-wrap"  @scroll="onScroll" v-if="followList && followList != ''">
      <div class="listItem" v-for="(item, index) in followList" :key="index" @click.stop="itemClick(item)" :class="{somberBackground:$vuetify.theme.dark}">
        <div class="contant d-flex flex-row">
         <Avatar
            :size="51"
            :hash="item.userBookInfo.profilePhoto"
            :grade="item.userBookInfo.grade  "
            imgType="bigv"
            :did="item.userBookInfo.address"
             fromSource="contact"
          />
          <div class="address ml-6">
            <div class="name" :class="{darkFont:$vuetify.theme.dark }">{{ item.userBookInfo.name | namefilter}} <span v-if="item.remark != null && item.remark != ''"> ( {{item.remark | remarkfilter}} )</span> </div>
            <div class="d-flex flex-row align-center">
              <div class="font-10">{{ item.userBookInfo.address }}</div>
             
            </div>
            <div class="d-flex flex-row align-center" v-if="isShowUptick">
              <div class="font-10">{{ item.userBookInfo.uptickAddress }}</div>
             
            </div>
          </div>
       
       
        </div>
      </div>
    </div>
      </div>
     
    
    
      <!-- <div class="footer">
        <button
          class="sub v-btn uc"
          :class="{ 'sub-dis': isPay }"
          submit
          @click="subCommit"
        >
          {{ $t("popupTransferCommit") }}
        </button>
      </div> -->
    </v-card>
  
    <uComponents ref="ucom"></uComponents>
  </div>
</template>
<script>
import api from "@/api";
import Avatar from "@/components/avatar/index.vue";
let last_time = 0;
export default {
  components: { Avatar },
  data: () => ({
    newOpen: true,
    searchInput:'',
     followList: [],
    pageNumber: 0,
    pageSize: 8,
    totalPage: 1,
    isShowUptick:true,
       loading: false,
       closeIcon:require('@/assets/btn_close.png'),
         darkcloseIcon:require('@/assets/darkimage/btn_close1.png'),
   
  }),

  computed: {
    isMobile: function () {
      return this.$vuetify.breakpoint.mobile;
    },
      handler: function () {
         return {
            ipt: this.searchInput,
         }
      }
  },
    filters: {
  	namefilter: function(value) {
		if(value && value.length > 30) {
			return value.substr(0,10)+"..."+value.substr(-10);
		} else {
			return value;
		}
    },
    remarkfilter: function(value) {
		if(value && value.length >= 10) {
			return value.substr(0,3)+"..."+value.substr(-3);
		} else {
			return value;
		}
  	}
  },
  watch:{
      handler:  function () {
      // this.pageNumber = 1
      // this.followList =[]
      // this.getcontarctList();
      clearTimeout(last_time);
      last_time = setTimeout(() => {
        this.followList = []
        this.pageNumber = 0;
        console.log("00")
        this.getcontarctList();
      }, 600)
  
      },
      deep: true
      
   

  },
  mounted() {
                // 初始化
    let currentChain = `${this.$env.VUE_APP_MARKET_TYPE}`
    

    if(currentChain == 'UPTICK'){
      this.isShowUptick = true
    }else if(currentChain == 'BSC' || currentChain == 'POLYGON' || currentChain == 'ARBITRUM'){
      this.isShowUptick = false
    }
     this.getcontarctList();
      
  },
  methods: {
    itemClick(item){
      console.log("itemClick",item);

      this.$emit('userInfo',item)
       this.$emit("getOpen", false);
    },
    async subCommit() {
     
    
    },
    closeDialog() {
      this.open = false;
      this.$emit("getOpen", this.open);
    },
      async getcontarctList() {
     if(this.loading)return 
     this.loading = true
     this.pageNumber++
      if (!this.$store.state.did) {
        this.$router.push({ name: "Home" });
        return;
      }
      let params = {
        pageSize: this.pageSize,
        pageNumber: this.pageNumber,
        searchKey:this.searchInput == null ? '':this.searchInput,
        //userAddress: 'iaa1ax4p4vkzccmldun8kpvqn45xru3twd6e6fvgp0',
      };
      this.isShowLoading = true;
      // let res = await api.home.contarctList(params);

        return await api.home.contarctList(params).then(res => {
        let { list } = res.data;
        this.totalPage =res.data.totalPage
        this.followList = this.followList.concat(list);
        
  
         this.loading = false
         this.isShowLoading = false;
      })
   
    },
       onScroll(e) {
        const {
          scrollTop,
          clientHeight,
          scrollHeight
        } = e.target;
        if (
          scrollTop + clientHeight +10 >= scrollHeight &&
		  this.totalPage > this.pageNumber
        ) {
          
        this.getcontarctList();
        }
      },
  },
};
</script>
<style lang="scss" scoped>
 
.search-inpt{
      align-self: center;
      width: 370px;


      .prepend-inner {
        width: 17px;
        height: auto;
 
        opacity: 1;
        margin: 1px 6px 5px 10px;
      }

      &.v-text-field.v-input--is-focused
        > .v-input__control
        > .v-input__slot:after {
        content: none !important;
      }

      .v-input__control {
        .v-input__slot::before {
          content: none;
        }
      }
           &.darkBackground{
             background-color:  #1E1E1E!important;
     .v-input__slot {
   
    align-items: center;
    background-color: #272727 !important;
    border-radius: 20px;
    outline-color: #272727;
    border: 1px #272727 solid;
    .v-input__prepend-inner {
      align-self: center;
      margin-top: 0;
      margin-left: 14px;
      margin-right: 13px;
      padding-right: 0;
    }
    .v-text-field__slot {
      flex: 1;
      padding-right: 20px;
      width: unset;
      input {
        width: auto;
      }
    }
  }
  }
 
      .v-input__slot {
        height: 40px;
        align-items: center;
        background-color: #f0f0f0;
        border-radius: 20px;
        outline-color: #f0f0f0;

        .v-input__prepend-inner {
          align-self: center;
          margin-top: 0;
          margin-left: 14px;
          margin-right: 13px;
          padding-right: 0;
        }
        .v-input__icon {
          margin-top: 4px !important;
          margin-right: 10px !important;
        }

        .v-text-field__slot {
          flex: 1;
          padding-right: 20px;
          width: unset;

          input {
            width: auto;
          }
        }
      }
    }

.card {
  display: flex;
  flex-direction: column;
  height: 400px;
  width: 860px;
  

  .top {
    display: flex;
    flex-direction: column;
    margin-left: 20px;
    .titles{
      margin-top: 12px;
      font-family: Helvetica;
      font-size: 13px;
      font-weight: bold;
      font-stretch: normal;
      line-height: 20px;
      letter-spacing: 0px;
      color: #270645;
    }
     .list {
    width: 100%;
    margin-top: 20px;
     max-height: 300px;
       overflow-y:scroll;
       padding: 4px;
    .listItem {
      width: 48%;
      height: 71px;
      background-color: #ffffff;
      box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.09);
      border-radius: 5px;
      margin-right: 2%;
      margin-top: 9px;
      &:nth-child(2n) {
        margin-right: 0;
      }
      .contant {
        margin: 10px 26px;
        position: relative;
        .address {
          .name {
            font-family: Helvetica;
            font-size: 12px;
            font-weight: bold;
            font-stretch: normal;
            line-height: 20px;
            letter-spacing: 0px;
            color: #270645;
         
          }
          .font-10 {
            font-family: Helvetica;
            font-size: 10px;
            font-weight: normal;
            font-stretch: normal;
            line-height: 15px;
            letter-spacing: 0px;
            color: #766983;
          }
          .copy {
            width: 7px;
            height: 9px;
            display: inline-block;
            vertical-align: middle;
            margin-left: 5px;
            cursor: pointer;
          }
          .click {
            margin-top: 5px;
          }
        }
      
       

      
        
      
       
      }
  
    }
  }

  }

  .clo {
    position: absolute;
    right: 10px;
    top: 10px;
    width: 30px;
    height: 31px;
    border-radius: 50%;
    margin-right: 0;
    cursor: pointer;
  }

  .footer {
    margin: 0 auto;

    .sub {
      margin: 30px auto;
      width: 450px;
      height: 51px;
      // background-image: linear-gradient(
      //     90deg,
      //     #d300fd 0%,
      //     #a439fe 26%,
      //     #7471ff 52%,
      //     #00fdcf 100%
      //   ),
      //   linear-gradient(#270645, #270645);
      background-blend-mode: normal, normal;
      border-radius: 25px;
      opacity: 0.9;
      font-weight: bold;;
      font-size: 20px;
      color: #ffffff;
    }
  }
  &.mobile {
    .footer {
      width: 100%;
      margin: 0;
      .sub {
        margin-left: 10%;
        width: 80%;
      }
    }
  }
}

img {
  display: inline-block;
  width: 126px;
  height: 126px;
  border-radius: 5px;
  margin: 1px 22px 10px 52px;
}
.sub-dis {
  position: relative;
  pointer-events: none;
  background-image: linear-gradient(#766983, #766983),
    linear-gradient(#270645, #270645) !important;
  background-blend-mode: normal, normal;
  border-radius: 25px;
  opacity: 0.9;
}
.sub-dis::after {
  content: "";
  background-image: url(../../assets/loading.gif);
  background-size: 100%;
  display: inline-block;
  position: absolute;
  width: 20px;
  height: 20px;
  margin-left: 110px;

  // box-shadow: 0 0 10px #fff;
  // border-radius: 50%;
}
</style>

