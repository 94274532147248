<template>
   <div id="container">
      <v-card :class="{card:true,mobile:isMobile}" @dragleave="dragContain"  @dragover="leaveContain" @drop="endContain">
         <img class="clo" @click="closeDialog" :src="$vuetify.theme.dark?darkcloseIcon:closeIcon" alt="">
         <div class="top">
            <h3 :class="{darkFont:this.$vuetify.theme.dark }">{{$t('popupAccountAppealAccountAppeal')}}</h3>
            <p :class="{darkFont:this.$vuetify.theme.dark }">{{$t('popupAccountAppealTheContent')}}</p>
         </div>
         <div class="ti-tx">
            <span class="title" :class="{darkFont:this.$vuetify.theme.dark }">{{$t('popupAccountAppealDescription')}}</span>
            <v-textarea ref="firsttxt" class="txt" label="" :placeholder="this.$t('popupAccountAppealPleaseDescribe')"
                        v-model="firsttxt" 
                        :rules="descRules"
                        outlined rows="5" 
                        row-height="18" 
                        counter="500"></v-textarea>
         </div>
         <div class="upload" style="margin-left: 52px;">
			 <div style="display: flex;">
				  <div class="uploadfile" v-for="(item,index) in uploadfile" :key="index">
						<img class="img" :src="item" />
					  <img class="icon" :src="$vuetify.theme.dark?darkcloseIcon:closeIcon" @click="imgRemove(index)" />
				  </div>

				  <div class="uploadfile" :class="{somberBackground:this.$vuetify.theme.dark,imgDrap:isDragImg }" v-if="uploadfile.length<3"  @drop="enentDrop"
              @dragleave="dragleave"
              @dragenter="dragleave"
              @dragover="dragleave"
              @dragend="endContain">
					  <FIleInput class="FIleInput" @change="change"  :key="fileInputKey"  accept=".jpg,.jpeg,.png,.gif">
						<v-icon>mdi-plus</v-icon>
					  </FIleInput>
				  </div>
			 </div>
            <span class="file-limit" >{{$t('popupNftAppealLimit')}}</span>
         </div>
         <div class="emailinp">
            <span class="title" :class="{darkFont:this.$vuetify.theme.dark }">{{$t('popupNftAppealEmail')}}</span>
            <v-textarea label="" v-model="emailValue" 
            ref="emailValue"
             :rules="[rule.emailRules, rule.email]"
            auto-grow 
            outlined 
            rows="2" 
            row-height="18"></v-textarea>
         </div>
         <div class="footer">
            <button class="sub" submit @click="subCommit">{{$t('popupTransferCommit')}}</button>
         </div>
      </v-card>
	  
	  <PromptBox ref="promptBox"></PromptBox>
      <uComponents ref="ucom"></uComponents>
   </div>
</template>

<script>
import api from "@/api"
import FIleInput from "@/components/FIleInput.vue";
import PromptBox from "@/components/PromptBox.vue"
import { getFileSrc } from "@/utils/file";

const UPLOAD_KEY = "UPLOAD";
   //账号申诉
   export default {
	components: { FIleInput, PromptBox },
      name: "accountAppeal",
      data: () => ({
         isDrop: false,
         isDragImg:false,
         closeIcon:require('@/assets/btn_close.png'),
         darkcloseIcon:require('@/assets/darkimage/btn_close1.png'),
         open: true,
         firsttxt: '',
         rules: [v => v.length <= 500 || 'Max 500 characters'],
         emailValue: '',
         descRules: [(v) => !!v || "description is required."],
            rule: {
        emailRules: value => !!value || 'Email is required.',
        email: value => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          return pattern.test(value) || 'Invalid e-mail.'
        },
      },

		uploadfile: [],
		 fileInputKey:0,
      }),
      computed: {
         isMobile: function () {
            return this.$vuetify.breakpoint.mobile;
         },
      },
      methods: {
            verify() {
        let emailVer = this.$refs.emailValue.validate(true);
        let nameVer = this.$refs.firsttxt.validate(true);
        !nameVer ? this.$refs.firsttxt.focus() : '';
        !emailVer ? this.$refs.emailValue.focus() : "";
        return emailVer && nameVer;
      },
         //提交转送数据
        async  subCommit() {
               if(!this.verify()) {
          return;
        }
            
                let params = {
                  complaintType:4,
                  complainants:this.$store.state.did,
                  email:this.emailValue,
                  content: this.firsttxt,
				      picture: this.uploadfile.join(',')

               }
               let res = await api.home.accountAppeal(params);
               if(res.success) {
                     this.$toast("success", this.$t("success")).then(() => {
                   this.$emit("getOpen", false)
        });
                
               } else {
                   this.$toast("error", res.msg);
               }
				// this.$refs.promptBox.show(res.msg);
         },
         //关闭弹框，发送open值
         closeDialog() {
            this.open = false;
            this.$emit('getOpen', this.open);
         },
		async change(file) {
         // if (file && (file.type !== 'image/jpeg' && file.type !== 'image/png')) {
         //    return;
         // }
             this.isDrop = true;
          let ft = file.name.substr(file.name.lastIndexOf(".") + 1);
      if ("JPG,JPEG,PNG,GIF".indexOf(ft.toUpperCase()) == -1) {
        this.$toast("error", this.$t("file_type_not_support"));
        this.fileInputKey++;
           this.isDrop = false;
        return;
      }
       if (file.size > 20971520) {
          this.$toast("error", this.$t("image_not_support"));
           this.isDrop = false;
          return;
        }

          let path = await api.image.uploadImage(file);
        let  imageUrl = path.data.data;
		  this.fileInputKey++;
		  let url = this.getImageIpfs(imageUrl);
        this.uploadfile.push(url);
         this.isDrop = false;

		},

		imgRemove(idx) {
			this.uploadfile.splice(idx, 1);
      },
        enentDrop(e) {
               e.stopPropagation();
      e.preventDefault(); //必填字段
           console.log('111111111');
      this.isDragImg = false
  
      let file = e.dataTransfer.files[0];
      this.change(file);
    },
    enentDropImg(e) {
       console.log('222222');
      e.stopPropagation();
      e.preventDefault(); //必填字段
      let file = e.dataTransfer.files[0];
      this.imgChange(file);
    },
    dragleave(e) {
       console.log('33333333');
         this.isDragImg = true
      e.stopPropagation();
      e.preventDefault();
    },

    dragContain(e) {
       console.log("44444");
      this.isDragImg = false;
    },
    leaveContain(e) {
       console.log("55555");
      this.isDragImg = true;
      e.stopPropagation();
      e.preventDefault();
    },
    endContain(e) {
       console.log("666");
      this.isDragImg = false;
      e.stopPropagation();
      e.preventDefault();
    },
      }
   }
</script>

<style lang="scss" scoped>
 ::v-deep .v-messages__message {
    color: red;
  }
   img {
      display: inline-block;
      width: 126px;
      height: 126px;
      background-color: #8a73ff;
      border-radius: 5px;
      margin: 1px 22px 10px 52px;
   }

   span {
      display: flex;
      align-items: center;
      font-family: Helvetica;
      color: #270645;
   }

   .title {
      font-weight: bold;;
      font-size: 15px;
      font-weight: normal;
      font-stretch: normal;
      color: #270645;
   }

   .card {
      display: flex;
      flex-direction: column;
      min-height: 569px;
      width: 600px;

      .clo {
         position: absolute;
         right: 10px;
         top: 10px;
         width: 30px;
         height: 31px;
         border-radius: 50%;
         margin-right: 0;
         cursor: pointer;
      }

      .top {
         h3 {
            margin: 41px 0 17px 52px;
            font-weight: bold;;
            font-size: 25px;
            color: #270645;
         }

         p {
            margin: 0 52px;
            width: 494px;
            height: 65px;
            font-family: Helvetica;
            font-size: 15px;
            font-weight: normal;
            font-stretch: normal;
            line-height: 28px;
            letter-spacing: 0;
            color: #766983;
         }
      }

      .ti-tx {
         margin: 25px 52px;

         .txt {
            width: 500px;
            height: 166px;
      
            background-blend-mode: normal, normal;
         }
      }

      .upload {
         .imgDrap{
            border: solid 2px #7800f4 !important;
        }
         .uploadfile {
            margin-bottom: 14px;
            width: 101px;
            height: 101px;
            background-color: #ffffff;
            border-radius: 1px;
            border: solid 2px #e3e3e3;

			display: flex;
			justify-content: center;
			align-items: center;
			position: relative;
			margin-right: 30px;
			cursor: pointer;
			.FIleInput {
					  width: 100%;
					  height: 100%;
					  .v-icon {
						  width: 100%;
						  height: 100%;
						  padding: 25px;
					  }

			}
			.icon {
					  width: 20px;
					  height: 20px;
					  border-radius: 50%;
					  position: absolute;
					  top: -10px;
					  left: 30px;
					  background-color: #fff;
					  z-index: 99;
					  cursor: pointer;
			}
			.img {
					  width: 100%;
					  height: 100%;
					  margin: 0;
			}
         }

         .file-limit {
            // width: 137px;
            height: 13px;
            font-family: Helvetica;
            font-size: 13px;
            font-weight: normal;
            font-stretch: normal;
            // line-height: 100px;
            letter-spacing: 0px;
            color: #766983;
         }
      }

      .emailinp {
         margin: 22px 52px;
      }

      .footer {
         display: flex;
         flex-direction: column;
         text-align: center;
         margin-bottom: 50px;

         .sub {
            margin: 0 auto;
            width: 450px;
            height: 51px;
            background-color: #7800f4;
            border-radius: 25px;
            opacity: 0.9;
            font-weight: bold;;
	         font-size: 20px;
            color: #ffffff;
         }
      }

   }
</style>


