import Vue from "vue";

(function() {
    let env = process.env.VUE_APP_ENVIRONMENT;
	let envs = process.env;
	let result = { };
	for (const key in envs) {
		if(key.search(/_dev|_uat|_pro/i) && key.length-key.search(/_dev|_uat|_pro/i)==4) {
			if(key.lastIndexOf(env) > 0) {
				let nkey = key.substring(0, key.length-4);
				result[nkey] = envs[key];
			}
		}
		else {
			result[key] = envs[key];
		}
	}
	window.$env = result;
	Vue.prototype.$env = result;
})();

// VUE_APP_API_URL +  api
// export const BASE_URL = `${window.$env.VUE_APP_API_URL}/api/1.0`;
export const BASE_URL = `/uptickapi`;
export const BASE_URL_S3 = `/uptickS3`;
// image base url
export const BASE_IMAGE_URL = `${process.env.VUE_APP_FILE_API_URL}/v2/image`;
export const BASE_JSON_URL = `${process.env.VUE_APP_FILE_API_URL}/file/upload/json`;
// video base url
export const BASE_VIDEO_URL = `${process.env.VUE_APP_FILE_API_URL}/file`;
