<template>
  <div id="container">
    <v-card :class="{ card: true, mobile: isMobile }">
      <img class="clo" @click="closeDialog" :src="$vuetify.theme.dark?darkcloseIcon:closeIcon" alt />
      <div class="top">
        <h3 :class="{darkFont:this.$vuetify.theme.dark }">{{ $t("popupWalletConnect") }}</h3>
        <p>{{ $t("popupWalletConnectWallet") }}</p>
      </div>
      <div class="link">
        <ul>
          <!-- <li
            v-if="keyChain == 'IRIS' || keyChain == 'UPTICK'"
            class="chain d-flex flex-row"
            @click="KeplrBtn"
          >
            <img src="@/assets/icons/logo_keplr.png" alt />
            <span>{{ $t("popupWalletKeplrWallet") }}</span>
          </li> -->
          <li class="fox d-flex flex-row" @click="metaMaskBtn" >
            <img src="@/assets/icons/logo_metamask.png" alt />
            <span>{{ $t("popupWalletMetaMask") }}</span>
          </li>
          <li class="wallet  d-flex flex-row" @click="wallentBtn" >
            <img   src="@/assets/icons/logo_walletconnect.png" alt />
            <span>WalletConnect</span>
          </li>
        </ul>
      </div>
      <div class="store" @click="clickInstall">
        <a target="_blank" href="https://chrome.google.com/webstore/detail/metamask/nkbihfbeogaeaoehlefnkodbefgpgknn" >If you have not installed the MetaMask wallet plugin, click here to install it.</a>
      </div>
      <!-- <spin>
        <a
          class="chormdetail"
          :href="walletDownLoadUrl"
          target="_blank"
        >
          {{ walletDownLoadNotice }}
        </a></spin
      > -->
    </v-card>
    <uComponents ref="ucom"></uComponents>
  </div>
</template>

<script>
import { getkeplaWeb3, getMatamaskWeb3 } from "../../utils/getAccount";
 import { connectWallets, events } from "@/walletConnectU";

import api from "@/api";
import { set } from 'vue-gtag';
//钱包登录弹层
export default {
  data: function() {
    return {
      open: true,
      isShowIcon:true,
      openWallet: false,
      closeIcon:require('@/assets/btn_close.png'),
         darkcloseIcon:require('@/assets/darkimage/btn_close1.png'),
      walletDownLoadUrl: (this.$chainName == "UPTICK EVM")
        ? "https://chrome.google.com/webstore/detail/metamask/nkbihfbeogaeaoehlefnkodbefgpgknn"
        : "https://chrome.google.com/webstore/detail/keplr/dmkamcknogkgcdfhhbddcghachkejeap?utm_source=chrome-ntp-icon"
    }
  },
  props: {
    keyChain: String,
  },
  computed: {
    isMobile: function () {
      return this.$vuetify.breakpoint.mobile;
    },
    walletDownLoadNotice: function() {
      if(this.$chainName == "UPTICK EVM") {
        return this.$t("walletDownLoadNotice").replace("Keplr","MetaMask");
      }
      return this.$t("walletDownLoadNotice");
    },
  },
  mounted(){
 //  wallectonnect 初始化  关闭弹层
    events.$on('wallet_is_connect',res=>{
      console.log("res ---- 1111",res);
      if(res)
        this.$emit("getOpen", false);

      })
      // 初始化
    let currentChain = `${this.$env.VUE_APP_MARKET_TYPE}`
    this.srcName = currentChain
    if(this.srcName == 'UPTICK'){
      this.isShowIcon = true
    }else if(this.srcName == 'BSC' || this.srcName == 'POLYGON' || this.srcName == 'ARBITRUM'){
      this.isShowIcon = false
    }


  },
  methods: {
    clickInstall(){
       this.$gtag.event('下载-metaMask', { 'event_category': 'Click', 'event_label': '下载-metaMask' })
    },
    closeDialog() {
      this.$gtag.event('登录-关闭', { 'event_category': 'Click', 'event_label': 'close' })
      this.open = false;
      this.$emit("getOpen", this.openWallet);
    },
    async metaMaskBtn() {
       this.$gtag.event('登录-metaMask', { 'event_category': 'Click', 'event_label': 'metaMask' })
      if(!window.ethereum) {
        this.$toast("error", "You have not installed the MetaMask wallet plugin");
        return;
      }
      // await loopringCreate();
      
      // let did = await this.LRCHandler.getAddresss();
      let did = await getMatamaskWeb3();
      this.$store.commit("SET_DID", did.toLowerCase());
      if (did.length > 0) {
        this.$emit("getOpen", false);
        this.$emit("getStatus", true, did, 2);
      } else {
        
        this.$emit("getStatus", false);
      }
    },
    wallentBtn() {
       this.$gtag.event('登录-walletConnect', { 'event_category': 'Click', 'event_label': 'walletConnect' })
       connectWallets();
   
     
      events.$on("connect", res => {

        console.log("wxl ------33333333333333",res);
        if(res){
          let walletparams = {
            connected:true
          }
          localStorage.setItem("walletconnect",JSON.stringify(walletparams) );
  
          let did = res.toLowerCase();
          this.$store.commit("SET_DID", did);
          this.$emit("getStatus", true, did, 3);
        }
       
  
          window.location.reload();
       
  
    
   
      });
    },
    async KeplrBtn() {
      let account = await getkeplaWeb3();

      let mount = await this.LRCHandler.getUserBalances(1);
      localStorage.setItem("key_balance", mount);

      this.$store.commit("SET_DID", account.toLowerCase());

      if (account.length > 0) {
        this.$emit("getOpen", false);
        this.$emit("getStatus", true);
        this.$router.push({ name: "Home" });
        window.location.reload();
      } else {
        this.$emit("getStatus", false);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.card {
  width: 500px;
  min-height: 400px;
  .clo {
    position: absolute;
    right: 10px;
    top: 10px;
    width: 30px;
    height: 31px;
    border-radius: 50%;
    margin-right: 0;
    cursor: pointer;
  }
  .chormdetail {
    margin: 0 52px;
    display: flex;
    flex-direction: column;
    color: #766983;
    font-size: 15px;
  }
  .top {
    margin: 0 52px;
    display: flex;
    flex-direction: column;
    h3 {
      margin: 41px 0 15px;
      font-weight: bold;;
      font-size: 25px;
      color: #270645;
    }
    p {
      // width: 367px;
      height: 41px;
      font-family: Helvetica;
      font-size: 15px;
      font-weight: normal;
      font-stretch: normal;
      letter-spacing: 0px;
      color: #766983;
    }
  }
  .link {
    margin: 26px 52px;
    ul {
      list-style: none;
      padding: 0;
      li {
        cursor: pointer;
        // width: 400px;
        height: 61px;
        background-image: linear-gradient(#ffffff, #ffffff),
          linear-gradient(#f8f6fd, #f8f6fd);
        background-blend-mode: normal, normal;
        box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.09);
        border-radius: 5px;
        display: block;
        margin-bottom: 9px;
        img {
          display: inline-block;
          margin: 10px 28px;
        }
        span {
          display: inline-block;
          //   width: 80px;
          position: relative;
          top: 3px;
          left: 0;
          height: 13px;
          font-weight: bold;;
          font-size: 15px;
          font-weight: bold;
          font-stretch: normal;
          line-height: 60px;
          letter-spacing: 0px;
          color: #270645;
        }
      }
      li:hover {
        box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.36);
      }
      .fox {
        img {
          width: 40px;
          height: 40px;
        }
      }
      .wallet {
        img {
          margin-top: 16px;
          width: 40px;
          height: 40px;
        }
        span {
          position: relative;
          top: 5px;
          left: 0;
        }
      }
      .chain {
        img {
          width: 40px;
          height: 40px;
        }
      }
    }
  }

  .store {
    margin: 26px 52px;
  }

  &.mobile {
    // min-height: 470px;
    .top {
      h3 {
        font-size: 20px;
      }
      p {
        height: auto;
      }
    }
    .link {
      ul {
        li {
          img {
            margin: 10px;
          }
        }
      }
    }
  }
}
</style>
