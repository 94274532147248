<template>
 
<div class="notice">
    <p class="animate">
       {{ title}}<a href="https://blog.uptickproject.com/tutorial-entering-the-uptick-testnet-2-0-world-60506f402dbe" target="_blank">「Tutorial one」,</a> 
       <a href="https://blog.uptickproject.com/uptick-evm-marketplace-beta-v2-0-1-release-note-6de83ac7e824" target="_blank">「Tutorial Two」</a>
    </p> 
   
</div>
</template>
<script>


export default {
  name: "Notice",

  data: function () {
    return {  
        title:''
    };
  },
  mounted() {
        window.eventBus.$on('LangChange', this.onLangChange);

   let t = this.$t("noticeText")
          let regN=new RegExp('<br/>','g')//g代表全部
		this.title=t.replace(regN," ");
  },
  methods: {
      onLangChange(){

   let t = this.$t("noticeText")
          let regN=new RegExp('<br/>','g')//g代表全部
		this.title=t.replace(regN," ");

      }
  },
};
</script>
 <style   lang="scss" scoped>
        .notice {
            width: 100%;
            height: 30px;
            /* margin: 0 auto; */
            /* border: 1px solid #ff6700; */
            overflow: hidden;
            background: #f8f1d4;
        }
 
        .animate {
            padding-left: 20px;
            font-size: 13px;
            color: red;
            display: inline-block;
            white-space: nowrap;
            animation: 50s wordsLoop linear infinite normal;
            &:hover{
                animation-play-state:paused;

            }
            a{
                 color: red;
            }
           
      
            
        }
        
        
 
        @keyframes wordsLoop {
            0% {
                transform: translateX(1220px);
                -webkit-transform: translateX(1220px);
            }
            100% {
                transform: translateX(-100%);
                -webkit-transform: translateX(-100%);
            }
        }
 
        @-webkit-keyframes wordsLoop {
            0% {
                transform: translateX(1220px);
                -webkit-transform: translateX(1220px);
            }
            100% {
                transform: translateX(-100%);
                -webkit-transform: translateX(-100%);
            }
        }
    </style>