import Vue from "vue";

import "@/assets/iconfont/iconfont";
import SvgIcon from "./SvgIcon.vue";

Vue.component("svg-icon", SvgIcon);

// 这里是需要注册的组件名称
const Icons = ["s"];

const IconFont = Icons.reduce((ret, icon) => {
  ret[icon] = {
    component: SvgIcon,
    props: {
      iconClass: icon,
      className: icon,
    },
  };
  return ret;
}, {});

export default IconFont;
