<template>
  <div :class="{ contain: true, mobile: isMobile }">
    <div class="ccokies">
      <div
        class="cookie d-flex align-center"
        :class="{ somberBackground: this.$vuetify.theme.dark }"
      >
        <!-- <img
          class="clo"
          @click="closeDialog"
          :src="$vuetify.theme.dark ? darkcloseIcon : closeIcon"
          alt=""
        /> -->
        <div class="d-flex flex-row align-center">
          <div class="content" :class="{ darkFont: this.$vuetify.theme.dark }" >
           {{$t('HomeCookies')}}
          </div>
          <!-- <div class="content" :class="{ darkFont: this.$vuetify.theme.dark }" v-else>
           {{$t('googleCookies')}}
          </div> -->
          <button class="accept" @click="acceptCookie">{{$t('acceptAll')}}</button>
          <button class="decline" @click="declineCookie">{{$t('Decline')}}</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "cookiesDialog",
  props: {
      source:{
          type:String
      }
  },
  data: () => ({
    closeIcon: require("@/assets/btn_close.png"),
    darkcloseIcon: require("@/assets/darkimage/btn_close1.png"),
  }),
  computed: {
    isMobile: function () {
      return this.$vuetify.breakpoint.mobile;
    },
  },
  methods: {
    closeDialog() {
      this.$emit("getOpen", false);
    },
    acceptCookie() {
       
             localStorage.setItem("acceptIndexCookie",true);
            //  this.$emit('setState',true)
            window.eventBus.$emit('acceptCookie')
        
     
      this.$emit("getOpen", false);
    },
    declineCookie() {
        
             localStorage.setItem("acceptIndexCookie",false);
              this.$emit('setState',false)
        
     
      this.$emit("getOpen", false);
    },
  },
};
</script>

<style lang="scss" scoped>
.contain {
  .ccokies {
    .cookie {
      padding: 10px 22px 0;
      width: 60%;
      height: 100px;
      left: 20%;
      right: 20%;
      position: fixed;
      bottom: 30px;
      background-color: #ffffff;
      // line-height: var(--footer-height);
      z-index: 999;
      box-shadow: 0px 0px 9px 0px 
		rgba(0, 0, 0, 0.09);
	border-radius: 5px;
	border: solid 1px #baa6cd;
      .clo {
        position: absolute;
        right: 10px;
        top: 10px;
        width: 20px;
        height: 20px;
        border-radius: 50%;
        margin-right: 0;
        cursor: pointer;
      }
      .content {
        width: 70%;
        font-family: Helvetica;
        font-size: 15px;
        font-weight: normal;
        font-stretch: normal;
        line-height: 20px;
        letter-spacing: 0px;
        color: #270645;
      }
      .accept {
        width: 13%;
        height: 40px;
        background-color: #7800f4;
        border-radius: 20px;
        font-family: Helvetica;
        font-size: 13px;
        font-weight: bold;
        font-stretch: normal;
        line-height: 20px;
        letter-spacing: 0px;
        color: #ffffff;
        margin-left: 3%;
      }
      .decline {
        margin-left: 1%;
        width: 13%;
        height: 40px;
        background-color: #ffffff;
        border-radius: 20px;
        border: solid 1px #cccccc;
        font-family: Helvetica;
        font-size: 13px;
        font-weight: bold;
        font-stretch: normal;
        line-height: 20px;
        letter-spacing: 0px;
        color: #270645;
      }
    }
  }
  &.mobile{
    .ccokies {
      .cookie{
        width: 100%;
        left: 0;
        height: 125px;
        .content {
          width: 65%;
          font-size: 12px;
        }
        .accept{
          width: 22%;
        }
         .decline {
           width: 22%;
         }
      }
    }

  }
}
</style>
