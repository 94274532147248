import Vue from "vue";
import VueRouter from "vue-router";
import Home from "@/views/home/index.vue";

Vue.use(VueRouter);
//解决编程式路由往同一地址跳转时会报错的情况
const originalPush = VueRouter.prototype.push;
const originalReplace = VueRouter.prototype.replace;
//push
VueRouter.prototype.push = function push(location, onResolve, onReject) {
   if (onResolve || onReject)
      return originalPush.call(this, location, onResolve, onReject);
   return originalPush.call(this, location).catch(err => err);
};
//replace
VueRouter.prototype.replace = function push(location, onResolve, onReject) {
   if (onResolve || onReject)
      return originalReplace.call(this, location, onResolve, onReject);
   return originalReplace.call(this, location).catch(err => err);
};

const routes = [
   {
      path: "/",
      redirect: '/index',
   },
   {
      path: "/index",
      name: "Home",
      component: Home,
   },
   {
      path: "/downloads",
      name: "Download",
      component: () => import("@/views/app_download/index.vue"),
   },
   {
      path: "/launchpad",
      name: "LaunchPad",
      component: () => import("@/views/marketplace/index.vue"),
   },
   {
      path: "/drops",
      name: "Drops",
      component: () => import("@/views/drops/index.vue"),
   },
   {
      path: "/marketplace",
      name: "Marketplace",
      component: () => import("@/views/resale/index.vue"),
   },
   {
      path: "/promo",
      name: "Promo",
      component: () => import("@/views/Promo/index.vue"),
   },
   {
      path: "/airdrop",
      name: "Airdrop",
      component: () => import("@/views/airdrop/index_uptick.vue"),
   },
   
   {
      path: "/mvprewards",
      name: "MvpRewards",
      component: () => import("@/views/Promo/components/MvpRewards.vue"),
   },

   {
      path: "/initialContributionReward",
      name: "InitialContributionReward",
      component: () => import("@/views/Promo/components/InitialContributionReward.vue"),
   },
   {
      path: "/promotest",
      name: "PromoTest",
      component: () => import("@/views/Promo/components/PromoTest.vue"),
   },
   {
      path: "/interchainReward",
      name: "InterchainReward",
      component: () => import("@/views/Promo/components/InterchainReward.vue"),
   },
   
   {
      path: "/promotionProgram",
      name: "PromotionProgram",
      component: () => import("@/views/Promo/components/PromotionProgram.vue"),
   },
   {
      path: "/genesisCampaign",
      name: "GenesisCampaign",
      component: () => import("@/views/Promo/components/GenesisCampaign.vue"),
   },
   {
      path: "/marketplaceCampaign",
      name: "MarketplaceCampaign",
      component: () => import("@/views/Promo/components/MarketplaceCampaign.vue"),
   },
   {
      path: "/halloween",
      name: "Halloween",
      component: () => import("@/views/Promo/components/Halloween.vue"),
   },

   
   {
      path: "/marketplaceCampaign2",
      name: "MarketplaceCampaign2",
      component: () => import("@/views/Promo/components/MarketplaceCampaign2.vue"),
   },
   
  
   {
      path: "/genesisCollectCampaign",
      name: "GenesisCollectCampaign",
      component: () => import("@/views/Promo/components/GenesisCollectCampaign.vue"),
   },
  
   
   {
      path: "/interchainNFTs",
      name: "InterchainNFTs",
      component: () => import("@/views/Promo/components/InterchainNFTs.vue"),
   },
   {
      path: "/onboardingProgram",
      name: "OnboardingProgram",
      component: () => import("@/views/Promo/components/OnboardingProgram.vue"),
   },
   {
      path: "/lazyMint",
      name: "LazyMint",
      component: () => import("@/views/Promo/components/LazyMint.vue"),
   },
   
   {
      path: "/detail",
      name: "Detail",
      component: () => import("@/views/detail/index.vue"),
   },
   {
      path: '/follows',
      name: 'Follows',
      component: () => import('../views/frame/follows.vue')
   },
   {
      path: '/fans',
      name: 'Fans',
      component: () => import('../views/frame/fans.vue')
   },
   {
      path: '/setting',
      name: 'Setting',
      component: () => import('../views/frame/setting.vue')
   },

   {
      path: '/auctionlist',
      name: 'Auctionlist',
      component: () => import('../views/frame/auction.vue')
   },
   {
      path: '/creatorlist',
      name: 'Creatorlist',
      component: () => import('../views/frame/creatorNew.vue')
   },
   {
      path: '/featurelist',
      name: 'Featurelist',
      component: () => import('../views/frame/featureList.vue')
   },
   {
      path: '/saledetail',
      name: 'Saledetail',
      component: () => import('../views/saleDetail/index.vue')
   },
   {
      path: '/salecopy',
      name: 'Salecopy',
      component: () => import('../views/frame/salecopy.vue')
   },
   {
      path: '/otherpage',
      name: 'Otherpage',
      component: () => import('../views/frame/otherpage.vue')
   },
   {
      path: '/otherpage/:shortUrl',
      name: 'Otherpage',
      component: () => import('../views/frame/otherpage.vue')
   },
   {
      path: '/carddetail',
      name: 'Carddetail',
      component: () => import('../views/frame/carddetail.vue')
   },
   {
      path: '/create',
      name: 'Create',
      component: () => import('../views/frame/create.vue')
   },
   {
      path: '/createnft',
      name: 'CreateNft',
      component: () => import('../views/frame/createNft.vue')
   },
   {
      path: '/collect',
      name: 'Collect',
      component: () => import('../views/frame/collect.vue')
   },
   {
      path: '/myInfo',
      name: 'MyInfo',
      component: () => import('../views/frame/myInfo.vue')
   },
   {
      path: '/faucet',
      name: 'Faucet',
      component: () => import('../views/frame/faucet.vue')
   },
   
   {
      path: '/editSpotlight',
      name: 'EditSpotlight',
      component: () => import('../views/frame/editShowCase.vue')
   },
   {
      path: '/myreferences',
      name: 'MyReferences',
      component: () => import('../views/frame/MyReferences.vue')
   },
   
   {
      path: '/referralmanagement',
      name: 'ReferralManagement',
      component: () => import('../views/frame/referralManagement.vue')
   },
   {
      path: '/collectionEdit/:nftAddress',
      name: 'collectionEdit',
      component: () => import('../views/lazyMintSetting/index.vue'),
      children: [
         {
            path: '/collectionEdit/link/:nftAddress',
            name: 'collectionEditLink',
            component: () => import('../views/lazyMintSetting/components/link.vue')
         },
         {
            path: '/collectionEdit/graphics/:nftAddress',
            name: 'collectionEditGraphics',
            component: () => import('../views/lazyMintSetting/components/graphics.vue')
         },
         
      ]
   },
   {
      path: '/dropEdit/:nftAddress',
      name: 'dropEdit',
      component: () => import('../views/dropEdit/index.vue')
   },
   {
      path: '/dropPage/:nftAddress',
      name: 'dropPage',
      component: () => import('../views/dropPage/index.vue')
   },
   
   
   // {
   //    path: '/collection_page',
   //    name: 'CollectionPage',
   //    component: () => import("@/views/frame/collectionPage.vue")
   // },
   {
      path:'/folder/:id/:source/:owner',
      name:'FolderPage',
      component: () => import("@/views/frame/folderPage.vue")
   },
   {
      path:'/folder/:id',
      name:'FolderPage',
      component: () => import("@/views/frame/folderPage.vue")
   },
   {
      path:'/folder_create/:id/:source/:owner',
      name:'FolderPage_create',
      component: () => import("@/views/frame/folderPage.vue")
   },
   {
      path:'/collection/:id/:source/:owner',
      name:'CollectionPage',
      component: () => import("@/views/frame/collectionPage.vue")
   },
   {
      path:'/collection/:id',
      name:'CollectionPage',
      component: () => import("@/views/frame/collectionPage.vue")
   },
   {
      path:'/collection_create/:id/:source/:owner',
      name:'CollectionPage_create',
      component: () => import("@/views/frame/collectionPage.vue")
   },
   {
      path: '/pointsCenter',
      name: 'PointsCenter',
      component: () => import('../views/pointsCenter/index.vue')
   },
   {
      path: '/claimHistory',
      name: 'ClaimHistory',
      component: () => import('../views/pointsCenter/claimHistory.vue')
   },
   {
      path: '/twitterApprove',
      name: 'TwitterApprove',
      component: () => import('../views/twitterApprove/index.vue')
   },
   
   {
      path: '/myOrders',
      name: 'MyOrders',
      component: () => import('../views/frame/myOrders.vue')
   },
   {
      path: '/message',
      component: () => import('../views/message/index.vue'),
      children: [
         {
            path: '',
            name: 'Message',
            component: () => import('../views/message/UserMessage.vue')
         },
         {
            path: 'notifications',
            name: 'Notifications',
            component: () => import('../views/message/Notifications.vue')
         },
         {
            path: 'system-alert',
            name: 'SystemAlert',
            component: () => import('../views/message/SystemAlert.vue')
         },
      ]
   },
   {
      path: '/personal-page',
      name: 'PersonalPage',
      component: () => import('../views/personalPage/index.vue'),
   },
   {
      path: '/createSuccess',
      name: 'CreateSuccess',
      component: () => import('../views/frame/createSuccess.vue'),
   },

   {
      path: '/spotlight',
      name: 'Spotlight',
      component: () => import('../views/showCasePage/index.vue'),
   },
   {
      path: '/spotlight/:source/',
      name: 'Spotlight',
      component: () => import('../views/showCasePage/index.vue'),
   },
   {
      path: '/lucky',
      name: 'Lucky',
      component: () => import('../views/frame/lucky.vue')
   },  
   {
      path: "/lottery",
      name: "Lottery",
      component: () => import("@/views/pool/lotteryList.vue"),
   },
   {
      path: "/lotterydetail",
      name: "Lotterydetail",
      component: () => import("@/views/pool/index.vue"),
   },
   {
      path: '/helpcenter',
      name: 'HelpCenter',
      component: () => import('../layout/components/HelpPage.vue'),
   },
   {
      path: '/privacy',
      name: 'Privacy',
      component: () => import('../layout/components/privacy_en.vue'),
   },
   {
      path: '/term',
      name: 'Term',
      component: () => import('../layout/components/term_en.vue'),
   },
   {
      path: '/privacy_zh',
      name: 'Privacyzh',
      component: () => import('../layout/components/privacy_zh.vue'),
   },
   {
      path: '/term_zh',
      name: 'Termzh',
      component: () => import('../layout/components/term_zh.vue'),
   },
   {
      path: '/auctionrule_zh',
      name: 'Auctionrulezh',
      component: () => import('../assets/html/AuctionRules_zh'),
   },
   {
      path: '/auctionrule_en',
      name: 'Auctionruleen',
      component: () => import('../assets/html/AuctionRules_en'),
   },
   {
      path: '/AuctionIosRules_en',
      name: 'AuctionIosRules_en',
      component: () => import('../assets/html/AuctionIosRules_en'),
   },
   {
      path: '/AuctionIosRules_zh',
      name: 'AuctionIosRules_zh',
      component: () => import('../assets/html/AuctionIosRules_zh'),
   },
   
];



const router = new VueRouter({
  mode: 'history',
   base: process.env.BASE_URL,
   routes,
   scrollBehavior(to, from, savedPosition) {
      if (savedPosition) {
         return savedPosition
      } else {
         return { x: 0, y: 0 }
      }
   }
});

export default router;
