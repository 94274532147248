<template>
  <div id="container">
    <v-card :class="{ card: true, mobile: isMobile }">
      <img
        class="clo"
        @click="closeDialog"
        :src="$vuetify.theme.dark?darkcloseIcon:closeIcon"
        alt=""
      />
      <div class="top d-flex ">
        <img class="successImg" src="@/assets/icons/icon_04.png" alt="">
      
       
      </div>
      <div class="Congrats" :class="{ darkFont: this.$vuetify.theme.dark }">{{$t('buy_congrats')}}</div>
      <div class="des mt-2" :class="{ darkFont: this.$vuetify.theme.dark }">{{$t('buy_progress')}}</div>
      <div class="hash" :class="{ darkFont: this.$vuetify.theme.dark }">{{$t('buy_hash')}}</div>
      <div class="copyRight d-flex flex-row">
          <div class="hashValue" :class="{ darkFont: this.$vuetify.theme.dark }">{{NFT_info.hash}}</div>
           <img
            class="copy ml-3"
            :class="{ click: isCopy }"
            src="@/assets/image/eara.png"
            @click="onCopyClick(NFT_info.hash)"
          />
      </div>
      <div class="buyInfo mt-5 d-flex flex-row align-center" >
          <div class="left">
              <img :src="imgUrl" alt="">
          </div>
          <div class="right ml-8">
              <div class="name" :class="{ darkFont: this.$vuetify.theme.dark }">{{NFT_info.NftName}}</div>
              <div class="from" :class="{ darkFont: this.$vuetify.theme.dark }">{{$t('saledetailHistoryForm')}}: {{NFT_info.creatorName ?NFT_info.creatorName:NFT_info.creator.substr(0, 12)+"..." +NFT_info.creator.substr(-12)}}</div>
              <div class="from" :class="{ darkFont: this.$vuetify.theme.dark }">{{$t('saledetailHistoryPrice')}}: {{NFT_info.marketPrice}} {{NFT_info.tokenType}}</div>
          </div>
      </div>
      <div class="footer mt-9 mb-9">
         <button
          class="btn v-btn uc"
          submit
          @click="toMarketPlace"
        >
        {{$t('shop_more')}}
        </button>
        <button
          class="btn v-btn uc ml-6"
          submit
          @click="toHomePage"
        >
        {{$t('view_pack')}}
        </button>
      </div>
    </v-card>
    <v-snackbar
      v-model="isShowFalse"
      color="error"
      centered
      timeout="2000"
      style="opacity: 0.8"
      ><span style="color: white; font-size: 20px">{{
        falseTitle
      }}</span></v-snackbar
    >
    <v-snackbar
      v-model="isShowSuccess"
      color="success"
      centered
      timeout="2000"
      style="opacity: 0.8"
      ><span style="color: white; font-size: 20px">{{
        successTitle
      }}</span></v-snackbar
    >
    <uComponents ref="ucom"></uComponents>
  </div>
</template>
<script>
import api from "@/api";
export default {
  data: () => ({
    isCopy:false,
    newOpen: true,
    nftName: "",
    isShowFalse: false,
    isPay: false,
    isShowSuccess: false,
    falseTitle: "this is a error",
    successTitle: "create success",
    multiNFTids:[],
    closeIcon:require('@/assets/btn_close.png'),
    darkcloseIcon:require('@/assets/darkimage/btn_close1.png'),
  }),
  props: {
   NFT_info:{
       type:Object
   },
   imgUrl:{
       type:String
   }
   
  },
  computed: {
    isMobile: function () {
      return this.$vuetify.breakpoint.mobile;
    },
  },
  mounted() {
      console.log("NFT_info",this.NFT_info,this.imgUrl);
  },
  methods: {
   toMarketPlace(){
      this.$gtag.event('购买成功-Continue browsing', { 'event_category': 'Click', 'event_label': 'Continue browsing' })
     this.$router.push({name:'Marketplace'})
   },
   toHomePage(){
      this.$gtag.event('购买成功-View my NFTs', { 'event_category': 'Click', 'event_label': 'View my NFTs' })
     this.$router.push({name:'PersonalPage'})
   
     this.$emit("getbuySuccess", false);
   },
    closeDialog() {
       this.$gtag.event('购买成功-关闭', { 'event_category': 'Click', 'event_label': '关闭' })
      this.open = false;
      this.$emit("getbuySuccess", this.open);
    },

   
    onCopyClick(hash) {
      this.$gtag.event('购买成功-链上hash', { 'event_category': 'Click', 'event_label': '链上hash' })
       // 当前所在市场
     let currentChain = `${this.$env.VUE_APP_MARKET_TYPE}`
      if(currentChain == 'POLYGON'){
            window.open("https://polygonscan.com/tx/"+hash , "_blank");
      }else if(currentChain == 'BSC')
      {
        //https://bscscan.com/tx/0xe7f7dba31745dd44ca228c67b9495e238e525882be16d1dacfd560b20922f2e4
         window.open("https://bscscan.com/tx/"+hash , "_blank");
      }else if(currentChain == 'ARBITRUM')
      {
        //https://bscscan.com/tx/0xe7f7dba31745dd44ca228c67b9495e238e525882be16d1dacfd560b20922f2e4
         window.open("https://arbiscan.io/tx/"+hash , "_blank");
      }
      else
       {
            window.open("https://evm-explorer.uptick.network/tx/"+hash +"/token-transfers", "_blank");
      }
      //  window.eventBus.$emit("copyHash",this.NFT_info.hash);
      //https://evm-explorer.origin.uptick.network/tx/0xec2ae28bfe68051395559cd2fd565d7730dc58f19fb1e3de91647206f80ef022/token-transfers

   


      //  https://polygonscan.com/tx/0x0de038ac3076558e0503399230dffad6cb445e51394a37cbfaf2ab1c3bb89b6e?__cf_chl_rt_tk=ZKAalqx44vU_i.C1PRZL1IYof8WLufUxiSZSTqkFSdk-1694354215-0-gaNycGzNDLs

    


    },

  
  },
};
</script>
<style lang="scss" scoped>

.card {
     background-image: url(../../assets/icons/icon_bg_01.png) ;
      background-repeat: no-repeat;
    background-size: cover;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 450px;
  width: 600px;

  .top {
    display: flex;
    flex-direction: column;
    margin: 30px auto 0;
    .successImg{
      width: 78px;
	    height: 68px;
    }

    .end {
      margin: 0px auto 13px;
      width: 100%;
      height: 21px;
      text-align: center;
      font-weight: bold!important;
      font-size: 25px;
      font-weight: normal;
      font-stretch: normal;
      letter-spacing: 0px;
      color: #270645;
      white-space: nowrap;
    }

    .content {
      margin: 19px auto 25px;
      width: 226px;
      min-height: 22px;
      font-family: Helvetica;
      font-size: 20px;
      font-weight: normal;
      font-stretch: normal;
      letter-spacing: 0px;
      color: #270645;
      text-align: center;
    }
  }
  .Congrats{
      margin-top: 26px;
      	font-family: Helvetica;
	font-size: 23px;
	font-weight: bold;
	font-stretch: normal;
	line-height: 25px;
	letter-spacing: 0px;
  color: #270645;
  text-align: center;
  }
  .des{
      width: 419px;
	height: 27px;
	font-family: Helvetica;
	font-size: 12px;
	font-weight: normal;
	font-stretch: normal;
	line-height: 20px;
	letter-spacing: 0px;
	color: #766983;
    text-align: center;

  }
  .hash{
      margin-top: 20px;
      font-family: Helvetica;
	font-size: 12px;
	font-weight: bold;
	font-stretch: normal;
	line-height: 30px;
	letter-spacing: 0px;
	color: #270645;
  }
  .copyRight{
      .hashValue{
      font-family: Helvetica;
	font-size: 12px;
	font-weight: normal;
	font-stretch: normal;
	line-height: 20px;
	letter-spacing: 0px;
	color: #766983;
      }
      .copy{
        
        width: 13px;
      height: 15px;
       display: inline-block;
      vertical-align: middle;
     
      cursor: pointer;
    
      }
  }
  .buyInfo{
      .left{
          img{
              	width: 101px;
	height: 101px;
    object-fit: cover;
    border-radius: 5px;
          }
      }
      .right{
          .name{
              font-family: Helvetica;
	font-size: 13px;
	font-weight: bold;
	font-stretch: normal;
	line-height: 24px;
	letter-spacing: 0px;
	color: #270645;
          }
          .from{
              font-family: Helvetica;
	font-size: 13px;
	font-weight: normal;
	font-stretch: normal;
	line-height: 20px;
	letter-spacing: 0px;
	color: #270645;
          }

      }
  }

  .clo {
    position: absolute;
    right: 10px;
    top: 10px;
    width: 30px;
    height: 31px;
    border-radius: 50%;
    margin-right: 0;
    cursor: pointer;
  }

  .footer {
    margin: 0 auto;

   .btn{
       	width: 136px;
	height: 41px;
	background-color: #ffffff !important;
	border-radius: 20px;
	border: solid 1px #cccccc;
    	font-family: Helvetica;
	font-size: 13px;
	font-weight: bold;
	font-stretch: normal;
	line-height: 20px;
	letter-spacing: 0px;
	color: #270645;
   }
   
  }
  &.mobile {
    .footer {
      width: 100%;
      margin: 0;
      .sub {
        margin-left: 10%;
        width: 80%;
      }
    }
  }
}


.sub-dis {
  position: relative;
  pointer-events: none;
  background-image: linear-gradient(#766983, #766983),
    linear-gradient(#270645, #270645) !important;
  background-blend-mode: normal, normal;
  border-radius: 25px;
  opacity: 0.9;
}
.sub-dis::after {
  content: "";
  background-image: url(../../assets/loading.gif);
  background-size: 100%;
  display: inline-block;
  position: absolute;
  width: 20px;
  height: 20px;
  margin-left: 110px;

  // box-shadow: 0 0 10px #fff;
  // border-radius: 50%;
}
</style>

