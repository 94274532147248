<template>
  <div id="container">
    <v-card :class="{ card: true, mobile: isMobile }">
      <img
        class="clo"
        @click="closeDialog"
        :src="$vuetify.theme.dark?darkcloseIcon:closeIcon"
        alt=""
      />
      <div class="top">
		   <img :src="Imgsrc" alt="" style="background-color: #fff" />
        <h3 class="end" :class="{darkFont:$vuetify.theme.dark }">{{ $t("Create_collection_end") }}</h3>
        <h3 class="content" :class="{darkFont:$vuetify.theme.dark }">
          {{ $t("Create_collection_end_desc") }}
        </h3>
      </div>
      <div class="footer">
        <button class="sub v-btn uc" :class="{ 'sub-dis': isPay }" submit @click="openEdit">
          {{ $t("Continue_editing") }}
        </button>
      </div>
    </v-card>

	
    <uComponents  ref="ucom"></uComponents>
	
  </div>
</template>
<script>
import api from '@/api'

import { getFileSrc } from "@/utils/file";
export default {
  data: () => ({
    open: true,
    isPay: false,
    multiNFTids:[],
    closeIcon:require('@/assets/btn_close.png'),
    darkcloseIcon:require('@/assets/darkimage/btn_close1.png'),
	Imgsrc:''
  }),
  props: {
    imageUrl: {
      type: String,
    },
    contractAddress: {
      type: String,
    }
  },
  computed: {
    isMobile: function () {
      return this.$vuetify.breakpoint.mobile;
    },
  },
  async mounted() {
	  this.Imgsrc = await getFileSrc("", this.imageUrl);
	      
      
  },
  methods: {
    async openEdit() {
	this.$router.push({path: `/collectionEdit/${this.contractAddress}`})
     this.$emit("getDropOpen", false);
        
    },
    closeDialog() {
      this.open = false;
      this.$emit("getDropOpen", this.open);
    },
  },
};
</script>
<style lang="scss" scoped>
.card {
  display: flex;
  flex-direction: column;
  min-height: 254px;
  width: 450px;
  .top {
    display: flex;
    flex-direction: column;
    margin: 30px 60px 30px;
    .end {
      margin: 0 auto;
      // width: 117px;
      // height: 20px;
      font-weight: bold!important;
      font-size: 22px!important;
      font-weight: normal;
      font-stretch: normal;
      letter-spacing: 0px;
      color: #270645;
    }
img{
	margin: 0 auto 23px;
	object-fit: cover;
}
    .content {
      font-weight: bold;;
      font-size: 12px;
      font-weight: normal;
      font-stretch: normal;
      letter-spacing: 0px;
      text-align: center;
      color: #766983;
    }
  }
  .clo {
    position: absolute;
    right: 10px;
    top: 10px;
    width: 30px;
    height: 31px;
    border-radius: 50%;
    margin-right: 0;
    cursor: pointer;
  }
  .footer {
    margin: 0 auto;
    .sub {
      margin: 20px auto;
      width: 160px;
      height: 40px;
      background-blend-mode: normal, normal;
      background-color: #7800f4;
      border-radius: 25px;
      opacity: 0.9;
      font-weight: bold;;
      font-size: 15px;
      color: #ffffff;
    }
    .sub-dis {
  position: relative;
  pointer-events: none;
  background-image: linear-gradient(#766983,
      #766983),
    linear-gradient(#270645,
      #270645) !important;
  background-blend-mode: normal,
    normal;
  border-radius: 25px;
  opacity: 0.9;
}

.sub-dis::after {
  content: "";
  background-image: url(../../assets/loading.gif);
  background-size: 100%;
  display: inline-block;
  position: absolute;
  width: 20px;
  height: 20px;
  margin-left: 130px;
}

  }
  &.mobile {
    .top {
      width: 100%;
      margin: 30px 10% 0;

      .content {
        width: 80%;
        height: auto;
        word-wrap: break-word;
      }
    }
    .footer {
      width: 100%;
      margin-left: 10%;
      .sub {
        width: 80%;
      }
    }
  }
}

img {
  display: inline-block;
  width: 126px;
  height: 126px;
  border-radius: 5px;
  margin: 1px 22px 10px 52px;
}
</style>




