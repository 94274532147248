<template>
  <v-chip :class="{ mobile: isMobile }"
    v-if="show"
      class="rds"
    color="rgba(255, 255, 255, 0.01)"
    text-color="primary"
    :ripple="false"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <slot />
  </v-chip>
   <v-chip :class="{ mobile: isMobile }"
    v-else
    class="rd"
    text-color="primary"
    :ripple="false"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <slot />
  </v-chip>
</template>

<script lang="js">

export default {
  name: 'Chip',
  inheritAttrs: false,
  computed: {
    isMobile: function () {
        return this.$vuetify.breakpoint.mobile;
    },
    show: function () {
        return this.$route.name === 'EditSpotlight' || this.$route.name === 'dropEdit'  || this.$route.name === 'dropPage' || this.$route.name === 'Spotlight' || this.$vuetify.theme.dark
     },
  },

};
</script>

<style lang="scss" scoped>
.v-chip.rd {
  height: 40px;
  font-size: 13px;
background-color: #ffffff;
  border-radius: 20px;
  

}
.v-chip.rds {
  height: 40px;
  font-size: 13px;
  border-radius: 20px;
  

}

.mobile.v-chip.rd {
  height: 41px !important;
}
</style>
