<template>
    <v-app :class="{ 'drawer-opened': drawerOpened }" >
        <!-- <Notice style="z-index: 201;position: sticky;top: 0px;" v-if="!show" /> -->
        <Header v-model="drawerOpened" :containerId="containerId" style="z-index: 201;position: sticky;top: 0px;" v-if="!showHeader" />
        <v-main v-show="!drawerOpened" class="setButtom" :class="{noButtom:this.$route.name === 'collectionEdit' ||this.$route.name === 'collectionEditLink' }" >
            <v-container :id="containerId" class="content" :class="{ShopWindow:show}">
                <router-view :key="key"></router-view>
            </v-container>
        </v-main>
        <Cookies @getOpen="openCookies" v-if="isopenCookies" source="Home"/>
        <Footer v-if="!isShowFooter"  />
    </v-app>
</template>

<script>
    import Header from './Header.vue';
    import Footer from './Footer.vue';
     import Notice from '../layout/components/Notice';
     import Cookies from "@/components/cookiesDialog.vue";

    export default {
        name: 'Layout',
        components: {
            Header,
            Footer,
            Notice,
            Cookies
        },
        data: function() {
            return {
                drawerOpened: false,
                containerId: 'container',
                    isopenCookies:true
            };
        },
        mounted(){
              // 判断是否展示cookies
    let isShowcookie= localStorage.getItem('acceptIndexCookie')
    if(isShowcookie){
      this.isopenCookies = false
    }
        },
        methods:{
              openCookies(e){
      this.isopenCookies = e
    },
        },
        computed: {

            key() {
                return this.$route.name !== undefined ?
                    this.$route.name + new Date() :
                    this.$route + new Date();
            },
            
            showHeader: function () {
                 return this.$route.name === 'EditSpotlight' || this.$route.name === 'Spotlight' || this.$route.name === 'AuctionIosRules_en' || this.$route.name === 'AuctionIosRules_zh' || this.$route.name === 'collectionEdit' ||  this.$route.name === 'dropEdit' || this.$route.name === 'dropPage' || this.$route.name === 'collectionEditLink' 
                 
             },
             show: function () {
                 return this.$route.name === 'EditSpotlight' || this.$route.name === 'Spotlight' || this.$route.name === 'AuctionIosRules_en' || this.$route.name === 'AuctionIosRules_zh' || this.$route.name === 'collectionEdit' ||  this.$route.name === 'dropEdit' || this.$route.name === 'dropPage' || this.$route.name === 'collectionEditLink'
                 ||  this.$route.name === 'CollectionPage' || this.$route.name === 'CollectionPage_create'|| this.$route.name === 'FolderPage_create' || this.$route.name ===  'FolderPage'
                 
             },
            isShowFooter:function(){
                return this.$route.name === 'AuctionIosRules_en'  || this.$route.name === 'AuctionIosRules_zh'  || this.$route.name === 'collectionEdit'|| this.$route.name === 'dropEdit' || this.$route.name === 'dropPage' || this.$route.name === 'collectionEditLink' 
            }
        },
        beforeMount() {
            this.$store.dispatch('setInfo');
        }

    };
</script>

<style lang="scss" scoped>
    .drawer-opened {
        height: 100vh;
    }

    .content {
        margin: 100px auto 0;
        padding: 0 2%;
        width: calc(100% - 40px);
        min-height: 550px;

        @media screen and (min-width: #{map-get($grid-breakpoints, 'lg')}) {
       
             width: 100%;
            min-height: 550px;
            max-width: 100%;
        }
    }
    .ShopWindow{
         margin: 0 auto;
        padding: 0;
        width: 100%;
        min-height: 550px;

        @media screen and (min-width: #{map-get($grid-breakpoints, 'lg')}) {
            width: 100%;
            min-height: 550px;
            max-width: 100%;
        }
    }
</style>

<style>
.setButtom{
    padding-top: 0px !important; 
    margin-bottom:100px
}
.noButtom{
          margin-bottom:0 !important
}
    .width-100 {
        display: inline-block !important;
        width: 100% !important;
        height: auto !important;
        overflow: hidden !important;
        white-space: nowrap !important;
        text-overflow: ellipsis !important;
        -o-text-overflow: ellipsis !important;
    }

    .width-18 {
        display: inline-block !important;
        width: 180px !important;
        height: auto !important;
        overflow: hidden !important;
        white-space: nowrap !important;
        text-overflow: ellipsis !important;
        -o-text-overflow: ellipsis !important;
    }

    .width-8 {
        display: inline-block !important;
        width: 80px !important;
        height: auto !important;
        overflow: hidden !important;
        white-space: nowrap !important;
        text-overflow: ellipsis !important;
        -o-text-overflow: ellipsis !important;
    }

    .theme--light.v-tabs>.v-tabs-bar .v-tab:not(.v-tab--active),
    .theme--light.v-tabs>.v-tabs-bar .v-tab:not(.v-tab--active)>.v-icon,
    .theme--light.v-tabs>.v-tabs-bar .v-tab:not(.v-tab--active)>.v-btn,
    .theme--light.v-tabs>.v-tabs-bar .v-tab--disabled {
        color: #766983 !important;
    }
</style>
