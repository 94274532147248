<template>
   <div style="height:95px;" :class="{mobile: isMobile}">
      <v-card-title class="text--title px-5  mt-n1">{{value.name ? value.name : "No Name"}}</v-card-title>
      <v-card-text v-if="value.makerType== '2'" class="d-flex flex-row justify-space-between py-0 px-5 mt-n3">
           <template v-if="value.auctionStatus == '0'"
      >
        <div>
          <div class="text--sub-title text--secondary-rd">
            {{ $t("popupAuctionStartingBid") }}
          </div>
          <div class="d-flex flex-row mt-1">
            <div>
               <v-img v-if="value.marketPrice" :src="getChainByName(value.tokenType).img" alt="logo" width="23px" height="23px"/>
            </div>
            <div
                class="ml-2 text--price text--primary-rd font-weight-medium" style="line-height:22px;"
            >
              {{ value.guaranteedPrice }}
            </div>
          </div>
        </div>
        <div>
          <div class="text--sub-title text--secondary-rd">
            {{ $t("workCardStartingin") }}
          </div>
          <div class="mt-1">
            <div class="text--number text--primary-rd font-weight-medium pt-1">
              {{ hour }}h {{ minutes }}m {{ second }}s
            </div>
          </div>
        </div>
      </template
      >
         <template v-else-if="value.auctionStatus == '1' ">
            <div>
               <div class="text--sub-title text--primary-rd">
                  {{ $t("workCardCurrentBid") }}
               </div>
               <div class="d-flex flex-row mt-2" style="margin-bottom: 10px;">
                  <div>
                     <v-img v-if="value.marketPrice" :src="getChainByName(value.tokenType).img" alt="logo" width="22px" height="22px"/>
                  </div>
                  <div class="ml-2 text--number text--primary-rd font-weight-medium" style="line-height:22px;">
                     {{ value.lastBidPrice }}
                  </div>
               </div>
            </div>
            <div>
               <div class="text--sub-title text--primary-rd">
                  {{ $t("workCardEndingin") }}
               </div>
               <div class="mt-2">
                  <div class="text--number text--primary-rd font-weight-medium">
                     {{ hour }}h {{ minutes }}m {{ second }}s
                  </div>
               </div>
            </div>
         </template>
         <template v-else>
            <div class="d-flex justify-space-between status-7">
               <div>
                  <div class="text--sub-title text--primary-rd">
                     {{ $t("workCardCurrentBid") }}
                  </div>
                  <div class="d-flex flex-row mt-2">
                     <div>
                        <v-img
                           v-if="value.marketPrice" :src="getChainByName(value.tokenType).img"
                            alt="logo"
                            width="22px"
                            height="22px"
                        />
                     </div>
                     <div
                         class="
                     ml-2
                     text--number text--primary-rd
                     font-weight-medium
                   " style="line-height:22px;"
                     >
                        {{ value.minPrice }}
                     </div>
                  </div>
               </div>
            </div>
            <div class="sold_out">{{$t("workCardSoldOut")}}</div>
         </template>
      </v-card-text>

      <v-card-text  class="py-0 px-5" style="height: 40px; margin-top: 13px;">
         <template>
            <template v-if="value.sellout == true">
               <div class="d-flex justify-space-between mt-n1">
                  <div class="d-flex justify-space-between ">
                   <div>
                      <div class="d-flex flex-row ">
                         <div >
                           <v-img v-if="value.issuerPrice" :src="getChainByName(value.issuerTokenType).img" alt="logo" width="22px" height="22px"/>
                        </div>
                         <div
                             class="ml-2 text--number text--primary-rd font-weight-medium" style="line-height:22px;"
                         >
                            {{value.issuerPrice}}
                         </div>
                      </div>
                   </div>
               </div>
               <div class="sold_out">{{$t("workCardSoldOut")}}</div>
               </div>
            </template>
            <template v-else>
                 <div>
                     <!-- <div class="text--sub-title text--primary-rd">Current Bid</div> -->
                     <div class="d-flex flex-row mt-n2" style="">
                        <div >
                           <v-img v-if="value.marketPrice" :src="getChainByName(value.tokenType).img" alt="logo" width="22px" height="22px"/>
                        </div>
                        <div
                            class="ml-2 text--number text--primary-rd font-weight-medium" style="line-height:22px;"
                        >
                           {{value.marketPrice}}
                        </div>
                     </div>
                  </div>
               
            </template>
         </template>
      </v-card-text>
   </div>
</template>

<script lang='js'>
   // import countLogo from "@/assets/icons/chain_iris_w.png"

  export default {
    name: "pageCardContent",
    props: {
      value: {
        type: Object,
        default: () => ({
          denomType: 8,
          onsale: true
        })
      }
    },
    data: function() {
      return {
        // countLogo,
        isStart: false,
        now: "",
        start: "",
        end: "",
        day: 0,
        hour: 0,
        minutes: 0,
        second: 0,
        seconds: "",
      };
    },
    computed: {
		isMobile: function () {
			return this.$vuetify.breakpoint.mobile;
		}
   },
    mounted() {
      this.Time(); //调用定时器
    },
    methods: {
      getTime() {
        this.now = Date.parse(new Date());
		if(this.value.auctionStartTime){
		   this.start = this.value.auctionStartTime;
        this.end = this.value.auctionEndTime;	
		   if (this.value.auctionStatus == 0 || this.value.auctionStatus == 1) {
          if (this.now < this.start) {
            this.isStart = false;
            this.seconds = (this.start - this.now) / 1000;
          } else if (this.now < this.end) {
            this.isStart = true;
            this.seconds = (this.end - this.now) / 1000;
          }
        }
		
		}
     
       

     
      },
      // 天 时 分 秒 格式化函数
      countDown() {
        let d = parseInt(this.seconds / (24 * 60 * 60));
        this.day = d < 10 ? "0" + d : d;
        let h = parseInt(((this.seconds / (60 * 60)) % 24) + d * 24);
        this.hour = h < 10 ? "0" + h : h;
        let m = parseInt((this.seconds / 60) % 60);
        this.minutes = m < 10 ? "0" + m : m;
        let s = parseInt(this.seconds % 60);
        this.second = s < 10 ? "0" + s : s;
      },
      //定时器没过1秒参数减1
      Time() {
        setInterval(() => {
          if (this.seconds > 0) {
            this.seconds -= 1;
            this.countDown();
          }else{
            this.getTime();
          }
        }, 1000);
      },
    },
  };

</script>

<style lang='scss' scoped>
   .status-7{
      padding-bottom:10px;
   }
   .text--primary-rd {
      color: #270645;
   }

   .text--secondary-rd {
      color: #766983;
   }

   .text--info {
      font-size: 10px;
      line-height: 1;
      letter-spacing: 0px;
   }

   .text--title {
      font-size: 13px;
      line-height: 24px;
      width: 217px;
      font-weight: bold;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      display: inline-block;
   }

   .text--sub-title {
      font-size: 12px;
      line-height: 1;
      text-align: right;
   }

   .text--number {
      font-size: 13px;
      line-height: 1;
      font-weight: bold!important;
   }

   .v-divider {
      border-color: #e3e3e3;
   }

   .sold_out {
      width: 115px;
      height: 36px;
      background-image: linear-gradient(
              91deg,
          
              #7800f4 58%,
              #9969fc 100%
      ),
      linear-gradient(#ffffff, #ffffff);
      background-blend-mode: normal, normal;
      border-radius: 18px;
      text-align: center;
      font-weight: bold;;
      font-size: 13px;
      font-weight: bold;
      font-stretch: normal;
      line-height: 36px;
      letter-spacing: 0px;
      color: #ffffff;
      position: absolute;
      right: -15px;
      bottom: 14px;
   }

   .btnbox {
      width: 119px;
      height: 35px;
      box-sizing: border-box;
      padding: 2px;
      background-image: linear-gradient(
              0deg,
              #d202fd 0%,
              #a538fe 26%,
              #776eff 51%,
              #01facd 100%
      );
      border-radius: 17px;

      .rd {
         width: 100%;
         height: 100%;
         border: none;
         border-radius: 17px;
         background: #fff;
         font-weight: bold;;
         font-size: 13px;
         font-weight: bold;
         font-stretch: normal;
         letter-spacing: 0;
         color: #270645;
      }
   }

   .mobile {
      .text--sub-title {
         // text-align: left;
         white-space:nowrap;
      }
      .text--number {
         white-space:nowrap;
      }
   }
</style>
