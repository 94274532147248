<template>
    <div class="content">
        <transition name="slide-fade" v-for="item in tips" :key="item.index">
            <div class="tip" v-if="item.isShow" >
                <img class="close" @click="close_click(item.index)" src="@/assets/icons/contacts_delete_w.png">
                <!-- <img class="icon" :src="item.data.icon" /> -->
                <div class="cont" @click="toNotifition">
                    <div class="title">{{ item.data.title }}</div>
                    <!-- <div class="text">{{ item.data.text }}</div> -->
                    <div class="number" v-if="item.data.number">{{item.data.number}}</div>
                </div>
            </div>
        </transition>
    </div>
</template>

<script>
    let tip_idx = 0;
    // import img_wait from '@/assets/loading.gif';
    // import img_yes from '@/assets/icons/icon_yan_yes.png';
    
    export default {
        name: 'Popup',
        data() {
            return {
                data: {
                    title:'',number:'',router:''
                    // 1: { icon: img_wait, title: "You succeed to create【The trip Rick and rty】"},
                    // 2: { icon: img_yes, title: "Your order【The trip Rick and morty】was successful, it can be viewed in 【My Collections】" }
                },
                tips: [],
                router:''
            }
        },
      
        methods: {
            show(opt) {

                console.log("--------",opt);
                let dt = {};
                this.router = opt.router
                if(typeof(opt) == 'number') {
                    dt = this.data[opt];
                } else if(typeof(opt) == 'object') {
                    dt = opt;
                } else {
                    throw "参数类型错误";
                }
                let tip = {
                    index: tip_idx,
                    isShow: true,
                    data: dt
                };
                console.log(225, tip);
                this.tips.push(tip);
                tip_idx++;
                
                this.closeTip(tip);
            },
            close_click(idx) {
                let fd = this.tips.find(f => f.index==idx);
                fd.isShow = false;
                
                clearTimeout(fd["time_"+idx]);
                
                console.log(222,idx,fd);
                // let fi = this.tips.findIndex(f => f.index==idx);
                // this.tips.splice(fi, 1);
            },
            closeTip(opt) {
                opt["time_"+opt.index] = setTimeout(() => {
                    this.close_click(opt.index);
                }, 5000);
            },
            toNotifition(){
                // console.log("toNotifition",this);
  
                this.router.push({name:'Notifications'});
            }
        },
    }
</script>

<style lang="scss" scoped>
    .content {
        position: fixed;
        // top: 120px;
        left: 0;
        bottom: 100px;
        width: 350px;
        z-index: 99999;
        // background-color: rgba(0,0,0,0.5);
        pointer-events: none;
        
        
        .tip {
            // padding: 10px 0;
            margin: 10px;
           background-image: linear-gradient(90deg, 
		#6e1cea 0%, 
		#7e0574 100%);
	border-radius: 5px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 5px;
            position: relative;
            pointer-events: auto;
            min-height: 45px;
            
            .close {
                margin: 20px 20px;
                position: absolute;
                left: 289px;
                top: -13px;
            //    background-color: #270645;
                color: white;
                border-radius: 50%;
                width: 10px;
                height: 10px;
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 13px;
                cursor: pointer;
            }
            
            // .icon {
            //     width: 28px;
            //     height: 28px;
            // }
            
            .cont {
                //margin-left: -17px;
                width: 100%;
                  .number{
                    min-width: 24px;
                    height: 20px;
                    background-color: #230942;
                    border-radius: 10px;
                    position: absolute;
                    bottom:5px;
                    right: 10px;
                    font-family: Helvetica;
                    font-size: 12px;
                    font-weight: normal;
                    font-stretch: normal;
                    line-height: 20px;
                    letter-spacing: 0px;
                    color: #bf95ff;
                    text-align: center;
                }
                
                .title {
                   padding-left: 20px;
                    // margin-left: -10px;
                    width: 265px;
	                // min-height: 80px;
                    font-size: 15px;
                    font-weight: bold;
                   font-family:Helvetica;
                    color: #FFFFFF;
                    line-height: 20px;
                    //white-space:nowrap;
                //   word-break: break-all;
                    word-wrap: break-word;
                     
                     margin-top:18px;
                     margin-bottom:20px;
                    
                }
                .text {
                    color: #FFFFFF;
                }
            }
        }
    }
    
    /* 设置持续时间和动画函数 */
    .slide-fade-enter-active {
      transition: all .3s ease;
    }
    .slide-fade-leave-active {
      transition: all .8s cubic-bezier(1.0, 0.5, 0.8, 1.0);
    }
    .slide-fade-enter, .slide-fade-leave-to
    /* .slide-fade-leave-active for below version 2.1.8 */ {
      transform: translateX(100px);
      opacity: 0;
    }
</style>
