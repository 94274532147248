<template> 
<div v-if="recommendList.length>=2" :class="{  mobile: isMobile}" >
  <div class="titleCenter">
       <div class="name" v-if="!isMobile" :class="{darkFont:this.$vuetify.theme.dark }">{{ $t('trendingCollections') }}</div>
        <div class="nameMobile" v-else :class="{darkFont:this.$vuetify.theme.dark }">{{ $t('trendingCollections') }}</div>
       <img @click="toCollection" :src="rightIcon" :alt="$t('homeFeaturedCreatorsTitle')" />
  </div>
  <!-- <div class="img">
     
  </div> -->
    <div class="recommendCard"  v-if="!isMobile" :class="{darkBackground:this.$vuetify.theme.dark }">
      <v-carousel v-model="activeSlide" @mousedown.native="carouselDown" @mouseup.native="carouselUp"
        :show-arrows="false" :hide-delimiters="isMobile" height=''
      >
        <v-carousel-item
          v-for="(pageItem, pageIndex) in carouselPage"
          :key="pageIndex"
        >
          <div
          
            class="d-flex flex-row"
            style="margin-left:0px;!important"
        
          >
            <div class="card" v-for="(item, index) in pageItem" :key="index">
             
               <trend-card :pageValue="item" :hash="item.imgUrl" type="indexpage" collectsource="firspublish" ></trend-card>
            </div>
          </div>
        </v-carousel-item>
      </v-carousel>
      
    </div>
      <div class="recommendCardMobile" v-else >
      <v-carousel v-model="activeSlide" @mousedown.native="carouselDown" @mouseup.native="carouselUp"
        :show-arrows="false" :hide-delimiters="isMobile"
      >
        <v-carousel-item
          v-for="(pageItem, pageIndex) in carouselPage"
          :key="pageIndex"
        >
          <v-row
          
            align="center"
            style="margin-left:0px;!important;margin:auto"
        
          >
            <div class="card" v-for="(item, index) in pageItem" :key="index">
             
               <trend-card :pageValue="item" :hash="item.imgUrl" type="indexpage" collectsource="firspublish" ></trend-card>
            </div>
          </v-row>
        </v-carousel-item>
      </v-carousel>
      
    </div>
  </div>    
  
</template>

<script>
let carouselPosition = 0;
import api from "@/api";
import TrendCard from '@/components/workCard/TrendCard.vue';
import rightIcon from "@/assets/icons/icon_r.png";

export default {
  name: "trandingCollection",
  components: { TrendCard },
  props:{
    recommendList:{
      type:Array
    }
  },
  
  data: function () {
    return {
      rightIcon,
      activeSlide: 0,
      carouselPage: [],
      list: [],
      pageNumber: 0,
      pageSize: 8,
      totalPage: false,
      isShowLoading: false,
     
    };
  },
  
  mounted() {
    // setTimeout(() => {
    //   this.getList();
    // }, 8000);
    this.getRecommendList();
  },
   computed: {
    isMobile: function () {
      return this.$vuetify.breakpoint.mobile;
    },
   },
  methods: {
     carouselDown(e) {
       this.$gtag.event('集合推荐-滚动', { 'event_category': 'Scroll', 'event_label': 'collection' })
      console.log("carouselDown",e);
      if(e.button == 0) {
        carouselPosition = e.clientX;
      }
    },
    carouselUp(e) {
      console.log("carouselUp",e);
      if(e.button == 0) {
        if(carouselPosition > e.clientX) {
          this.activeSlide++;
        } else if(carouselPosition < e.clientX) {
          this.activeSlide--;
        }
      }
    },
    toCollection(){
      this.$gtag.event('集合推荐-查看更多', { 'event_category': 'Click', 'event_label': 'collection' })
       this.$router.push({name:'LaunchPad'})
    },
     async getRecommendList(){
      // let params = {
      //   pageNumber:1,
      //   pageSize:10,
      //   owner:this.$store.state.did
      // }
 
      // let lists= await api.home.getRecommendList(params)
      // this.recommendList = lists.data.list;
      if(!this.isMobile) {
        for (let i = 0; i < Math.ceil(this.recommendList.length/4); i++) {
          this.carouselPage.push([]);
          for (let j = i*4; j < i*4+4; j++) {
            const element = this.recommendList[j];
            if(!element) continue;
            this.carouselPage[i].push(element);
          }
        }
      } else {
        for (let i = 0; i < this.recommendList.length; i++) {
          const element = this.recommendList[i];
          this.carouselPage.push([]);
          this.carouselPage[i].push(element);
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
 .mobile .titleCenter {
  margin-top:80px;
 }
.titleCenter {
  margin-top: 40px;
  min-width: 100%;
  text-align: left;
  display: flex;
  flex-direction: row;
 justify-content: space-between;
 .name{
   width: 100%;
  margin-bottom: 34px;
	font-family: Helvetica;
	font-size: 30px;
	font-weight: bold;
	font-stretch: normal;
	letter-spacing: 0px;
	color: #270645;

 }

 .nameMobile{
    width: 100%;
  margin-bottom: 34px;
	font-family: Helvetica;
	font-size: 29px;
	font-weight: bold;
	font-stretch: normal;
	letter-spacing: 0px;
	color: #270645;
 }
 img {
    display: inline-block;
    width: 41px;
    height: 41px;
    // margin-top: 40px;
  }
}
.img{
   
}
 .recommendCard {
   width: 100%;
    display: flex;
    user-select: none;
    &::v-deep .v-carousel__controls{
      background:white;
      // position:relative;
      
      // top:430px;
      .v-btn {
    color:   #766983!important;
}
  .v-btn--active {
    color:#7800f4!important;
  

  }
   .v-btn--active::before {
    background:white;
      opacity: 0.01;
    
  }
  .v-icon{
    font-size:10px!important;
  }


    }
   
    .card {
      margin-top: 16px;
      margin-bottom: 16px;
      margin-right: 2%;
      width: 23%;
      padding-bottom: 40px;

      // justify-content: space-between;
      &:nth-child(5n) {
        margin-right: 0;
      }
    }
    &.darkBackground{
       &::v-deep .v-carousel__controls{
      background:#121212 !important;
      // top:420px;
      .v-btn {
    color:   #766983!important;
}
  .v-btn--active {
    color:#7800f4!important

  }
   .v-btn--active::before {
    background:white;
    
  }
  .v-icon{
    font-size:10px!important;
  }


    }

    }
  }
 
    .recommendCardMobile {
    display: flex;
    user-select: none;
    // margin-left: 40px;
    margin-bottom: 70px;
    position: relative;
    &::v-deep .v-carousel__controls{
      background:white;
      // top:420px;
      .v-btn {
    color:   #766983!important;
}
  .v-btn--active {
    color:#7800f4!important

  }
   .v-btn--active::before {
    background:white;
    
  }
  .v-icon{
    font-size:10px!important;
  }


    }
    &::v-deep .v-carousel{
      height: 100%!important;
    }
    &::v-deep .v-carousel__item{
       height: 100%!important;
    }
   
    .card {
      width: 100%;
      aspect-ratio: 1 / 1;
      margin-top: 16px;
      margin-bottom: 16px;
      margin-right: 22px;

      // justify-content: space-between;
      &:nth-child(5n) {
        margin-right: 0;
      }
    }

  }
</style>
