<script lang='js'>
import { VFileInput } from 'vuetify/lib'

export default {
  extends: VFileInput,
  name: 'FIleInput',
  props: {
    hideInput: {
        type: Boolean,
        default: true
    },
    prependIcon: {
      type: String,
      default: null
    },
  },
  
  methods: {
    genSelfDefault() {
        const data = {
          style: {
            width: '100%'
          },
        attrs: {
          'aria-label': 'file-input',
          color: this.validationState,
          dark: this.dark,
          disabled: this.isDisabled,
          light: this.light
        },
        on: {
          click: e => {
            e.preventDefault();
            e.stopPropagation();
            this.$refs.input.click();
          },
          mouseup: e => {
            e.preventDefault();
            e.stopPropagation();
          }
        }
      };
      return this.$createElement('div',data, [ this.$slots.default]);
    },
    genContent() {
      return [this.genPrependSlot(), this.genSelfDefault(), this.genControl(), this.genAppendSlot()];
    },

  }
};

</script>

<style lang='less' scoped></style>
