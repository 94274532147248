import { ethers } from "ethers";
 import { initProvider} from "@/walletConnectU";
let { bech32 } = require('bech32');
import Web3 from 'web3';
let uptickUrl = process.env.VUE_APP_ADD_NETWORK_CHAIN_UPC_URL;
 let currentSymple = process.env.VUE_APP_ADD_NETWORK_CHAIN_SYMBOL
 if(currentSymple=="UOC"){
	 uptickUrl = window.location.protocol + "//" + window.location.host + "/uptickNode";
   
 }
const web3Obj = new Web3(uptickUrl);

const fromHexString = hexString =>
  new Uint8Array(hexString.match(/.{1,2}/g).map(byte => parseInt(byte, 16)));

export async function getBalance() {
    // const account = await getAccounts();
    // 根据缓冲获取地址
    const json = localStorage.getItem("key_user");
    let address = JSON.parse(json);
    let amt = web3Obj.eth.getBalance(address.did);
    return amt;
}

export async function getAccounts(){
    let web3Provider,provider,isWalletConnect
    const data = localStorage.getItem("walletconnect");
    if(!data)
    {
        isWalletConnect = false
    } else{
        isWalletConnect = JSON.parse(data).connected
    }

    if(isWalletConnect){
	 provider=await initProvider();
	 console.log("provider====",provider);
      await provider.enable();
     web3Provider = new ethers.providers.Web3Provider(provider);

    }else{
     web3Provider = new ethers.providers.Web3Provider(window.ethereum);
    }
  
      let signer = await web3Provider.getSigner();

      console.log("wxl ------ signer",signer);
	//   let chainId = await provider.request({ method: "eth_chainId" });
    return signer;
}

export async function getSigner(){
  let isWalletConnect,provider,web3Provider
  const data = localStorage.getItem("walletconnect");
    if(!data)
    {
        isWalletConnect = false
    } else{
        isWalletConnect = JSON.parse(data).connected
    }
if(isWalletConnect){
  provider=await initProvider();
}else{
  provider=window.ethereum
}
  
web3Provider  = new ethers.providers.Web3Provider(provider);
  
  let signer = await web3Provider.getSigner();
//
  return signer;
}
    
 
// 获取所有链的chainID
 async function getAllChainIds() {
  const chainIds = [];
  const chainCount = await window.ethereum.request({ method: 'eth_chainId' });
  for (let i = 1; i <= chainCount; i++) {
    const chainId = await window.ethereum.request({ method: 'eth_chainId', params: [i] });
    chainIds.push(chainId);
  }
  return chainIds;
}


// 检查是否包含特定的chainID
export async function containsChainId(targetChainId) {
  const allChainIds = await getAllChainIds();
  return allChainIds.includes(targetChainId);
}


    export function checkExitChain(chainId){
        // 检查是否安装了MetaMask
if (typeof window.ethereum !== 'undefined') {
    const ethereum = window.ethereum;
  
    // 请求用户授权访问其钱包
    ethereum.enable().then(() => {
      // 获取当前选定的网络ID
      const currentNetworkId = ethereum.networkVersion;
  
      // 切换到目标网络
      ethereum.request({
        method: 'wallet_switchEthereumChain',
        params: [{ chainId: chainId }] // 目标网络的链ID
      }).then(() => {
        // 切换成功后的逻辑
        console.log('网络切换成功');
      }).catch((error) => {
        // 切换失败后的逻辑
        console.error('网络切换失败', error);
      });
    }).catch((error) => {
      // 用户拒绝授权访问钱包或发生其他错误
      console.error('无法访问用户钱包', error);
    });
  } else {
    // MetaMask未安装
    console.error('请先安装MetaMask');
  }

    }

export function getUptickAddress(evmAddress){

    //xxl TODO from evm
    //let preFix = "uptick"
    //let uptickAddress = Base58.encode(fromHexString(evmAddress));

    let words = bech32.toWords(fromHexString(evmAddress));
    return bech32.encode('uptick', words)

    //return preFix + uptickAddress;
}

export async function addNetwork() {
    try {
      let res =  await window.ethereum.request({
            method: 'wallet_addEthereumChain',
          
            params: [{
                chainId: `${process.env.VUE_APP_ADD_NETWORK_CHAIN_ID}`,
                chainName: `${process.env.VUE_APP_ADD_NETWORK_CHAIN_NAME}`,
                nativeCurrency: {
					name:'Ether',
                    symbol: `${process.env.VUE_APP_ADD_NETWORK_CHAIN_SYMBOL}`, // 2-6 characters long
                    decimals: 18,
                },
                // rpcUrls: ['http://47.89.185.2:8545'],
                rpcUrls: [`${process.env.VUE_APP_ADD_NETWORK_CHAIN_UPC_URL}`],
                blockExplorerUrls: [`${process.env.VUE_APP_ADD_NETWORK_CHAIN_BLOCK_URL}`],
                // iconUrls:['https://www.uptick.network/assets/img/08/icon_telegram.png']
            }],
        });
        console.log("wallet_addEthereumChain",res);
        return true
   
    }
    catch (error) {
       
        console.error(error);
        return false
    }
}

const { utils } = require('ethers')
export async function transfer(toAddress, value, memo) {
    let hexValue = utils.parseEther(value).toHexString();
    //xxl TODO read from .env
    // const toAddress2 = process.env.VUE_APP_AUPTICK_PREFIX;
    const gasPrice = '0x2540be400'
    const gas =  '0xF4240'
    
    const account = await getAccounts();
    let address = await account.getAddress();

     const transactionParameters = {
       gasPrice,gas,to:toAddress,from: address, value: hexValue,data:memo, 
     };
     
     // txHash is a hex string
     // As with any RPC call, it may throw an error
     const txHash = await window.ethereum.request({
       method: 'eth_sendTransaction',
       params: [transactionParameters],
     });
     return txHash
     //console.log(txHash);
}

// var Web3 = require('web3');
export async function getGasPriceAndGasLimit(){

  let gasPrice= await web3Obj.eth.getGasPrice()
  let gasLimit = process.env.VUE_APP_BASE_GASLIMIT;
  console.log('VUE_APP_BASE_GASLIMIT',gasLimit);
  
  console.log("gasPrice",gasPrice);
 
    
    // let gasPrice = 1500 0000 0000;
    //1244 7253 1244
    //xxl TODO setting from .evn



    return {gasPrice,gasLimit};

}