
 import Web3 from 'web3';
// const Web3 = require('web3');

import { wallet as evmWallet } from "@/metaMask";
export const getMatamaskWeb3 = async () => {
	// await checkmetamaskconnect();
    let account = await evmWallet().getAccountInfo();
    let address = await account.getAddress();
    return address;
};
