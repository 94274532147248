<template>
  <div id="container">
    <v-card :class="{ card: true, mobile: isMobile }">
      <!-- <img
        class="clo"
        @click="closeDialog"
        :src="$vuetify.theme.dark?darkcloseIcon:closeIcon"
        alt=""
      /> -->
      <div class="top d-flex flex-row align-center">
        <img class="successImg" src="@/assets/icons/icon_03.png" alt="">
        <h3 class="end ml-4" :class="{darkFont:$vuetify.theme.dark }">{{$t('onsale_success')}}</h3>
       
      </div>
      <div class="footer mt-9">
         <button
          class="marketplace v-btn uc"
          submit
          @click="toMarketPlace"
        >
         {{$t('view_marketplace')}}
        </button>
        <button
          class="homepage v-btn uc ml-6"
          submit
          @click="toHomePage"
        >
          {{$t('return_homepage')}}
        </button>
      </div>
    </v-card>
    <v-snackbar
      v-model="isShowFalse"
      color="error"
      centered
      timeout="2000"
      style="opacity: 0.8"
      ><span style="color: white; font-size: 20px">{{
        falseTitle
      }}</span></v-snackbar
    >
    <v-snackbar
      v-model="isShowSuccess"
      color="success"
      centered
      timeout="2000"
      style="opacity: 0.8"
      ><span style="color: white; font-size: 20px">{{
        successTitle
      }}</span></v-snackbar
    >
    <uComponents ref="ucom"></uComponents>
  </div>
</template>
<script>
import api from "@/api";
export default {
  data: () => ({
    newOpen: true,
    nftName: "",
    isShowFalse: false,
    isPay: false,
    isShowSuccess: false,
    falseTitle: "this is a error",
    successTitle: "create success",
    multiNFTids:[],
    closeIcon:require('@/assets/btn_close.png'),
         darkcloseIcon:require('@/assets/darkimage/btn_close1.png'),
  }),
  props: {
   
   
  },
  computed: {
    isMobile: function () {
      return this.$vuetify.breakpoint.mobile;
    },
  },
  mounted() {
   
  },
  methods: {
   toMarketPlace(){
      this.$gtag.event('上架成功-View Marketplace', { 'event_category': 'Click', 'event_label': 'View Marketplace' })
     this.$store.state.isSourceDetail = false
     this.$router.push({name:'Marketplace'})
   },
   toHomePage(){
     this.$gtag.event('上架成功-Return to my profile', { 'event_category': 'Click', 'event_label': 'Return to my profile' })
     this.$router.push({name:'PersonalPage'})
    window.eventBus.$emit("collectionPay");
     this.$emit("getonsaleOpen", false);
   },
    closeDialog() {
      // this.open = false;
      // this.$emit("getonsaleOpen", this.open);
      this.$router.push({name:'Marketplace'})
    },
  },
};
</script>
<style lang="scss" scoped>
.card {
  display: flex;
  flex-direction: column;
  min-height: 239px;
  width: 600px;

  .top {
    display: flex;
    flex-direction: column;
    margin: 80px auto 0;
    .successImg{
      width: 34px;
	    height: 34px;
    }

    .end {
      margin: 0px auto 13px;
      width: 100%;
      height: 21px;
      text-align: center;
      font-weight: bold!important;
      font-size: 25px;
      font-weight: normal;
      font-stretch: normal;
      letter-spacing: 0px;
      color: #270645;
      white-space: nowrap;
    }

    .content {
      margin: 19px auto 25px;
      width: 226px;
      min-height: 22px;
      font-family: Helvetica;
      font-size: 20px;
      font-weight: normal;
      font-stretch: normal;
      letter-spacing: 0px;
      color: #270645;
      text-align: center;
    }
  }

  .clo {
    position: absolute;
    right: 10px;
    top: 10px;
    width: 30px;
    height: 31px;
    border-radius: 50%;
    margin-right: 0;
    cursor: pointer;
  }

  .footer {
    margin: 0 auto;

    .sub {
      margin: 30px auto;
      width: 450px;
      height: 51px;
      // background-image: linear-gradient(
      //     90deg,
      //     #d300fd 0%,
      //     #a439fe 26%,
      //     #7471ff 52%,
      //     #00fdcf 100%
      //   ),
      //   linear-gradient(#270645, #270645);
      background-blend-mode: normal, normal;
      border-radius: 25px;
      opacity: 0.9;
      font-weight: bold;;
      font-size: 20px;
      color: #ffffff;
    }
    .marketplace{
      	width: 221px;
        height: 51px;
        background-color: #7800f4;
        border-radius: 25px;
        font-family: Helvetica;
        font-size: 15px;
        font-weight: bold;
        font-stretch: normal;
        line-height: 20px;
        letter-spacing: 0px;
        color: #ffffff;
    }
    .homepage{
      width: 235px;
	height: 51px;
	background-color: #ffffff !important;
	border-radius: 25px;
  border: solid 1px #cccccc;
  font-family: Helvetica;
	font-size: 15px;
	font-weight: bold;
	font-stretch: normal;
	line-height: 20px;
	letter-spacing: 0px;
	color: #270645;
    }
  }
  &.mobile {
    .footer {
      width: 100%;
      margin: 0;
      .sub {
        margin-left: 10%;
        width: 80%;
      }
    }
  }
}


.sub-dis {
  position: relative;
  pointer-events: none;
  background-image: linear-gradient(#766983, #766983),
    linear-gradient(#270645, #270645) !important;
  background-blend-mode: normal, normal;
  border-radius: 25px;
  opacity: 0.9;
}
.sub-dis::after {
  content: "";
  background-image: url(../../assets/loading.gif);
  background-size: 100%;
  display: inline-block;
  position: absolute;
  width: 20px;
  height: 20px;
  margin-left: 110px;

  // box-shadow: 0 0 10px #fff;
  // border-radius: 50%;
}
</style>

