<template>
  <div>
    <v-menu :attach="$el" right offset-y nudge-bottom="10px">
      <template v-slot:activator="{ on, attrs, value }">
        <Chip v-bind="attrs" v-on="on" v-if="show">
            <img :src="chain =='Polygon' ? 'https://ipfs.upticknft.com/ipfs/QmPnYDKfLftUmDdRbRvbeXjLfJ5tvYeR6inFohuQDUossc':chain =='BSC'?'https://ipfs.upticknft.com/ipfs/QmP8a3Lrq9oxvftJbosM3bA43yHpMVdfDdbPcBJU34Cevh': chain == 'Arbitrum'? 'https://ipfs.upticknft.com/ipfs/QmXGtYVcXhwEezy9bcT7tKpqc6Stgdu25gicNdZpE4hZNp' : 'https://ipfs.upticknft.com/ipfs/QmbB8RMaypbmrpug6k6BN8UNuiDUGLqCLBF2jrsZaf3hMS'" alt="" width="25" height="25" class="mr-lg-3 mr-0">
          <span style="color:rgba(255,255,255)">{{chain}}</span>
          <v-icon class="ml-2" color="rgba(255,255,255)" v-if="value">mdi-menu-up</v-icon>
          <v-icon class="ml-2" color="rgba(255,255,255)" v-else>mdi-menu-down</v-icon>
        </Chip>
         <Chip v-bind="attrs" v-on="on" v-else>
          <img :src="chain =='Polygon' ? 'https://ipfs.upticknft.com/ipfs/QmPnYDKfLftUmDdRbRvbeXjLfJ5tvYeR6inFohuQDUossc':chain =='BSC'?'https://ipfs.upticknft.com/ipfs/QmP8a3Lrq9oxvftJbosM3bA43yHpMVdfDdbPcBJU34Cevh': chain == 'Arbitrum'? 'https://ipfs.upticknft.com/ipfs/QmXGtYVcXhwEezy9bcT7tKpqc6Stgdu25gicNdZpE4hZNp' : 'https://ipfs.upticknft.com/ipfs/QmbB8RMaypbmrpug6k6BN8UNuiDUGLqCLBF2jrsZaf3hMS'"  alt="" width="25" height="25" class="mr-lg-3 mr-0">
          <span  v-if="!isMobile">{{chain}}</span>
          <v-icon class="ml-2" color="primary" v-if="value">mdi-menu-up</v-icon>
          <v-icon class="ml-2" color="primary" v-else>mdi-menu-down</v-icon>
        </Chip>
      </template>
      <MarketList :list="marketlist">
        <template v-slot:default="{ label }" >
          <div  :class="{darkFont:$vuetify.theme.dark}">  {{ label }}</div>
        
        </template>
      </MarketList>
    </v-menu>
  </div>
</template>

<script lang="js">
import Chip from '@/components/Chip.vue';
import MarketList from '@/components/MarketList.vue';


export default {
  name: 'LangSelect',
  components: { Chip, MarketList },
  props: {
      value: String,
        isDrak:{
      type:Boolean
    }
  },
  data: function() {
    return {
        
        name:'',
        activeDark: this.isDrak,
        chain:'Uptick Chain', 
      marketlist :[
  { label: 'Uptick Chain',value:'Uptick Chain', icon:' https://ipfs.upticknft.com/ipfs/QmbB8RMaypbmrpug6k6BN8UNuiDUGLqCLBF2jrsZaf3hMS ' },
  { label: 'IRISnet', value:'IRISnet',icon: 'https://ipfs.upticknft.com/ipfs/QmVfXR5GiH4aPgJWX6ekbCVJz2WpLa7nWLyCsnBxwMBwvy' },
  { label: 'Polygon',value:'Polygon', icon: 'https://ipfs.upticknft.com/ipfs/QmPnYDKfLftUmDdRbRvbeXjLfJ5tvYeR6inFohuQDUossc ' },
  { label: 'BSC',value:'BSC', icon: 'https://ipfs.upticknft.com/ipfs/QmP8a3Lrq9oxvftJbosM3bA43yHpMVdfDdbPcBJU34Cevh' },
  { label: 'Arbitrum',value:'Arbitrum', icon: 'https://ipfs.upticknft.com/ipfs/QmXGtYVcXhwEezy9bcT7tKpqc6Stgdu25gicNdZpE4hZNp' },
  { label: 'Loopring',value:'Loopring', icon: 'https://ipfs.upticknft.com/ipfs/QmS1NGTcNpZZhB7Am3EQCoVcvPQdNvyQfkBsPF4wz1dcXg' },
]
     
    };
  },

  computed: {
       show: function () {
        return this.$route.name === 'EditSpotlight' || this.$route.name === 'Spotlight' || this.$route.name === 'dropEdit' || this.$route.name === 'dropPage' 
     },
      isMobile: function () {
      return this.$vuetify.breakpoint.mobile;
    },
  },
mounted(){
  // 初始化
      let currentChain = `${this.$env.VUE_APP_MARKET_TYPE}`
      if(currentChain == 'POLYGON'){
        this.chain = 'Polygon'
        this.marketlist = [
        
  { label: 'Uptick Chain',value:'Uptick Chain', icon:' https://ipfs.upticknft.com/ipfs/QmbB8RMaypbmrpug6k6BN8UNuiDUGLqCLBF2jrsZaf3hMS ' },
  { label: 'IRISnet', value:'IRISnet',icon: 'https://ipfs.upticknft.com/ipfs/QmVfXR5GiH4aPgJWX6ekbCVJz2WpLa7nWLyCsnBxwMBwvy' },
  { label: 'Polygon',value:'Polygon', icon: 'https://ipfs.upticknft.com/ipfs/QmPnYDKfLftUmDdRbRvbeXjLfJ5tvYeR6inFohuQDUossc ' },
  { label: 'BSC',value:'BSC', icon: 'https://ipfs.upticknft.com/ipfs/QmP8a3Lrq9oxvftJbosM3bA43yHpMVdfDdbPcBJU34Cevh' },
  { label: 'Arbitrum',value:'Arbitrum', icon: ' https://ipfs.upticknft.com/ipfs/QmXGtYVcXhwEezy9bcT7tKpqc6Stgdu25gicNdZpE4hZNp' },
]
    }else if(currentChain == 'BSC'){
        this.chain = 'BSC'
            this.marketlist = [
        
  { label: 'Uptick Chain',value:'Uptick Chain', icon:' https://ipfs.upticknft.com/ipfs/QmbB8RMaypbmrpug6k6BN8UNuiDUGLqCLBF2jrsZaf3hMS ' },
  { label: 'IRISnet', value:'IRISnet',icon: 'https://ipfs.upticknft.com/ipfs/QmVfXR5GiH4aPgJWX6ekbCVJz2WpLa7nWLyCsnBxwMBwvy' },
  { label: 'Polygon',value:'Polygon', icon: 'https://ipfs.upticknft.com/ipfs/QmPnYDKfLftUmDdRbRvbeXjLfJ5tvYeR6inFohuQDUossc' },
  { label: 'BSC',value:'BSC', icon: 'https://ipfs.upticknft.com/ipfs/QmP8a3Lrq9oxvftJbosM3bA43yHpMVdfDdbPcBJU34Cevh' },
   { label: 'Arbitrum',value:'Arbitrum', icon: ' https://ipfs.upticknft.com/ipfs/QmXGtYVcXhwEezy9bcT7tKpqc6Stgdu25gicNdZpE4hZNp' },
]    
   }else if(currentChain == 'ARBITRUM'){
        this.chain = 'Arbitrum'
            this.marketlist = [
        
  { label: 'Uptick Chain',value:'Uptick Chain', icon:' https://ipfs.upticknft.com/ipfs/QmbB8RMaypbmrpug6k6BN8UNuiDUGLqCLBF2jrsZaf3hMS ' },
  { label: 'IRISnet', value:'IRISnet',icon: 'https://ipfs.upticknft.com/ipfs/QmVfXR5GiH4aPgJWX6ekbCVJz2WpLa7nWLyCsnBxwMBwvy' },
  { label: 'Polygon',value:'Polygon', icon: 'https://ipfs.upticknft.com/ipfs/QmPnYDKfLftUmDdRbRvbeXjLfJ5tvYeR6inFohuQDUossc' },
  { label: 'BSC',value:'BSC', icon: 'https://ipfs.upticknft.com/ipfs/QmP8a3Lrq9oxvftJbosM3bA43yHpMVdfDdbPcBJU34Cevh' },
   { label: 'Arbitrum',value:'Arbitrum', icon: ' https://ipfs.upticknft.com/ipfs/QmXGtYVcXhwEezy9bcT7tKpqc6Stgdu25gicNdZpE4hZNp' },

]
        
      }
  
  // 判断是否为黑暗模式
        let isDarkMode =  localStorage.getItem('isDarkMode');
        if(isDarkMode){
             if(isDarkMode == 'true'){
              this.activeDark = true
           }else{
             this.activeDark = false 
           } 
        }else{
            this.activeDark = false
        }
        window.eventBus.$on("DarkModeChange", this.DarkModeChange);
   window.eventBus.$on("DarkModeChange", this.DarkModeChange);
},
  
  watch: {
   
  },
  methods:{
       DarkModeChange(value){
        this.activeDark = value

    }, 
  },
};
</script>

<style lang="scss" scoped>
.v-menu__content{
  position:fixed !important;
  top: 75px !important;
}</style>
