<template>
  <div id="container">
      <v-card :class="{ card: true, mobile: isMobile }">
      <img
        class="clo"
        @click="closeDialog"
        :src="$vuetify.theme.dark?darkcloseIcon:closeIcon"
        alt=""
      />
      <div class="top">
         <h3 class="end" :class="{darkFont:$vuetify.theme.dark }">{{$t('ReceivingSale')}}</h3>
        <canvas id="qrcode" ref="qrcode" width="218"  height="218"></canvas>
      </div>
      <div class="footer">
        <button
          class="sub v-btn uc"
          submit
          @click="subCommit"
        >
         {{$t('Download_code')}}
        </button>
      </div>
    </v-card>
    <uComponents ref="ucom"></uComponents>
  </div>
</template>
<script>
import api from "@/api";
import qrcode from "qrcode";
export default {
  data: () => ({
    closeIcon:require('@/assets/btn_close.png'),
         darkcloseIcon:require('@/assets/darkimage/btn_close1.png'),
  
  }),
  props: {
    Nft_sku: {
      type: Object,
    },
  },
  computed: {
    isMobile: function () {
      return this.$vuetify.breakpoint.mobile;
    },
  },
  mounted() {
    //  {"source":"web","target":"buyFree","nftAddress":"0x2fAbf62106410048B8CF2a0C4169648706dBFD2d","nftId":"1670300525843482920","owner":"0xe232f88674904086220c5a1d76b56f58a76702f2"}
	let chainId = process.env.VUE_APP_ADD_NETWORK_CHAIN_ID
	let chainIdInt =  parseInt(chainId, 16)
 let link= `{"source":"web","target":"buyFree","nftAddress":"${this.Nft_sku.nftAddress}","nftId":"${this.Nft_sku.nftId}","owner":"${this.Nft_sku.owner}","chainId":"${chainIdInt}"}`;
 //{"source":"web","target":"buy","nftAddress":"${this.Nft_sku.nftAddress}","nftId":"${this.Nft_sku.nftId}","owner":"${this.Nft_sku.owner}"}
this.generateQRCode(link)
      
  },
  methods: {
    	// 生成二维码的方法
    	generateQRCode(content) {
      		this.$nextTick(() => {
       			const canvas = this.$refs.qrcode;
        		canvas.width = 200;
        		canvas.height = 200;
        		canvas.getContext("2d").clearRect(0, 0, canvas.width, canvas.height);
        		qrcode.toCanvas(
         		canvas,
          		content,
          		{
            		width: 218,
          		},
        		);
      	    });
       },
       //保存canvas 生成的二维码图片
    	savePic() {
      	 var canvasData =document.getElementById('qrcode');
         var a = document.createElement("a");
         a.href = canvasData.toDataURL();;
         a.download = "ReceivingCode";
         a.click();
        setTimeout(()=>{
           this.$toast("success", this.$t("success")).then(()=>{
             this.$emit("getOpenQRcode", false);
           })
          
        },1000)
        
        },



    async subCommit() {
     this.savePic()
    
    },
    closeDialog() {
      this.open = false;
      this.$emit("getOpenQRcode", this.open);
    },
  },
};
</script>
<style lang="scss" scoped>
.card {
  display: flex;
  flex-direction: column;
  min-height: 427px;
  width: 500px;

  .top {
    display: flex;
    flex-direction: column;
    margin: 50px auto 0;
    .end {
      margin: 0 auto 13px;
      width: 100%;
      height: 21px;
      text-align: center;
      font-weight: bold!important;
      font-size: 25px;
      font-weight: normal;
      font-stretch: normal;
      letter-spacing: 0px;
      color: #270645;
      white-space: nowrap;
    }

    .content {
      margin: 19px auto 25px;
      width: 226px;
      min-height: 22px;
      font-family: Helvetica;
      font-size: 20px;
      font-weight: normal;
      font-stretch: normal;
      letter-spacing: 0px;
      color: #270645;
      text-align: center;
    }
  }

  .clo {
    position: absolute;
    right: 10px;
    top: 10px;
    width: 30px;
    height: 31px;
    border-radius: 50%;
    margin-right: 0;
    cursor: pointer;
  }

  .footer {
    margin: 0 auto;

    .sub {
      margin: 30px auto;
      width: 250px;
	height: 51px;
      // background-image: linear-gradient(
      //     90deg,
      //     #d300fd 0%,
      //     #a439fe 26%,
      //     #7471ff 52%,
      //     #00fdcf 100%
      //   ),
      //   linear-gradient(#270645, #270645);
      background-blend-mode: normal, normal;
      border-radius: 25px;
      opacity: 0.9;
      font-weight: bold;;
      font-size: 20px;
      color: #ffffff;
    }
  }
  &.mobile {
    .footer {
      width: 100%;
      margin: 0;
      .sub {
        margin-left: 10%;
        width: 80%;
      }
    }
  }
}

img {
  display: inline-block;
  width: 126px;
  height: 126px;
  border-radius: 5px;
  margin: 1px 22px 10px 52px;
}
.sub-dis {
  position: relative;
  pointer-events: none;
  background-image: linear-gradient(#766983, #766983),
    linear-gradient(#270645, #270645) !important;
  background-blend-mode: normal, normal;
  border-radius: 25px;
  opacity: 0.9;
}
.sub-dis::after {
  content: "";
  background-image: url(../../assets/loading.gif);
  background-size: 100%;
  display: inline-block;
  position: absolute;
  width: 20px;
  height: 20px;
  margin-left: 110px;

  // box-shadow: 0 0 10px #fff;
  // border-radius: 50%;
}
</style>

