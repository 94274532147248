<template>
  <div style="height:90px;" :class="{mobile: isMobile,somberBackground:this.$vuetify.theme.dark}">
    <!-- <v-card-title class="d-block text--title pt-4  pb-2 " style="font-weight:bold;">
	 {{value.name ? value.name : "No Name"}} 
	   <img style="height:20px;width:20px; float: right;" src="@/assets/icons/officially_verified.png" alt=""  @mouseenter="mousedContract()" @mouseleave="leaveContract()"/>
	 </v-card-title> -->
    <div class="d-flex flex-row justify-space-between" style="height:25px;">
      <v-card-title class="d-block text--title  " style="font-weight:bold;" :class="{darkFont:$vuetify.theme.dark }">
	  {{value.name ? value.name : "No Name"}}
	 </v-card-title>
     <img style="height:20px;width:20px;margin-top:14px;margin-right:5px; display: none;" src="@/assets/icons/officially_verified.png" alt=""  @mouseenter="mousedContract()" @mouseleave="leaveContract()"/>
   </div>
   
   
    <v-card-text v-if="value.marketType == '2' && (owerfrom =='firspublish' || owerfrom =='onsale')&& value.amount!=0" class="d-flex flex-row justify-space-between mt-1 " style="min-height: 70px">
      <template v-if="value.auction.auctionStatus == '0'"
      >
        <div>
          <div class="text--bid-title text--secondary-rd " style="margin-bottom:-2px;" :class="{darkFont:$vuetify.theme.dark }">
            {{ $t("popupAuctionStartingBid") }}
          </div>
          <div class="d-flex flex-row mt-2">
            <div>
               <v-img v-if="value.marketPrice" :src="getChainByName(value.tokenType).img" alt="logo" width="23px" height="23px"/>
            </div>
            <div
                class="ml-2 text--price text--primary-rd font-weight-medium" style="line-height:22px;" :class="{darkFont:$vuetify.theme.dark }"
            >
              {{ value.auction.lastBidPrice  }}
            </div>
          </div>
        </div>
        <div>
          <div class="text--sub-title text--secondary-rd" style="margin-bottom:-2px;" :class="{darkFont:$vuetify.theme.dark }">
            {{ $t("workCardStartingin") }}
          </div>
          <div class="mt-1">
            <div class="text--number text--primary-rd font-weight-medium " style="margin-bottom:-2px;">
              {{ hour }}h {{ minutes }}m {{ second }}s
            </div>
          </div>
        </div>
      </template
      >
      <template v-else-if="value.auction.auctionStatus == '1'"
      >
        <div>
          <div class="text--bid-title text--secondary-rd" style="margin-bottom:-2px;" :class="{darkFont:$vuetify.theme.dark }">
            {{ $t("workCardCurrentBid") }}
          </div>
          <div class="d-flex flex-row mt-2">
            <div>
               <v-img v-if="value.marketPrice" :src="getChainByName(value.tokenType).img" alt="logo" width="23px" height="23px"/>
            </div>
            <div class="ml-2 text--price text--primary-rd font-weight-medium" style="line-height:22px;" :class="{darkFont:$vuetify.theme.dark }">
              {{ value.auction.lastBidPrice}}
            </div>
          </div>
        </div>
        <div>
          <div class="text--sub-title text--secondary-rd" style="margin-bottom:-2px;" :class="{darkFont:$vuetify.theme.dark }">
            {{ $t("workCardEndingin") }}
          </div>
          <div class="mt-1">
            <div class="text--number text--primary-rd font-weight-medium">
              {{ hour }}h {{ minutes }}m {{ second }}s
            </div>
          </div>
        </div>
      </template
      >
    </v-card-text>
    <v-card-text v-if="value.marketType == '2' && (owerfrom !='firspublish'&&owerfrom !='onsale')&& value.amount!=0" class="d-flex flex-row justify-space-between mt-n2 " style="min-height: 70px">
      <template v-if="value.auction.auctionStatus == '0'"
      >
        <div>
          <div class="text--bid-title text--secondary-rd " style="margin-bottom:-2px;" :class="{darkFont:$vuetify.theme.dark }">
            {{ $t("popupAuctionStartingBid") }}
          </div>
          <div class="d-flex flex-row mt-2">
            <div>
               <v-img v-if="value.marketPrice" :src="getChainByName(value.tokenType).img" alt="logo" width="23px" height="23px"/>
            </div>
            <div
                class="ml-2 text--price text--primary-rd font-weight-medium" style="line-height:22px;" :class="{darkFont:$vuetify.theme.dark }"
            >
              {{ value.auction.lastBidPrice }}
            </div>
          </div>
        </div>
        <div>
          <div class="text--sub-title text--secondary-rd" style="margin-bottom:-2px;" :class="{darkFont:$vuetify.theme.dark }">
            {{ $t("workCardStartingin") }}
          </div>
          <div class="mt-1">
            <div class="text--number text--primary-rd font-weight-medium " style="margin-bottom:-2px;">
              {{ hour }}h {{ minutes }}m {{ second }}s
            </div>
          </div>
        </div>
      </template
      >
      <template v-else-if="value.auction.auctionStatus == '1'"
      >
        <div>
          <div class="text--bid-title text--secondary-rd" style="margin-bottom:-2px;" :class="{darkFont:$vuetify.theme.dark }">
            {{ $t("workCardCurrentBid") }}
          </div>
          <div class="d-flex flex-row mt-2">
            <div>
               <v-img v-if="value.marketPrice" :src="getChainByName(value.tokenType).img" alt="logo" width="23px" height="23px"/>
            </div>
            <div class="ml-2 text--price text--primary-rd font-weight-medium" style="line-height:22px;" :class="{darkFont:$vuetify.theme.dark }">
              {{ value.marketPrice }}
            </div>
          </div>
        </div>
        <div>
          <div class="text--sub-title text--secondary-rd" style="margin-bottom:-2px;" :class="{darkFont:$vuetify.theme.dark }">
            {{ $t("workCardEndingin") }}
          </div>
          <div class="mt-1">
            <div class="text--number text--primary-rd font-weight-medium">
              {{ hour }}h {{ minutes }}m {{ second }}s
            </div>
          </div>
        </div>
      </template
      >
    </v-card-text>
    <v-card-text  v-if="((value.marketType == '1' || value.marketType == '3' || value.marketType == '4' ) || value.marketType == null || value.marketType == '5')&& (owerfrom =='firspublish' || owerfrom =='onsale' || owerfrom =='offsale')&& value.amount!=0"  class="d-flex flex-row pl-2 mt-2 text-number">
       
       <div >
                  <v-img v-if="value.marketPrice" :src="getChainByName(value.tokenType).img" alt="logo" width="23px" height="23px"/>
        </div>
      <div class="ml-2 text--price text--primary-rd font-weight-medium" style="line-height:22px;" :class="{darkFont:$vuetify.theme.dark }">
        {{value.marketPrice}}
      </div>
      <div v-if="value.saleStatus ==0" :class="{darkFont:$vuetify.theme.dark }" > {{ $t("NotListedYet")}}</div>
       <div class="time" v-if="value.marketType == '5' && value.couponTime">
        <img src="@/assets/icons/icon_time_d.png"  width="30px"
            height="30px" >

      </div>
   
     
    </v-card-text>
    <v-card-text  v-if="((value.marketType == '1' || value.marketType == '3' || value.marketType == '4' ) || value.marketType == null  || value.marketType == '5')&& (owerfrom !='firspublish'&&owerfrom !='onsale'&&owerfrom !='offsale') "  class="d-flex flex-row  mt-1 text-number">
       <div class="lastsale " v-if="selectType ==  '3'" :class="{darkFont:$vuetify.theme.dark }">Last sale</div>
        <div >
                  <v-img v-if="value.marketPrice" :src="getChainByName(value.tokenType).img" alt="logo" width="23px" height="23px"/>
        </div>
      <div class="ml-2 text--price text--primary-rd font-weight-medium" style="line-height:22px;" :class="{darkFont:$vuetify.theme.dark }">
        {{value.marketPrice}}
      </div>
       <div class="time" v-if="value.marketType == '5' && value.couponTime">
        <img src="@/assets/icons/icon_time_d.png"  width="30px"
            height="30px" >

      </div>
    
    </v-card-text>
	
     <v-card-text  v-if="value.amount == 0 && owerfrom !='firspublish' && selectType !=  '3'"  class="d-flex flex-row  mt-1 text-number">

        <div >
                  <v-img class="" v-if="value.marketPrice" :src="getChainByName(value.tokenType).img" alt="logo" width="23px" height="23px"/>
        </div>
      <div class="ml-2  text--price text--primary-rd font-weight-medium" style="line-height:22px;" :class="{darkFont:$vuetify.theme.dark }">
        {{value.marketPrice}}
      </div>
       <img
             class="sold_out"
             :class="{sold_out_mobile:isMobile}"
              src="@/assets/icons/icon_soldout.png"
              width="95px"
              height="36px"
              alt
            />
      
    </v-card-text>
     <v-card-text  v-if="value.amount == 0 && owerfrom =='firspublish' && selectType !=  '3'"  class="d-flex flex-row  mt-1 text-number">
      <!-- <v-icon size="15">mdi-facebook</v-icon> -->
        <div >
                  <v-img class="mt-2" v-if="value.marketPrice" :src="getChainByName(value.tokenType).img" alt="logo" width="23px" height="23px"/>
        </div>
      <div class="ml-2 mt-2 pl-2 text--price text--primary-rd font-weight-medium" style="line-height:22px;" :class="{darkFont:$vuetify.theme.dark }">
        {{value.marketPrice}}
      </div>
       <img
             class="sold_out"
             :class="{sold_out_mobile:isMobile}"
              src="@/assets/icons/icon_soldout.png"
              width="95px"
              height="36px"
              alt
            />
       <!-- <div  class="sold_out" :class="{sold_out_mobile:isMobile}">{{$t("workCardSoldOut")}}</div> -->
    </v-card-text>

  </div>
</template>

<script lang='js'>
 // import countLogo from "@/assets/icons/chain_iris_w.png";
  import Avatar from '@/components/avatar/index.vue';

export default {
  name: 'SellCardContent',
    components: { Avatar },
  props: {
    value: {
      type: Object,
      default: () => {}
    },
    owerfrom:String,
    selectType:{
      type:Number
    }
  },
  data: function() {
    
    return {
      // countLogo,
      isStart: false,
      now: "",
      start: "",
      end: "",
      day: 0,
      hour: 0,
      minutes: 0,
      second: 0,
      seconds: "",
    };
  },
  mounted() {
    console.log("wxl ----  77777",this.owerfrom)
    this.Time(); //调用定时器
  },
  computed: {
		isMobile: function () {
			return this.$vuetify.breakpoint.mobile;
		}
   },
  methods: {
    getTime() {
      if(this.value.auction){
        this.now = Date.parse(new Date());
      this.start = this.value.auction.startTime;
      this.end = this.value.auction.endTime;
      // this.start = Date.parse(new Date("2022-01-09 13:48:00"));
      // this.end = Date.parse(new Date("2022-01-09 13:49:00"));

      if (this.value.auction.auctionStatus == 0 || this.value.auction.auctionStatus == 1) {
        if (this.now < this.start) {
          this.isStart = false;
          this.seconds = (this.start - this.now) / 1000;
        } else if (this.now < this.end) {
          this.isStart = true;
          this.seconds = (this.end - this.now) / 1000;
        }
      }
      }
      
    },
    // 天 时 分 秒 格式化函数
    countDown() {
      let d = parseInt(this.seconds / (24 * 60 * 60));
      this.day = d < 10 ? "0" + d : d;
      let h = parseInt(((this.seconds / (60 * 60)) % 24) + d * 24);
      this.hour = h < 10 ? "0" + h : h;
      let m = parseInt((this.seconds / 60) % 60);
      this.minutes = m < 10 ? "0" + m : m;
      let s = parseInt(this.seconds % 60);
      this.second = s < 10 ? "0" + s : s;
    },
    //定时器没过1秒参数减1
    Time() {
      setInterval(() => {
        if (this.seconds > 0) {
          this.seconds -= 1;
          this.countDown();
        }else{
          this.getTime();
        }
      }, 1000);
    },
  },
};

</script>

<style lang='scss' scoped>
.darkBackground1{
  background-color: #272727 !important ; 
}
.time{
     position: absolute;
     bottom:0;
    right: 0;
    width: 30px;
	height: 30px;
  }
.text--primary-rd {
  color: #270645;
}
.text--secondary-rd {
  color: #766983;
}
.text--title {
  font-size: 13px;
  line-height: 15px;
  overflow: hidden;
   white-space: nowrap;
  text-overflow: ellipsis;
}
.text--sub-title {
  font-size: 11px;
  line-height: 1;
  text-align: right;
}
.text--bid-title {
  font-size: 11px;
  line-height: 1;
  text-align: left;
}
.text--number {
  font-size: 11px;
  line-height: 2;
}
.text--price{
   font-size: 13px;
  line-height: 1;
}
.v-divider {
  border-color: #e3e3e3;
}
  .sold_out {
  
      color: #ffffff;
      position: absolute;
      right: 0px;
      bottom: 7px;
   }
   .sold_out_mobile{
      width: 85px;
      height: 36px;
   }
   .lastsale{
     margin-right: 8px;
 font-family: Helvetica;
 font-size: 11px;
 font-weight: normal;
 font-stretch: normal;
 letter-spacing: 0px;
 color: #766983;

   }

   .mobile {
      .text--sub-title {
         // text-align: left;
         white-space:nowrap;
      }
      .text--number {
         white-space:nowrap;
      }
      .v-card__text{
        padding:10px!important;
      }
   }
</style>
