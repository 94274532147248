<template>
  <div class="contains" >
         <DropCarousel />
     </div>
</template>

<script>
import Avatar from "@/components/avatar/index";
import api from "@/api";
import { getFileSrc } from "@/utils/file";
import DropCarousel from "@/components/dropCarousel.vue";
const WORK_KEY = "WORK";
export default {
  name: "FeatureDrops",
  components: { Avatar,DropCarousel },
  data:function () {
    return{
        dropInfo:{},
        userInfo:{},
        dropInfoExtension:{},
        dropLogo:'',
        imgSrc:'',
        isMint:false,
        isEnd :false,
    }
  },
  props: {
    dropList: {
      type: Array,
      default: () =>[],
    },

  },
  mounted(){
    this.init();
  },
  methods:{
    async  init(){
 let nowTime =new Date().getTime();
      if(this.dropList[0].startTime && this.dropList[0].endTime){

      if(Number(nowTime) < Number(this.dropList[0].startTime)){
        this.isEnd = false
         this.isMint = false
        console.log("未开始");
      }
       else if( Number(this.dropList[0].startTime) < Number(nowTime) &&  Number(nowTime) < Number(this.dropList[0].endTime) )
    {
       this.isEnd = false
        this.isMint = true
    }else{
      console.log("已经结束");
        this.isEnd = true
         this.isMint = false
    }
    }
   

     if( this.dropList && this.dropList[0].dropBanner){
         this.imgSrc =   await getFileSrc(WORK_KEY, this.dropList[0].dropBanner);
     }else{
         //https://d3i65oqeoaoxhj.cloudfront.net/QmUn6JVu4YcVykZt9qYAbwocxPgR6Qsr6K7eb8jZbhji3u/small
           this.imgSrc =   await getFileSrc(WORK_KEY, 'QmUn6JVu4YcVykZt9qYAbwocxPgR6Qsr6K7eb8jZbhji3u');
     }

      //dropLogo
      if( this.dropList[0].imgUrl){
         this.dropLogo =   await getFileSrc(WORK_KEY, this.dropList[0].imgUrl);
     }else{
         //https://d3i65oqeoaoxhj.cloudfront.net/QmUn6JVu4YcVykZt9qYAbwocxPgR6Qsr6K7eb8jZbhji3u/small
           this.dropLogo =   await getFileSrc(WORK_KEY, 'QmUn6JVu4YcVykZt9qYAbwocxPgR6Qsr6K7eb8jZbhji3u');
     }
     

  },
  toDetail(){
     this.$router.push({path: `/dropPage/${this.dropList[0].nftAddress}`})
  }
  }

};
</script>

<style lang="scss" scoped>
.contains {
  position: relative;
  .topInfo {
    cursor: pointer;
    width: 100%;
    height: 550px;
    background-size: cover;
    background-position: center;
    border-radius: 15px;
    .mbimg {
    
      width: 100%;
      height: 550px;
      border-radius: 15px;
    }
   

    .dropInfo {
      position: absolute;
      top: 110px;
      width: 100%;

      .backavaimg {
        width: 185px;
        height: 185px;

        display: flex;
        align-items: center;
        justify-content: center;
        .avaImg {
          width: 175px;
          height: 175px;
          object-fit: cover;
          border-radius: 20px;
          border: 7px solid #ffffff;
        }
      }
      .name{
        width: 80%;
        text-align: center;
        font-family: Helvetica;
	font-size: 50px;
	font-weight: bold;
	font-stretch: normal;
	line-height: 60px;
	letter-spacing: 0px;
	color: #ffffff;
           white-space: nowrap;
  overflow: hidden;
  text-overflow:ellipsis;
    cursor: pointer;
      }
      .Username{
        font-family: Helvetica;
	font-size: 14px;
	font-weight: bold;
	font-stretch: normal;
	line-height: 20px;
	letter-spacing: 0px;
	color: #ffffff;
      }
      .status{
        margin-top:60px;
        .price{
            font-family: Helvetica;
	font-size: 20px;
	font-weight: bold;
	font-stretch: normal;
	line-height: 24px;
	letter-spacing: 0px;
	color: #ffffff;
        }
      }
    }
  }
}

</style>
