<template>
   <div :class="{ contain: true, mobile: isMobile }">
      <div v-if="isShowLoading" class="load py-lg-16 d-flex flex-column">
         <img src="@/assets/loading.gif" alt=""/>
         <h1 :class="{darkFont:this.$vuetify.theme.dark }">{{ $t('loadingLoading') }}...</h1>
      </div>
   </div>
</template>

<script>
  export default {
    name: "loading.vue",
    props: {
      isShowLoading: {
        type: Boolean
      },
    
    },
    data: () => ({}),
    computed: {
      isMobile: function() {
        return this.$vuetify.breakpoint.mobile;
      }
    }
  };
</script>

<style lang="scss" scoped>
   .contain {
      
      .load {
         img {
            display: block;
            margin: 0 auto;
            width: 42px;
            height: 42px;
         }

         h1 {
            margin: 30px auto;
         }
      }
   }

</style>
