<template>
  <div id="container">
    <v-card :class="{ card: true, mobile: isMobile }">
      <img
        class="clo"
        @click="closeDialog"
        :src="$vuetify.theme.dark ? darkcloseIcon : closeIcon"
        alt
      />
      <!-- step 1 -->
      <div class="step1">
        <div class="top pt-6">
          <h3 :class="{ darkFont: this.$vuetify.theme.dark }">Step 1</h3>
          <span :class="{ darkFont: this.$vuetify.theme.dark }">{{
            $t("create_title")
          }}</span>
        </div>
        <div class="linkstep1">
          <ul class="d-flex flex-lg-row">
            <li
              class="mr-lg-4 d-flex flex-row align-center"
              :class="{ somberBackground: this.$vuetify.theme.dark,chooseBackground: chooseCollect }"
     @click.stop="clickCollect"
            >
              <img :src=" chooseCollect ?  defaultIcon :chooseIcon" width="18" height="18" alt=""  />
              <img
                class="ml-6"
                src="@/assets/icons/icon_collection1.png"
                width="40px"
                height="40px"
                alt
              />
              <div
                class="infotit ml-5"
                :class="{ darkFont: this.$vuetify.theme.dark }"
              >
                {{ $t("Collections") }}
              </div>
            </li>
            <li
              class=" d-flex flex-row align-center"
              :class="{ somberBackground: this.$vuetify.theme.dark,chooseBackground: chooseNft }"
    @click.stop="clickNft"
            >
              <img :src="chooseNft ? defaultIcon   :chooseIcon" width="18" height="18" alt=""  />

              <img
                src="@/assets/icons/icon_nft1.png"
                width="40px"
                height="40px"
                class="ml-6"
                alt
              />
              <div
                class="infotit ml-5"
                :class="{ darkFont: this.$vuetify.theme.dark }"
              >
                NFT
              </div>
            </li>
          </ul>
        </div>
      </div>

      <div class="step2">
        <div class="top">
          <img src="@/assets/icons/bottom.png" width="19" height="24" class="mt-3" alt="">
          <h3 :class="{ darkFont: this.$vuetify.theme.dark }">Step 2</h3>

          <span :class="{ darkFont: this.$vuetify.theme.dark }">{{
            $t("create_description")
          }}</span>
        </div>
        <div class="link d-flex flex-column align-center">
          <ul class="d-flex flex-lg-row">
            <li
              class="mr-lg-4 d-flex flex-row"
              :class="{
                somberBackground: this.$vuetify.theme.dark,
                chooseBackground: createType == 'ERC721',

              }"
               @click.stop="clickERC721('ERC721')"
            >
              <img :src="chooseERC721 ? defaultIcon   :chooseIcon" width="18" height="18" alt=""/>
              <div class="ml-6">
                <div class="d-flex flex-column">
                  <div class="d-flex flex-row">
                    <img
                      src="@/assets/icons/icon_erc721.png"
                      width="40px"
                      height="40px"
                      alt
                    />
                    <div class="ml-5">
                      <div
                        class="infotit"
                        :class="{ darkFont: this.$vuetify.theme.dark }"
                      >
                        {{ $t("create_single") }}
                      </div>
                      <div class="infotype">{{ $t("ERC-721") }}</div>
                    </div>
                  </div>
                  <div
                    class="infos"
                    :class="{ darkFont: this.$vuetify.theme.dark }"
                  >
                    {{ $t("create_721_description") }}
                  </div>
                </div>
              </div>
            </li>
           
              <li
              class=" d-flex flex-row"
               :class="{
                somberBackground: this.$vuetify.theme.dark,
                chooseBackground: createType == 'ERC1155',
              }"
             @click.stop="clickERC1155('ERC1155')"
            >
              <img :src="chooseERC1155 ? defaultIcon   :chooseIcon" width="18" height="18" alt=""  />
              <div class="ml-6">
                <div class="d-flex flex-column">
                  <div class="d-flex flex-row">
                    <img
                      src="@/assets/icons/icon_erc1155.png"
                      width="40px"
                      height="40px"
                      alt
                    />
                    <div class="ml-5">
                      <div
                        class="infotit"
                        :class="{ darkFont: this.$vuetify.theme.dark }"
                      >
                        {{ $t("create_multiple") }}
                      </div>
                      <div class="infotype">{{ $t("ERC-1155") }}</div>
                    </div>
                  </div>
                  <div
                    class="infos"
                    :class="{ darkFont: this.$vuetify.theme.dark }"
                  >
                    {{ $t("create_1155_description") }}
                  </div>
                </div>
              </div>
            </li>



          </ul>

           <button class="chooseBtn mt-7" v-if="(chooseERC721 ||chooseERC1155)&&(chooseNft || chooseCollect)"  @click="create">Create</button>
            <button class="btn mt-7" v-else>Create</button>
           
        </div>
      </div>
     
    </v-card>
    <v-dialog v-model="openCreateCollection" v-if="openCreateCollection">
      <createCollection
        @getOpen="OpenCreateCollection"
        :createType="createType"
      ></createCollection>
    </v-dialog>
  </div>
</template>

<script>
import CreateCollection from "@/components/popup/createCollection";
export default {
  components: { CreateCollection },
  props: {
    createSource: {
      type: String,
    },
  },
  data: () => ({
    chooseCollect:false,
    chooseNft:false,
      chooseERC721:false,
    chooseERC1155:false,
    open: false,
    sum: 1,
    openCreateCollection: false,
    createType: "",
    closeIcon: require("@/assets/btn_close.png"),
    darkcloseIcon: require("@/assets/darkimage/btn_close1.png"),
    chooseIcon: require("@/assets/icons/icon_dx_n.png"),
    defaultIcon: require("@/assets/icons/icon_dx_y.png"),
  }),
  computed: {
    isMobile: function () {
      return this.$vuetify.breakpoint.mobile;
    },
  },
  mounted() {},
  methods: {
    closeDialog() {
      this.$emit("getOpen", this.open);
    },
    create(){
      // 判断选择集合 or NFT ?
      if(this.chooseCollect){
         this.$emit("getOpen", false, this.createType);
      }else{
         this.$router.push({
        name: "CreateNft",
        query: { createType: this.createType },
      });
      this.$emit("getOpen", false);
      }
    },
    clickCollect(){
        this.$gtag.event('创建-Collection', { 'event_category': 'Click', 'event_label': 'Collection' })
 this.chooseCollect = true
      this.chooseNft = false
    },
     clickERC721(type){
        this.$gtag.event('创建-ERC721', { 'event_category': 'Click', 'event_label': 'ERC721' })
        this.createType = type;
       this.chooseERC721 = true
       this.chooseERC1155 = false
    },
    clickERC1155(type){
       this.$gtag.event('创建-ERC1155', { 'event_category': 'Click', 'event_label': 'ERC1155' })
       this.createType = type;
      this.chooseERC721 = false
       this.chooseERC1155 = true
    },
     clickNft(){
        this.$gtag.event('创建-NFT', { 'event_category': 'Click', 'event_label': 'NFT' })
        this.chooseCollect = false
      this.chooseNft = true
    },
   
    OpenCreateCollection(e) {
      this.openCreateCollection = e;
    },
  },
};
</script>

<style lang="scss" scoped>
.card {
  width: 730px;
  min-height: 560px;
  .clo {
    position: absolute;
    right: 10px;
    top: 10px;
    width: 30px;
    height: 31px;
    border-radius: 50%;
    margin-right: 0;
    cursor: pointer;
  }

  .top {
    margin: 0 52px;
    display: flex;
    flex-direction: column;
    align-items: center;
    h3 {
   
      font-weight: bold !important;
      font-size: 25px;
      font-style: italic;
      font-weight: normal;
      font-stretch: normal;
      letter-spacing: 0px;
      color: #8519f5;
      text-align: center;
    }
    span {
      width: 635px;
      text-align: center;
      font-family: Helvetica;
      font-size: 13px;
      font-weight: normal;
      font-stretch: normal;

      letter-spacing: 0px;
      color: #270645;
    }
  }
  .chooseBtn{
    width: 451px;
  height: 51px;
  // #7800f4
	border-radius: 25px;
  opacity: 0.9;
  font-family: Helvetica;
	font-size: 20px;
	font-weight: bold;
	font-stretch: normal;
	line-height: 20px;
	letter-spacing: 0px;
	color: #ffffff;
    	background-color: #7800f4 !important;
  }
  .btn{
    width: 451px;
  height: 51px;
  // #7800f4
	background-color: #766983;
	border-radius: 25px;
  opacity: 0.9;
  font-family: Helvetica;
	font-size: 20px;
	font-weight: bold;
	font-stretch: normal;
	line-height: 20px;
	letter-spacing: 0px;
	color: #ffffff;
  }
  .linkstep1 {
    margin: 15px 48px 0px;
    ul {
      list-style: none;
      padding: 0;
        .chooseBackground {
       	border-radius: 5px !important;
	border: solid 1px #8519f5 !important;
      }
      li {
        padding: 0px 20px 0;
        text-align: center;
        cursor: pointer;
        width: 310px;
        height: 70px;
        background-color: #ffffff;
        box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.09);
        border-radius: 5px;
        display: block;
        margin-bottom: 9px;
        img {
          display: block;
        }
        .infotit {
          font-family: Helvetica;
          font-size: 17px;
          font-weight: bold;
          font-stretch: normal;
          letter-spacing: 0px;
          color: #270645;
        }
        .infotype {
          margin-top: 8px;
          font-family: Helvetica;
          font-size: 13px;
          font-weight: normal;
          font-stretch: normal;
          letter-spacing: 0px;
          color: #766983;
        }
        .infotitauc {
          height: 22px;
          font-weight: bold;
          font-size: 20px;
          font-weight: bold;
          font-stretch: normal;
          letter-spacing: 0px;
          line-height: 22px;
          color: #270645;
          margin: 0 auto 7px;
        }
        .infos {
        
          margin-top: 8px;
          font-family: Helvetica;
          font-size: 13px;
          font-weight: normal;
          font-stretch: normal;
          letter-spacing: 0px;
          color: #270645;
        }
      }
      .onlyread {
        .infotitauc,
        .infos {
          color: #ccc;
        }
      }
      li:hover {
        box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.36);
      }
    }
  }
  .link {
    margin: 15px 48px 0px;
    padding-bottom: 20px;
    ul {
      list-style: none;
      padding: 0;
      .chooseBackground {
       	border-radius: 5px !important;
	border: solid 1px #8519f5 !important;
      }
      li {
        padding: 20px 0 0 20px;
        text-align: center;
        cursor: pointer;
        width: 310px;
        height: 130px;
        background-color: #ffffff;
        box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.09);
        border-radius: 5px;
        display: block;
        margin-bottom: 9px;
        img {
          display: block;
          // margin: 20px auto 15px;
        }
        .infotit {
          font-family: Helvetica;
          font-size: 17px;
          font-weight: bold;
          font-stretch: normal;
          letter-spacing: 0px;
          color: #270645;
        }
        .infotype {
          margin-top: 8px;
          font-family: Helvetica;
          font-size: 13px;
          font-weight: normal;
          font-stretch: normal;
          letter-spacing: 0px;
          color: #766983;
        }
        .infotitauc {
          height: 22px;
          font-weight: bold;
          font-size: 20px;
          font-weight: bold;
          font-stretch: normal;
          letter-spacing: 0px;
          line-height: 22px;
          color: #270645;
          margin: 0 auto 7px;
        }
        .infos {
            width: 225px;
          margin-top: 8px;
          font-family: Helvetica;
          font-size: 13px;
          font-weight: normal;
          font-stretch: normal;
          letter-spacing: 0px;
          color: #270645;
          text-align: left;
        }
      }
      .onlyread {
        .infotitauc,
        .infos {
          color: #ccc;
        }
      }
      li:hover {
        box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.36);
      }
    }
  }
  &.mobile {
    height: 550px;
    .top {
      h3 {
        font-size: 20px;
      }
      p {
        height: auto;
      }
    }
    .link {
      ul {
        li {
          img {
            margin: 35px auto;
          }
        }
      }
    }
  }
}
</style>
