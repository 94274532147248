<template>
  <v-list nav dense :light="false" >
    <!-- color="#fff" -->
    <v-list-item-group mandatory :value="value" >
      <v-list-item v-for="item in list" :value="item.value" :key="item.value" @click="itemclick(item.value)">
        <v-list-item-content>
          <v-list-item-title v-if="$scopedSlots.default">
            <slot :value="item.value" :label="item.label"> </slot>
          </v-list-item-title>
          <v-list-item-title v-else v-text="item.label"  :class="{darkFont:isDark}"></v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list-item-group>
  </v-list>
</template>

<script>
export default {
  name: "List",
  inheritAttrs: false,
  data(){
    return{
         isDark:false
    }

  },
  props: {
    list: {
      type: Array,
      defualt: () => [],
   
    },
    value: [String, Number],
  },
  methods: {
      itemclick(v) {
        this.$emit("input", v);
        this.$emit("change", v);
      },
      DarkModeChange(value){
        this.isDark = value
     },
  },
  mounted(){
       // 判断是否为黑暗模式
        let isDarkMode =  localStorage.getItem('isDarkMode');
        if(isDarkMode){
             if(isDarkMode == 'true'){
              this.isDark = true
           }else{
             this.isDark = false 
           } 
        }else{
            this.isDark = false
        }
        window.eventBus.$on("DarkModeChange", this.DarkModeChange);
  }
};
</script>

<style lang="scss" scoped>
.userList{
  
}



</style>
