<template>
  <div class="contains" :class="{ mobile: isMobile }">
    <div class="topInfo" :style="{ backgroundImage: `url(${imgSrc})` }" @click="toDetail">
      <img
        class="mbimg"
        src="https://d3i65oqeoaoxhj.cloudfront.net/QmZnSAS1VMVvHW5iDYDqBYjHKEjw3YrdBFoKZMFyBSyrnb/small"
        alt=""
      />
      <div class="dropInfo d-flex flex-column align-center justify-center">
             <div class="backavaimg">
          <img
            class="avaImg"
            :src="dropLogo"
            alt=""
          />
        </div>
        <div class="name mt-5"> {{dropList.name}}</div>
        <div class="d-flex flex-row align-center mt-3">
            <Avatar
                  :did="dropList.owner"
                  :size="33"
                  :hash="dropList.creatorPhoto"
                  :grade="dropList.creatorGender"
                  imgType="smallv"
                
                />
            <div class="Username ml-4">{{dropList.creatorName ? dropList.creatorName :dropList.owner.substr(0,6)+'...'+ dropList.owner.substr(-6)}}</div>
          </div>
        <div class="status d-flex flex-row ">
          <div class="d-flex flex-row">
              <div class="price" v-if="isMint">{{$t('Minting')}}</div>
            <div class="price" v-else-if="!isMint && !isEnd">{{$t('popupAuctionStartDate')}}</div>
            <div class="price" v-else>{{$t('Started')}}</div>
            <div class="price ml-5" v-if="isMint">{{$t('Now')}}</div>
             <div class="price ml-5" v-else>{{timestampToDate(dropList.startTime)}} </div>
          </div>
        
         <div class="d-flex flex-row ml-16">
           <div class="price">{{$t('popupBuyPricePrice')}}</div>
            <div class="price ml-5" v-if="dropList.dropStatus == 3">{{$t('workCardSoldOut')}}</div>
             <div class="price ml-5" v-else-if="dropList.dropStatus == 4">{{$t('collection_tag_end')}}</div>
             <div class="price ml-5" v-else>{{dropList.payAmount }} {{dropList.payTokenType}} </div>
         </div>
          
        </div>
      </div>
    </div>
     </div>
</template>

<script>
import Avatar from "@/components/avatar/index";
import api from "@/api";
import { getFileSrc } from "@/utils/file";
const WORK_KEY = "WORK";
export default {
  name: "FeatureDrops",
  components: { Avatar },
  data:function () {
    return{
        dropInfo:{},
        userInfo:{},
        dropInfoExtension:{},
        dropLogo:'',
        imgSrc:'',
        isMint:false,
        isEnd :false,
    }
  },
  props: {
    dropList: {
      type: Object,
    },

  },
  mounted(){
   console.log("111111")
    this.init();
  },
  methods:{
    async  init(){
     
 let nowTime =new Date().getTime();
      if(this.dropList.startTime && this.dropList.endTime){

      if(Number(nowTime) < Number(this.dropList.startTime)){
        this.isEnd = false
         this.isMint = false
        console.log("未开始");
      }
       else if( Number(this.dropList.startTime) < Number(nowTime) &&  Number(nowTime) < Number(this.dropList.endTime) )
    {
       this.isEnd = false
        this.isMint = true
    }else{
      console.log("已经结束");
        this.isEnd = true
         this.isMint = false
    }
    }
   

     if( this.dropList && this.dropList.dropBanner){
         this.imgSrc =   await getFileSrc(WORK_KEY, this.dropList.dropBanner);
     }else{
         //https://d3i65oqeoaoxhj.cloudfront.net/QmUn6JVu4YcVykZt9qYAbwocxPgR6Qsr6K7eb8jZbhji3u/small
           this.imgSrc =   await getFileSrc(WORK_KEY, 'QmUn6JVu4YcVykZt9qYAbwocxPgR6Qsr6K7eb8jZbhji3u');
     }

      //dropLogo
      if( this.dropList.imgUrl){
         this.dropLogo =   await getFileSrc(WORK_KEY, this.dropList.imgUrl);
     }else{
         //https://d3i65oqeoaoxhj.cloudfront.net/QmUn6JVu4YcVykZt9qYAbwocxPgR6Qsr6K7eb8jZbhji3u/small
           this.dropLogo =   await getFileSrc(WORK_KEY, 'QmUn6JVu4YcVykZt9qYAbwocxPgR6Qsr6K7eb8jZbhji3u');
     }
     

  },
  toDetail(){
     this.$router.push({path: `/dropPage/${this.dropList.nftAddress}`})
  }
  }

};
</script>

<style lang="scss" scoped>
.contains {
  position: relative;
  .topInfo {
    cursor: pointer;
    width: 100%;
    height: 550px;
    background-size: cover;
    background-position: center;
    border-radius: 15px;
    .mbimg {
    
      width: 100%;
      height: 550px;
      border-radius: 15px;
    }
   

    .dropInfo {
      position: absolute;
      top: 110px;
      width: 100%;

      .backavaimg {
        width: 185px;
        height: 185px;

        display: flex;
        align-items: center;
        justify-content: center;
        .avaImg {
          width: 175px;
          height: 175px;
          object-fit: cover;
          border-radius: 20px;
          border: 7px solid #ffffff;
        }
      }
      .name{
        width: 80%;
        text-align: center;
        font-family: Helvetica;
	font-size: 50px;
	font-weight: bold;
	font-stretch: normal;
	line-height: 60px;
	letter-spacing: 0px;
	color: #ffffff;
           white-space: nowrap;
  overflow: hidden;
  text-overflow:ellipsis;
    cursor: pointer;
      }
      .Username{
        font-family: Helvetica;
	font-size: 14px;
	font-weight: bold;
	font-stretch: normal;
	line-height: 20px;
	letter-spacing: 0px;
	color: #ffffff;
      }
      .status{
        margin-top:60px;
        .price{
            font-family: Helvetica;
	font-size: 20px;
	font-weight: bold;
	font-stretch: normal;
	line-height: 24px;
	letter-spacing: 0px;
	color: #ffffff;
        }
      }
    }
  }
}

</style>
