<template>
  <img
    alt="logo"
    src="@/assets/image/logo_network.svg"
    :height="height"
    :width="`${simple ? height : 'auto'}`"
    v-bind="$attrs"
    v-on="$listeners"
    @click="openHome"
    :class="{ logo: true }"
  />
</template>

<script lang="js">

export default {
  name: 'Logo',
  inheritAttrs: false,
  
  props: {
      simple: Boolean,
      height: [String, Number]
  },

  computed: {
    isMobile: function () {
      return this.$vuetify.breakpoint.mobile;
    }
  },

  methods:{
    openHome(){
       this.$router.push({name:"Home"})
    }

  }
};
</script>

<style lang="scss" scoped>
.logo {
  object-fit: cover;
  object-position: left;
  cursor:pointer;
}

</style>
