<template>
  <div id="container">
    <v-card :class="{ card: true, mobile: isMobile }">
      <img
        class="clo"
        @click="closeDialog"
        :src="$vuetify.theme.dark?darkcloseIcon:closeIcon"
        alt=""
      />
      <div class="top">
          <div class="font-15" :class="{darkFont:this.$vuetify.theme.dark }"> <img src="@/assets/image/icon_hi.png"  alt=""> Welcome to the Uptick NFT Marketplace! </div>
          <div class="font-13 mt-3" :class="{darkFont:this.$vuetify.theme.dark }">Elevate your journey through the Web3 creative economy with the feature-rich and user-friendly Uptick NFT Marketplace.</div>
          <div class="font-13 mt-7" :class="{darkFont:this.$vuetify.theme.dark }">Uptick NFT Marketplace makes it incredibly easy for users to create, collect and trade NFTs, and enables interoperability among all homogeneous Cosmos chains, and all heterogeneous EVM chains. </div>
          <div class="font-13" :class="{darkFont:this.$vuetify.theme.dark }">It also covers several blockchain networks, including Uptick Chain and IRISnet. You are currently on the default Uptick NFT Marketplace on Uptick Chain.</div>
          <div class="font-13 mt-7" :class="{darkFont:this.$vuetify.theme.dark }">If you are an existing user of Uptick on IRISnet, please click <a href="https://irisnet.upticknft.com" target="_blank" >[Explore on IRISnet]</a>  to switch marketplaces, or alternatively you can switch via the navigation bar at the top of the page. </div>
          <div class="font-13 mt-7" :class="{darkFont:this.$vuetify.theme.dark }">After switching, a new marketplace will be opened and you can connect the wallet of the corresponding chain.</div>
          <div class="font-13 mt-7" :class="{darkFont:this.$vuetify.theme.dark }">Each marketplace operates on its own blockchain. This means that the content, user profiles, and NFTs owned by individuals are unique to each marketplace. Additionally, there may be some variations in the features and functionalities offered by each marketplace.</div>
      </div>
      <div class="footer">
       
      </div>
    </v-card>
  </div>
</template>
<script>
import api from "@/api";
export default {
  data: () => ({
    newOpen: true,
    nftName: "",
    isShowFalse: false,
    isPay: false,
    isShowSuccess: false,
    falseTitle: "this is a error",
    successTitle: "create success",
    multiNFTids:[],
    localUrl:'',
    closeIcon:require('@/assets/btn_close.png'),
         darkcloseIcon:require('@/assets/darkimage/btn_close1.png'),
  }),
  props: {
   
  },
  computed: {
    isMobile: function () {
      return this.$vuetify.breakpoint.mobile;
    },
  },
  mounted() {


 
      
  },
  methods: {
   
    
    closeDialog() {
      this.open = false;
      this.$emit("getOpen", this.open);
    },
  },
};
</script>
<style lang="scss" scoped>
.card {
  display: flex;
  flex-direction: column;
  min-height: 510px;
  width: 800px;

  .top {
    display: flex;
    flex-direction: column;
    margin: 40px 60px ;
    img{
      height: 80px;

    }
    .font-15{
        font-family:Helvetica;
          font-size: 18px;
          font-weight: bold;
          font-stretch: normal;
          line-height: 23px;
          letter-spacing: 0px;
          color: #270645;
          align-items: center;
          display: flex;
        }
        .font-13{
        font-family:Helvetica;
          font-size: 13px;
          font-weight: normal;
          font-stretch: normal;
          line-height: 23px;
          letter-spacing: 0px;
          color: #270645;
          a{
            color: #7800f4;
          }
        }


  }

  .clo {
    position: absolute;
    right: 10px;
    top: 10px;
    width: 30px;
    height: 31px;
    border-radius: 50%;
    margin-right: 0;
    cursor: pointer;
  }

  .footer {
    margin: 0 auto;

    .sub {
      margin: 30px auto;
      width: 450px;
      height: 51px;
      // background-image: linear-gradient(
      //     90deg,
      //     #d300fd 0%,
      //     #a439fe 26%,
      //     #7471ff 52%,
      //     #00fdcf 100%
      //   ),
      //   linear-gradient(#270645, #270645);
      background-blend-mode: normal, normal;
      border-radius: 25px;
      opacity: 0.9;
      font-weight: bold;;
      font-size: 20px;
      color: #ffffff;
    }
  }
  &.mobile {
    .footer {
      width: 100%;
      margin: 0;
      .sub {
        margin-left: 10%;
        width: 80%;
      }
    }
  }
}

img {
  display: inline-block;

  height: 80px;

}
.sub-dis {
  position: relative;
  pointer-events: none;
  background-image: linear-gradient(#766983, #766983),
    linear-gradient(#270645, #270645) !important;
  background-blend-mode: normal, normal;
  border-radius: 25px;
  opacity: 0.9;
}
.sub-dis::after {
  content: "";
  background-image: url(../../assets/loading.gif);
  background-size: 100%;
  display: inline-block;
  position: absolute;
  width: 20px;
  height: 20px;
  margin-left: 110px;

  // box-shadow: 0 0 10px #fff;
  // border-radius: 50%;
}
</style>

