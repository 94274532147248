<template>
  <div v-if="!isMobile">
     <div class="contest creatbtn d-flex flex-row" style="cursor: pointer;"  @click="clickPool">
        <img class="icon ml-lg-2 " src="@/assets/image/icon_pool.png" />
        <span class="ml-0">{{$t('headerNavPool')}}</span>
     </div>
    </div>
</template>

<script lang="js">


export default {
  name: 'Contest',
computed: {
     isMobile: function () {
      return this.$vuetify.breakpoint.mobile;
    },
    show: function() {
        return this.$route.name != 'Auctionlist' && this.$route.name != 'Marketplace'&&this.$route.name != 'Collectionlist'
    }
 
  },
  methods:{
       clickPool(){
          this.$router.push({ name: "Lottery" });
      }
     
  },
  mounted(){
     

  }
};
</script>

<style lang="scss" scoped>
  .creatbtn{
       animation: tada 1s;
      font-family: MicrosoftYaHei-Bold;
	   font-size: 15px;
	   font-weight: bold;
	   font-stretch: normal;
	   line-height: 100px;
	   letter-spacing: 0px;
	   color: #ffffff;
   }

.creatbtn:active{
  animation: none;
}
.contest{
   min-width: 102px;
	height: 31px;
	background-image: linear-gradient(0deg, 
		#6017c3 0%, 
		#7800f4 100%), 
	linear-gradient(
		#bfb3ff, 
		#bfb3ff);
	background-blend-mode: normal, 
		normal;
	border-radius: 15px;
    text-align: center;
    .icon{
          margin-top:-5px;
        width: 28px;
        height: 32px;
    }
    span{
        min-width: 60px;
	height: 11px;
	font-family:Helvetica;
	font-size: 12px;
	font-weight: bold;
	font-stretch: normal;
	line-height: 30px;
	letter-spacing: 0px;
	color: #ffdf7e;
    }

}
</style>
