<template>
    <div>
        <PromptBox ref="confirm"></PromptBox>
        <uToast ref="toast"></uToast>
    </div>
</template>

<script>
    import PromptBox from "@/components/PromptBox.vue"
    export default {
        components: {  PromptBox },
        methods: {
            toast(type, txt, timer) {
                return this.$refs.toast.show(type, txt, timer);
            },
            confirm(txt, ps) {
                this.$refs.confirm.show(txt, ps);
            }
        },
    }
</script>

<style lang="scss">
</style>
