import
service, { $postBodyJson }
    from '@/utils/request';
import { BASE_URL,BASE_URL_S3 } from '../config';
import rsa from "@/utils/RSAEncrypt.js";
import request from "@/utils/twitter";
import {$postThird} from '@/utils/request';
const OAuth = require('oauth-1.0a');
const crypto = require('crypto');
let key = 'M3ieFojAUrp5ggGrOVKLqFDt9'
let secret = 'hqKEWOFXjZnZ7uSMJ12cCqQLhh51Hkow1ryVZcAwKZezYscpe8'
const oauth = OAuth({
    consumer: {
      key:key,
      secret:secret
    },
    signature_method: 'HMAC-SHA1',
    hash_function: (baseString, key) => crypto.createHmac('sha1', key).update(baseString).digest('base64')
  });
  
 
//   const authHeader = oauth.toHeader(oauth.authorize(authData));

export function access_token(oauth_token,oauth_verifier,callback) {
   const authData ={
        url: "https://api.twitter.com/oauth/request_token?oauth_callback="+callback,
        method: 'POST',
       data:{
        oauth_token:oauth_token,
        oauth_verifier:oauth_verifier
    
       }
      }
   return request({
		url: `access_token`,
		method: 'post',
		headers: {
			'Authorization': oauth.toHeader(oauth.authorize(authData))["Authorization"],	
			'content-type': "application/json",
			'accept': "application/json"
        },
    
	})

}


 // getToken
export function getToken(href) {

	let params = {
		callback: href,
	};
	return service.post(`${BASE_URL}/user/getTwitterToken`, transObjToParamStr2(params));

}
//oath/getTwitterToken

export function getTwitterInfo(params) {

	return service.get(`${BASE_URL}/oath/getTwitterToken?`+  transObjToParamStr2(params));

}

// let BASE_URL2="http://13.213.149.227:7091/api/1.0";
export function loopringUserInfo(params) {
    return service.post(`${BASE_URL}/user/info.json`,
        transObjToParamStr2(params)
    );
}

//POST /api/1.0/nft/topsku

export function getHomeNftListTopsku(params) {
    return service.post(`${BASE_URL}/nft/topsku`,
        transObjToParamStr2(params)
    );
}
//POST /api/1.0/market/indexCreators
export function getHomeNftListCreators(params) {
    return service.post(`${BASE_URL}/market/indexCreators`,
        transObjToParamStr2(params)
    );
}
// 首页 gallery  POST /api/1.0/market/getFirstPublish.json

export function getGalleryList(params) {

		// return service.post(`${BASE_URL}/market/getFirstPublish.json`,
		// transObjToParamStr2(params)
		// )
		// 不再区分 一手二手，更换成市场一样的接口
		return service.post(`${BASE_URL}/market/getMarketplaceWeb.json`,
		    transObjToParamStr2(params)
		)

}

export function loopringUserSave(params) {
    return service.post(`${BASE_URL}/user/update.do`,
        transObjToParamStr2(params,true)
    );
}
export function updateBackground(params) {
    return service.post(`${BASE_URL}/user/updateBackground`,
        transObjToParamStr2(params,true)
    );
}
// 跟新橱窗页背景 updateShowcaseBackground
export function updateShowCaseBackground(params) {
    return service.post(`${BASE_URL}/user/updateShowcaseBackground`,
        transObjToParamStr2(params,true)
    );
}

// 跟新头像
export function updateprofilePhoto(params) {
    return service.post(`${BASE_URL}/user/updatePhoto`,
        transObjToParamStr2(params,true)
    );
}

//POST /api/1.0/market/getLaunchpad.json
export function getLaunchPad(params) {
    return service.post(`${BASE_URL}/market/getLaunchpad.json`,
        transObjToParamStr2(params)
    );
}

// 获取费用 GET /api/1.0/data/query.json
export function getFee() {
    return service.get(`${BASE_URL}/data/cost/transfer.json`)
}
//获取resourceId
export function getResourceId(params) {
    return service.get(`${BASE_URL}/data/query.json?`+ transObjToParamStr2(params))
}


// 获取市场行情 exchangeRate/getExchangeRateInfo
export function getMarketRate() {
    return service.post(`${BASE_URL}/exchangeRate/getExchangeRateInfo`)
}

export function nftDeploy(params) {
	if(params.contractType&&params.contractType=="LAZYN_COLLECTION"){
		return service.post(`${BASE_URL}/lazynft/deploy.do`,
		    transObjToParamStr2(params)
		);
	}else{
		return service.post(`${BASE_URL}/nft/deploy.do`,
		    transObjToParamStr2(params)
		);
	}
   
}
// lazy设置相关
// 修改懒铸造集合信息
export function lazyCollectionInfoUpdate(params) {
		return service.post(`${BASE_URL}/lazynft/update/collectionInfo.do`,
		    transObjToParamStr2(params)
		);
	
   
}
// 查询懒铸造集合信息
export function lazyCollectionInfo(params) {
		return service.post(`${BASE_URL}/lazynft/collectionInfo.json`,
		    transObjToParamStr2(params)
		);
	
   
}
// 修改懒铸造集合扩展信息（logo banner twitter地址）
export function lazyExtensionUpdate(params) {
		return service.post(`${BASE_URL}/lazynft/update/extension.do`,
		    transObjToParamStr2(params)
		);
	
   
}
// 修改服务费（千分位）
export function updateCreateFee(params) {
		return service.post(`${BASE_URL}/lazynft/update/createFee.do`,
		    transObjToParamStr2(params)
		);
	
   
}
// 查询懒铸造集合扩展信息（logo banner twitter地址）
export function lazyExtensionInfo(params) {
		return service.post(`${BASE_URL}/lazynft/extension.json`,
		    transObjToParamStr2(params)
		);
	
   
}
// 添加预售信息(票档)
export function lazyaddPresale(params) {
	 
	//xxl post third
	  return $postThird(`${BASE_URL}/lazynft/add/presale.do`, JSON.stringify(params) ,{
	    headers: {
	        'Content-Type': 'application/json',   
	    }
	  
	})
   
}
// 修改预售信息(票档)
export function lazyUpdatePresale(params) {
		return service.post(`${BASE_URL}/lazynft/update/presale.do`,
		    transObjToParamStr2(params)
		);
	
   
}
// 执行drop发布
export function lazyPublish(params) {
		return service.post(`${BASE_URL}/lazynft/publish.do`,
		    transObjToParamStr2(params)
		);
	
   
}
// 判断是否可发布
export function lazyisPublish(params) {
		return service.post(`${BASE_URL}/lazynft/isPublish.json`,
		    transObjToParamStr2(params)
		);
	
   
}
// 预售信息列表(票档)
export function lazyPresaleList(params) {
		return service.post(`${BASE_URL}/lazynft/presale/list.json`,
		    transObjToParamStr2(params)
		);
	
   
}
// 指定序号的预售信息详情(票档详情）
export function lazyPresaleinfo(params) {
		return service.post(`${BASE_URL}/lazynft/presale/info.json`,
		    transObjToParamStr2(params)
		);
	
   
}
// 添加白名单
export function lazyaddWhiteList(params) {
		return service.post(`${BASE_URL}/lazynft/add/whiteList.do`,
		    transObjToParamStr2(params)
		);
	
   
}
// 删除白名单
export function lazydelWhiteList(params) {
		return service.post(`${BASE_URL}/lazynft/del/whiteList.do`,
		    transObjToParamStr2(params)
		);
	
   
}
// 白名单列表
export function lazyWhiteList(params) {
		return service.post(`${BASE_URL}/lazynft/whiteList.json`,
		    transObjToParamStr2(params)
		);
	
   
}
// 当前用户是否在白名单列表
export function lazyhasInWhiteList(params) {
		return service.post(`${BASE_URL}/lazynft/hasInWhiteList.json`,
		    transObjToParamStr2(params)
		);
	
   
}
// 修改NFT信息（metadata信息）
export function lazyUpdateInftInfo(params) {
		return service.post(`${BASE_URL}/lazynft/update/nftInfo.do`,
		    transObjToParamStr2(params)
		);
	
   
}

// 添加NFT信息（metadata信息）
export function lazyAddInftInfo(params) {
		return service.post(`${BASE_URL}/lazynft/add/nftInfo.do`,
		    transObjToParamStr2(params)
		);
	
   
}

// 批量添加NFT信息（metadata信息）
export function lazyAddInftList(params) {
	
		return $postThird(`${BASE_URL}/lazynft/add/nftInfoList.do`, JSON.stringify(params) ,{
		    headers: {
		        'Content-Type': 'application/json',   
		    }
		  
		})
		
	
}
// 删除NFT信息（metadata信息）
export function lazydelInftInfo(params) {
		return service.post(`${BASE_URL}/lazynft/del/nftInfo.do`,
		    transObjToParamStr2(params)
		);
	
   
}
// NFT信息列表（metadata信息）
export function lazyInfotlist(params) {
		return service.post(`${BASE_URL}/lazynft/nftInfo.list`,
		    transObjToParamStr2(params)
		);
	
   
}
// NFT信息详情（metadata信息）
export function lazyGetInftInfo(params) {
		return service.post(`${BASE_URL}/lazynft/nftInfo.json`,
		    transObjToParamStr2(params)
		);
	
   
}
// 添加投放预览图和多媒体信息
export function lazyAddPreview(params) {
		return service.post(`${BASE_URL}/lazynft/add/preview.do`,
		    transObjToParamStr2(params)
		);
	
        
}
// 修改投放预览图和多媒体信息
export function lazyUpdatePreview(params) {
		return service.post(`${BASE_URL}/lazynft/update/preview.do`,
		    transObjToParamStr2(params)
		);
	
   
}
// 投放预览图和多媒体信息列表
export function lazypreviewList(params) {
		return service.post(`${BASE_URL}/lazynft/preview.list`,
		    transObjToParamStr2(params)
		);
	
   
}
// 投放预览图和多媒体信息详情
export function lazyPreviewInfo(params) {
		return service.post(`${BASE_URL}/lazynft/preview.json`,
		    transObjToParamStr2(params)
		);
	
   
}
// mint 签名
export function lazyMintSign(params) {
		return service.post(`${BASE_URL}/lazynft/mintSign.do`,
		    transObjToParamStr2(params)
		);
	
   
}
// mint交易确认
export function lazyMint(params) {
		return service.post(`${BASE_URL}/lazynft/mint.do`,
		    transObjToParamStr2(params)
		);
	
   
}

// 查询mint NFT结果
export function lazyNftInfo(params) {
    return service.post(`${BASE_URL}/lazynft/tokenInfo.json`,
        transObjToParamStr2(params)
    );


}


//获取drop列表 /api/1.0/market/getLazyNFTLaunchpad.json
export function getDropsList(params) {
    return service.post(`${BASE_URL}/market/getLazyNFTLaunchpad.json`,
        transObjToParamStr2(params)
    );


}

// 首页Drop列表推荐
export function DropsList(params) {
    return service.post(`${BASE_URL}/nft/drop.json`,
        transObjToParamStr2(params)
    );

}



// POST /api/1.0/offer/myOffer.list 我的出价列表
export function myOfferList(params) {
    return service.post(`${BASE_URL}/offer/myOffer.list`,
        transObjToParamStr2(params)
    );


}



// 创建作品夹   /nft/contractCollection/create
export function createFolder(params) {
    return service.post(`${BASE_URL}/nft/contractCollection/create`,
        transObjToParamStr2(params)
    );
}
///myFolder.json
export function getMyFolder(params) {
    return service.post(`${BASE_URL}/nft/myFolder.json`,
        transObjToParamStr2(params)
    );
}
//POST /api/1.0/raredrop/contractCollection/add
export function addFolder(params) {
  
    return service.post(`${BASE_URL}/nft/contractCollection/add`, 
        transObjToParamStr2(params)
    )
}

export function nftMint(params) {
    return service.post(`${BASE_URL}/nft/mint.do`,
        transObjToParamStr2(params)
    );
}

export function nftContractList(params) {
    return service.post(`${BASE_URL}/nft/contractList.json`,
        transObjToParamStr2(params)
    );
}

// export function nftCreated(params) {
//     return service.post(`${BASE_URL}/nft/getCreated.json`,
//         transObjToParamStr2(params)
//     );
// }

export function nftList(params) {
    return service.post(`${BASE_URL}/nft/list.do`,
        transObjToParamStr2(params)
    );
}

// Newsletter api/1.0/email/gather.do
export function Newsletter(params) {
    return service.post(`${BASE_URL}/email/gather.do`, 
    transObjToParamStr2(params)
    )
}
// 创作者列表
export function creatorList(params) {
    console.log(BASE_URL)
    return service.post(`${BASE_URL}/user/creator/list`, 
        transObjToParamStr2(params)
    )
}

// 获取系统信息
export function sysMessage(params) {
    console.log(BASE_URL)
    return service.post(`${BASE_URL}/message/sysMsg`, 
        transObjToParamStr2(params)
    )
}

//一键读取
export function readAllMessage(params) {
    console.log(BASE_URL)
    return service.post(`${BASE_URL}/message/read`, 
        transObjToParamStr2(params)
    )
}

///checkLinkUrl.do
export function checkLinkUrl(params) {
    console.log(BASE_URL)
    return service.post(`${BASE_URL}/nft/checkLinkUrl.do`, 
        transObjToParamStr2(params)
    )
}
// cerfified/create.do
export function cerfifiedApply(params) {
    return service.post(`${BASE_URL}/cerfified/create.do`, 
        transObjToParamStr2(params)
    )
}

//系统消息查询（分页显示),不需要参数
export function sysNews(params) {
    console.log(BASE_URL)
    return service.post(`${BASE_URL}/sysNews/list.page`, 
        transObjToParamStr2(params)
    )
}
export function nftDeList(params) {
    return service.post(`${BASE_URL}/nft/deList.do`,
        transObjToParamStr2(params)
    );
}

export function nftMyNFTList(params) {
    return service.post(`${BASE_URL}/nft/myNFTList.json`,
        transObjToParamStr2(params)
    );
}
// Query nft market info
export function marketNftInfo(params) {
    return service.post(`${BASE_URL}/market/nftInfo.json`,
        transObjToParamStr2(params)
    );
}
// POST /api/1.0/raredrop/creator/list
// 加V创作者列表
export function creatorVList(params) {
    console.log(BASE_URL)
    return service.post(`${BASE_URL}/user/creatorV `, 
        transObjToParamStr2(params)
    )
}

export function nftTrade(params) {
    return service.post(`${BASE_URL}/nft/trade.do`,
        transObjToParamStr2(params)
    );
}

function transObjToParamStr2(object,isSign) {
	
    let lang = localStorage.getItem("KEY_LANG");
    const json = localStorage.getItem("key_user");
    let data
    if(!object){
        object={}  
    }
    if (json) {
       data = JSON.parse(json);
       object.authenticationAddress= data.did;
       object.lang=lang;   
    }

	
    //  object.authenticationAddress= 'iaa1r44svrd44syk7g79z3h53chg2ar624l8fc5ap5'
    if(isSign){
        let sign =  rsa.encrypt(object)
        object = {sign:sign}
    }


    let arr = [];
    for (const key in object) {
        if (Object.hasOwnProperty.call(object, key)) {
            const element = object[key];
            arr.push(`${key}=${element}`);
        }
    }
    return arr.join('&');
}
function transObjToParamAirDrop(object,isSign) {
	
    let data
    if(!object){
        object={}  
    }
    if(isSign){
        let sign =  rsa.encryptAirdrop(object)
        object = {sign:sign}
    }


    let arr = [];
    for (const key in object) {
        if (Object.hasOwnProperty.call(object, key)) {
            const element = object[key];
            arr.push(`${key}=${element}`);
        }
    }
    return arr.join('&');
}

function setEvmParams(object) {
    let key = localStorage.getItem('KEY_CHAIN');
    if (key == "UPTICK EVM") {
        object.chainType = "EVM_UPTICK";
    }
}


//uptickLoopring jiekou

export function getMarketnftList(params) {
    return service.post(`${BASE_URL}/market/list.json`,
        transObjToParamStr2(params)
    )
}
///api/1.0/nft/getRecommendContract.json
export function getRecommendList(params) {
    return service.post(`${BASE_URL}/nft/getRecommendContract.json`,
        transObjToParamStr2(params)
    )
}

export function getLaunchList(params) {
    return service.post(`${BASE_URL}/market/getFirstPublish.json`,
        transObjToParamStr2(params)
    )
}

export function getPersonnftList(params) {
    return service.post(`${BASE_URL}/homepage/token.json`,
        transObjToParamStr2(params)
    )
}

export function getPersoncollectionList(params) {
    return service.post(`${BASE_URL}/homepage/collection.json`,
        transObjToParamStr2(params)
    )
}

//resale  
export function getMarketList(params) {
    return service.post(`${BASE_URL}/market/getMarketplaceWeb.json`,
        transObjToParamStr2(params)
    )
}

export function getResaleNftInfo(params) {
    return service.post(`${BASE_URL}/fleaMarket/nftInfo.json`,
        transObjToParamStr2(params)
    )
}

export function NftSingleTrade(params) {
    return service.post(`${BASE_URL}/nft/singleTrade.do`,
        transObjToParamStr2(params)
    )
}

//POST /api/1.0/nft/getMyTokenId.json
export function getNftOwn(params) {
    return service.post(`${BASE_URL}/nft/getMyTokenId.json`,
        transObjToParamStr2(params)
    )
}
//POST /api/1.0/nft/getMyContract.json
export function getOwnByCollection(params) {
    return service.post(`${BASE_URL}/nft/getMyContract.json`,
        transObjToParamStr2(params)
    )
}

export function getOwnByCollectionweb(params) {
    return service.post(`${BASE_URL}/nft/getMyContractWeb.json`,
        transObjToParamStr2(params)
    )
}


export function getOwnByCreator(params) {
    return service.post(`${BASE_URL}/nft/getOwnByCreator.json`,
        transObjToParamStr2(params)
    )
}

//POST /api/1.0/nft/basetokenInfo.json
export function nftTokenInfo(params) {
    return service.post(`${BASE_URL}/nft/basetokenInfo.json`,
        transObjToParamStr2(params)
    )
}

// 商品属性  POST /api/1.0/nft/propertyBynftID.json
export function getNftProperty(params) {
    return service.post(`${BASE_URL}/nft/propertyBynftID.json`,
        transObjToParamStr2(params)
    )
}


export function assetHistory(params) {
    return service.post(`${BASE_URL}/nft/history.json`,
        transObjToParamStr2(params)
    )
}

// 卡详情的历史记录
export function cardDetailtHistory(params) {
    return service.post(`${BASE_URL}/nft/historyByOwner.json`,
        transObjToParamStr2(params)
    )
}


//POST /api/1.0/auction/bidHistory
export function bidHistory(params) {
    return service.post(`${BASE_URL}/nft/auctionHistory.json`,
        transObjToParamStr2(params)
    )
}
//POST /api/1.0/auction/auctionDetail/bid
export function auctionBid(params) {
    return service.post(`${BASE_URL}/auction/auctionDetail/bid`,
        transObjToParamStr2(params)
    )
}
export function nftSingleList(params) {
    return service.post(`${BASE_URL}/nft/singleList.do`,
        transObjToParamStr2(params)
    )
}

export function nftSingleDeList(params) {
    return service.post(`${BASE_URL}/nft/singleDeList.do`,
        transObjToParamStr2(params)
    )
}

export function orderList(params) {
    return service.post(`${BASE_URL}/order/list.json`,
        transObjToParamStr2(params)
    )
}

export function collectionInfo(params) {
    return service.post(`${BASE_URL}/nft/collectionInfo.json`,
        transObjToParamStr2(params)
    )
}

export function chainToken(params) {
    return service.post(`${BASE_URL}/data/token.json`,
        transObjToParamStr2(params)
    )
}

export function nftBurn(params) {
    return service.post(`${BASE_URL}/nft/burn.do`,
        transObjToParamStr2(params,true)
    )
}
///getMyAssetsNoFolder.json
export function getMyAssetsNoFolder(params) {
  
    return service.post(`${BASE_URL}/nft/getMyAssetsNoFolder.json`, 
        transObjToParamStr2(params)
    )
}

//POST /api/1.0/raredrop/contractCollection/out
export function removeFolder(params) {
  
    return service.post(`${BASE_URL}/nft/contractCollection/out`, 
        transObjToParamStr2(params)
    )
}

export function nftTransfer(params) {
    return service.post(`${BASE_URL}/nft/transfer.do`,
        transObjToParamStr2(params)
    )
}

export function nftSingleTransfer(params) {
    return service.post(`${BASE_URL}/nft/singleTransfer.do`,
        transObjToParamStr2(params)
    )
}
export function getNftTokenId(params) {
    return service.post(`${BASE_URL}/nft/getTokenId.json`,
        transObjToParamStr2(params)
    )
}

export function orderStatements(params) {
    return service.post(`${BASE_URL}/order/statements.json`,
        transObjToParamStr2(params)
    )
}
//POST /api/1.0/fans/add.do
export function addFollow(params) {
    return service.post(`${BASE_URL}/fans/add.do`,
        transObjToParamStr2(params,true)
    )
}
// POST /api/1.0/fans/remove.do
export function removeFollow(params) {
    return service.post(`${BASE_URL}/fans/remove.do`,
        transObjToParamStr2(params,true)
    )
}
//POST /api/1.0/fans/isFans.do
export function isFollow(params) {
    return service.post(`${BASE_URL}/fans/isFans.do`,
        transObjToParamStr2(params)
    )
}
//POST /api/1.0/fans/myFocus.page
export function myFocus(params) {
    return service.post(`${BASE_URL}/fans/myFocus.page`,
        transObjToParamStr2(params)
    )
}
//POST /api/1.0/fans/myFans.page
export function myFans(params) {
    return service.post(`${BASE_URL}/fans/myFans.page`,
        transObjToParamStr2(params)
    )
}
// POST /api/1.0/follow/follow.do
export function followDo(params) {
    return service.post(`${BASE_URL}/follow/follow.do`,
        transObjToParamStr2(params,true)
    )
}
// POST /api/1.0/follow/isFollow.info
export function isCollect(params) {
    return service.post(`${BASE_URL}/follow/isFollow.info`,
        transObjToParamStr2(params)
    )
}
//POST /api/1.0/follow/list.page
export function followList(params) {
    return service.post(`${BASE_URL}/follow/list.page`,
        transObjToParamStr2(params)
    )
}
//留言、回复记录（指定好友）POST /api/1.0/words/record.page
export function messageRecord(params) {
    console.log(BASE_URL)
    return service.post(`${BASE_URL}/words/record.page`, 
    transObjToParamStr2(params)
    )
}
//POST /api/1.0/words/add.do
export function message(params) {
    console.log(BASE_URL)
    return service.post(`${BASE_URL}/words/add.do`, 
    transObjToParamStr2(params,true)
    )
}
// POST /api/1.0/words/list.page
export function listRecord(params) {
    return service.post(`${BASE_URL}/words/list.page`, 
    transObjToParamStr2(params)
    )
}
//查询群发记录 POST /api/1.0/words/group/record.page
export function messageRecordGroup(params) {
    return service.post(`${BASE_URL}/words/group/record.page`, 
        transObjToParamStr2(params)
    )
}
//添加群发记录
export function messageGroup(params) {
    return service.post(`${BASE_URL}/words/group/add.do`, 
        transObjToParamStr2(params,true)
    )
}
// 检查是否有留言 ,返回新消息条数，页面显示massage，以及头像小红点 POST /api/1.0/words/noRead.json
export function isNewMessage(params) {
    return service.post(`${BASE_URL}/words/noRead.json`, 
        transObjToParamStr2(params)
    )
}

export function scoreRecord(params) {
    return service.post(`${BASE_URL}/score/record.page`, 
        transObjToParamStr2(params)
    )
}
// score/exchangeInfo 圈存总数
export function scoreTotalInfo(params) {
    return service.post(`${BASE_URL}/score/exchangeInfo`, 
        transObjToParamStr2(params)
    )
}
//score/exchangeList 圈存总记录
export function scoreList(params) {
    return service.post(`${BASE_URL}/score/exchangeList`, 
        transObjToParamStr2(params)
    )
}


export function scoreRecordAction(params) {
    return service.post(`${BASE_URL}/score/mining/reward.json`, 
        transObjToParamStr2(params)
    )
}
//score/order/record.page
export function orderRecord(params) {
	debugger
    return service.post(`${BASE_URL}/score/order/record.page`, 
        transObjToParamStr2(params)
    )
}

// 积分兑换
export function scoreExchange(params) {
    return service.post(`${BASE_URL}/score/exchange.do`, 
        transObjToParamStr2(params,true)
    )
}

//  /api/1.0/user/updateData.do 
export function loopringUpdateDate(params) {
    return service.post(`${BASE_URL}/user/updateData.do`, 
        transObjToParamStr2(params)
    )
}

// POST /api/1.0/auction/lastBidPrice
export function currentBid(params) {
    return service.post(`${BASE_URL}/nft/auction/lastBidPrice`, 
        transObjToParamStr2(params)
    )
}

// POST 
export function getSystemParams() {
    return service.get(`${BASE_URL}/data/all.json`

    )
}

//POST POST /api/1.0/nft/pending.do
export function isDoing(params) {
    return service.post(`${BASE_URL}/nft/pending.do`, 
        transObjToParamStr2(params,true)
    )
}

//睁眼睛闭眼睛
export function openAsset(params) {
	 //String nftId,  String nftAddress, String owner, Boolean assetPublic
    return service.post(`${BASE_URL}/nft/saveState.do`, 
        transObjToParamStr2(params,true)
    )
}
// 预下单  order/preOrder.do
export function preOrder(params) {
    return service.post(`${BASE_URL}/order/preOrder.do`, 
        transObjToParamStr2(params,true)
    )
}
// 取消订单 POST /api/1.0/order/cancel.do
export function orderCancel(params) {
  
    return service.post(`${BASE_URL}/order/cancel.do`, 
        transObjToParamStr2(params,true)
    )
}

//POST /api/1.0/order/payOrder.do 
export function payOrder(params) {
  
    return service.post(`${BASE_URL}/order/payOrder.do`, 
        transObjToParamStr2(params)
    )
}

// 创建的收藏卡列表
export function couponList(params) {
    // {"pageNumber":"1","contractId":"9529","pageSize":"50"}
    return service.post(`${BASE_URL}/coupon/list.json`, 
        transObjToParamStr2(params)
    )
}
// 是否拥有优惠卡或者转卖卡
export function getCouponInfoJson(params) {
	// http://192.168.1.113:9997/api/1.0/coupon/info.json?userAddress=iaa15k328z4v65qd7tp82fcvrymfmfn6lnkq5ep30c&denom=uptick611b93934fc8e453b253518c93d1e478
	
    return service.get(`${BASE_URL}/coupon/info.json?`+ transObjToParamStr2(params)
    )

}
// /api/1.0//nft/onsale/before.do
export function onSaleBefore(params) {
    // {"pageNumber":"1","contractId":"9529","pageSize":"50"}
    return service.post(`${BASE_URL}/nft/onsale/before.do`, 
        transObjToParamStr2(params)
    )
}
//POST /api/1.0/coupon/nftList
export function getCollectionList(params) {
    // {"pageNumber":"1","contractId":"9529","pageSize":"50"}
    return service.post(`${BASE_URL}/coupon/nftList`, 
        transObjToParamStr2(params)
    )
}
//出价价格 BidPrice  拍卖人 bidder=did，  auctionNo 拍卖号 POST /api/1.0/nft/auction/update
export function auctionUpdate(params) {
  
    return service.post(`${BASE_URL}/nft/auction/update`, 
        transObjToParamStr2(params)
    )
}
///file/upload/json
export function updateCollection(params) {
    return service.post(`${BASE_URL_S3}/file/upload/json`, 
        transObjToParamStr2(params)
    )
}

// 作品投诉
export function nftComplaint(params) {
    console.log(BASE_URL)
    return service.post(`${BASE_URL}/complaint/create.do`, 
        transObjToParamStr2(params,true)
    )
}
// 作品申诉
export function nftAppeal(params) {
    console.log(BASE_URL)
   return service.post(`${BASE_URL}/complaint/appeal.do`,
       transObjToParamStr2(params,true)
   )
}

//创作者投诉
export function accountComplaint(params) {
    console.log(BASE_URL)
    return service.post(`${BASE_URL}/complaint/creator/create.do`, 
        transObjToParamStr2(params,true)
    )
}

// 监测是否可作者申诉
export function checkComplaint(params) {
    console.log(BASE_URL)
    return service.post(`${BASE_URL}/complaint/creator/check.do`, 
        transObjToParamStr2(params)
    )
}
// 根据订单号查询  order/preOrder.do
export function orderByOrderNo(params) {
    console.log(BASE_URL)
    return service.post(`${BASE_URL}/order/findByOrderNo.json`, 
        transObjToParamStr2(params)
    )
}



// 创作者申诉
export function accountAppeal(params) {
    console.log(BASE_URL)
    return service.post(`${BASE_URL}/complaint/creator/appeal.do`, 
        transObjToParamStr2(params,true)
    )
}

//获取我参加过的活动列表  /api/1.0/activity/luck/joinListByOwner

export function joinListByOwner(params) {
  
    return service.post(`${BASE_URL}/activity/luck/joinListByOwner`, 
        transObjToParamStr2(params)
    )
}

//获取我参加过的活动详情  /api/1.0/activity/luck/joinListByOwner

export function joinInfoByOwner(params) {
  
    return service.post(`${BASE_URL}/activity/luck/joinInfoByOwner`, 
        transObjToParamStr2(params)
    )
}

// 获取抽奖活动列表 /api/1.0/activity/luck/joinList
export function joinList(params) {
  
    return service.post(`${BASE_URL}/activity/luck/joinList`, 
        transObjToParamStr2(params)
    )
}
// activity/luck/irisLuckEnable.json
export function irisLuckEnable() {
  
    return service.get(`${BASE_URL}/activity/luck/irisLuckEnable.json`
        
    )
}
export function getmetaDataJsonByUrl(hash) {
  
    return service.get(`/uptickMetaView/`+hash)
}
//获取抽奖活动信息 /api/1.0/activity/luck/info
export function luckInfo(params) {
  
    return service.post(`${BASE_URL}/activity/luck/info`, 
        transObjToParamStr2(params)
    )
}
// nft/nftOrderHistory.json
export function orderHistory(params) {
  
    return service.post(`${BASE_URL}/nft/nftOrderHistory.json`, 
        transObjToParamStr2(params)
    )
}

// 隐藏用户列表
export function hideUserList(params) {
  
    return service.post(`${BASE_URL}/market/blockOwnerList`, 
        transObjToParamStr2(params)
    )
}
//隐藏集合列表
export function hideCollectList(params) {
  
    return service.post(`${BASE_URL}/market/blockNftAddressList`, 
        transObjToParamStr2(params)
    )
}
// 隐藏资产列表
export function hideNftsList(params) {
  
    return service.post(`${BASE_URL}/market/blockNftIdList`, 
        transObjToParamStr2(params)
    )
}
//隐藏资产
export function hideNfts(params) {
  
    return service.post(`${BASE_URL}/market/block`, 
        transObjToParamStr2(params)
    )
}
//释放资产
export function releaseNfts(params) {
  
    return service.post(`${BASE_URL}/market/unBlock `, 
        transObjToParamStr2(params)
    )
}
//我的推荐者 /user/myRecommended
export function myRecommended(params) {
  
    return service.post(`${BASE_URL}/user/myRecommended `, 
        transObjToParamStr2(params)
    )
}

// 我的邀请人列表 /user/myInvite
export function myInviteList(params) {
  
    return service.post(`${BASE_URL}/user/myInvite `, 
        transObjToParamStr2(params)
    )
}

//添加推荐人接口
export function addInviteCode(params) {
  
    return service.post(`${BASE_URL}/user/bind/inviteCode `, 
        transObjToParamStr2(params)
    )
}

//邀请码归属校验
export function checkInviteCode(params) {
  
    return service.post(`${BASE_URL}/user/check/inviteCode `, 
        transObjToParamStr2(params)
    )
}
//保存橱窗信息
export function shopWindowAdd(params) {
  
    return service.post(`${BASE_URL}/user/shopWindowAdd `, 
        transObjToParamStr2(params)
    )
}
//查询橱窗信息
export function shopWindow(params) {
  
    return service.post(`${BASE_URL}/user/shopWindow `, 
        transObjToParamStr2(params)
    )
}

// 查询空投列表
export function airdropList(params) {
    return service.post('/apidrop/airdrop/list', 
        transObjToParamAirDrop(params)
    )
}


// 空投领取
export function evmReceive(params) {
    return service.post('/apidrop/airdrop/evm/receive', 
        transObjToParamAirDrop(params,true)
    )
}
// 添加用户到通讯录 /api/1.0/addressBook/addAddressBook
export function addUserToContarct(params) {
    return service.post(`${BASE_URL}/addressBook/addAddressBook`, 
    transObjToParamStr2(params)
    )
}

//通讯录列表 /api/1.0/addressBook/list
export function contarctList(params) {
    return service.post(`${BASE_URL}/addressBook/list`, 
    transObjToParamStr2(params)
    )
}

// 修改通讯录 /api/1.0/addressBook/updateAddressBook
export function updateContract(params) {
    return service.post(`${BASE_URL}/addressBook/updateAddressBook`, 
    transObjToParamStr2(params)
    )
}

// 删除通讯录 /api/1.0/addressBook/deleteAddressBook
export function deleteContract(params) {
    return service.post(`${BASE_URL}/addressBook/deleteAddressBook`, 
    transObjToParamStr2(params)
    )
}
//关注粉丝导入 /api/1.0/addressBook/followList
export function addFansContract(params) {
    return service.post(`${BASE_URL}/addressBook/followList`, 
    transObjToParamStr2(params)
    )
}

// 获取通讯录用户信息 /api/1.0/addressBook/getAddressBook
export function getContractInfo(params) {
    return service.post(`${BASE_URL}/addressBook/getAddressBook`, 
    transObjToParamStr2(params)
    )
}
// 删除订单记录  order/cleanOrder.do
export function deleteOrder(params) {
    return service.post(`${BASE_URL}/order/cleanOrder.do`, 
    transObjToParamStr2(params)
    )
}

// Message Tab是否有红点 words/msgStatus.json
export function noreadMessage(params) {
    return service.post(`${BASE_URL}/words/msgStatus.json`, 
    transObjToParamStr2(params)
    )
}

 // 检查人机验证token
 export function checkToken(params) {
    return service.get(`${BASE_URL}/auth/siteverify?`+transObjToParamStr2(params)
)}

//水龙头领取 /api/1.0/faucet/receive
export function faucetReceive(params) {
    return service.post(`${BASE_URL}/faucet/receive`,transObjToParamStr2(params)
)}

// 水龙头解绑twitter
export function disconnectTwitter(params) {
    return service.post(`${BASE_URL}/user/unbindTwitter.do`,transObjToParamStr2(params,true)
)}

//POST /api/1.0/nft/onsaleListBynftID.json
export function getOnsaleList(params) {
    return service.post(`${BASE_URL}/nft/onsaleListBynftID.json`, 
        transObjToParamStr2(params)
    )
}

// POST /api/1.0/offer/acceptOfferByNftID.list 出价列表
export function getOfferList(params) {
    return service.post(`${BASE_URL}/offer/acceptOfferByNftID.list`, 
        transObjToParamStr2(params)
    )
}
// POST /api/1.0/nft/recommendListBynftID.json 推荐集合商品
export function recommendCollectList(params) {
    return service.post(`${BASE_URL}/nft/recommendListBynftID.json`, 
        transObjToParamStr2(params)
    )
}

// POST /api/1.0/nft/assetCouponByOwner.json 优惠专卖详情
export function getCouponInfo(params) {
    return service.post(`${BASE_URL}/nft/assetCouponByOwner.json`, 
        transObjToParamStr2(params)
    )
}

// /api/1.0/order/soldlist.json  折线图列表
export function getSoldList(params) {
    return service.post(`${BASE_URL}/order/soldlist.json`, 
        transObjToParamStr2(params)
    )
}

//POST /api/1.0/nft/assetByOwner.json 是否有自己的资产
export function getAssetsDetail(params) {
    return service.post(`${BASE_URL}/nft/assetByOwner.json`, 
        transObjToParamStr2(params)
    )
}

// 投放页详情 /api/1.0/lazynft/info.json
export function dropDetail(params) {
    return service.post(`${BASE_URL}/lazynft/info.json`, 
        transObjToParamStr2(params)
    )
}

// 投放页NFT详情 /api/1.0/lazynft/pageList.json
export function getDropNftList(params) {
    return service.post(`${BASE_URL}/lazynft/pageList.json`, 
        transObjToParamStr2(params)
    )
}


// 根据钱包地址查询跨链记录 /api/1.0/cross/getDataByAddress.json
export function getCrossListByAddress(params) {
    return service.post(`${BASE_URL}/cross/getDataByAddress.json`, 
        transObjToParamStr2(params)
    )
}

// 根据nftId查询跨链记录 /api/1.0/cross/getDataByNftID.json
export function getCrossListByTokenId(params) {
    return service.post(`${BASE_URL}/cross/getDataByNftID.json`, 
        transObjToParamStr2(params)
    )
}

// 根据nftId查询跨链记录 /api/1.0/cross/getDataByNftID.json
export function getCrossChainClassId(tokenId) {
    return service.get( '/uptickrest/erc721/v1/token_pairs/'+tokenId, 
  
    )
}

// POST /api/1.0/promo/user 判断活动是否可以领取
export function isGetToken (params) {
    return service.post(`${BASE_URL}/promo/user`, 
        transObjToParamStr2(params)
    )
}
//  领取活动卡片  POST /api/1.0/raredrop/applyPromo
export function applyPromo(params) {
  
    return service.post(`${BASE_URL}/promo/applyPromo`, 
    transObjToParamStr2(params)
    )
}
// 首页集合加资产推荐 /api/1.0//market/getMarketplaceExplore.json
export function getCollectNftExplore(params) {
    return service.post(`${BASE_URL}/market/getMarketplaceExplore.json`, 
        transObjToParamStr2(params)
    )
}

// 设置 nft头像 /addNftPhoto.do
export function setNftAvatar(params) {
    return service.post(`${BASE_URL}/user/addNftPhoto.do`, 
        transObjToParamStr2(params)
    )
}

// 获取可设置头像列表 /api/1.0/user/nftPhoto.json
export function getNftAvatar(params) {
    return service.post(`${BASE_URL}/user/nftPhoto.json`, 

        transObjToParamStr2(params)
    )
}

// nft/collectionStatistics.json 
export function getCollectDataStatistics(params) {
    return service.post(`${BASE_URL}/nft/collectionStatistics.json`, 

        transObjToParamStr2(params)
    )
}

//nft/folderStatistics.json
export function getFolderDataStatistics(params) {
    return service.post(`${BASE_URL}/nft/folderStatistics.json`, 

        transObjToParamStr2(params)
    )
}

// NFT集合订单记录  /api/1.0/nft/collectionOrderList.json
export function getcollectionOrderList(params) {
    return service.post(`${BASE_URL}/nft/collectionOrderList.json`, 

        transObjToParamStr2(params)
    )
}

//NFT 集合订单统计  /api/1.0/nft/collectionOrderStatistics.json
export function getcollectionOrderStatistics(params) {
    return service.post(`${BASE_URL}/nft/collectionOrderStatistics.json`, 

        transObjToParamStr2(params)
    )
}
//NFT 集合懒铸造订单记录  /api/1.0/nft/collectionLazyList.json
export function getcollectionLazyList(params) {
    return service.post(`${BASE_URL}/nft/collectionLazyList.json`, 

        transObjToParamStr2(params)
    )
}

//NFT 集合懒铸造订单统计  /api/1.0/nft/collectionLayzStatistics.json
export function getcollectionLayzStatistics(params) {
    return service.post(`${BASE_URL}/nft/collectionLayzStatistics.json`, 

        transObjToParamStr2(params)
    )
}
//NFT 集合懒铸造订单统计  /api/1.0/nft/collectionLayzStatistics.json
export function collectionWordsAdd(params) {
    return service.post(`${BASE_URL}/words/order/add.do`, 

        transObjToParamStr2(params)
    )
}

//懒铸造奖励 collectionLayzRewardStatistics.json 
export function collectionReward(params) {
    return service.post(`${BASE_URL}/nft/collectionLayzRewardStatistics.json `, 

        transObjToParamStr2(params)
    )
}

// 个人信息检测Url是否重复
export function checkWebUrl(params) {
    return service.post(`${BASE_URL}/user/check/webUrl.do `, 

        transObjToParamStr2(params)
    )
}







