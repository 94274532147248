<template>
  <div v-if="creator" class="featured-creator d-flex flex-column justify-center align-center"  :class="{dark:this.$vuetify.theme.dark}">
    <Avatar :size="70" :did="creator.address" :grade="creator.grade" :hash="creator.profilePhoto"  imgType="bigv" :isNftImage="creator.isNftImage" showNftImage=true nftImageType="nftBig" />
    <div class="name mt-lg-2" :class="{darkFont:this.$vuetify.theme.dark }">{{username?username:(creator.address).substr(0,3)+"..."+(creator.address).substr(-3)}}</div>
  </div>
</template>

<script>
import Avatar from "@/components/avatar/index.vue";

export default {
  name: "FeaturedCreator",
  components: { Avatar },
  props: {
    creator: {
      type: Object,
      require: true,
    },
  },
  data() {
  	return {
  		username: ""
  	}
  },
  mounted() {
	  this.username = this.creator.name;
	 
  }
};
</script>

<style lang='scss' scoped>
.featured-creator {
  width: 131px;
  height: 131px;
  line-height: initial;
  background-image: linear-gradient(#ffffff, #ffffff),
    linear-gradient(#6f58d9, #6f58d9);
  background-blend-mode: normal, normal;
  box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.09);
  border-radius: 5px;
  &:hover{
    box-sizing: border-box;
    border-radius: 5px;
    border: solid 2px #7800f4;
  }
}
.dark{
  background-image: linear-gradient(#272727, #272727),
    linear-gradient(#272727, #272727);
}
.name {
  font-size: 15px;
	letter-spacing: 0px;
	color: #270645;
  width:100px;
  height: 20px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow:ellipsis;
  text-align:center;
}
</style>
