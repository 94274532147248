<template>
  <v-menu open-on-hover bottom offset-y  :close-on-content-click="false" :disabled="isShowAvatarInfo()">
    <template v-slot:activator="{ on, attrs }">
      <v-avatar
        v-if="showCardList"
        @click="clickCardlist"
        v-bind="{ ...attrs, ...$attrs }"
        v-on="{ ...on, ...$listeners }"
        class="avatar"
      >
      <img class="img"   width="100%"
      :src="src" alt="avatar" />
      <img
		    v-if="grade==3"
        src="@/assets/icons/icon_v.png"
        alt="V"
	      v-bind:class="imgType"
      />
    
 
      </v-avatar>
        <v-avatar
        v-else
        @click.stop="click"
        v-bind="{ ...attrs, ...$attrs }"
        v-on="{ ...on, ...$listeners }"
        class="avatar"
      >
      <img class="img"   width="100%"
     :src="src" alt="avatar" />
      <img
		  v-if="grade==3"
        src="@/assets/icons/icon_v.png"
        alt="V"

    :class="isNftImage ? nftImageType=='nftBig' ? 'nftBig':'nftEspecially':imgType"
      />
      <img
           v-if="isNftImage"
           src="@/assets/icons/icon_nft3.png"
           alt="V"
         
           :class="grade==3 ? nftImageType=='nftBig' ? 'vBig':'vEspecially':imgType"
         />
 
      </v-avatar>
    </template>
    <AvatarInfo :did="did" />
  </v-menu>
</template>

<script>
import AvatarInfo from "./AvatarInfo.vue";
import logoimg from "@/assets/default.png"
import { getFileSrc } from "@/utils/file";
// import api from "@/api";

const AVATAR_KEY = "AVATAR";

export default {
  name: "Avatar",
  inheritAttrs: false,
  components: {
    AvatarInfo,
  },
  props: {
    hash: {
      type: String,
    },
    did: {
      type: String,
    },
	grade: {
	  type: Number,
	},
  isNftImage:{
    type: Boolean,
  },
    showAvatarInfo: {
      type: Boolean,
      default: false,
    },
    imgType: {
     type: String,
    },
     showCardList: {
     type: Boolean,
    },
    fromSource:{
      type:String
    },
    showNftImage:{
      type:Boolean
    },
    nftImageType:{
      type:String
    }
    
  },

  data: () => ({
    logoimg,
    src: undefined,
    showAvatar: false,
    avaInfo: {},
	isMenuBottom: false
  }),
  watch: {
    hash: {
      handler: async function (hash) {
        if (hash) {
          this.src = await getFileSrc(AVATAR_KEY, hash);
        }else{
          this.src = logoimg
        }
      },
      immediate: true,
    },
	// "$store.state.homeScrollTop":{
	//     deep:true,//深度监听设置为 true
	//     handler:function(newVal){
	// 		if(newVal < 400) {
	// 			this.isMenuBottom = false;
	// 		} else {
	// 			this.isMenuBottom = true;
	// 		}
	//     }
	//   }
  },
  mounted() {
	// if(this.imgType==undefined||this.imgType==""){
	// 	this.imgType="middlev"
	// }

  },
  methods: {
    
    click() {
      // if( this.fromSource == "showcase" || this.fromSource == "contact"){
      //   return;
      // }else{
      //   if(this.did){
      //      this.$router.push({
      //       name: "Spotlight",
      //       query: { did: this.did},
      // });
      //   }
        
      // }
     
      if( this.fromSource == "showcase"){
        this.$router.push({
            name: "ShowCasePage",
            query: { did: this.did},
          });
    }else{
           console.log("每一个头像的did" + this.did);
      if(this.did == this.$store.state.did){
        this.$router.push({
          name: "PersonalPage",
          query: { did: this.did },
        });
      }else{
        this.$router.push({
          name: "Otherpage",
          query: { did: this.did },
        });
      }
    }
   
    },
    clickCardlist(){

    },
	isShowAvatarInfo() {
		if(this.showAvatarInfo && this.did) {
			 return false;
		} else {
			return true;
		}
	}
  },
};
</script>

<style lang="scss" scoped>
    .img ::v-deep.v-responsive__sizer {
       
      
        // padding-top: 100px !important;
        padding-bottom: 100% !important;
    }
    .img{
      border: solid 2px #ffffff;
      object-fit: cover
    }
    .avatar{
      cursor: pointer;
       overflow:visible;
        position: relative;
        object-fit: cover
    }
     .spotlight{
         width:40px;
      height:40px;
        position: absolute;
      right: -4px;
      bottom: 1px;
    }
    .especially{
       width:30px;
      height:30px;
        position: absolute;
      right: -4px;
      bottom: 1px;

    }
   
    .bigv{

      width:20px;
      height:20px;
        position: absolute;
      right: -4px;
      bottom: 1px;
    }
	.middlev{
	   width:15px;
	  height:15px;
	    position: absolute;
	  right: -4px;
	  bottom: 1px;
	}
  .nftBig{
    width:20px;
      height:20px;
        position: absolute;
      right: 10px;
      bottom: -4px;
  }
  .vBig{
    width:20px;
      height:20px;
        position: absolute;
      right: 38px;
      bottom: -4px;
  }
  .nftEspecially{
    width:30px;
      height:30px;
        position: absolute;
      right: 20px;
      bottom: -4px;
  }
  .vEspecially{
    width:30px;
      height:30px;
        position: absolute;
      right: 60px;
      bottom: -4px;

  }
    .smallv{
       width:10px;
      height:10px;
        position: absolute;
      right: -4px;
      bottom: 3px;
    }

</style>
