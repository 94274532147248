<template>
  <div>
    <v-card-title
      class="d-block text--title pt-4 px-5 pb-2 text-truncate"
      style="max-width: 100%"
      >{{ value.name }}</v-card-title
    >
    <v-card-text class="d-flex flex-row align-center pb-3 px-5">
      <Avatar
        size="40"
        :did="value.owner"
        :hash="value.profilePhoto"
        showAvatarInfo
      />
      <div class="ml-4 text-truncate" style="max-width: 100%">
        {{ value.issuerName ? value.issuerName:this.value.owner.substr(0, 6) + "..." + this.value.owner.substr(-6)}}
      </div>
    </v-card-text>
    <v-divider></v-divider>
    <v-card-text class="d-flex flex-row justify-space-between px-5 pt-6 pb-7">
      <template v-if="!isStart"
        ><div>
          <div class="text--sub-title text--secondary-rd">
            {{ $t("popupAuctionStartingBid") }}
          </div>
          <div class="d-flex flex-row mt-2">
            <div>
              <v-img :src="$walletIcon" alt="logo" width="22px" height="22px" />
            </div>
            <div
              class="ml-2 text--number text--secondary-rd font-weight-medium" style="line-height:22px;"
            >
              {{ value.lastBidPrice }}
            </div>
          </div>
        </div>
        <div>
          <div class="text--sub-title text--secondary-rd">
            {{ $t("workCardStartingin") }}
          </div>
          <div class="mt-2">
            <div class="text--number text--secondary-rd font-weight-medium">
              {{ hour }}h {{ minutes }}m {{ second }}s
            </div>
          </div>
        </div></template
      >
      <template v-else
        ><div>
          <div class="text--sub-title text--primary-rd">
            {{ $t("workCardCurrentBid") }}
          </div>
          <div class="d-flex flex-row mt-2">
            <div>
              <v-img :src="$walletIcon" alt="logo" width="22px" height="22px" />
            </div>
            <div class="ml-2 text--number text--primary-rd font-weight-medium" style="line-height:22px;">
              {{ value.lastBidPrice }}
            </div>
          </div>
        </div>
        <div>
          <div class="text--sub-title text--primary-rd">
            {{ $t("workCardEndingin") }}
          </div>
          <div class="mt-2">
            <div class="text--number text--primary-rd font-weight-medium">
              {{ hour }}h {{ minutes }}m {{ second }}s
            </div>
          </div>
        </div></template
      >
    </v-card-text>
  </div>
</template>

<script>
import Avatar from "@/components/avatar/index.vue";
// import countLogo from "@/assets/icons/chain_iris_w.png";


export default {
  name: "AuctionCardContent",
  components: { Avatar },
  data: function () {
    return {
      isStart: false,
      // countLogo,
      now: "",
      start: "",
      end: "",
      day: 0,
      hour: 0,
      minutes: 0,
      second: 0,
      seconds: "",
    };
  },
  props: {
    value: {
      type: Object,
      default: () => {},
    },
  },
  mounted() {
    this.Time(); //调用定时器
  },
  methods: {
    getTime() {
      this.now = Date.parse(new Date());
      this.start = this.value.startTime;
      this.end = this.value.endTime;
      // this.start = Date.parse(new Date("2022-01-09 13:48:00"));
      // this.end = Date.parse(new Date("2022-01-09 13:49:00"));

      if (this.value.auctionStatus == 0 || this.value.auctionStatus == 1) {
        if (this.now < this.start) {
          this.isStart = false;
          this.seconds = (this.start - this.now) / 1000;
        } else if (this.now < this.end) {
          this.isStart = true;
          this.seconds = (this.end - this.now) / 1000;
        }
      }
    },
    // 天 时 分 秒 格式化函数
    countDown() {
      let d = parseInt(this.seconds / (24 * 60 * 60));
      this.day = d < 10 ? "0" + d : d;
      let h = parseInt(((this.seconds / (60 * 60)) % 24) + d * 24);
      this.hour = h < 10 ? "0" + h : h;
      let m = parseInt((this.seconds / 60) % 60);
      this.minutes = m < 10 ? "0" + m : m;
      let s = parseInt(this.seconds % 60);
      this.second = s < 10 ? "0" + s : s;
    },
    //定时器没过1秒参数减1
    Time() {
      setInterval(() => {
        if (this.seconds > 0) {
          this.seconds -= 1;
          this.countDown();
        }else{
           this.getTime();
        }
      }, 1000);
    },
  },
};
</script>

<style lang='scss' scoped>
.text--primary-rd {
  color: #270645;
}
.text--secondary-rd {
  color: #766983;
}
.text--title {
  font-size: 20px;
  line-height: 24px;
}
.text--sub-title {
  font-size: 12px;
  line-height: 1;
}
.text--number {
  font-size: 15px;
  line-height: 1;
}
.v-divider {
  border-color: #e3e3e3;
}
</style>
