<template>
   <div class="contain">
      <v-card id="card" :class="{ card: true }">
         <img
             class="clo"
             width="31px"
             height="31px"
             @click="closeDialog"
             :src="$vuetify.theme.dark?darkcloseIcon:closeIcon"
             alt=""
         />
        <div class="contant">
            <div class="language" :class="{darkFont:$vuetify.theme.dark}">Language</div>
              <div class="languageList mt-6" >
               <div class="item">
                  <div class="list" v-for="(item,index) in lang" :key="index">
                  <div class="name mt-1" :class="{currentLang:currentLanguage == item.label,darkFont:$vuetify.theme.dark }" @click="subCommit(item.value)">{{item.label}}</div>
               </div>
               </div>
              
             </div>
        </div>
       
       
      </v-card>
   </div>
</template>
<script>
  import vueQr from "vue-qr";

  export default {
    data: () => ({
      open: true,
      currentLanguage:'',
     lang:[
        { label: 'English', value: 'en' },
        { label: '中文', value: 'zh' },
        { label: '日本語', value: 'ja' },
        { label: '코리아', value: 'ko' },
        // { label: 'Português', value: 'pt' },
      ],
      closeIcon:require('@/assets/btn_close.png'),
         darkcloseIcon:require('@/assets/darkimage/btn_close1.png'),
    }),
    components: {
      vueQr
    },
    props: {
      
    },
    
    async mounted(){
         let language =  localStorage.getItem("KEY_LANG");
     if(!language){
      await this.setLanguage('en')
     }else{
       await this.setLanguage(language)
     }
    
    },
    computed: {
     
      isMobile: function() {
        return this.$vuetify.breakpoint.mobile;
      }
    },
    methods: {
         setLanguage(language) {     
        //  this.currentLanguage = this.lang[]
        this.lang.forEach(element => {
          if(element.value == language)
          this.currentLanguage = element.label
        });
        document.documentElement.lang = language;
        localStorage.setItem("KEY_LANG", language);
        this.$i18n.locale = language;
        this.$vuetify.lang.current = language;
        window.eventBus.$emit("LangChange", language);
        // if(this.isMounted)
        // this.$emit("getOpen", false);
      },
      subCommit(language) {
           this.lang.forEach(element => {
          if(element.value == language)
          this.currentLanguage = element.label
        });
        document.documentElement.lang = language;
        localStorage.setItem("KEY_LANG", language);
        this.$i18n.locale = language;
        this.$vuetify.lang.current = language;
        window.eventBus.$emit("LangChange", language);

     this.$emit("getOpen", false);
      },
      closeDialog() {
        this.open = false;
        this.$emit("getOpen", this.open);
      },
     
    }
  };
</script>
<style lang="scss" scoped>
   // .contain {
   .card {
      display: flex;
      flex-direction: column;
      width: 474px;
	height: 281px;
      .clo {
         display: block;
         position: absolute;
         right: 10px;
         top: 10px;
         border-radius: 50%;
         cursor: pointer;
      }
      .contant{
          margin: 24px 37px;
          .language{
              font-family: Helvetica;
                font-size: 20px;
                font-weight: bold;
                font-stretch: normal;
                line-height: 20px;
                letter-spacing: 1px;
                color: #270645;
          }
          .languageList{
              .item{
                  .name{
                      	width: 400px;
                        height: 41px;
                     
                        border-radius: 5px;
                        font-family: Helvetica;
                        font-size: 14px;
                        font-weight: bold;
                        font-stretch: normal;
                        line-height: 20px;
                        letter-spacing: 0px;
                        color: #260645;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        &.currentLang{
                            background-color: #7800f4 !important;
                            color: #ffffff;
                        }
                        &:hover{
                      	background-color: #baa6cd;
                  }
                  }
                  
              }

          }
      }

      

      &.mobile {
         
         
      }
   }

   // }
</style>




