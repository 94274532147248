<template>
<div class="contant">
  <div class="top">
     <v-card class="rd elevation-0" 	@mouseleave ="leaveCard()"> 
    <img
      class="img"
        v-lazy="src"
      width="100%"
      @click="imgClick"
      style="cursor: pointer"
    />

    <span class="name">{{ pageValue.name || "No Name" }}</span>
    <span class="num">{{ pageValue.nftCount || 0}} </span>
    <div class="showIcon" v-if="pageValue.contractType == 'COLLECTION' ||pageValue.contractType == 'LAZYN_COLLECTION' ">
        <img
								class="icon_collect"
								src="@/assets/icons/icon_collection.png"
								width="20px"
								height="20px"
								alt=""
								@mouseenter="mousedCollect()"
								@mouseleave ="leaveCollect()"
           				 />
							<div class="explainCollect d-flex flex-row align-center" v-if="isshowCollect">
               				 <span>{{ $t("nft_explain")}}</span>
             	</div>
    </div>
     <div class="showIcon" v-else>
        <img
								class="icon_collect"
								src="@/assets/icons/icon_folder.png"
								width="20px"
								height="20px"
								alt=""
								@mouseenter="mousedCollect()"
								@mouseleave ="leaveCollect()"
           				 />
							<div class="explainCollect d-flex flex-row align-center" v-if="isshowCollect">
               				 <span>{{ $t("collection_explain")}}</span>
             	</div>
    </div>

    <div class="leftimg">
      <img class="left" @click="changeLeft" src="@/assets/icons/icon_left.png" alt="">
    </div>
    <div class="rightimg">
      <img class="right" @click="changeRight" src="@/assets/icons/icon_right.png" alt="">
    </div>
  

    <slot></slot>
  </v-card>
  </div>
  
    <div class="showTex mt-4">
      <div class="des" :class="{darkFont:this.$vuetify.theme.dark }">{{pageValue.description}}</div>
      <div class="d-flex flex-row bottom">
         <div class="avatar">
       <Avatar  :size="47" :did="pageValue.owner" :hash="pageValue.creatorPhoto" imgType="middlev"
                :grade="pageValue.grade"  showAvatarInfo/>
        </div>
       
        <div class="creatname" :class="{darkFont:this.$vuetify.theme.dark }">{{pageValue.creatorName}}</div>
      </div>
    </div>
</div>
 
</template>

<script>
import { getFileSrc } from "@/utils/file";
const WORK_KEY = "WORK";

const AVATAR_KEY = "AVATAR";
import defaultImg from "@/assets/default.png";
import Avatar from "@/components/avatar/index";

export default {
    components: {	Avatar	},
  props: {
    hash: {
      type: String,
    },
    pageValue: {
      type: Object,
    },
    showAvatar: {
      type: Boolean,
      default: false,
    },
    otherpage: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
    },
    collectfrom: {
      type: String,
    },
    collectsource: {
      type: String,
    },
  },
  data: () => ({
    src: undefined,
    usersrc: "",
    defaultImg,
    isshowCollect:false,
    leftIndex:0,
    // imgList:[
    //  "Qmb2XPxjsJHcUSPpj2QvhTqfAkCYgghSNXj84F5iDnZxGU",
    //  "QmYfCyfG9Cg2FTHUTcf322s2MGCJChediTBSYquNWoVjMg",
    //  "QmPWc7kR5Q4sozBfhdEhA83Y25sjqaaUSuu3x71a8MqzaX",
    //  "QmZ8rnFLUCfta6MxxyuXQiRX39STytkUYcv2DuFEZXNRzG",
    //  "QmepwQUPCaCCMNeoVVWwbV1FgzTJkbvnYY4bEk3BW5rGft"
    // ]
  }),
  watch: {
    hash: {
      handler: async function (hash) {
       if (!hash) {
          hash = "QmPcXBirGiUSyjb8i2FQUpNDTtPHBsgo7tviwwm4YQeJ6p"
        }
        this.src = await getFileSrc(WORK_KEY, hash);
      },
      immediate: true,
    },
  },
  async mounted() {
    if (this.pageValue.creatorPhoto != null) {
      this.usersrc = await getFileSrc(AVATAR_KEY, this.pageValue.creatorPhoto);
    } else {
      this.usersrc = defaultImg;
    }
	if (this.pageValue.creatorName == null) {
	 this.pageValue.creatorName=this.pageValue.owner.substr(0,6)+"..."+this.pageValue.owner.substr(-6)
	}

    if (this.pageValue.listAmount) {
      this.pageValue.amount = this.pageValue.listAmount;
    }
  },
  methods: {
   async changeLeft(){
      this.leftIndex -- 
      // 如何首图 重置
      if( this.leftIndex < 0){
         this.leftIndex =this.pageValue.listAssets.length-1
         
      }
   this.src = await getFileSrc(WORK_KEY, this.pageValue.listAssets[this.leftIndex].imgUrl);

    //  if( this.leftIndex < this.imgList.length){
    //    this.src = await getFileSrc(WORK_KEY, this.imgList[this.leftIndex]);
        
    //  }else{
    //    this.leftIndex = 0
    //      this.src = await getFileSrc(WORK_KEY, this.imgList[this.leftIndex]);
    //       this.leftIndex++
    //  }
       
    },
    async changeRight(){
       this.leftIndex ++
      // 如何首图 重置
      if( this.leftIndex >this.pageValue.listAssets.length-1 ){
         this.leftIndex = 0
         
      }
   this.src = await getFileSrc(WORK_KEY, this.pageValue.listAssets[this.leftIndex].imgUrl);
    },
    tocollectpage() {
      this.$router.push({
        name: "Otherpage",
        query: { did: this.pageValue.owner },
      });
    },
    mousedCollect(){
				this.isshowCollect = true
			},
			leaveCollect(){
				this.isshowCollect = false
      },
     async leaveCard(){
     this.src = await getFileSrc(WORK_KEY, this.hash);
      },
    imgClick() {
      let url;
      if (this.pageValue.linkUrl) {
        url = this.pageValue.linkUrl;
      } else {
        url = this.pageValue.address;
      }
      debugger
      console.log("wxl -- ",this.type)
      if(this.pageValue.contractType == 'COLLECTION' || this.pageValue.contractType == 'LAZYN_COLLECTION'){
         if (this.type == "createorPage") {

        this.$router.push({
          path: `/collection_create/${url}/createCollect/${this.$store.state.did}`,
        });
      } else {
        this.$router.push({
           path: `/collection/${url}/${this.collectsource}/${this.pageValue.owner}`,  
        });
      }
      }else{
        if (this.type == "createorPage" ) {
                  // source From Folder
           this.$router.push({
             path: `/folder_create/${url}/${this.collectsource}/${this.did}`,   
        });
           }else{
              let owner;
        if(this.did){
          owner = this.did
        }else{
          owner = this.pageValue.owner
        }
                  // source From Folder
           this.$router.push({
             path: `/folder/${url}/${this.collectsource}/${owner}`,   
        });
           }

      }
     
    },
  },
};
</script>


<style lang="scss" scoped>
.v-card {
  width: 100%;
  aspect-ratio: 1 / 1;
  background-color: #270645;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  background-color: #270645;

  @media screen and (min-width: #{map-get($grid-breakpoints, 'lg')}) {
    // max-width: 286px;
    max-width: 100%
  }

  &:hover {
    border: solid 2px #7800f4;
  }

  &:hover .leftimg{
     visibility: visible;
  }
  &:hover .rightimg{
     visibility: visible;
  }
  .leftimg{
    visibility: hidden;
    .left{
      width:31px;
      height:31px;
      position: absolute;
						cursor: pointer;
						top: 50%;
						left: 10px;
    }

  }
 
  .rightimg{
      visibility: hidden;
    .right{
       width:31px;
      height:31px;
      position: absolute;
						cursor: pointer;
						top: 50%;
						right: 10px;
    }

  }

  .img {
    object-fit: cover;
    opacity: 0.95;
  }

  .name {
    position: absolute;
    font-family: Helvetica;
    font-weight: bold;
    font-size: 15px;
    line-height: 24px;
    width: 150px;
    color: #ffffff;
    top: 18px;
    left: 18px;
    font-weight: bold !important;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .num {
    position: absolute;
    font-family: Helvetica;
    font-weight: bold;
    font-size: 15px;
    line-height: 24px;
    color: #ffffff;
    top: 42px;
    left: 18px;
  }
  .showIcon{
    .icon_collect{
						position: absolute;
						cursor: pointer;
						top: 20px;
						right: 10px;
					}
    	.explainCollect{
							width: 185px;
							height: 56px;
							background-color: #270645;
							border-radius: 3px;
							position: absolute;
							top: 45px;
							right: 10px;
							span {
								font-family:Helvetica;
								font-size: 12px;
								font-weight: normal;
								font-stretch: normal;
								line-height: 15px;
								letter-spacing: 0px;
								color: #ffffff;
								margin-left: 13px;
								margin-right: 13px;
								}
						}
  }
  
  .contant {
    position: absolute;
    bottom: -95px;
    left: 0;
    .des{
      width:280px;
      	font-family: Helvetica;
	font-size: 15px;
	font-weight: normal;
	font-stretch: normal;
	line-height: 24px;
	letter-spacing: 0px;
	color: #270645;
    overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      display: inline-block;
    }

    .bottom {
      text-align: center;
      align-content: center;
      margin-top:2px;
      .avatar {
        width: 47px !important;
        height: 47px !important;
        border-radius: 50% !important;
      }
      .creatname {
        width: 220px;
        font-family: Helvetica;
        font-size: 15px;
        font-weight: bold;
        font-stretch: normal;
        line-height: 24px;
        letter-spacing: 0px;
        color: #270645;
        margin-top: 10px;
        margin-left: 10px;
        text-align: left;
           overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      display: inline-block;
      }
    }
  }
  
}
.showTex{
  
        .des{
      width:100%;
      	font-family: Helvetica;
	font-size: 15px;
	font-weight: normal;
	font-stretch: normal;
	line-height: 24px;
	letter-spacing: 0px;
	color: #270645;
    overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      display: inline-block;
    }
     .bottom {
      text-align: center;
      align-content: center;
      margin-top:2px;
      .avatar {
        width: 47px !important;
        height: 47px !important;
        border-radius: 50% !important;
      }
      .creatname {
        width: 220px;
        font-family: Helvetica;
        font-size: 15px;
        font-weight: bold;
        font-stretch: normal;
        line-height: 24px;
        letter-spacing: 0px;
        color: #270645;
        margin-top: 10px;
        margin-left: 10px;
        text-align: left;
        overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      display: inline-block;
      }
    }
    
  }
</style>