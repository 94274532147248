<template>
  <div class="work d-flex align-center" :class="{showOver:sourcetop}">
    <img
    class="mainImg"

      :class="{topImg:sourcetop,'imgs': isCreateCheck == true}"
      :src="src"
      :contain="contain"
      width="100%"
      max-height="100%"
      :aspect-ratio="aspectRatio === -1 ? undefined : aspectRatio"
      @click="imgClick"
      style="cursor: pointer;border-radius: 5px 5px 0px 0px;"
      v-if="!videoPic && cardType == 'my'"
    >
      <img
    class="mainImg"
      :class="{topImg:sourcetop,'imgs': isCreateCheck == true}"
      v-lazy="src"
      :contain="contain"
      width="100%"
      max-height="100%"
      :aspect-ratio="aspectRatio === -1 ? undefined : aspectRatio"
      @click="imgClick"
      style="cursor: pointer;border-radius: 5px 5px 0px 0px;"
      v-if="!videoPic&&  cardType != 'my'"
    >
    <div class="video">
       <v-icon v-if="value.fileType != null  && value.fileType == 'MP4'" class="icons" size="30" color="#fff"
        >mdi-video</v-icon
    >
     
   </div>
     <video  v-if="sourcetop &&videoPic "   width="100%"  class=""
      max-height="100%" ref="video" autoplay loop  muted controlslist="nodownload"
            :src="videoPic"  @click.stop="imgClick" ></video>
    <div class="text"  v-if="cardType == 'my' || cardType == 'clearmode'">
      <div class="number">{{value.amount}}</div>
    </div>
    <div class="crossChian">
      <img  src="@/assets/icons/interchain_list.png" alt="">
    </div>
  
     <div class="eyeimg" v-if="cardType == 'my' && value.listAmount == 0 && value.amount > 0">
        <div >
           <img class="img" @click.stop="clickEyes" @mouseenter="moused()" @mouseleave="leave()" :src="
                require('@/assets/icons/' +
                  (!isVisible ? 'eye1_w' : 'eye_w') +
                  '.png')
              " />
            <div class="explain d-flex flex-row align-center" v-if="show">
              <span v-if="isVisible">{{ $t("Viewable_des") }}</span>
              <span v-else>{{ $t("Viewable_visible_des") }}</span>
            </div>
          
      </div>
      </div>
        <div class="time" v-if="value.listAmount != 0 && value.marketType == '5'&&  value.couponTime && (cardType == 'my' || cardType == 'other' || cardType == 'clearmode')">
        <img src="@/assets/icons/icon_time_t.png"  width="30px"
            height="30px" >
      </div> 
       <div class="crossChain"  v-if="cardType == 'my'" >
        <img src="@/assets/icons/interchain_list.png"  width="120px"
            height="40px" >
      </div> 
  </div>
</template>

<script>
import { getFileSrc } from "@/utils/file";
import api from "@/api";

const WORK_KEY = "WORK";

export default {
  name: "Work",
  inheritAttrs: false,
  props: {
    //头图不放大
    sourcetop:{
      type:String

    },
    videoPic:{
      type:String
      },
     isCreateCheck:{
      type:Boolean
    },
    // -1 处理不设置的情况
    aspectRatio: {
      type: Number,
      default: 1,
    },
    hash: {
      type: String,
    },
    attribute: Number, // 7 video
    contain: {
      type: Boolean,
      default: false,
    },
    value: {
      type: Object,
      default: () => {}
    },
    cardType:String
  },
  data: () => ({
    src: undefined,
    videoUrl: undefined,
    videoOptions: {},
     isVisible: false,
     show:false
    
  }),
  watch: {
    hash: {
      handler: async function (hash) {
        if (!hash) {
         hash = "QmPcXBirGiUSyjb8i2FQUpNDTtPHBsgo7tviwwm4YQeJ6p"
         
        }
        
         let metadataUrl = hash.indexOf('http')
         let metadataUrls = hash.indexOf('https')

  if(metadataUrl == -1 || metadataUrls == -1){
   
       this.src = await getFileSrc(WORK_KEY, hash);
  }else{
      this.src = hash
  }
  
  
      
          
      },
      immediate: true,
    },
  },
  mounted(){
     this.isVisible = this.value.assetPublic
   
  },
  methods: {
    moused() {
        this.show = true
      },
      leave() {
        this.show = false
      },
    imgClick() {
      this.$emit("imgClick");
    },
    async  clickEyes() {
      this.$gtag.event('资产是否可见', { 'event_category': 'Click', 'event_label': '资产是否可见' })
      this.isVisible = !this.isVisible;
      let params={
        nftId:this.value.nftId,
        nftAddress:this.value.nftAddress,
        owner:this.value.owner,
        assetPublic:this.isVisible
      }
      await api.home.openAsset(params)
    },
  },
};
</script>

<style lang="scss" scoped>
.work {
.mainImg{
     transition: all .5s ease .1s;
      aspect-ratio: 1 / 1;
      object-fit: cover;
  }
  .imgs{
    opacity: 0.3;
  }
  overflow: hidden;
  .mainImg:hover{
    transform: scale(1.1);
    // transform: translate(0px, 0px) scale(2) rotate(0deg);  
  }
   .topImg:hover{
    transform: scale(1);
    // transform: translate(0px, 0px) scale(2) rotate(0deg);  
  }
  .video{
    .icons{
       position: absolute;
      left: 10px;
      bottom: 5px;
    }
   
  }
  .text{

         display: flex;
    align-items: center;
    //  position: relative;
    //  top: 187px;  //-25px
    // right: -168px;
    width: 43px;
  height: 23px;
  background-color: rgb(39,6,69,0.3);
  border-radius: 6px;
    position: absolute;
       right: 10px;

    bottom: 10px;
    .number{
         width: 45px;
      font-family: Helvetica;
      font-size: 13px;
      font-weight: bold;
      font-stretch: normal;
      letter-spacing: 0px;
      color:#ffffff;
      text-align: center;
    }
  }

  .content {
    width: 217px;
    height: 217px;
    position: relative;
    height: 100%;
    .icon {
      position: absolute;
      left: 10px;
      bottom: 10px;
    }
  }
  .amount{
      display: flex;
    align-items: center;
     position: absolute;
       right: 10px;

    top: 10px;
    width: 43px;
  height: 23px;
  background-color: rgb(39,6,69,0.3);
  border-radius: 6px;

 
  .number{
      width: 45px;
      font-family: Helvetica;
      font-size: 13px;
      font-weight: bold;
      font-stretch: normal;
      letter-spacing: 0px;
      color:#ffffff;
      text-align: center;
  }
  }
  .crossChian{
       display: none;
    align-items: center;
     position: relative;
    left:-80px;
    top:-85px;
  width: 120px;
  height: 30px;
  background-color: rgb(39,6,69,0.3);
  border-radius: 6px;

  }
  .eyeimg{
      display: none;
    align-items: center;
     position: absolute;
       right: 10px;

    top: 10px;
  width: 30px;
  height: 30px;
  background-color: rgb(39,6,69,0.3);
  border-radius: 6px;
  .img{
    width: 30px;
  height: 30px;
  color:#ffffff;

  }
  
  
  }
  .time{
     position: absolute;
     top:0;
    right: 0;
    width: 30px;
  height: 30px;
  }
  .crossChain{
    display: none;
      position: absolute;
     top:0;
    left:  0;
    width: 120px;
  height: 30px;

  }
  .explain {
    width: 200px;
    height: 40px;
    background-color: #270645;
    border-radius: 3px;
    position: absolute;
    top: 33px;
    right: 0;
    z-index: 2;


    span {
      font-family: Helvetica;
      font-size: 12px;
      font-weight: normal;
      font-stretch: normal;
      line-height: 15px;
      letter-spacing: 0px;
      color: #ffffff;
      margin-left: 13px;
      margin-right: 13px;
    }
  }
 
  &:hover{
    .eyeimg{
      display: block;
    }
    .crossChain{
display: block;
    }
  }
  
}
// .showOver{
//   overflow: visible !important;
// }
</style>