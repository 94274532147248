<template>
    <Layout />
</template>

<script>
import Layout from "@/layout/index.vue";

export default {
  name: "App",
  components: { Layout },

// watch:{
// 	$route(to,from){
//      this.$router.push({name:'Home'})
// 	}
// }
};
</script>
