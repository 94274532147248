<template>
  <div id="container">
    <v-card :class="{ card: true, mobile: isMobile }">
      <img
        class="clo"
        @click="closeDialog"
        :src="$vuetify.theme.dark?darkcloseIcon:closeIcon"
        alt=""
      />
      <div class="top">
        <h3 :class="{darkFont:$vuetify.theme.dark }">{{$t('ReceivingSale')}}</h3>
        <div class="d-flex flex-row ">
              <img class="img" :src="getImageIpfs(parentValue.imgUrl)" />
              <div class="d-flex flex-column ml-5">
                      <div class="pupils mt-n1" :class="{darkFont:$vuetify.theme.dark }" >{{decodeURIComponent(NftName)  }}</div>
                      <div class="name" :class="{darkFont:$vuetify.theme.dark }">{{parentValue.amount}}</div>
              </div>
        </div>
     
      </div>
      <div
        class="maxamount  mb-6 mt-8 d-flex flex-row justify-space-between"
       v-if="parentValue.nftType == 'ERC1155'"
      >
        <div class="box d-flex flex-row">
          <div class="amount mt-4" :class="{darkFont:$vuetify.theme.dark }">
            {{ $t("popupMarketMaximumPurchaseAmount") }}
          </div>
          <v-text-field
            class="txt"
            ref="amount"
            v-model="amount"
            height:10px
            full-width:false
            outlined
            :rules="[rules.maxCount]"
            onkeyup="value=value.replace(/^\D*(\d*(?:\.\d{0,4})?).*$/g, '$1')"
            :maxlength="amountString.length"
            v-if="switch1"
          ></v-text-field>
        </div>
        <div class="box2 mr-n4">
          <v-sheet class="sth">
            <v-switch v-model="switch1" inset></v-switch>
          </v-sheet>
        </div>
      </div>
    
    <div class="footer">
        <span class="price"
          >{{ $t("popupAuctionDefectPriceHandingFee") }}：{{ value1 }}
          UPTICK</span
        >
        <button class="sub" :class="{ 'sub-dis': isPay }" @click="subCommit">
          {{ $t("popupTransferCommit") }}
        </button>
        <span class="price"
          >{{ $t("popupNewTransferBalance") }}：{{ value3 }} UPTICK
         </span
        >
      </div>
    </v-card>
    <!-- <PromptBox ref="promptBox"></PromptBox> -->
    <uComponents ref="ucom"></uComponents>
  </div>
</template>

<script>
import api from "@/api";

//xxl ##02 add web3 convert import
import Web3 from "web3";
const web3Obj = new Web3();

export default {
  components: {  },
  name: "transfer",
  data: () => ({
    open: true,
    value1:'',
    value3:'',
    selectnft: false,
    SalePrice: "",
    reducedPrice: "",
    // starsCard: "",
    // reducedPriceCard: "",
    amount:1,
    switch1: true,
    // switch2: false,
    switch3: false,
    value2: "0.075",
    assetBalance: "",
    NftName: "",
    isShow: false,
    isPay: false,
    discountVer: "",
    closeIcon:require('@/assets/btn_close.png'),
         darkcloseIcon:require('@/assets/darkimage/btn_close1.png'),
    rules: {
      //amount: (value) => !!value || "Place amount is required.",
      maxCount: (value) => {
        const pattern = /^(\+?[1-9][0-9]{0,8})$/;
        return pattern.test(value) || "verify amount";
      },
      errorPrice: (value) => {
       const pattern =  /^(?:0|[1-9][0-9]?|100)(\.[0-9]{0,8})?$/;
        return pattern.test(value) || "Invalid";
        // if (parseInt(value) === parseFloat(value)){
        //      const pattern =/^[1-9]\d{0,7}$/;
        // return pattern.test(value) || "Invalid";
        //   //^[1-9]\d{0,7}$
        // }else{
        //     value = value.replace(/^\s+|\s+$/g,"")
        // // const pattern = /^(\+?[0-9][0-9]{0,8})$/;
        // const pattern =/^\d*(?:\.\d{0,4})?$/;
        // return pattern.test(value) || "Invalid";
        // }
      
      },
    },
    starsCardlist: [],
    reducedPriceCardlist: [],
    getNftList: [],
    flag: true,
    isYes: false,
    deductionType: 2,
    onsaleTokenId:"",
    chain:'UPTICK',
    chainAddress:'0x0000000000000000000000000000000000000000',
    decimals:18,
    coindenom:'uiris',
    multiNFTids:'',
    prices:[],
    feePrice:'',
    amountString:''
  }),

  props: {
    address: {
      type: String,
    },
     onsaleType:{
      type:String
    },
    name: {
      type: String,
    },
    parentValue: {
      type: Object,
    },
    	contractCollectionId: {
      type: Number,
    },
     Type: {
      type: String,
    },
    onSaleTokenIds: {
      type: Array,
    },
    offSaleTokenIds: {
      type: Array,
    },
     nftAddresss: {
      type: Array,
    },
     NFTAmount:{
      type: Array,
    },
  },
  computed: {
    isMobile: function () {
      return this.$vuetify.breakpoint.mobile;
    },
    handler: function () {
      return {
        switch: this.switch1,
      };
    },
  },
  watch: {
    handler: {
      handler: function () {
        if (this.switch1 == true) {
          console.log("true");
       
          this.isShow = true;
        } else {
          console.log("false");

          this.isShow = false;
        }
      },
      deep: true,
    },
    // amount(value){
    //   if(value > this.parentValue.amount){
   
    //         this.amount = this.parentValue.amount
    //   }
    //     this.value1 =this.NumberMul(this.feePrice,value)
    //      this.$forceUpdate();
    //       console.log("wwww",value,this.value1,this.amount)
          
    // }
  },
  async mounted() {
    this.amountString = this.parentValue.amount.toString();
   console.log("ssssss",this.parentValue,this.Type,this.onSaleTokenIds,this.onsaleType,this.nftAddresss)
   if(this.onSaleTokenIds &&this.onSaleTokenIds.length >0){
     for (let index = 0; index < this.onSaleTokenIds.length; index++) {
     this.multiNFTids+=this.onSaleTokenIds[index]+ ",";
     
   }
    this.multiNFTids = this.multiNFTids.substring(0, this.multiNFTids.lastIndexOf(','));
   }
   
   console.log("wxl -- eeee", this.multiNFTids)
    let res2 = await api.home.getFee();
    if(this.parentValue.nftType=="ERC721") {
      this.value1= this.NumberMul(Number(res2.data.erc721OnsaleUptickAssetFee),this.parentValue.amount) ;
     
    }
    else if(this.parentValue.nftType=="ERC1155") {
     
     this.value1= this.NumberMul(Number(res2.data.erc1155OnsaleUptickAssetFee),this.parentValue.amount) 
    }
    this.NftName = this.parentValue.name;
    //let balance =  await getMyBalance();
    let res = await this.$wallet.getMyBalance();
    console.log("updata balance ----");
    console.log(res);
    let mount = localStorage.getItem("key_balance");
    this.value3 = mount;
  
    console.log(this.parentValue);
    //  this.value1 = 0.1 * this.offSaleTokenIds.length
  },
  methods: {
     sleep(time) {
      return new Promise((resolve) => setTimeout(resolve, time));
    },
    verify() {
        if(this.parentValue.nftType == 'ERC1155'){
           let amountVer = true;
      if (this.switch1 == true) {
        amountVer = this.$refs.amount.validate(true);
      }

    
      !amountVer ? this.$refs.amount.focus() : "";
      return  amountVer;
        }else{
          return true
        }

     
    },
    //提交转送数据
    async subCommit() {
      try {
        if (!this.verify()) {
          return;
        }
        // if (this.switch1 && this.amount <= 0) {
        //   return;
        // }
      
        this.isPay = true;
        let res = await api.home.getFee();
     
      
         let result;
    
          this.$wallet.setContractAddress(this.parentValue.nftType, {
            address: this.parentValue.nftAddress,
            platformAddress: this.parentValue.nftType == 'ERC721'? res.data.erc721Platform : res.data.erc1155Platform ,
          });

      
             result = await this.$wallet.onSale(
            this.parentValue.nftType,
            this.parentValue.nftAddress,
            this.parentValue.nftId,
            0,
            this.value1,
            this.parentValue.amount,
            this.chainAddress,
            this.decimals
          );
          
		 
       
        // let buyLimit = "";
        // if (this.switch1 == true) {
        //   buyLimit = 0;
        // } else {
        //   buyLimit = this.amount;
        // }
        if(!result.hash){
           this.isPay = false
           this.$toast("error",this.$t("requireFailed"))
           return
        }
           if(!this.switch1){
        this.amount = 0
      }
        let isDoingParams = {
          nftAddress: this.parentValue.nftAddress,
          nftId:this.onsaleType == 'multi' ? this.multiNFTids: this.parentValue.nftId,
          hash:result.hash,
          owner:this.$store.state.did,
          saleStatus:6,
          buyLimit:this.amount,
          marketType:6
        };
     
        let isding = await api.home.isDoing(isDoingParams);
        console.log(isding);
        if (isding.success == true) {

         await this.sleep(5000).then(()=>{
        this.isPay = false;  
        this.$emit("getOpen", false,'onSaleSuccess')
   
    
   })
      
    
  
        // let isShowTip = localStorage.getItem("isShowTip_"+this.$store.state.did)
        // if(isShowTip == 'true'){
        //    this.$mtip({
        //       title: this.$t("onSaleSuccess"),
        //     });
        // }

          
        //      this.$emit("getOpen", false);
        //    this.isPay = false;
        //     window.eventBus.$emit("collectionPay");
        
        }
     
      } catch (e) {
        console.log("eee",e)
        //this.$refs.promptBox.show(this.$t('putawayFailed'));
        //this.$toast("error", this.$t("amCreateFalseTitle2"));
        this.$toast("error", this.$t("transactionInprogressError"));
        this.isPay = false;
      }
    },
     subCommit1() {
      this.selectnft = true;
    },
    NumberMul(arg1, arg2) {
      var m = 0;
      var s1 = arg1.toString();
      var s2 = arg2.toString();
      try {
		  if(s1.indexOf(".") != -1){
			    m += s1.split(".")[1].length;
		  }
      
      } catch (e) {
        console.log(e)
      }
      try {
		  if(s2.indexOf(".") != -1){
		  			 m += s2.split(".")[1].length;
		  }
       
      } catch (e) {
        console.log(e)
      }

      return (
        (Number(s1.replace(".", "")) * Number(s2.replace(".", ""))) /
        Math.pow(10, m)
      );
    },
    //弹框关闭后获取open值
    Openselectnft(e) {
      this.selectnft = e;
    },
    getCardList(data) {
      this.getNftList = data;
      
      this.$forceUpdate();
    },
   //关闭弹框，发送open值
    closeDialog() {

      this.open = false;
      this.$emit("getOpen", this.open);
    },
    iconBtn1() {
      this.isYes = false;
      this.deductionType = 2;
    },
    iconBtn2() {
      this.isYes = true;
      this.deductionType = 1;
    },
    closenft(index) {
      this.getNftList.splice(index, 1);
    },
  },
};
</script>

<style lang="scss" scoped>
.sth {
  margin-left: 108px;
  width: 60px;
  height: 35px;
  // background-color: #270645;
  display: block;
  position: relative;
  right: -15px;
  bottom: 15px;
  border-radius: 18px;
}
.tip {
  font-family: "Helvetica";
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  letter-spacing: 0px;
  color: #766983;
}
.card {
  display: flex;
  flex-direction: column;
  min-height: 300px;
  width: 600px;

  .clo {
    position: absolute;
    right: 10px;
    top: 10px;
    width: 30px;
    height: 31px;
    border-radius: 50%;
    margin-right: 0;
    cursor: pointer;
  }

  .top {
    margin-left: 52px;

    h3 {
      margin: 41px 0 29px;
      font-family: "Helvetica";
      font-size: 25px;
      color: #270645;
    }
    .name{
        margin-top: 10px;
        height: 12px;
        font-family: Helvetica;
        font-size: 15px;
        font-weight: normal;
        font-stretch: normal;
        line-height: 20px;
        letter-spacing: 0px;
        color: #270645;
    }
    .pupils {
	    height: 22px;
        font-family: Helvetica;
        font-size: 20px;
        font-weight: bold;
        font-stretch: normal;
        line-height: 30px;
        letter-spacing: 0px;
        color: #270645;
    }
    .img{
        width: 51px;
        height: 51px;
        background-color: #270645;
        border-radius: 5px;
        margin: 0;
    }
  }

  .form {
    margin: 10px 52px;

    .title {
      font-family: "Helvetica";
      font-size: 15px !important;
      font-weight: bold;
      font-stretch: normal;
      color: #270645;
    }
    .forms {
  
    img{
      width: 25px;
      height: 25px;
      background-color:white;
    }
    .title {

      font-size: 15px;
      font-weight: bold;
      font-stretch: normal;
      color: #270645;
    }
    	.reserveContant{
					position: absolute;
					right: 150px;
					top: 10px;
					width: 212px;
					height: 101px;
					background-color: #270645;
					border-radius: 3px;
					z-index:1;
					.des{
						margin: 15px;
						width: 183px;
						height: 71px;
						font-family: Helvetica;
						font-size: 12px;
					font-weight: normal;
					font-stretch: normal;
					letter-spacing: 0px;
					color: #ffffff;
					line-height: 15px;
					}
					
	
				}
  }
  }
 
 

  .maxamount {
    margin: 0 52px;
    height: 56px;

    .amount {
      margin-right: 18px;
      font-weight: bold;
      font-size: 15px;
      min-width: 212px;
      height: 17px;
      color: #270645;
      display: block;
    }

    .txt {
         &::v-deep  .v-input__slot {   
            height:10px!important;        
            }
      width: 98px;
      height: 55px;
      border-radius: 3px;
      display: inline-block;
    }

    .sth {
      margin-left: 108px;
      width: 60px;
      height: 35px;
      // background-color: #270645;
      display: block;
      position: relative;
      right: 0px;
      bottom: 0px;
      border-radius: 18px;
    }
  }

  .footer {
    display: flex;
    flex-direction: column;
    text-align: center;
    margin: 10px auto;

    .price {
      display: block;
      font-family: Helvetica;
      font-size: 13px;
      font-weight: normal;
      font-stretch: normal;
      line-height: 40px;
      color: #766983;
    }

    .sub {
      margin: 0 auto;
      width: 450px;
      height: 51px;
      	background-color: #7800f4;
      border-radius: 25px;
      opacity: 0.9;
      font-size: 20px;
      color: #ffffff;
    }
  }

  &.mobile {
    .maxamount {
      .sth {
        margin-left: 50px;
      }
    }

    .footer {
      margin: 10px 10%;

      .sub {
        width: 80%;
      }
    }
  }
}

img {
  display: inline-block;
  width: 126px;
  height: 126px;
  border-radius: 5px;
  margin: 1px 22px 10px 52px;
}

span {
  align-items: center;
  font-family: "Helvetica";
  color: #270645;
}

.sub-dis {
  position: relative;
  pointer-events: none;
  background-image: linear-gradient(#766983, #766983),
    linear-gradient(#270645, #270645) !important;
  background-blend-mode: normal, normal;
  border-radius: 25px;
  opacity: 0.9;
}

.sub-dis::after {
  content: "";
  background-image: url(../../assets/loading.gif);
  background-size: 100%;
  display: inline-block;
  position: absolute;
  width: 20px;
  height: 20px;
  margin-left: 10px;
  margin-top: 5px;

  // box-shadow: 0 0 10px #fff;
  // border-radius: 50%;
}
</style>
