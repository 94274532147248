<template>
  <div class="root-home" :class="{  mobile: isMobile}">
    <Product v-if="isload" :sku="topSku" :address="address" :price="price" />
   <div class="contantNFT">
    <div
      class="titlefeature mb-9"
      v-if="this.dropList && this.dropList.length > 0"
    >
      <div class="name" :class="{ darkFont: this.$vuetify.theme.dark }">
        Featured Drops
      </div>
    </div>

    <FeatureDrops
      :dropList="dropList"
      v-if="this.dropList && this.dropList.length > 0"
    />
    <div
      class="title mb-6"
      :class="{ noDrop: this.dropList.length == 0 }"
      v-if="creators.length > 0"
    >
      <div
        v-if="creators.length > 0"
        class="name"
        :class="{ darkFont: this.$vuetify.theme.dark }"
      >
        {{ $t("homeFeaturedCreatorsTitle") }}
      </div>
      <img
        @click="toCreatorList"
        :src="rightIcon"
        :alt="$t('homeFeaturedCreatorsTitle')"
        v-if="creators.length > 0"
      />
    </div>

    <FeaturedCreators
      v-if="creators.length > 0"
      :creators="creators"
      class="mt-3"
    />
  
    <TrandingCollection
      v-if="this.recommendList.length > 0"
      :recommendList="recommendList"
    />
    <div class="titlListing">
      <div
        class="name"
        :class="{ mobile: isMobile, darkFont: this.$vuetify.theme.dark }"
        v-if="showGallery"
      >
        {{ $t("home_listing") }}
      </div>
      <img @click="toMarketplace" :src="rightIcon" v-if="showGallery" />
    </div>
    <Listing v-if="showGallery" />
    <div class="titlexplore">
      <div
        class="name"
        :class="{ mobile: isMobile, darkFont: this.$vuetify.theme.dark }"
        v-if="showGallery"
      >
        {{ $t("homeGalleryTitle") }}
      </div>
      <img @click="toCollect" :src="rightIcon" v-if="showGallery" />
    </div>
    <Gallery v-if="showGallery" />
   </div>

    <div class="right_bottom" v-if="!isMobile">
      <img
        v-if="!openmsg_group"
        src="@/assets/image/btn_service-message.png"
        @click="openServiceMessage"
        alt=""
      />
    </div>

    <div
      v-if="openmsg_group"
      style="position: fixed; right: 20px; bottom: 20px; z-index: 300"
    >
      <message-service
        @getOpen="OpenMsg_group"
        v-if="openmsg_group"
        :currfriend="currfriend_group"
      ></message-service>
    </div>
    <v-dialog v-model="openWelcome">
      <Welcome @getOpen="OpenCard"></Welcome>
    </v-dialog>
  </div>
</template>

<script>
import Product from "./Product.vue";
import FeaturedCreators from "./FeaturedCreators.vue";
import FeatureDrops from "./featureDrops.vue";

import Listing from "./Listing.vue";
import Gallery from "./Gallery.vue";
import TrandingCollection from "./trandingCollection.vue";
import rightIcon from "@/assets/icons/icon_r.png";
import MessageService from "@/components/popup/messageService.vue";
import Welcome from "@/components/popup/welcome.vue";

import api from "@/api";

export default {
  name: "App",
  components: {
    Product,
    FeaturedCreators,
    Gallery,
    Listing,
    TrandingCollection,
    MessageService,
    Welcome,
    FeatureDrops,
  },
  data: function () {
    return {
      openWelcome: false,
      rightIcon,
      topSku: {},
      creators: [],
      dropList: [],
      recommendList: [],
      address: "",
      price: "",
      isload: false,
      isShowLoading: false,
      showGallery: false,
      openmsg_group: false,
      currfriend_group: {},
      localUrl: "",
      isShow: true,
    };
  },

  methods: {
    gethost() {
      let url = window.location.href;

      var urlstr = url.split("/");
      var urls = "";
      if (urlstr[2]) {
        urls = urlstr[0] + "//" + urlstr[2];
      }
      return urls;
    },
    openServiceMessage() {
      console.log("openServiceMessage");
      this.currfriend_group.fansAddress =
        "0x91eB1DE0543a4AA41ac960E67c1ECF729AAEdf06";
      this.currfriend_group.fansName = "Uptick CS";
      this.openmsg_group = true;
    },
    OpenMsg_group(e) {
      this.openmsg_group = e;
    },
    toCreatorList() {
      this.$gtag.event("创作者推荐-查看更多", {
        event_category: "Click",
        event_label: "creator",
      });
      this.$router.push({ name: "Creatorlist" });
    },
    toMarketplace() {
      this.$router.push({ name: "Marketplace" });
    },
    toCollect() {
      this.$router.push({ name: "LaunchPad" });
    },
    scroll() {
      let top = document.documentElement.scrollTop || document.body.scrollTop;
      this.$store.commit("SET_HOME_SCROLLTOP", top);

      // console.log(223,this.$store.state.homeScrollTop);
      // sessionStorage.setItem("KEY_HOME_SCROLLTOP", top);
    },
    OpenCard(e) {
      this.openWelcome = e;
    },
  },
  computed: {
    isMobile: function () {
      return this.$vuetify.breakpoint.mobile;
    },
  },

  async mounted() {
    // 当前市场
    let currentChain = `${this.$env.VUE_APP_MARKET_TYPE}`;
    if (
      currentChain == "POLYGON" ||
      currentChain == "BSC" ||
      currentChain == "ARBITRUM"
    ) {
      this.isShow = false;
    } else {
      this.isShow = true;
    }
    // 判断是否展示cookies
    let isShowcookie = localStorage.getItem("acceptIndexCookie");
    if (isShowcookie) {
      this.isopenCookies = false;
    }

    this.localUrl = this.gethost();
    // let isInit = sessionStorage.getItem("initApplication");

    // if (!isInit) {
    //   if (this.localUrl != "https://uptick.upticknft.com") {
    //     sessionStorage.setItem("initApplication", true);
    //     if (this.isShow) this.openWelcome = true;
    //   }
    // }

    this.$store.state.isSourceDetail = false;
    this.isShowLoading = true;
    document.addEventListener("scroll", this.scroll);

    let params = {
      sortType: 1,
      authenticationAddress: this.$store.state.did,
    };

    // indexTopsku
    await api.home.getHomeNftListTopsku(params).then((res) => {
      this.topSku = res.data;
      this.address = res.data.address;
      this.price = res.data.minPrice;
      this.isload = true;
      if (this.topSku.length > 0) {
        console.log("rrrrr");
        this.isShowLoading = false;
      }
    });

    // Drop
    await api.home.DropsList(params).then((res) => {
      this.dropList = res.data;
      //   if(this.dropList &&this.dropList.length >0)
      // {
      //   console.log("rrrrr")
      //   this.isShowLoading = false;
      // }
    });

    //indexCreators
    await api.home.getHomeNftListCreators(params).then((res) => {
      this.creators = res.data;
      if (this.creators.length > 0) {
        console.log("rrrrr");
        this.isShowLoading = false;
      }
    });

    let collectParams = {
      pageNumber: 1,
      pageSize: 12,
      owner: this.$store.state.did,
    };

    let lists = await api.home.getRecommendList(collectParams);
    this.recommendList = lists.data.list;
    if (this.recommendList.length > 0) {
      this.isShowLoading = false;
    }
    this.showGallery = true;
  },
};
</script>

<style lang="scss" scoped>
.right_bottom {
  position: fixed;
  bottom: 220px;
  right: 50px;
  z-index: 99;

  img {
    display: block;
    width: 59px;
    height: 59px;
    margin-top: 35px;
  }
}
.titlefeature {

  min-width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  text-align: left;
  .name {
    width: 100%;
    font-family: Helvetica;
    font-size: 30px;
    font-weight: bold;
    font-stretch: normal;
    letter-spacing: 0px;
    color: #270645;
  }
}
.mobile .titlefeature{
  margin-top:770px !important
}
.title {
  margin-top: 100px;
  min-width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  text-align: left;

  .name {
    width: 100%;
    font-family: Helvetica;
    font-size: 30px;
    font-weight: bold;
    font-stretch: normal;
    letter-spacing: 0px;
    color: #270645;
  }

  img {
    display: inline-block;
    width: 41px;
    height: 41px;
    // margin-top: 40px;
  }
}

.titlListing {
  margin-top: 76px;
  min-width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  text-align: left;

  .name {
    width: 100%;
    font-family: Helvetica;
    font-size: 30px;
    font-weight: bold;
    font-stretch: normal;
    letter-spacing: 0px;
    color: #270645;
    margin-bottom: 34px;
  }

  img {
    display: inline-block;
    width: 41px;
    height: 41px;
    // margin-top: 40px;
  }
}
.titlexplore {
  margin-top: 50px;
  min-width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  text-align: left;

  .name {
    width: 100%;
    font-family: Helvetica;
    font-size: 30px;
    font-weight: bold;
    font-stretch: normal;
    letter-spacing: 0px;
    color: #270645;
    margin-bottom: 34px;
  }

  img {
    display: inline-block;
    width: 41px;
    height: 41px;
    // margin-top: 40px;
  }
}
.contantNFT {
  margin-top: 700px !important;
}
.titleCenter {
  margin-top: 80px;
  min-width: 100%;
  text-align: left;
  .name {
    margin-bottom: 34px;
    font-family: Helvetica;
    font-size: 30px;
    font-weight: bold;
    font-stretch: normal;
    letter-spacing: 0px;
    color: #270645;
  }
}

.root-home {
  ::v-deep.card-box .v-card.rd {
    @media screen and (max-width: #{map-get($grid-breakpoints, 'lg')}) {
      width: 100% !important;
    }
  }
}
.mobile {
  margin-bottom: 30px !important;
}
</style>
