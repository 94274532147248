<template>
  <div>
    <v-menu :attach="$el" right offset-y nudge-bottom="10px">
      <template v-slot:activator="{ on, attrs, value }">
        <Chip v-bind="attrs" v-on="on" v-if="show ">
          <span style="color:rgba(255,255,255)">{{ name }}</span>
          <v-icon class="ml-2" color="rgba(255,255,255)" v-if="value">mdi-menu-up</v-icon>
          <v-icon class="ml-2" color="rgba(255,255,255)" v-else>mdi-menu-down</v-icon>
        </Chip>
         <Chip v-bind="attrs" v-on="on" v-else>
          <span  >{{ name }}</span>
          <v-icon class="ml-2" color="primary" v-if="value">mdi-menu-up</v-icon>
          <v-icon class="ml-2" color="primary" v-else>mdi-menu-down</v-icon>
        </Chip>
      </template>
      <List :list="lang" v-model="currentLang">
        <template v-slot:default="{ label }"  >
         <div  :class="{darkFont:$vuetify.theme.dark}">{{ label }}</div> 
        </template>
      </List>
    </v-menu>
  </div>
</template>

<script lang="js">
import Chip from '@/components/Chip.vue';
import List from '@/components/List.vue';

// [语言包列表](https://vuetifyjs.com/zh-Hans/features/internationalization/#section-5feb901f516595e8)
const lang = [
  { label: 'English', value: 'en' },
  { label: '中文', value: 'zh' },
  { label: '日本語', value: 'ja' },
  { label: '코리아', value: 'ko' },
  // { label: 'Português', value: 'pt' },
];

export default {
  name: 'LangSelect',
  components: { Chip, List },
  props: {
      value: String,
      isDrak:{
      type:Boolean
    }
  },
  data: function() {
    return {
        lang,
        currentLang: localStorage.getItem('KEY_LANG') || 'en',
         activeDark: this.isDrak,
    };
  },
  
  computed: {
    name: function() {
      const current = this.lang.find(v => v.value === this.currentLang);
      return current ? current.label : '';
    },
       show: function () {
        return this.$route.name === 'EditSpotlight' || this.$route.name === 'Spotlight'
     },
  },

  
  watch: {
    currentLang: {
      handler: function(v) {
        this.setLanguage(v);
      },
      immediate: true,
    }
  },
  mounted(){
    // 判断是否为黑暗模式
        let isDarkMode =  localStorage.getItem('isDarkMode');
        if(isDarkMode){
             if(isDarkMode == 'true'){
              this.activeDark = true
           }else{
             this.activeDark = false 
           } 
        }else{
            this.activeDark = false
        }

     window.eventBus.$on("DarkModeChange", this.DarkModeChange);
  },
  methods:{
     DarkModeChange(value){
        this.activeDark = value

    },
      setLanguage(language) {
        document.documentElement.lang = language;
        localStorage.setItem("KEY_LANG", language);
        this.$i18n.locale = language;
        this.$vuetify.lang.current = language;
        window.eventBus.$emit("LangChange", language);
      },
  },
};
</script>

<style lang="scss" scoped>
.v-menu__content{
  position:fixed !important;
  top: 75px !important;
}</style>
