import axios from 'axios'

// create an axios instance
const service = axios.create({
  // baseURL: process.env.VUE_APP_BASE_API, // url = base url + request url
  // baseURL: 'http://192.168.31.229:5090/',
  baseURL: '/twitter',
  // withCredentials: true, // send cookies when cross-domain requests
  timeout: 60000 ,// request timeoutd

})



export default service