import Vue from "vue";

import {
    getMyBalance as uptickevm_getMyBalance,
    transfer as uptickevm_transfer,
    transferFrom as uptickevm_transferFrom,
    onSale as uptickevm_onSale,
    onSaleBatch as uptickevm_onSaleBatch,
    couponOnSale as uptickevm_couponOnSale,
    offSale as uptickevm_offSale,
    offSaleBatch as uptickevm_offSaleBatch,
    revokeApprovesWithArray as uptickevm_revokeApprovesWithArray,
    orderPay as uptickevm_orderPay,
    getAccountInfo as uptickevm_getAccountInfo,
    getUptickAddress as uptickevm_getUptickAddress,
    setContractAddress as uptickevm_setContractAddress,
    mintNft as uptickevm_mintNft,
    placeOrder as uptickevm_placeOrder,
    auction_onsale as uptickevm_auctionOnsale,
    auction_placeBid as uptickevm_placeBid,
    auction_end as uptickevm_end,
    getTokenBalance as uptickevm_getTokenBalance,
    createOffer as uptickevm_createOffer,
    cancelOffer as uptickevm_cancelOffer,
    acceptOffer as uptickevm_acceptOffer,
    lazyNftMint as lazyNftMint,
    uptickToPolygon as uptickevm_uptickToPolygon,
    getFeeByChainID as uptickevm_getFee,
    setBridgeApproval as uptickevm_setBridgeApproval

} from '@/metaMask/evm/index';


import chain_iris_w from "@/assets/icons/chain_iris_w.png";
import chain_uptick_w from "@/assets/icons/chain_uptick_w.png";

let chainName = "UPTICK EVM";

export function wallet() {
    let wt = {};

    if(chainName == "UPTICK EVM") {
        wt = {
            getMyBalance: uptickevm_getMyBalance,
            transfer: uptickevm_transfer,
            transferFrom: uptickevm_transferFrom,
            onSale: uptickevm_onSale,
            onSaleBatch:uptickevm_onSaleBatch,
            couponOnSale:uptickevm_couponOnSale,
            offSale : uptickevm_offSale,
            offSaleBatch:uptickevm_offSaleBatch,
            revokeApprovesWithArray: uptickevm_revokeApprovesWithArray,
            orderPay: uptickevm_orderPay,
            getAccountInfo: uptickevm_getAccountInfo,
            getUptickAddress: uptickevm_getUptickAddress,
            setContractAddress: uptickevm_setContractAddress,
            mintNft:uptickevm_mintNft,
            placeOrder:uptickevm_placeOrder,
            auction_onsale:uptickevm_auctionOnsale,
            auction_placeBid:uptickevm_placeBid,
            auction_end: uptickevm_end,
            getTokenBalance: uptickevm_getTokenBalance,
            createOffer :uptickevm_createOffer,
            cancelOffer : uptickevm_cancelOffer,
            acceptOffer : uptickevm_acceptOffer,
            lazyNftMint : lazyNftMint,
            uptickToPolygon : uptickevm_uptickToPolygon,
            getFeeByChainID:uptickevm_getFee,
            setBridgeApproval: uptickevm_setBridgeApproval
        };
    }
    return wt;
};

export function init(chain) {
    if(chain) chainName = chain;
    Vue.prototype.$chainName = chain;
    
    let unit = chain=="IRIS" ? "IRIS":
               chain=="UPTICK" ? "UPTICK":
               chain=="UPTICK EVM" ? "UPTICK":
               "";
    Vue.prototype.$walletUnit = unit;

    let img = chain=="IRIS" ? chain_iris_w:
              chain=="UPTICK" ? chain_uptick_w:
              chain=="UPTICK EVM" ? chain_uptick_w:
              "";
    Vue.prototype.$walletIcon = img;

    Vue.prototype.$wallet = wallet(chain);
    Vue.filter("$unitFormat", function(val) {
        return val.replaceAll("$UNIT", unit);
    });
}
