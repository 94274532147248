<template>
    <v-list  nav dense :light="false">
      <v-list-item-group v-model="selectValue">
        <v-list-item
          v-for="(item, i) in list"
          :key="i"
           @click="itemclick(item.value)"
        >
          <img :src="item.icon" width="25" height="25" class="mr-3" alt="">
          <v-list-item-content>
            <v-list-item-title v-text="item.label"></v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list-item-group>
    </v-list>
</template>

<script>
export default {
  name: "List",
  inheritAttrs: false,
  props: {
    list: {
      type: Array,
      defualt: () => [],
    },
    value: [String, Number],
      isDrak:{
      type:Boolean
    }
  },
  data:function(){
    return{
      selectValue:2
    }
  },
  mounted(){
    // 当前市场
   let currentChain = `${this.$env.VUE_APP_MARKET_TYPE}`
      if(currentChain == 'POLYGON'){
        this.selectValue =2
      }else if(currentChain == 'BSC')
      {
         this.selectValue =3
      }else if(currentChain == 'ARBITRUM')
      {
         this.selectValue =4
      }
      else
      {
        this.selectValue =0
      }

  },
  methods: {
    gethost(){
      let url = window.location.href 
 
     var urlstr = url.split("/"); 
    var urls = '';
    if (urlstr[2]) {
        urls = urlstr[0]+'//'+urlstr[2];
    }
      return urls
    },
    itemclick(v) {
        if(v == 'IRISnet'){
           this.$gtag.event('导航市场切换-IRISnet', { 'event_category': 'Click', 'event_label': 'IRISnet' })
          window.open('https://irisnet.upticknft.com', "_blank");
        }else if(v == 'Polygon'){
            this.$gtag.event('导航市场切换-Polygon', { 'event_category': 'Click', 'event_label': 'Polygon' })
             window.open('https://polygon.upticknft.com', "_blank");
        }else if(v == 'BSC'){
            this.$gtag.event('导航市场切换-BSC', { 'event_category': 'Click', 'event_label': 'BSC' })
            window.open(' https://bsc.upticknft.com', "_blank");
        }else if(v == 'Arbitrum'){
            this.$gtag.event('导航市场切换-Arbitrum', { 'event_category': 'Click', 'event_label': 'Arbitrum' })
            window.open('https://arbitrum.upticknft.com/', "_blank");
        }else if(v == 'Loopring'){
            this.$gtag.event('导航市场切换-Loopring', { 'event_category': 'Click', 'event_label': 'Loopring' })
            window.open(' https://loopring.upticknft.com', "_blank");
        }
        else{
          if( this.gethost() != 'https://uptick.upticknft.com'){
             window.open('https://uptick.upticknft.com', "_blank");
          }
        }
        debugger
      },
  },
};
</script>

<style lang="scss" scoped>
.border{
    // background: #7800f4;
  }



</style>
